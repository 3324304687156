import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useMember, mappingRow } from '@/common/models/member/useMember';
import * as errorHandler from '@/common/utils/errorHandler';
import { useAnalytics } from '@/common/models/analytics/useAnalytics';
import * as schema from '@/bundles/schema/typescript/schema';
import { Variants } from '@/common/components/messages/CommonMessage';
import locale from '@/common/utils/locale';
import { DateFormat } from '@/common/constants/dateFormat';
import usePreventWindowUnload from '@/common/components/hooks/usePreventWindowUnload';

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

/**
 * @description 利用状況ログのダウンロード期間は最大366日間である
 */
export const isDisableAnalyticsDownload = (startDate: Date, endDate: Date) => {
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
        return true;
    }
    const diff = dayjs(endDate).diff(dayjs(startDate), 'day');
    return diff >= 366;
};

/**
 * @description 初期ダウンロード日付
 */
export const initialDownloadDate = (now: Date) => {
    const lastWeek = dayjs(now)
        .add(-1, 'week')
        .startOf('day');

    const lastMonday = lastWeek.startOf('isoWeek').startOf('day');
    const lastSunday = lastWeek.endOf('isoWeek').startOf('day');

    return {
        startDate: lastMonday.toDate(),
        endDate: lastSunday.toDate(),
    };
};

const useAnalyticsContainer = () => {
    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI(UI.Loaded);
    const member = useMember();
    const analytics = useAnalytics();
    const [isEdit, setIsEdit] = useState(false); // フォーム編集後における画面離脱時の警告メッセージを表示するかどうかのフラグ

    usePreventWindowUnload(isEdit); // 画面リロード時の警告メッセージ

    useEffect(() => {
        (async () => {
            try {
                ui.update(UI.Loading);

                // アナリティクスの通知設定を取得
                const notificationConfig = await analytics.showNotificationConfig(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                // サインアップ済みのアクティブな管理者のみの表示
                const mappingedRow = mappingRow(
                    notificationConfig.targetUsers.map((targetUser) => {
                        return {
                            active: targetUser.user.active,
                            analyticsReportTarget: targetUser.analyticsReport,
                            avatarUrl: targetUser.user.avatarUrl,
                            contactEmail: targetUser.user.contactEmail,
                            id: targetUser.user.id,
                            invitationEmail: targetUser.user.invitationEmail,
                            language: targetUser.user.language,
                            name: targetUser.user.name,
                            phoneticName: targetUser.user.phoneticName,
                            role: targetUser.user.role,
                        };
                    }),
                );
                member.setMemberList({
                    rows: mappingedRow,
                    memberTotal: mappingedRow.length,
                });
                // 選択済みのユーザーを設定
                const selected = notificationConfig.targetUsers.filter((targetUser) => targetUser.analyticsReport).map((targetUser) => targetUser.user.id);
                member.setForm({
                    ...member.form,
                    selectedMemberIdList: selected,
                });

                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toStartDateISOString = (given: Date): string => {
        const startDate = dayjs(given).toISOString();
        return startDate;
    };

    const toEndDateISOString = (given: Date): string => {
        const endDate = dayjs(given)
            .add(1, 'days')
            .toISOString();
        return endDate;
    };

    const updateNotificationConfig = async () => {
        try {
            ui.update(UI.Saving);
            if (analytics.notificationConfig) {
                const targetUsers: schema.V1ObjectsWorkspacesNotificationTargetUser[] = member.memberList.rows.map((member) => {
                    return {
                        analyticsReport: member.isChecked,
                        user: {
                            active: member.active,
                            avatarUrl: member.avatarUrl,
                            contactEmail: member.contactEmail,
                            id: member.objId,
                            invitationEmail: member.email,
                            language: member.language,
                            name: member.name,
                            phoneticName: '',
                            role: member.role[0],
                        },
                    };
                });
                const req = {
                    analyticsReport: analytics.isNotification,
                    targetUsers: targetUsers,
                };
                await analytics.updateNotificationConfig(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode, req);
                ui.update(UI.Loaded);
                setIsEdit(false);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.saved),
                    variant: Variants.success,
                });
                return;
            }
            throw new Error('notificationConfig is not found');
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @param startDate 開始日
     * @param endDate 終了日
     * @description アナリティクスCSVのダウンロード
     */
    const downloadAnalyticsCSV = async (startDate: Date, endDate: Date) => {
        ui.update(UI.Saving);
        try {
            const req = {
                analyticsType: schema.AnalyticsType.Transfer,
                dataStart: toStartDateISOString(startDate),
                dataEnd: toEndDateISOString(endDate),
            };
            const createAnalyticsResult = await analytics.createAnalytics(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode, req);

            const res = await analytics.showAnalytics(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode, createAnalyticsResult.id);

            const url = window.URL.createObjectURL(res);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                locale.t(locale.keys.analytics.usageLog.usageLogCsvName, {
                    startDate: dayjs(startDate).format(DateFormat.fullDateWithNoneSeparate),
                    endDate: dayjs(endDate).format(DateFormat.fullDateWithNoneSeparate),
                }),
            );
            document.body.appendChild(link);
            link.click();

            ui.update(UI.Loaded);
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @description アナリティクスの通知設定のハンドラ
     */
    const handleIsNotificationReport = () => {
        analytics.setIsNotification(!analytics.isNotification);
        setIsEdit(true);
    };

    /**
     * @param isAllSelect 全選択かどうか
     * @param memberId メンバーID(isAllSelectがfalseの場合のみ必要)
     * @description アナリティクスの通知設定対象のメンバー選択のチェックボックスハンドラ
     */
    const handleSelectMember = (isAllSelect: boolean, memberId?: string) => {
        if (isAllSelect) {
            member.onSelectAllMember();
        } else {
            member.onSelectMember(memberId!);
        }
        setIsEdit(true);
    };

    return {
        ui,
        isEdit,
        toStartDateISOString,
        toEndDateISOString,
        member,
        analytics,
        updateNotificationConfig,
        downloadAnalyticsCSV,
        handleIsNotificationReport,
        handleSelectMember,
    };
};
export const AnalyticsContainer = createContainer(useAnalyticsContainer);
