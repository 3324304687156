import { useEffect } from 'react';

// Borrowed from react-use library.
// https://github.com/streamich/react-use/blob/master/src/useTitle.ts
const useTitle = (title: string) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
};

export default useTitle;
