import { default as React, useEffect } from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid, Fab } from '@material-ui/core';
import useTitle from '@/common/components/hooks/useTitle';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { AnalyticsContainer } from '@/admin/components/analytics/AnalyticsContainer';
import { State as UI } from '@/common/components/hooks/useUI';
import Error from '@/common/components/state/Error';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import locale from '@/common/utils/locale';
import ReportExport from './ReportExport';
import { Prompt } from 'react-router-dom';

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
    skipEffect?: boolean;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const container = AnalyticsContainer.useContainer();

    useTitle(locale.t(locale.keys.analytics.index));

    useEffect(() => {
        appContainer.updateLoadingState(container.ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.ui]);

    if (container.ui.current === UI.Loading || container.ui.current === UI.Error) {
        return (
            <>
                <SubHeader title={locale.t(locale.keys.analytics.index)} />

                {container.ui.current === UI.Loading && <></>}

                {container.ui.current === UI.Error && (
                    <div data-testid={UI.Error}>
                        <Error />
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            <SubHeader
                title={locale.t(locale.keys.analytics.index)}
                Fab={
                    <Fab
                        variant="extended"
                        onClick={() => {
                            container.updateNotificationConfig();
                        }}
                        size="large"
                        color="secondary"
                        style={{ color: 'white', top: '-23px', height: 44 }}
                    >
                        {locale.t(locale.keys.analytics.save)}
                    </Fab>
                }
            />
            <Grid container style={{ marginInline: '22px', marginBlockStart: '8px', gap: '16px', width: 'auto' }}>
                <ReportExport />
            </Grid>
            <Prompt when={container.isEdit} message={locale.t(locale.keys.promptMessage)} />
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
