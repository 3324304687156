import { default as React } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import locale from '@/common/utils/locale';

const styles = (theme: Theme) =>
    createStyles({
        select: {
            [theme.breakpoints.up('md')]: {
                maxWidth: '100%',
                minWidth: '100%',
            },
            [theme.breakpoints.down('md')]: {
                textAlign: 'center',
                maxWidth: '400px',
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: '400px',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '250px',
            },
            maxWidth: '100%',
            minWidth: '100%',
            marginBottom: 20,
        },
        formControl: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        selectInput: {
            padding: '28px 12px 9px 12px',
        },
    });
interface Props extends WithStyles<typeof styles> {
    value: string;
    label: string;
    handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleValidate: () => string;
    disabled: boolean;
}
export const LanguageSelect: React.FC<Props> = (props) => {
    const { classes } = props;
    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel htmlFor="lang-select">{props.label}</InputLabel>
            <Select
                className={classes.select}
                native
                value={props.value}
                variant="filled"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => props.handleChange(event)}
                error={props.handleValidate() !== ''}
                input={<FilledInput name="lang" id="lang-select" classes={{ input: classes.selectInput }} />}
                inputProps={{
                    name: 'lang',
                    id: 'lang',
                    style: { height: '1.46em', lineHeight: 'normal' },
                }}
                disabled={props.disabled}
            >
                <option value={locale.Language.Japanese}>{locale.t(locale.keys.common.language.japanese)}</option>
                <option value={locale.Language.English}>{locale.t(locale.keys.common.language.english)}</option>
            </Select>
            {props.handleValidate() !== '' && <FormHelperText error={true}>{props.handleValidate()}</FormHelperText>}
        </FormControl>
    );
};

export default withStyles(styles)(LanguageSelect);
