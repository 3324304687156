import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import SubWorkspaces from './SubWorkspaces';
import { SubWorkspacesContainer } from './SubWorkspacesContainer';
import locale from '@/common/utils/locale';

interface Props {}

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.subWorkspaceManagement.index));

    return (
        <>
            <SubWorkspacesContainer.Provider>
                <SubWorkspaces />
            </SubWorkspacesContainer.Provider>
        </>
    );
};

export default withStyles(styles)(Component);
