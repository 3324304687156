import * as React from 'react';
import Button from '@material-ui/core/Button';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import CovasAvatar, { CovasGroupAvatar } from '@/common/components/CovasAvatar';
import { ImageFileSelectorContainer } from '@/common/components/file/ImageFileSelectorContainer';
import { StateContainer } from '@/common/components/AppContainerBase';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

const styles = (theme: Theme) =>
    createStyles({
        dropzone: {
            color: 'white',
            background: 'gray',
            margin: '0 auto 24px',
        },
        dropzoneText: {
            margin: '0 auto 24px',
        },
        button: {
            color: 'white',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '110px',
                minWidth: '110px',
            },
        },
        image: {
            margin: `0 auto ${theme.spacing.unit * 3}px`,
            border: `solid 3px`,
            color: `${theme.palette.secondary.main}`,
        },
    });

export interface ImageFileSelectorProps extends WithStyles<typeof styles> {
    // アバター: email
    // ワークスペース: ワークスペースid
    theme?: Theme;
    seed?: any;
    defaultUrl: string;
    logoDataUri?: string;
    uploadWidth: number;
    uploadHeight: number;
    mobileHeight: number;
    mobileWidth: number;
    pcHeight: number;
    pcWidth: number;
    isAvatar: boolean;
    imageClassName?: string;
    editable: boolean;
    onLoaded(datauri: string): void;
    dependAppContainer: () => StateContainer;
    isGroup?: boolean;
}

export enum UI {
    Default,
    Selector,
    Crop,
    CropCancel,
    Uploading,
    Error,
}

// PC判定のコンポーネント
const FileSelect: React.FC<ImageFileSelectorProps> = (props) => {
    const { classes } = props;
    const appContainer = props.dependAppContainer();
    const imagefileContainer = ImageFileSelectorContainer.useContainer();
    const onStart = () => {
        if (!props.editable) {
            return;
        }
        imagefileContainer.onStart(() => {
            appContainer.setValues({
                ...appContainer.values,
                cropAndDropProps: {
                    ...appContainer.values.cropAndDropProps,
                    ui: UI.Selector,
                    visibility: 'show',
                    onDropAction: onDrop,
                    onDropCancelAction: onDropCancel,
                    onLoadAction: onLoad,
                    onCropCancelAction: onCropCancel,
                },
            });
        });
    };
    const onDrop = (accepts: File[]) => {
        imagefileContainer.onDrop(
            accepts,
            (reader: FileReader) => {
                appContainer.setValues({
                    ...appContainer.values,
                    cropAndDropProps: {
                        ...appContainer.values.cropAndDropProps,
                        ui: UI.Crop,
                        visibility: 'show',
                        datauri: reader.result as string,
                        onDropAction: onDrop,
                        onDropCancelAction: onDropCancel,
                        onLoadAction: onLoad,
                        onCropCancelAction: onCropCancel,
                    },
                });
            },
            () => {
                appContainer.setValues({
                    ...appContainer.values,
                    cropAndDropProps: {
                        ...appContainer.values.cropAndDropProps,
                        ui: UI.Error,
                        visibility: 'hidden',
                        onDropAction: onDrop,
                        onDropCancelAction: onDropCancel,
                        onLoadAction: onLoad,
                        onCropCancelAction: onCropCancel,
                    },
                });
            },
        );
    };
    const onLoad = (cropperRef: React.MutableRefObject<Cropper | null>) => {
        imagefileContainer.onLoad(
            cropperRef,
            props,
            () => {
                appContainer.setValues({
                    ...appContainer.values,
                    cropAndDropProps: {
                        ...appContainer.values.cropAndDropProps,
                        ui: UI.Uploading,
                        visibility: 'show',
                        onDropAction: onDrop,
                        onDropCancelAction: onDropCancel,
                        onLoadAction: onLoad,
                        onCropCancelAction: onCropCancel,
                    },
                });
            },
            (datauri: string) => {
                appContainer.setValues({
                    ...appContainer.values,
                    cropAndDropProps: {
                        ...appContainer.values.cropAndDropProps,
                        ui: UI.Default,
                        datauri: `${datauri}`,
                        visibility: 'hidden',
                        onDropAction: onDrop,
                        onDropCancelAction: onDropCancel,
                        onLoadAction: onLoad,
                        onCropCancelAction: onCropCancel,
                    },
                });
            },
        );
    };
    const onDropCancel = () => {
        imagefileContainer.onDropCancel(() => {
            appContainer.setValues({
                ...appContainer.values,
                cropAndDropProps: {
                    ...appContainer.values.cropAndDropProps,
                    ui: UI.Default,
                    visibility: 'hidden',
                    onDropAction: onDrop,
                    onDropCancelAction: onDropCancel,
                    onLoadAction: onLoad,
                    onCropCancelAction: onCropCancel,
                },
            });
        });
    };
    const onCropCancel = () => {
        imagefileContainer.onCropCancel(() => {
            appContainer.setValues({
                ...appContainer.values,
                cropAndDropProps: {
                    ...appContainer.values.cropAndDropProps,
                    ui: UI.Default,
                    visibility: 'hidden',
                    onDropAction: onDrop,
                    onDropCancelAction: onDropCancel,
                    onLoadAction: onLoad,
                    onCropCancelAction: onCropCancel,
                },
            });
        });
    };

    if (props.isGroup) {
        return (
            <>
                {props.isAvatar && props.defaultUrl && (!imagefileContainer.state.datauri && !props.logoDataUri) ? (
                    <CovasGroupAvatar
                        onClick={onStart}
                        size={props.pcWidth > props.pcHeight ? props.pcHeight : props.pcWidth}
                        style={{
                            width: props.pcWidth,
                            height: props.pcHeight,
                        }}
                        src={props.defaultUrl}
                        className={props.imageClassName || classes.image}
                    />
                ) : (
                    <CovasGroupAvatar
                        onClick={onStart}
                        size={props.pcWidth > props.pcHeight ? props.pcHeight : props.pcWidth}
                        style={{
                            width: props.pcWidth,
                            height: props.pcHeight,
                        }}
                        src={imagefileContainer.state.datauri || props.logoDataUri}
                        className={props.imageClassName || classes.image}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                {props.isAvatar && props.defaultUrl && (!imagefileContainer.state.datauri && !props.logoDataUri) ? (
                    <CovasAvatar
                        onClick={onStart}
                        size={props.pcWidth > props.pcHeight ? props.pcHeight : props.pcWidth}
                        seed={props.seed}
                        editable={props.editable}
                        style={{
                            width: props.pcWidth,
                            height: props.pcHeight,
                        }}
                        src={props.defaultUrl}
                        className={props.imageClassName || classes.image}
                    />
                ) : (
                    <CovasAvatar
                        onClick={onStart}
                        size={props.pcWidth > props.pcHeight ? props.pcHeight : props.pcWidth}
                        seed={props.seed}
                        editable={props.editable}
                        style={{
                            width: props.pcWidth,
                            height: props.pcHeight,
                        }}
                        src={imagefileContainer.state.datauri || props.logoDataUri}
                        className={props.imageClassName || classes.image}
                    />
                )}
            </>
        );
    }
};

// モバイル判定のコンポーネント
const FileSelectMobile: React.FC<ImageFileSelectorProps> = (props) => {
    const { classes } = props;
    const imagefileContainer = ImageFileSelectorContainer.useContainer();
    const inputFile = React.useRef<HTMLInputElement>(null);
    const appContainer = props.dependAppContainer();
    const onStart = () => {
        if (!props.editable) {
            return;
        }
        if (inputFile != null && inputFile.current != null) {
            inputFile.current.value = '';
            inputFile.current!.click();
        }
    };
    const onCropCancel = () => {
        imagefileContainer.onCropCancel(() => {
            appContainer.setValues({
                ...appContainer.values,
                cropAndDropProps: {
                    ...appContainer.values.cropAndDropProps,
                    ui: UI.Default,
                    visibility: 'hidden',
                    onLoadAction: onLoad,
                    onCropCancelAction: onCropCancel,
                },
            });
        });
    };
    const onLoad = (cropperRef: React.MutableRefObject<Cropper | null>) => {
        imagefileContainer.onLoad(
            cropperRef,
            props,
            () => {
                appContainer.setValues({
                    ...appContainer.values,
                    cropAndDropProps: {
                        ...appContainer.values.cropAndDropProps,
                        ui: UI.Uploading,
                        visibility: 'show',
                        onLoadAction: onLoad,
                        onCropCancelAction: onCropCancel,
                    },
                });
            },
            (datauri: string) => {
                appContainer.setValues({
                    ...appContainer.values,
                    cropAndDropProps: {
                        ...appContainer.values.cropAndDropProps,
                        ui: UI.Default,
                        datauri: `${datauri}`,
                        visibility: 'hidden',
                        onLoadAction: onLoad,
                        onCropCancelAction: onCropCancel,
                    },
                });
            },
        );
    };
    const onDrop = (accepts: File) => {
        imagefileContainer.onDrop(
            [accepts],
            (reader: FileReader) => {
                appContainer.setValues({
                    ...appContainer.values,
                    cropAndDropProps: {
                        ...appContainer.values.cropAndDropProps,
                        ui: UI.Crop,
                        visibility: 'show',
                        datauri: reader.result as string,
                        onLoadAction: onLoad,
                        onCropCancelAction: onCropCancel,
                    },
                });
            },
            () => {
                appContainer.setValues({
                    ...appContainer.values,
                    cropAndDropProps: {
                        ...appContainer.values.cropAndDropProps,
                        ui: UI.Error,
                        visibility: 'hidden',
                        onLoadAction: onLoad,
                        onCropCancelAction: onCropCancel,
                    },
                });
            },
        );
    };

    if (props.isGroup) {
        return (
            <>
                <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onChange={(e) => onDrop(e.target.files![0])} />
                {props.isAvatar && props.defaultUrl && (!imagefileContainer.state.datauri && !props.logoDataUri) ? (
                    <CovasGroupAvatar
                        onClick={() => onStart()}
                        size={props.mobileWidth > props.mobileHeight ? props.mobileHeight : props.mobileHeight}
                        style={{
                            width: props.mobileWidth,
                            height: props.mobileHeight,
                        }}
                        src={props.defaultUrl}
                        className={props.imageClassName || classes.image}
                    />
                ) : (
                    <CovasGroupAvatar
                        onClick={() => onStart()}
                        size={props.mobileWidth > props.mobileHeight ? props.mobileHeight : props.mobileHeight}
                        style={{
                            width: props.mobileWidth,
                            height: props.mobileHeight,
                        }}
                        src={imagefileContainer.state.datauri || props.logoDataUri}
                        className={props.imageClassName || classes.image}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onChange={(e) => onDrop(e.target.files![0])} />
                {props.isAvatar && props.defaultUrl && (!imagefileContainer.state.datauri && !props.logoDataUri) ? (
                    <CovasAvatar
                        onClick={() => onStart()}
                        size={props.mobileWidth > props.mobileHeight ? props.mobileHeight : props.mobileHeight}
                        seed={props.seed}
                        editable={props.editable}
                        style={{
                            width: props.mobileWidth,
                            height: props.mobileHeight,
                        }}
                        src={props.defaultUrl}
                        className={props.imageClassName || classes.image}
                    />
                ) : (
                    <CovasAvatar
                        onClick={() => onStart()}
                        size={props.mobileWidth > props.mobileHeight ? props.mobileHeight : props.mobileHeight}
                        seed={props.seed}
                        editable={props.editable}
                        style={{
                            width: props.mobileWidth,
                            height: props.mobileHeight,
                        }}
                        src={imagefileContainer.state.datauri || props.logoDataUri}
                        className={props.imageClassName || classes.image}
                    />
                )}
            </>
        );
    }
};

// モバイル or PC 判定
export const FileSelectAvatar: React.FC<ImageFileSelectorProps> = (props) => {
    const { theme } = props;
    const matchesBreakPoint = useMediaQuery(theme!.breakpoints.down('md'));
    const imagefileContainer = ImageFileSelectorContainer.useContainer();
    if (imagefileContainer.state.ui === UI.Error) {
        return (
            <section className="uploader">
                <div className="notification is-warning">
                    {locale.t(locale.keys.error.uploadFailure)}
                    <br />
                    {imagefileContainer.state.errorMessage ? imagefileContainer.state.errorMessage : null}
                </div>

                <Button variant="outlined" className="button is-text" size="large" color="secondary" onClick={() => imagefileContainer.setState({ ...imagefileContainer.state, ui: UI.Selector })}>
                    {locale.t(locale.keys.action.tryAgain)}
                </Button>
            </section>
        );
    }
    return (
        <section className="uploader">
            {matchesBreakPoint ? <FileSelectMobile {...props} /> : <FileSelect {...props} />}
            <br />
        </section>
    );
};

// コンテナProvider兼公開用component
export const ImageFileSelector: React.FC<ImageFileSelectorProps> = (props) => {
    return (
        <ImageFileSelectorContainer.Provider>
            <FileSelectAvatar {...props} />
        </ImageFileSelectorContainer.Provider>
    );
};

export default withStyles(styles, { withTheme: true })(ImageFileSelector);
