import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Theme, createStyles, withStyles, WithStyles, ListItem, Divider, Typography, Grid } from '@material-ui/core';
import { ExitToApp, Help, Info } from '@material-ui/icons';
import { showStringWithLimitByte } from '@/common/utils/webappUtil';
import * as locale from '@/common/utils/locale/locale';
import CovasAvatar from '@/common/components/CovasAvatar';
import { isAgent } from '@/common/utils/authManagement/agentSignIn';
import environment, * as environmentAll from '@/common/constants/environment';
import publics from '@/common/constants/environment';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case 'ricoh':
            return '#FFE6E6';
        default:
            return '#d1dde3';
    }
};

const styles = (theme: Theme) =>
    createStyles({
        avatarGrid: {
            display: 'flex',
            alignItems: 'center',
            top: '50%',
        },
        userNameGrid: {
            display: 'flex',
            alignItems: 'center',
            top: '50%',
            lineHeight: 'normal',
            wordBreak: 'break-all',
        },
        userName: {
            fontSize: '15px',
        },
        nickName: {
            fontSize: '10px',
        },
        avatar: {
            marginTop: '30px',
            marginBottom: '30px',
        },
    });

const CustomListItem = withStyles({
    root: {
        '&$selected': {
            backgroundColor: selectColor(publics.tenant),
        },
    },
    selected: {},
})(ListItem);

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    isAdminApp: boolean;
    signoutPath: string;
    name: string;
    phoneticName: string;
    email: string;
    avatarUrl: string;
    commonMenuCloseFunc: () => void;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    const handClose = () => {
        props.commonMenuCloseFunc();
    };

    return (
        <>
            <CustomListItem style={{ height: 'auto' }} selected={false}>
                <Grid container>
                    <Grid item xs={5} className={classes.avatarGrid}>
                        <CovasAvatar size={80} seed={props.email} src={props.avatarUrl} className={classes.avatar} />
                    </Grid>
                    <Grid item xs={7} className={classes.userNameGrid}>
                        <div>
                            <Typography className={classes.userName}>{showStringWithLimitByte(props.name, 80)}</Typography>
                            <Typography className={classes.nickName}>{showStringWithLimitByte(props.phoneticName, 80)}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </CustomListItem>
            {/* 代理ログインの場合はサインアウトできないようにする*/}
            {!isAgent() && (
                <CustomListItem onClick={handClose} style={{ height: 'auto' }}>
                    <Link
                        style={{
                            lineHeight: 'normal',
                            wordBreak: 'break-all',
                        }}
                        to={props.signoutPath}
                    >
                        <ExitToApp viewBox="-3 -3 24 24" style={{ marginRight: '8px', width: '18px', height: '18px' }} />
                        {locale.t(locale.keys.pageTitle.signOut.index)}
                    </Link>
                </CustomListItem>
            )}

            <Divider />
            {/* 管理者サイトならお知らせサイトをメニューリストに追加 */}
            {props.isAdminApp ? (
                <CustomListItem onClick={handClose} selected={false}>
                    <a target="covas_information" href={environment.webapp.admin.supportUrl}>
                        <Info viewBox="-3 -3 25 25" style={{ marginRight: '8px', width: '16.6px', height: '16.6px' }} />
                        {locale.t(locale.keys.pageTitle.supporturl.common.index)}
                    </a>
                </CustomListItem>
            ) : null}
            <CustomListItem onClick={handClose} selected={false}>
                {props.isAdminApp ? (
                    <a target="admin_manual" href={environment.webapp.admin.url}>
                        <Help viewBox="-3 -3 25 25" style={{ marginRight: '8px', width: '16.6px', height: '16.6px' }} />
                        {locale.t(locale.keys.pageTitle.manual.admin.index)}
                    </a>
                ) : (
                    <a target="app_manual" href={environment.webapp.user.url}>
                        <Help viewBox="-3 -3 25 25" style={{ marginRight: '8px', width: '16.6px', height: '16.6px' }} />
                        {locale.t(locale.keys.pageTitle.manual.app.index)}
                    </a>
                )}
            </CustomListItem>
            <CustomListItem onClick={handClose} selected={false}>
                <a target="admin_manual" href={`https://${environment.webapp.admin.hostname}${locale.t(locale.keys.common.tosURL)}`}>
                    {locale.t(locale.keys.pageTitle.tos.index)}
                </a>
            </CustomListItem>
            <CustomListItem onClick={handClose} selected={false}>
                <a target="admin_manual" href={`https://${environmentAll.default.webapp.admin.hostname}/ja/terms/privacy-policy/index.html`}>
                    {locale.t(locale.keys.pageTitle.privacyPolicy.index)}
                </a>
            </CustomListItem>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
