import { useState } from 'react';
import { createContainer } from 'unstated-next';
import moment from 'moment';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import * as deviceModel from '@/common/api/workspace/mfp/mfp';
import { Variants } from '@/common/components/messages/CommonMessage';
import { AdminAppContainer } from '../../AdminAppContainer';
import { default as UI } from '@/common/constants/ui';
import * as errorHandler from '@/common/utils/errorHandler';
import { DateFormat } from '@/common/constants/dateFormat';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';

interface NewDevice {
    id: string;
    hasDetail: boolean;
    mfpNumber: string;
    registeredPerson: string;
    registeredDate: string;
    deadline: number;
    registrationPin: string;
}

const useNewDeviceContainer = () => {
    const [ui, setUI] = useState(UI.state.Loading);
    const [mfpList, setMfpList] = useState<string[]>([]);
    const [deadlineSelected, setDeadlineSelected] = useState(7);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState<NewDevice[]>([]);
    const [idSelected, setIdSelected] = useState('');
    const [goToDetailDevice, setGoToDetailDevice] = useState(false);
    const [isReloaded, setIsReloaded] = useState(false);
    const [isRegisting, setIsRegisting] = useState(false);
    const appContainer = AdminAppContainer.useContainer();
    const [errors, setErrors] = useState({
        mfpNumber: '',
    });
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [everyoneChecked, setEveryoneChecked] = useState(true);
    const maxSteps: number = 3;

    const handleEveryoneChecked = () => {
        setEveryoneChecked(!everyoneChecked);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const loadDummyData = (dummyMemberList: NewDevice[]) => {
        setRows(dummyMemberList);
        setTotalCount(dummyMemberList.length);
    };

    const getRegisteringDeviceList = async () => {
        try {
            const dataResponse = await deviceModel.getRegisteringWorkspaceMfp(
                appContainer.values.signinWorkspaceObject.id || '',
                appContainer.values.authorizationCode,
                (page * rowsPerPage).toString(),
                rowsPerPage.toString(),
            );
            if (!dataResponse) {
                throw new Error('force to fail');
            }
            const dataArr: schema.V1ObjectsWorkspacesMfpInfo[] = dataResponse.mfps;
            const mfpList = dataArr.map((mfp) => ({
                id: mfp.id,
                hasDetail: mfp.existSetting,
                mfpNumber: mfp.mfpNumber,
                registeredPerson: mfp.registrationUser,
                registeredDate: moment(mfp.registrationDate).format(DateFormat.fullDateTimeWithDot),
                deadline: mfp.registrationEndDate !== mfp.registrationDate ? Math.ceil((moment(mfp.registrationEndDate).valueOf() - moment().valueOf()) / (24 * 60 * 60000)) : Infinity,
                registrationPin: mfp.registrationPin,
            }));
            if (mfpList) {
                setTotalCount(dataResponse.totalCount);
                setRows(mfpList);
                setIsReloaded(true);
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const showRows = () => {
        return rows.sort((a, b) => (a.id < b.id ? -1 : 1));
    };

    const handleClickGoToDetailDevice = (id: string) => {
        setIdSelected(id);
        setGoToDetailDevice(true);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
        setIsReloaded(false);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const maxPage = Math.floor(totalCount / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        if (page > maxPage) {
            setPage(maxPage);
        }
        setIsReloaded(false);
    };

    const handleClickRegisterMfp = async () => {
        let groupList: string[] = [];
        if (everyoneChecked) {
            groupList.push(schema.V1ObjectsDefaultGroup.Everyone);
        }
        try {
            const dataResponse = await deviceModel.createWorkspaceMfp(
                appContainer.values.signinWorkspaceObject.id || '',
                { mfps: mfpList, registeredUser: '', registerLimitDays: deadlineSelected, groups: groupList },
                appContainer.values.authorizationCode,
            );
            if (!dataResponse) {
                setIsRegisting(false);
                throw new Error('force to fail');
            } else {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.resistered),
                    variant: Variants.success,
                });
                await getRegisteringDeviceList();
                setMfpList([]);
                setIsRegisting(false);
            }
        } catch (e) {
            setIsRegisting(false);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const buttonDisabled = () => {
        if (mfpList.length === 0) return true;
        return false;
    };

    const handleAddMfp = (chip: string) => {
        let dataList = [...mfpList];
        let isMultipleInput = false;
        let foundItemError = false;
        if (chip.trim().match(/(^[A-Za-z0-9]{4}-[0-9]{6}$)/g)) {
            dataList.push(chip.trim());
            setErrors({
                mfpNumber: '',
            });
        } else {
            setErrors({
                mfpNumber: locale.t(locale.keys.validation.mfpNumber),
            });
        }
        let chipList: string[] = [];
        if (chip.includes(',')) {
            chipList = [...chip.split(',')];
        }
        for (const i in chipList) {
            if (chipList[i].trim().match(/(^[A-Za-z0-9]{4}-[0-9]{6}$)/g)) {
                dataList.push(chipList[i].trim());
                isMultipleInput = true;
            } else {
                foundItemError = true;
            }
        }
        if (isMultipleInput && foundItemError) {
            setErrors({
                mfpNumber: locale.t(locale.keys.validation.mfpNumber),
            });
        }
        if (isMultipleInput && !foundItemError) {
            setErrors({
                mfpNumber: '',
            });
        }
        dataList = dataList.filter((item, pos) => dataList.indexOf(item) === pos);
        setMfpList(dataList);
    };

    const handleDeleteMfp = (data: string) => {
        let dataList = [...mfpList];
        dataList = dataList.filter((it: string) => data !== it);
        setMfpList(dataList);
    };

    const handleChangeDeadlineSelected = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        setDeadlineSelected(Number(event.target.value));
    };

    return {
        ui,
        setUI,
        loadDummyData,
        page,
        handleChangePage,
        rowsPerPage,
        handleChangeRowsPerPage,
        totalCount,
        showRows,
        handleClickRegisterMfp,
        getRegisteringDeviceList,
        mfpList,
        deadlineSelected,
        handleChangeDeadlineSelected,
        buttonDisabled,
        handleAddMfp,
        handleDeleteMfp,
        idSelected,
        goToDetailDevice,
        setGoToDetailDevice,
        handleClickGoToDetailDevice,
        isReloaded,
        isRegisting,
        setIsRegisting,
        errors,
        isOpenPopup,
        setIsOpenPopup,
        activeStep,
        setActiveStep,
        handleBack,
        handleNext,
        maxSteps,
        everyoneChecked,
        handleEveryoneChecked,
    };
};
export const NewDeviceContainer = createContainer(useNewDeviceContainer);
