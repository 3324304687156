import { default as React, useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import * as schema from '@/bundles/schema/typescript/schema';
import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import * as errorHandler from '@/common/utils/errorHandler';
import { State as UI } from '@/common/components/hooks/useUI';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import Error from '@/common/components/state/Error';
import { Variants } from '@/common/components/messages/CommonMessage';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as workspaceuser from '@/common/api/workspaceuser/workspaceuser';
import * as uploader from '@/common/utils/uploader';
import { MemberDetailContainer } from './MemberDetailContainer';
import Config from './Config';
import Control from './Control';
import Remove from './Remove';
import userRole from '@/common/constants/userRole';

interface Props {
    ui?: UI;
    skipEffect?: boolean;
    userId: string;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const container = MemberDetailContainer.useContainer();
    const [members, setMembers] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);


    const handleSave = () => (event: React.MouseEvent<HTMLInputElement>) => {
        logger.debug('handleSave');
        logger.debug(container.values);

        if (!container.values.user) {
            logger.debug('handleSave failed');
            container.openMessage(`${locale.t(locale.keys.action.failedToSave)}`, Variants.error);
            return;
        }

        container.updateUIStatus(UI.Saving);
        (async () => {
            try {
                if (container.values && container.values.user) {
                    logger.debug('handleSave save');

                    let avatorUrl = container.values.user.avatarUrl;
                    if (container.avatarDataUri) {
                        const resp = await uploader.Upload(`${container.values.user.id}.png`, schema.BlobType.UserAvatar, appContainer.values.authorizationCode, container.avatarDataUri);
                        avatorUrl = resp.publicUrl;
                    }
                    container.form.avatarUrl = avatorUrl;
                    container.values.user.invitationEmail = container.form.email;
                    container.values.user.avatarUrl = container.form.avatarUrl;
                    container.values.user.name = container.form.name;
                    container.values.user.phoneticName = container.form.phonetic;
                    container.values.user.role = parseInt(container.form.admin, 10);
                    container.values.user.language = container.toLanguage(container.form.language);
                    container.values.user.deviceLoginUser = container.form.deviceLoginUser;
                    container.values.user.pin = container.form.pin;
                    container.values.user.contactEmail = container.form.contactEmail;

                    const req = workspaceuser.parseUpdateMemberRequest(container.values.user);
                    const result = await workspaceuser.updateUser(container.values.user.id, appContainer.values.authorizationCode, req);

                    if (result.user) {
                        // success
                        let savedMessage = locale.t(locale.keys.action.saved);
                        if (container.initEmail !== result.user.invitationEmail) {
                            savedMessage = locale.t(locale.keys.action.saveChangeEmail);
                        }
                        container.openMessage(savedMessage, Variants.success);
                        container.values.user = result.user;

                        // 自分自身が更新された場合signinWorkspaceUserObjectを更新する。
                        // 2019/08/30 時点では、メンバー詳細で自分自身を更新できない仕様の為この処理は実行されない。
                        if (appContainer.values.signinWorkspaceUserObject.id === result.user.id) {
                            appContainer.values.signinWorkspaceUserObject = result.user;
                            appContainer.setValues(appContainer.values);
                            // 言語設定
                            locale.set(result.user.language, appContainer.values.signinWorkspaceObject.language || '');
                        }
                    } else {
                        // failure
                        container.openMessage(`${locale.t(locale.keys.action.failedToSave)}`, Variants.error);
                    }
                    container.updateUIStatus(UI.Loaded);
                }
            } catch (e) {
                container.updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    const modifiable = () => {
        return (
            container.values.user && (appContainer.values.signinWorkspaceUserObject.id !== container.values.user.id || appContainer.values.signinWorkspaceUserObject.role === userRole.externalAdmin)
        );
    };

    useEffect(() => {
        appContainer.updateLoadingState(container.ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.ui]);

    useEffect(() => {
        if (props.skipEffect || container.ui.current !== UI.Loading) {
            return;
        }
        (async () => {
            try {
                const result = await workspaceuser.showWorkspaceUser(appContainer.values.signinWorkspaceObject.id!, props.userId, appContainer.values.authorizationCode);
                logger.debug(JSON.stringify(result.user));
                if (!result.user) {
                    // TODO: エラー表示
                    container.updateUIStatus(UI.Error);
                    return;
                }

                container.values.user = result.user;
                container.form.avatarUrl = container.values.user.avatarUrl;
                container.form.email = container.values.user.invitationEmail;
                container.form.name = container.values.user.name;
                container.form.phonetic = container.values.user.phoneticName;
                container.form.admin = container.values.user.role.toString();
                container.form.language = container.values.user.language;
                container.form.language = container.values.user.language;
                container.form.deviceLoginUser = container.values.user.deviceLoginUser;
                container.form.pin = container.values.user.pin;
                container.form.contactEmail = container.values.user.contactEmail;
                container.validate(container.form);

                container.updateUIStatus(UI.Loaded);
            } catch (e) {
                container.updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const fetchMembers = () => {
            const detailFlag = sessionStorage.getItem('detailFlag');
            if (detailFlag === 'inviteDetail') {
                // 招待画面から詳細画面に遷移した場合
                const storedMembers = sessionStorage.getItem('invitationMembers');
                if (storedMembers) {
                    const parsedMembers = JSON.parse(storedMembers);
                    setMembers(parsedMembers);
                    const initialIndex = parsedMembers.findIndex(member => member.id === props.userId);
                    setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
                    sessionStorage.setItem('LastViewedMemberIndex', initialIndex.toString());
                }
            } else if (detailFlag === 'groupDetail') {
                // グループ管理画面から詳細画面に遷移した場合
                const storedMembers = sessionStorage.getItem('selectedGroupMembers');
                if (storedMembers) {
                    const parsedMembers = JSON.parse(storedMembers);
                    setMembers(parsedMembers);
                    const initialIndex = parsedMembers.findIndex(member => member.id === props.userId);
                    setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
                    sessionStorage.setItem('LastViewedMemberIndex', initialIndex.toString());
                }
            }
        };

        fetchMembers();
    }, [props.userId, appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode]);

    return (
        <>
            {container.ui.current === UI.Loading && (
                <div data-testid={UI.Loading}>
                    <Loading />
                </div>
            )}
            {container.ui.current === UI.Loaded && (
                <>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={8}>
                            <Config handleSave={handleSave} modifiable={modifiable} />
                        </Grid>
                        {modifiable() && (
                            <Grid item xs={12} md={4}>
                                <Control />
                                <br />
                                <Remove />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        {sessionStorage.getItem('detailFlag') === 'inviteDetail' && (
                            <Grid item xs={12} md={8} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <a
                                    href={currentIndex > 0 ? `${members[currentIndex - 1].id}?invite=true` : '#'} // invite=trueを付与して招待画面に戻る
                                    style={{ textDecoration: 'none', pointerEvents: currentIndex === 0 ? 'none' : 'auto' }}
                                >
                                    <Button variant="contained" color="primary" disabled={currentIndex === 0}>
                                        &lt;
                                    </Button>
                                </a>
                                <a
                                    href={currentIndex < members.length - 1 ? `${members[currentIndex + 1].id}?invite=true` : '#'} // invite=trueを付与して招待画面に戻る
                                    style={{ textDecoration: 'none', pointerEvents: currentIndex === members.length - 1 ? 'none' : 'auto' }}
                                >
                                    <Button variant="contained" color="primary" disabled={currentIndex === members.length - 1}>
                                        &gt;
                                    </Button>
                                </a>
                            </Grid>
                        )}
                        {sessionStorage.getItem('detailFlag') === 'groupDetail' && (
                            <Grid item xs={12} md={8} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <a
                                    href={currentIndex > 0 ? `${members[currentIndex - 1].id}` : '#'}
                                    style={{ textDecoration: 'none', pointerEvents: currentIndex === 0 ? 'none' : 'auto' }}
                                >
                                    <Button variant="contained" color="primary" disabled={currentIndex === 0}>
                                        &lt;
                                    </Button>
                                </a>
                                <a
                                    href={currentIndex < members.length - 1 ? `${members[currentIndex + 1].id}` : '#'}
                                    style={{ textDecoration: 'none', pointerEvents: currentIndex === members.length - 1 ? 'none' : 'auto' }}
                                >
                                    <Button variant="contained" color="primary" disabled={currentIndex === members.length - 1}>
                                        &gt;
                                    </Button>
                                </a>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
            {container.ui.current === UI.Saving && (
                <div data-testid={UI.Saving}>
                    <Saving />
                </div>
            )}

            {container.ui.current === UI.Error && (
                <div data-testid={UI.Error}>
                    <Error />
                </div>
            )}
        </>
    );
};

export default Component;
