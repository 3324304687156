import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as schema from '@/bundles/schema/typescript/schema';
import * as workspace from '@/common/api/workspace/workspace';
import logger from '@/common/utils/logger';

// ワークスペースユーザー取得
export const findSubWorkspace = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceIndexResponse> => {
    return workspace.findSubWorkspaces(workspaceId, auth);
};
export const findSubWorkspaceMfpCombinations = async (req: schema.V1WorkspaceMfpsListIndexRequest, auth: string): Promise<schema.V1WorkspaceMfpsListIndexResponse> => {
    return workspace.findSubWorkspaceMfpCombinations(req, auth);
};

export const findWorkspaceUserCount = async (workspaceId: string, auth: string): Promise<number> => {
    const res = await workspace.findWorkspaceUser(workspaceId, auth);
    return res.users.length;
};
const rowToUpdateRequest = (row: Row): schema.V1WorkspaceUpdateRequest => {
    // const activeStatus = row.active === locale.t(locale.keys.memberManagement.active.index);
    return {
        displayId: row.displayId,
        displayName: row.displayName,
        enableSubWorkspace: row.enableSubWorkspace,
        language: row.language,
        logoUrl: row.logoUrl,
    };
};

// ワークスペース名更新
export const updateWorkspaceDisplayId = async (auth: string, row: Row): Promise<schema.V1WorkspaceUpdateResponse> => {
    logger.debug('updateWorkspaceDisplayId');
    const req = rowToUpdateRequest(row);
    return workspace.updateWorkspace(row.objId, auth, req);
};

// ユーザー権限更新
export const updateWorkspaceDisplayName = async (auth: string, row: Row): Promise<schema.V1WorkspaceUpdateResponse> => {
    logger.debug('updateWorkspaceDisplayName');
    const req = rowToUpdateRequest(row);
    return workspace.updateWorkspace(row.objId, auth, req);
};
// サブワークスペース作成フラグ更新
export const updateEnableSubWorkspace = async (auth: string, row: Row): Promise<schema.V1WorkspaceUpdateResponse> => {
    logger.debug('updateEnableSubWorkspace');
    const req = rowToUpdateRequest(row);
    return workspace.updateWorkspace(row.objId, auth, req);
};

export interface Form {
    filter: string;
    rows: Row[];
    init: boolean;
    validateInit: {
        name: boolean;
    };
    errors: validator.ValidationOutput;
}

export const New = (): Form => ({
    filter: '',
    rows: [],
    init: false,
    validateInit: {
        name: false,
    },
    // Initialize error holder.
    errors: NewValidation(),
});

// リクエストに使用されるが、rowToUpdateRequestで型変換されているのでoptional keyなら追加してもデグレしないはず。
export interface Row {
    id: number;
    objId: string;
    logoUrl: string;
    displayId: string;
    displayName: string;
    enableSubWorkspace: boolean;
    memberCount: number;
    active: boolean;
    language: schema.Language;
    billingPlan: string;
    organizationDetail: schema.V1ObjectsWorkspacesOrganizationDetail | null;
    contactEmail?: string;
    parentWorkspaceId?: string;
}

export const validations: validator.Constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: locale.t(locale.keys.validation.required),
        },
    },
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        name: null,
    };
};
