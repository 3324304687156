import { default as React, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import { Grid, Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';

import routes from '@/admin/constants/routes';
import { State as UI } from '@/common/components/hooks/useUI';
import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';

import * as workspace from '@/common/api/workspace/workspace';
import UserSetting from './UserSetting';
import WorkspaceSetting from './WorkspaceSetting';
import PasswordSetting from './PasswordSetting';
import OrganizationDetail from './OrganizationDetail';
import { WorkspaceSignupContainer } from './WorkspaceSignupContainer';
import { VerifyContainer } from '../VerifyContainer';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import { Variants } from '@/common/components/messages/CommonMessage';

import * as uploader from '@/common/utils/uploader';
import * as errorHandler from '@/common/utils/errorHandler';

import * as login from '@/common/api/auth/login/Login';
import * as workspaceuser from '@/common/api/workspaceuser/workspaceuser';
import * as schema from '@/bundles/schema/typescript/schema';

import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import Error from '@/common/components/state/Error';

import * as model from '@/common/api/auth/workspace-signup/WorkspaceSignup';

const styles = (theme: Theme) =>
    createStyles({
        link: {
            color: '#0d47a1',
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
            },
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    ui?: UI;
    skipEffect?: boolean;
}

export enum State {
    ProfileSetting = 'profileSetting',
    OrganizationSetting = 'organizationSetting',
    AdminSetting = 'adminSetting',
    PasswordSetting = 'passwordSetting',
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const vc = VerifyContainer.useContainer();
    const container = WorkspaceSignupContainer.useContainer();
    const lhc = LogoHeaderContainer.useContainer();
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        appContainer.updateLoadingState(container.ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.ui]);

    useEffect(() => {
        if (props.skipEffect || container.ui.current !== UI.Loading) {
            return;
        }
        logger.debug(`WorkspaceSignup useEffect Loading`);

        const verify = vc.values;
        if (!verify.user || !verify.workspace) {
            container.ui.update(UI.Error);
            return;
        }

        if (workspace.isInitialValue(verify.workspace.displayId)) {
            verify.workspace.displayId = '';
        }
        if (workspace.isInitialValue(verify.workspace.displayName)) {
            verify.workspace.displayName = '';
        }

        // 言語設定
        // ワークスペース言語で言語を決定する。新規ワークスペース招待時にユーザ言語を設定できないため。
        // locale.set(verify.user.language, verify.workspace.language || '');
        locale.set(verify.workspace.language || '');

        container.ui.update(UI.Loaded);
        container.setValues({
            ...container.values,
            avatarUrl: verify.user.avatarUrl,
            name: verify.user.name,
            phoneticName: verify.user.phoneticName,
            mail: verify.user.invitationEmail,
            userLang: verify.user.language || '',
            logoUrl: verify.workspace.logoUrl ? verify.workspace.logoUrl : '',
            workspaceId: verify.workspace.displayId,
            workspaceName: verify.workspace.displayName,
            workspaceLang: verify.workspace.language || '',
            withoutEmail: workspace.parseWithoutEmailInvite(window.location.search),
            token: vc.values.token,
            active: verify.user.active,
            role: verify.user.role,
            enableSubWorkspace: !!verify.workspace.enableSubWorkspace,
        });
        if (verify.workspace.organizationDetail) {
            container.setOrganization({
                name: verify.workspace.organizationDetail.name ? verify.workspace.organizationDetail.name : '',
                zipCode: verify.workspace.organizationDetail.zipCode ? verify.workspace.organizationDetail.zipCode : '',
                country: verify.workspace.organizationDetail.country ? verify.workspace.organizationDetail.country : '',
                address1: verify.workspace.organizationDetail.address1 ? verify.workspace.organizationDetail.address1 : '',
                address2: verify.workspace.organizationDetail.address2 ? verify.workspace.organizationDetail.address2 : '',
                phoneNumber: verify.workspace.organizationDetail.phoneNumber ? verify.workspace.organizationDetail.phoneNumber : '',
                customerId: verify.workspace.organizationDetail.customerId ? verify.workspace.organizationDetail.customerId : '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreateWorkspace = () => {
        logger.debug('handleCreateWorkspace');
        container.ui.update(UI.Saving);
        lhc.setBackFunc();

        (async () => {
            try {
                // 招待Eメール送信せずに利用開始した場合はサインアップリクエストにフラグを加える
                const req = workspace.parseWorkspaceSignupRequest(container.values);
                const result = await workspace.workspaceSignup(req, true);

                let userObj = result.user;
                // アバター更新
                if (container.avatarDataUri) {
                    const avatarRes = await uploader.Upload(`${result.user.id}.png`, schema.BlobType.UserAvatar, result.id, container.avatarDataUri);
                    const workspaceuserUpdateRequest: schema.V1WorkspaceuserUpdateRequest = {
                        active: result.user.active,
                        avatarUrl: avatarRes.publicUrl,
                        language: result.user.language,
                        name: result.user.name,
                        phoneticName: result.user.phoneticName,
                        role: result.user.role,
                        deviceLoginUser: result.user.deviceLoginUser,
                        pin: result.user.pin,
                        contactEmail: result.user.contactEmail,
                    };
                    const updatedUser = await workspaceuser.updateUser(result.user.id, result.id, workspaceuserUpdateRequest);
                    userObj = updatedUser.user;
                }

                // ロゴ更新
                if (container.logoDataUri) {
                    const workspaceRes = await uploader.Upload(`${result.workspace.id}.png`, schema.BlobType.WorkspaceLogo, result.id, container.logoDataUri);
                    const workspaceUpdateRequest: schema.V1WorkspaceUpdateRequest = {
                        displayId: result.workspace.displayId,
                        displayName: result.workspace.displayName,
                        enableSubWorkspace: result.workspace.enableSubWorkspace!,
                        language: result.workspace.language!,
                        logoUrl: workspaceRes.publicUrl,
                    };
                    await workspace.updateWorkspace(result.workspace.id!, result.id, workspaceUpdateRequest);
                }
                const workspaceObj = await login.getWorkspaceObject(result.workspace.displayId, result.id);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.saved),
                    variant: Variants.success,
                });
                appContainer.onSignin(appContainer, result.id, workspaceObj, userObj);
                container.ui.update(UI.Loaded);
                setLoggedIn(true);
            } catch (e) {
                lhc.setBackFunc(() => {
                    container.setScreenState(model.WorkspaceSignupState.AdminSetting);
                });
                container.ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    return (
        <>
            {loggedIn ? (
                <Redirect to={routes.dashboard.index} />
            ) : (
                <>
                    {container.ui.current === UI.Loading && (
                        <div data-testid={UI.Loading}>
                            <Loading />
                        </div>
                    )}

                    {container.ui.current === UI.Loaded && (
                        <div data-testid={UI.Loaded}>
                            <Grid container spacing={16}>
                                {container.screenState === model.WorkspaceSignupState.ProfileSetting && (
                                    <>
                                        <Grid item sm />
                                        <Grid item xs={12} sm={4}>
                                            <WorkspaceSetting />
                                        </Grid>
                                        <Grid item sm />
                                    </>
                                )}
                                {container.screenState === model.WorkspaceSignupState.OrganizationSetting && (
                                    <>
                                        <Grid item sm />
                                        <Grid item xs={12} sm={4}>
                                            <OrganizationDetail />
                                        </Grid>
                                        <Grid item sm />
                                    </>
                                )}
                                {container.screenState === model.WorkspaceSignupState.AdminSetting && (
                                    <>
                                        <Grid item sm />
                                        <Grid item xs={12} sm={4}>
                                            <UserSetting />
                                        </Grid>
                                        <Grid item sm />
                                    </>
                                )}
                                {container.screenState === model.WorkspaceSignupState.PasswordSetting && (
                                    <>
                                        <Grid item sm />
                                        <Grid item xs={12} sm={4}>
                                            <PasswordSetting createWorkspaceFunc={handleCreateWorkspace} />
                                        </Grid>
                                        <Grid item sm />
                                    </>
                                )}
                            </Grid>
                        </div>
                    )}

                    {container.ui.current === UI.Saving && (
                        <div data-testid={UI.Saving}>
                            <Saving />
                        </div>
                    )}

                    {container.ui.current === UI.Error && (
                        <div data-testid={UI.Error}>
                            <Error />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default withStyles(styles)(withRouter(Component));
