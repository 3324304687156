import { default as React } from 'react';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { Theme, createStyles, WithStyles, withStyles, Card, CardContent, TextField, CardActions, Button, FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import { DetailDeviceContainer } from './DetailDeviceContainer';
import { Delete } from '@material-ui/icons';
import Confirm from '@/common/components/Confirm/Confirm';
import { Prompt } from 'react-router';

const styles = (theme: Theme) =>
    createStyles({
        input: {
            height: 40,
            paddingLeft: 10,
        },
        cardEdit: {
            minHeight: 196,
            borderRadius: 4,
        },
        cardAction: {
            minHeight: 196,
            borderRadius: 4,
            marginLeft: 20,
            height: 196,
        },
        cardContent: {
            marginTop: 5,
            marginBottom: 20,
        },
        textFieldHeight: {
            height: 66,
            borderRadius: 4,
            backgroundColor: '#eeeeee',
        },
        textFieldDeviceName: {
            width: 300,
        },
        textFieldMfpNumber: {
            width: 300,
            '&& p': {
                marginTop: 50,
                position: 'absolute',
                zIndex: 99,
            },
        },
        textFieldMfpName: {
            width: 300,
            height: 66,
            borderRadius: 4,
            marginTop: 30,
        },
        textFieldRegistrationPin: {
            width: 300,
            height: 66,
            borderRadius: 4,
            marginTop: 30,
        },
        formControlLabel: {
            height: 66,
            marginTop: 30,
        },
        buttonFrame: {
            border: `1px solid ${theme.palette.secondary.main}`,
            margin: '25px auto 40px',
            backgroundColor: '#ffffff',
            color: `${theme.palette.secondary.main}`,
            borderRadius: 6,
            minHeight: 48,
            '&:hover': {
                color: '#ffffff',
            },
        },
        buttonFrameDisable: {
            margin: '25px auto 40px',
            backgroundColor: '#ffffff',
            color: `${theme.palette.secondary.main}`,
            borderRadius: 6,
            minHeight: 48,
            '&:hover': {
                color: '#ffffff',
            },
        },
        button: {
            minWidth: 196,
            maxWidth: 246,
            height: 44,
            margin: `0 auto 30px`,
        },
        deleteButton: {
            minWidth: 60,
            height: 44,
            color: '#999999',
            margin: `0 auto`,
        },
        cardButtonSave: {
            display: 'flex',
            justifyContent: 'center',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const detailDeviceContainer = DetailDeviceContainer.useContainer();
    return (
        <>
            <Grid container spacing={8}>
                <Grid item xs={8}>
                    <Card className={classes.cardEdit}>
                        <CardContent className={classes.cardContent}>
                            <Grid container spacing={16}>
                                <Grid item xs={6}>
                                    <TextField
                                        className={classes.textFieldMfpNumber}
                                        label={locale.t(locale.keys.manageShareDevice.mfpNumber.index)}
                                        variant="filled"
                                        InputProps={{ disableUnderline: true, inputProps: { style: { height: '100%' } }, className: classes.textFieldHeight }}
                                        value={detailDeviceContainer.mfpNumber}
                                        onChange={detailDeviceContainer.handleChangeMfpNumber}
                                        onKeyPress={detailDeviceContainer.handlePressEnterUpdate}
                                        error={detailDeviceContainer.errors.mfpNumber !== ''}
                                        helperText={detailDeviceContainer.errors.mfpNumber}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className={classes.textFieldDeviceName}
                                        label={locale.t(locale.keys.manageShareDevice.deviceName.index)}
                                        variant="filled"
                                        InputProps={{ disableUnderline: true, inputProps: { style: { height: '100%' } }, className: classes.textFieldHeight }}
                                        value={detailDeviceContainer.deviceName}
                                        onChange={detailDeviceContainer.handleChangeDeviceName}
                                        onKeyPress={detailDeviceContainer.handlePressEnterUpdate}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className={classes.textFieldMfpName}
                                        label={locale.t(locale.keys.manageShareDevice.mfpName.index)}
                                        InputProps={{ className: classes.input, readOnly: true, inputProps: { style: { height: '100%' } } }}
                                        InputLabelProps={{ variant: 'filled' }}
                                        value={detailDeviceContainer.mfpName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className={classes.textFieldRegistrationPin}
                                        label={locale.t(locale.keys.manageShareDevice.registrationPin.index)}
                                        InputProps={{ className: classes.input, readOnly: true, inputProps: { style: { height: '100%' } } }}
                                        InputLabelProps={{ variant: 'filled' }}
                                        value={detailDeviceContainer.registrationPin}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} container alignContent="flex-start">
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={<Checkbox checked={detailDeviceContainer.omitPin} onChange={detailDeviceContainer.handleChangeOmitPin} />}
                                    label={locale.t(locale.keys.manageShareDevice.omitPin.index)}
                                />
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button className={classes.button} fullWidth variant="contained" disabled={detailDeviceContainer.buttonDisabled()} onClick={detailDeviceContainer.handleClickUpdateDevice}>
                                {locale.t(locale.keys.manageShareDevice.saveChanges.index)}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card className={classes.cardAction}>
                        <CardContent className={classes.cardContent}>
                            <Grid item xs={12}>
                                <Button
                                    className={!detailDeviceContainer.isRegistered ? classes.buttonFrameDisable : classes.buttonFrame}
                                    variant="contained"
                                    onClick={detailDeviceContainer.handleClickSuspendDevice}
                                    disabled={!detailDeviceContainer.isRegistered}
                                >
                                    {detailDeviceContainer.status === schema.V1ObjectsWorkspacemfpStatus.Active && <span>{locale.t(locale.keys.manageShareDevice.suspendDevice.index)}</span>}
                                    {detailDeviceContainer.status === schema.V1ObjectsWorkspacemfpStatus.Inactive && <span>{locale.t(locale.keys.manageShareDevice.resumeDevice.index)}</span>}
                                </Button>
                            </Grid>
                            <Grid container justify="center">
                                <Confirm value={detailDeviceContainer.workspaceMfpId} name={detailDeviceContainer.deviceName} callBack={detailDeviceContainer.confirmRemoveDevice}>
                                    <Button className={classes.deleteButton}>
                                        <Delete /> {locale.t(locale.keys.manageShareDevice.deleteDevice.index)}
                                    </Button>
                                </Confirm>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Prompt when={detailDeviceContainer.isEdit} message={locale.t(locale.keys.promptMessage)} />
            </Grid>
        </>
    );
};

export default withStyles(styles)(Component);
