import { default as React } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTitle from '@/common/components/hooks/useTitle';
import InvitationMember from './InvitationMember';
import InvitationMemberTable from './InvitationMemberTable';
import { State as UI } from '@/common/components/hooks/useUI';
import Error from '@/common/components/state/Error';
import locale from '@/common/utils/locale';
import { InvitationMemberContainer } from '@/admin/components/member/invite/InvitationMemberContainer';
import SubHeader from '@/admin/components/common/subheader/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles> {}

const ChildComponent: React.FC<Props> = (props) => {
    const container = InvitationMemberContainer.useContainer();

    return (
        <>
            {/* Loading, Saving中でも画面は出したままにしておく */}
            {container.ui.current !== UI.Error && (
                <div className={props.classes.mainDiv}>
                    <div className={props.classes.root}>
                        <InvitationMember onInvite={container.onInvite} />
                        <InvitationMemberTable
                            invitationRow={container.invitationRow}
                            reloadCallback={() => {
                                container.onInvite(null);
                            }}
                        />
                    </div>
                </div>
            )}
            {container.ui.current === UI.Error && (
                <div data-testid={UI.Error}>
                    <Error />
                </div>
            )}
        </>
    );
};

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.memberInvitation.index));

    return (
        <>
            <SubHeader title={locale.t(locale.keys.memberInvitation.index)} pageDescription={locale.t(locale.keys.pageTitle.adminHeaderDescription.subWorkspaceInvite)} />
            <InvitationMemberContainer.Provider>
                <ChildComponent {...props} />
            </InvitationMemberContainer.Provider>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
