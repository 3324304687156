import { default as React, useState } from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid, FormControl, InputLabel, TextField, FilledInput, Select } from '@material-ui/core';

import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as ModelOrganization from '@/common/models/organization';

const styles = (theme: Theme) =>
    createStyles({
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        tabGrid: {
            padding: '24px 16px 0 16px',
            backgroundColor: theme.palette.background.paper,
            minHeight: '500px',
            [theme.breakpoints.down('sm')]: {
                minHeight: '700px',
            },
        },
        selectInput: {
            padding: '28px 12px 9px 12px',
        },
    });
interface Props extends WithStyles<typeof styles> {
    organization: ModelOrganization.OrganizationInfo;
    disabled: boolean;
    setOrganization: (info: ModelOrganization.OrganizationInfo) => void;
}

export const FormOrganization: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const [errors, setErrors] = useState(ModelOrganization.NewValidation());

    const handleChangeOrganization = (name: keyof ModelOrganization.OrganizationInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const changed = { ...props.organization, [name]: event.target.value };
        props.setOrganization(changed);

        const valideteOutput = validator.Validate<ModelOrganization.OrganizationInfo>(changed, ModelOrganization.validation(), ModelOrganization.NewValidation);
        if (valideteOutput) {
            setErrors(valideteOutput);
        } else {
            setErrors({});
        }
    };
    const handleChangeOrganizationCountry = (name: keyof ModelOrganization.OrganizationInfo) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        const changed = { ...props.organization, [name]: event.target.value };
        props.setOrganization(changed);

        const valideteOutput = validator.Validate<ModelOrganization.OrganizationInfo>(changed, ModelOrganization.validation(), ModelOrganization.NewValidation);
        if (valideteOutput) {
            setErrors(valideteOutput);
        } else {
            setErrors({});
        }
    };

    const isErrorOrganization = (name: keyof ModelOrganization.OrganizationInfo) => {
        return !!(errors && name in errors && !!errors[name]);
    };
    const getHelperTextOrganization = (name: keyof ModelOrganization.OrganizationInfo) => {
        if (errors && name in errors && errors[name]) {
            return errors[name];
        }
        return '';
    };

    return (
        <>
            <Grid container spacing={16} className={classes.tabGrid}>
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                    <TextField
                        className={classes.text}
                        label={locale.t(locale.keys.organizationInfo.name)}
                        placeholder=""
                        margin="none"
                        variant="filled"
                        value={props.organization.name}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeOrganization('name')}
                        error={isErrorOrganization('name')}
                        helperText={getHelperTextOrganization('name')}
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <TextField
                        className={classes.text}
                        label={locale.t(locale.keys.organizationInfo.zipCode)}
                        placeholder=""
                        margin="none"
                        variant="filled"
                        value={props.organization.zipCode}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeOrganization('zipCode')}
                        error={isErrorOrganization('zipCode')}
                        helperText={getHelperTextOrganization('zipCode')}
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <FormControl variant="filled" className={classes.text}>
                        <InputLabel htmlFor="lang-select">{locale.t(locale.keys.organizationInfo.country)}</InputLabel>
                        <Select
                            native
                            value={props.organization.country}
                            variant="filled"
                            onChange={handleChangeOrganizationCountry('country')}
                            input={<FilledInput name="country" id="country-select" classes={{ input: classes.selectInput }} />}
                            inputProps={{
                                name: 'country',
                                id: 'country',
                                style: { height: '1.46em', lineHeight: 'normal' },
                            }}
                            disabled={props.disabled}
                        >
                            <option value={''} />
                            <option value={'jp'}>日本</option>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <TextField
                        className={classes.text}
                        label={locale.t(locale.keys.organizationInfo.address1)}
                        placeholder=""
                        margin="none"
                        variant="filled"
                        value={props.organization.address1}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeOrganization('address1')}
                        error={isErrorOrganization('address1')}
                        helperText={getHelperTextOrganization('address1')}
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <TextField
                        className={classes.text}
                        label={locale.t(locale.keys.organizationInfo.address2)}
                        placeholder=""
                        margin="none"
                        variant="filled"
                        value={props.organization.address2}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeOrganization('address2')}
                        error={isErrorOrganization('address2')}
                        helperText={getHelperTextOrganization('address2')}
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <TextField
                        className={classes.text}
                        label={locale.t(locale.keys.organizationInfo.phoneNumber)}
                        placeholder=""
                        margin="none"
                        variant="filled"
                        value={props.organization.phoneNumber}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeOrganization('phoneNumber')}
                        error={isErrorOrganization('phoneNumber')}
                        helperText={getHelperTextOrganization('phoneNumber')}
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <TextField
                        className={classes.text}
                        label={locale.t(locale.keys.organizationInfo.customerId)}
                        placeholder=""
                        margin="none"
                        variant="filled"
                        value={props.organization.customerId}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeOrganization('customerId')}
                        error={isErrorOrganization('customerId')}
                        helperText={getHelperTextOrganization('customerId')}
                        disabled={props.disabled}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(FormOrganization);
