import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Button, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import ModalHeader from '@/common/components/headers/ModalHeader';
import { MemberContainer } from '../MemberContainer';
import { MultiLineText } from '@/common/components/messages/MultiLineText';
import { CovasGroupAvatar } from '@/common/components/CovasAvatar';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: `0px ${theme.spacing.unit * 3}px`,
            height: '100%',
            gap: '12px',
            paddingTop: 24,
        },
        listRoot: {
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        okBtn: {
            color: '#ff7300',
        },
    });

interface Props extends WithStyles<typeof styles> {
    groupId: string;
    groupName: string;
    memberCount: number;
    avatarUrl?: string;
}

const DeleteGroup: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const memberContainer = MemberContainer.useContainer();

    return (
        <>
            <ModalHeader
                title={locale.t(locale.keys.memberManagement.modal.deleteGroup.title)}
                onClose={() => {
                    memberContainer.closeModal();
                }}
                isLoading={appContainer.loadingState}
            />
            <Grid container direction="column" style={{ height: 'calc(100% - 48px)', justifyContent: 'space-between', flexWrap: 'nowrap' }} className={classes.root}>
                <Grid item container direction="column" style={{ gap: '8px', overflow: 'auto', flexWrap: 'nowrap', flexGrow: 1 }}>
                    <Grid item container direction="column" style={{ gap: '8px' }}>
                        <MultiLineText value={locale.t(locale.keys.memberManagement.modal.deleteGroup.description)} />
                    </Grid>

                    <Grid item container direction="column" style={{ gap: '8px' }}>
                        <ListItem>
                            <ListItemAvatar>
                                <CovasGroupAvatar size={40} src={props.avatarUrl} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={props.groupName}
                                primaryTypographyProps={{
                                    style: { fontSize: 12, fontWeight: 'bold', color: '#333', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' /**空白は詰めない */ },
                                }}
                                secondary={locale.t(locale.keys.memberManagement.groupList.groupList.group.count, { memberCount: props.memberCount === 0 ? '0' : props.memberCount.toString() })}
                                secondaryTypographyProps={{ style: { fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }}
                            />
                        </ListItem>
                    </Grid>
                </Grid>

                <Grid item container direction="row" justify="flex-end" id="emailmodal-footer" style={{ width: '100%' }}>
                    <Button onClick={() => memberContainer.closeModal()} size="small" color="primary">
                        {locale.t(locale.keys.action.cancel)}
                    </Button>
                    <Button
                        onClick={() => {
                            memberContainer.deleteGroup(props.groupId);
                            memberContainer.closeModal();
                        }}
                        variant="outlined"
                        className={classes.okBtn}
                        disabled={false}
                        style={{ border: 'none' }}
                    >
                        {locale.t(locale.keys.action.ok)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(DeleteGroup);
