import logger from '@/common/utils/logger';
import * as crypto from 'crypto-js';
const secKey = 'covas-storageKey';
export const getValue = <T>(key: string) => {
    const value = localStorage.getItem(key);
    if (value) {
        try {
            const bytes = crypto.AES.decrypt(value, secKey);
            const originalText = bytes.toString(crypto.enc.Utf8);
            return JSON.parse(originalText) as T;
        } catch (e) {
            logger.error(e);
            return null;
        }
    }
    return null;
};

export const setValue = <T>(key: string, value: T) => {
    const encryptValue = crypto.AES.encrypt(JSON.stringify(value), secKey).toString();
    localStorage.setItem(key, encryptValue);
};
export const remove = <T>(key: string) => {
    localStorage.removeItem(key);
};
