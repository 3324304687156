import { useState } from 'react';
import * as schema from '@/bundles/schema/typescript/schema';
import { getNotificationConfig, patchNotificationConfig, postDownload, getDownload } from '@/common/api/workspace/analytics';

export const useAnalytics = () => {
    const [notificationConfig, setNotificationConfig] = useState<schema.V1WorkspaceAnalyticsNotificationConfigShowResponse | null>(null);
    const [isNotification, setIsNotification] = useState<boolean>(false);

    /**
     * @description アナリティクスの通知設定を取得
     */
    const showNotificationConfig = async (workspaceId: string, auth: string) => {
        const res = await getNotificationConfig(workspaceId, auth);
        setNotificationConfig(res);
        setIsNotification(res.analyticsReport);
        return res;
    };

    /**
     * @description アナリティクスの通知設定を更新
     */
    const updateNotificationConfig = async (workspaceId: string, auth: string, req: schema.V1WorkspaceAnalyticsNotificationConfigUpdateRequest) => {
        const res = await patchNotificationConfig(workspaceId, auth, req);
        setNotificationConfig(res);
        setIsNotification(res.analyticsReport);
        return res;
    };

    /**
     * @description アナリティクスの作成
     */
    const createAnalytics = async (workspaceId: string, auth: string, req: schema.V1WorkspaceAnalyticsDownloadCreateRequest) => {
        const res = await postDownload(workspaceId, auth, req);
        return res;
    };

    /**
     * @description アナリティクスのダウンロード
     */
    const showAnalytics = async (workspaceId: string, auth: string, downloadRequestId: string) => {
        const res = await getDownload(workspaceId, auth, downloadRequestId);
        return res;
    };

    return {
        notificationConfig,
        isNotification,
        setIsNotification,
        showNotificationConfig,
        updateNotificationConfig,
        createAnalytics,
        showAnalytics,
    };
};
