import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import locale from '@/common/utils/locale';
import Entry from './Entry';
import { EntryContainer } from './EntryContainer';

interface Props {}

const styles = () => createStyles({});

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = () => {
    useTitle(locale.t(locale.keys.applicationList.index));

    return (
        <>
            <EntryContainer.Provider>
                <Entry />
            </EntryContainer.Provider>
        </>
    );
};

export default withStyles(styles)(Component);
