import logout from './logout';
import recover from './recover';
import verify from './verify';
import signin from './signin';

export default {
    logout,
    recover,
    verify,
    signin,
};
