import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Paper, Fab } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EntryDetailContainer, SelectedTab } from './EntryDetailContainer';
import { StyledTab, StyledTabs } from '../Tab';
import { BasicInfo } from './BasicInfo';
import { ClientInfo } from './ClientInfo';
import { ManagerInfo } from './ManagerInfo';
import { Menu } from './Menu';
import { State as UI } from '@/common/components/hooks/useUI';
import locale from '@/common/utils/locale';

const Tabs = () => {
    const container = EntryDetailContainer.useContainer();

    return container.ui.current === UI.Loading ? (
        <StyledTabs disabled={true} value={container.tab} onChange={(e: React.ChangeEvent<{}>, v: SelectedTab) => container.handleTabChange(e, v)}>
            <StyledTab disabled={true} value={SelectedTab.tab1} label={locale.t(locale.keys.applicationList.applicationDetails.tab1)} />
            <StyledTab disabled={true} value={SelectedTab.tab2} label={locale.t(locale.keys.applicationList.applicationDetails.tab2)} />
            <StyledTab disabled={true} value={SelectedTab.tab3} label={locale.t(locale.keys.applicationList.applicationDetails.tab3)} />
        </StyledTabs>
    ) : (
        <StyledTabs value={container.tab} onChange={(e: React.ChangeEvent<{}>, v: SelectedTab) => container.handleTabChange(e, v)}>
            <StyledTab value={SelectedTab.tab1} label={locale.t(locale.keys.applicationList.applicationDetails.tab1)} />
            <StyledTab value={SelectedTab.tab2} label={locale.t(locale.keys.applicationList.applicationDetails.tab2)} />
            <StyledTab value={SelectedTab.tab3} label={locale.t(locale.keys.applicationList.applicationDetails.tab3)} />
        </StyledTabs>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing.unit * 3,
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        paperMainRoot: {
            backgroundColor: theme.palette.background.paper,
            minHeight: '500px',
            [theme.breakpoints.down('sm')]: {
                minHeight: '700px',
            },
        },
        displayNone: {
            display: 'none',
        },
        editFab: {
            color: 'white',
            right: theme.spacing.unit * 2,
            '&& span': {
                fontSize: '1rem',
            },
            marginBottom: '40px',
            height: '40px',
            width: '200px',
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    displayId: string;
}
const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = EntryDetailContainer.useContainer();

    // 保存中はボタンを非活性化
    const onClickEditSave = async () => {
        container.setSaveButtonDisabled(true)
        await container.editSave()
        container.setSaveButtonDisabled(false)
    }

    return (
        <Grid className={classes.root}>
            <Grid container direction="row" spacing={24}>
                <Grid item xs={12} md={8}>
                    <Paper className={classes.paperMainRoot}>
                        <Grid style={{ textAlign: 'left' }}>
                            <Tabs />
                        </Grid>
                        {container.ui.current === UI.Loading ? (
                            <></>
                        ) : container.tab === SelectedTab.tab1 ? (
                            <Grid>
                                <BasicInfo />
                            </Grid>
                        ) : container.tab === SelectedTab.tab2 ? (
                            <Grid>
                                <ClientInfo />
                            </Grid>
                        ) : (
                            <Grid>
                                <ManagerInfo />
                            </Grid>
                        )}
                        {container.ui.current === UI.Loading ? (
                            <></>
                        ) : (
                            <Fab disabled={container.saveButtonDisabled} variant="extended" onClick={() => onClickEditSave()} className={classes.editFab} size="large" color="secondary">
                                {locale.t(locale.keys.applicationList.applicationDetails.saveButton)}
                            </Fab>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Menu />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
