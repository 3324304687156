import { useState } from 'react';
import { createContainer } from 'unstated-next';
import useUI, { State as UI } from '@/common/components/hooks/useUI';

import * as schema from '@/bundles/schema/typescript/schema';
import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as config from '@/common/api/workspace/setting/config';
import * as modelOrganization from '@/common/models/organization';

const useWorkspaceContainer = () => {
    const ui = useUI();
    const [form, setForm] = useState(config.New());
    const [organization, setOrganization] = useState(modelOrganization.New());
    const [logoDataUri, setLogoDataUri] = useState('');
    const defaultExtension: schema.V1ObjectsExtension[] = [];
    const [extensions, setExtensions] = useState(defaultExtension);

    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };

    const fileChanged = (value: string) => {
        setLogoDataUri(value);
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setForm({
            ...form,
            validateInit: {
                displayId: form.validateInit.displayId,
                displayName: form.validateInit.displayName,
                language: true,
                contactEmail: form.validateInit.contactEmail,
            },
            language: event.target.value === locale.Language.English ? schema.Language.En : schema.Language.Ja,
        });
    };
    const handleValidateDispId = () => {
        if (!form.validateInit.displayId) {
            return '';
        }
        const msg = validator.Validate<config.Form>(form, config.validations(), config.NewValidation);
        const dispMessage = msg === null ? '' : msg.displayId === null ? '' : msg!.displayId.toString();
        return dispMessage;
    };
    const handleValidateDispName = () => {
        if (!form.validateInit.displayName) {
            return '';
        }
        const msg = validator.Validate<config.Form>(form, config.validations(), config.NewValidation);
        const dispMessage = msg === null ? '' : msg.displayName === null ? '' : msg!.displayName.toString();
        return dispMessage;
    };
    const handleValidateLanguage = () => {
        if (!form.validateInit.language) {
            return '';
        }
        const msg = validator.Validate<config.Form>(form, config.validations(), config.NewValidation);
        const dispMessage = msg === null ? '' : msg.language === null ? '' : msg!.language.toString();
        return dispMessage;
    };
    const handleValidateContactEmail = () => {
        if (!form.validateInit.contactEmail) {
            return '';
        }
        const msg = validator.Validate<config.Form>(form, config.validations(), config.NewValidation);
        const dispMessage = msg === null ? '' : msg.contactEmail === null ? '' : msg!.contactEmail.toString();
        return dispMessage;
    };

    const buttonDisabled = () => {
        return (
            validator.Validate<config.Form>(form, config.validations(), config.NewValidation) !== null ||
            validator.Validate<modelOrganization.OrganizationInfo>(organization, modelOrganization.validation(), modelOrganization.NewValidation) !== null
        );
    };

    return {
        ui,
        form,
        setForm,
        organization,
        setOrganization,
        logoDataUri,
        extensions,
        setExtensions,
        updateUIStatus,
        fileChanged,
        handleChange,
        handleValidateDispId,
        handleValidateDispName,
        handleValidateLanguage,
        handleValidateContactEmail,
        buttonDisabled,
    };
};
export const WorkspaceContainer = createContainer(useWorkspaceContainer);
