import { default as React, useEffect } from 'react';
import locale from '@/common/utils/locale';
import {
    Theme,
    createStyles,
    WithStyles,
    withStyles,
    Button,
    Grid,
    Paper,
    FormControl,
    Select,
    FilledInput,
    InputLabel,
    Typography,
    Popover,
    Chip,
    Fab,
    Card,
    CardContent,
    Checkbox,
} from '@material-ui/core';
import { NewDeviceContainer } from './NewDeviceContainer';
import { default as ChipInput } from 'material-ui-chip-input';
import * as errorHandler from '@/common/utils/errorHandler';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Help } from '@material-ui/icons';
import PopupMfp from './PopupMfp';
import routes from '@/admin/constants/routes';
import { Link } from 'react-router-dom';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            overflowX: 'auto',
        },
        mfpListContainer: {
            width: '100%',
            maxWidth: 740,
            minHeight: 90,
            marginLeft: 20,
            marginTop: 20,
            marginBottom: 5,
            borderRadius: '4px',
            border: '2px solid #bbbbbb',
            paddingTop: '10px',
            paddingLeft: '10px',
            textAlign: 'left',
            '&& input': {
                paddingBottom: 40,
            },
            '&& p': {
                marginTop: -15,
            },
        },
        input: {
            height: 40,
            paddingLeft: 10,
        },
        cardEdit: {
            minHeight: 196,
            borderRadius: 4,
        },
        cardAction: {
            minHeight: 196,
            borderRadius: 4,
            marginLeft: 20,
        },
        cardContent: {
            marginTop: 5,
            marginBottom: 20,
        },
        textFieldDeviceName: {
            width: 300,
            height: 66,
            borderRadius: 4,
            backgroundColor: '#eeeeee',
            marginRight: 50,
        },
        textFieldMfpNumber: {
            width: 240,
            height: 66,
            borderRadius: 4,
            backgroundColor: '#eeeeee',
        },
        textFieldMfpName: {
            width: 300,
            height: 66,
            borderRadius: 4,
            marginTop: 30,
            marginRight: 50,
        },
        textFieldRegistrationPin: {
            width: 240,
            height: 66,
            borderRadius: 4,
            marginTop: 30,
        },
        formControl: {
            float: 'left',
            minWidth: '255px',
            minHeight: '65px',
            borderRadius: '4px',
            marginTop: 20,
            marginLeft: 40,
        },
        buttonFrame: {
            border: `1px solid ${theme.palette.secondary.main}`,
            marginTop: 25,
            marginBottom: 40,
            backgroundColor: '#ffffff',
            color: `${theme.palette.secondary.main}`,
            borderRadius: 6,
            height: '48px',
            '&:hover': {
                color: '#ffffff',
            },
        },
        button: {
            minWidth: 240,
            maxWidth: 280,
            color: 'white',
            height: 44,
            margin: `10px auto 30px`,
        },
        deleteButton: {
            minWidth: 60,
            height: 44,
            color: '#999999',
            margin: `0 auto`,
        },
        label: {
            textAlign: 'left',
            'font-size': '14px',
            'padding-left': '23px',
            'padding-top': '6px',
        },
        btnPopup: {
            marginTop: '6px',
            marginLeft: '24px',
            color: '#ff7300',
            cursor: 'pointer',
        },
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            maxWidth: '50%',
            backgroundColor: '#666666',
            color: 'white',
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        selectInput: {
            padding: '28px 12px 9px 12px',
        },
        collectiveRegister: {
            textAlign: 'center',
        },
        register: {
            textAlign: 'end',
        },
        everyoneCheckRoot: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: 10,
        },
        everyoneCheckText: {
            marginBlock: 'auto',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const newDeviceContainer = NewDeviceContainer.useContainer();
    const appContainer = AdminAppContainer.useContainer();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const openPopover = Boolean(anchorEl);

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        void (async () => {
            try {
                if (newDeviceContainer.isRegisting) {
                    await newDeviceContainer.handleClickRegisterMfp();
                }
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newDeviceContainer.isRegisting]);

    const deadlineList = [];
    const dayList = [7, 30];
    for (let i = 0; i < dayList.length; i += 1) {
        deadlineList.push(
            <option key={dayList[i]} value={dayList[i]}>
                {locale.t(locale.keys.manageShareDevice.deadlineValue.index, { day: dayList[i] })}
            </option>,
        );
    }
    deadlineList.push(
        <option key={0} value={0}>
            {locale.t(locale.keys.manageShareDevice.deadlineValue.infinity)}
        </option>,
    );

    return (
        <div>
            <Card>
                <CardContent>
                    <Grid container className={classes.root}>
                        <Grid item xs={12} container spacing={8}>
                            <Typography className={classes.label}>{locale.t(locale.keys.manageShareDevice.mfpNumber.index)}</Typography>
                            <div className={classes.btnPopup} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                                <Help fontSize={'small'} onClick={() => newDeviceContainer.setIsOpenPopup(true)} />
                            </div>
                            <Popover
                                id="mouse-over-popover"
                                open={openPopover}
                                anchorEl={anchorEl}
                                className={classes.popover}
                                classes={{
                                    paper: classes.paper,
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                {locale.t(locale.keys.pageTitle.mfp.popupMfp.popoverMessage)}
                            </Popover>
                        </Grid>
                        <Grid item xs={12} container spacing={8}>
                            <Grid item xs={8}>
                                <Paper className={classes.mfpListContainer}>
                                    <ChipInput
                                        disableUnderline={true}
                                        value={newDeviceContainer.mfpList}
                                        onAdd={(chip: string) => newDeviceContainer.handleAddMfp(chip.toUpperCase())}
                                        onDelete={(chip: string) => newDeviceContainer.handleDeleteMfp(chip)}
                                        fullWidth
                                        error={newDeviceContainer.errors.mfpNumber !== ''}
                                        helperText={newDeviceContainer.errors.mfpNumber}
                                        chipRenderer={({ value, handleDelete, className }, key) => {
                                            return <Chip key={key} className={className} onDelete={handleDelete} label={value} />;
                                        }}
                                    />
                                </Paper>
                                <Grid className={classes.everyoneCheckRoot} item xs={12}>
                                    <Checkbox color="secondary" onChange={newDeviceContainer.handleEveryoneChecked} checked={newDeviceContainer.everyoneChecked} />
                                    <Typography className={classes.everyoneCheckText}>{locale.t(locale.keys.manageShareDevice.everyoneCheck.index)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel>{locale.t(locale.keys.manageShareDevice.deadlineTitle.index)}</InputLabel>
                                    <Select
                                        native
                                        value={newDeviceContainer.deadlineSelected}
                                        onChange={newDeviceContainer.handleChangeDeadlineSelected}
                                        input={<FilledInput classes={{ input: classes.selectInput }} />}
                                        inputProps={{
                                            style: { height: '1.46em', lineHeight: 'normal' },
                                        }}
                                    >
                                        {deadlineList}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={7} className={classes.register}>
                            <Button className={classes.button} fullWidth variant="contained" disabled={newDeviceContainer.buttonDisabled()} onClick={() => newDeviceContainer.setIsRegisting(true)}>
                                {locale.t(locale.keys.manageShareDevice.applyToRegisterButton.index)}
                            </Button>
                        </Grid>
                        {/* 一括登録ボタン */}
                        <Grid item xs={12} sm={5} className={classes.collectiveRegister}>
                            <Link to={routes.device.collective.index}>
                                <Fab variant="extended" className={classes.button} size="large" color="secondary">
                                    {locale.t(locale.keys.pageTitle.mfp.collective)}
                                </Fab>
                            </Link>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <PopupMfp />
        </div>
    );
};

export default withStyles(styles)(Component);
