import { default as React, useEffect } from 'react';
import { RouteComponentProps, withRouter, generatePath, Prompt } from 'react-router';
import locale from '@/common/utils/locale';
import routes from '@/admin/constants/routes';
import { Theme, createStyles, WithStyles, withStyles, Grid, Tabs, Tab, Paper } from '@material-ui/core';
import { DetailDeviceContainer, disableRegisterDeviceMemberButton, disabledEveryoneCheck } from './DetailDeviceContainer';
import { AdminAppContainer } from '../../AdminAppContainer';
import MemberResister from './MemberResister';
import MemberTable from './MemberTable';
import GroupTable from './GroupTable';
import { styles as baseTabStyles } from './tabStyle';

const styles = (theme: Theme) =>
    createStyles({
        ...baseTabStyles(theme),
        root: {
            width: '100%',
            overflowX: 'auto',
        },
    });

type TParams = { id: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    skipEffect?: boolean;
    setPreviousRoute: (previousRoute: string) => void;
    setPreviousTitle: (previousTitle: string) => void;
}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const detailDeviceContainer = DetailDeviceContainer.useContainer();
    detailDeviceContainer.setWorkspaceMfpId(props.match.params.id);
    const appContainer = AdminAppContainer.useContainer();
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        if (detailDeviceContainer.isRegistered) {
            props.setPreviousRoute(routes.device.index);
            props.setPreviousTitle(locale.t(locale.keys.manageShareDevice.index));
        } else {
            props.setPreviousRoute(routes.device.new);
            props.setPreviousTitle(locale.t(locale.keys.manageShareDevice.new));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailDeviceContainer.isRegistered]);

    useEffect(() => {
        if (detailDeviceContainer.isDeleted && detailDeviceContainer.isRegistered) {
            props.history.push(`${generatePath(routes.device.index)}`);
            detailDeviceContainer.setIsDeleted(false);
        }
        if (detailDeviceContainer.isDeleted && !detailDeviceContainer.isRegistered) {
            props.history.push(`${generatePath(routes.device.new)}`);
            detailDeviceContainer.setIsDeleted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailDeviceContainer.isDeleted, detailDeviceContainer.isRegistered]);

    useEffect(() => {
        appContainer.updateLoadingState(detailDeviceContainer.ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailDeviceContainer.ui]);

    return (
        <>
            <Grid container style={{ gap: 24 }}>
                <Grid item xs={12}>
                    <MemberResister
                        handleClickRegisterMember={detailDeviceContainer.handleClickRegisterMember}
                        buttonRegisterDisabled={disableRegisterDeviceMemberButton(detailDeviceContainer.device.form.allowedMember)}
                        everyoneChecked={detailDeviceContainer.isEveryoneChecked()}
                        handleChangeEveryoneChecked={detailDeviceContainer.handleEveryoneCheck}
                        disabledEveryoneCheck={detailDeviceContainer.device.deviceMemberData ? disabledEveryoneCheck(detailDeviceContainer.device.deviceMemberData) : false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        <div className={classes.tabs} style={{ marginInlineStart: '4px' }}>
                            <Tabs value={tabIndex} onChange={handleTabChange} classes={{ root: classes.tabsContainer, indicator: classes.indicator }}>
                                <Tab label={locale.t(locale.keys.manageShareDevice.selectTable.user)} className={classes.tabTags} classes={{ selected: classes.tabSelected }} />
                                <Tab label={locale.t(locale.keys.manageShareDevice.selectTable.group)} className={classes.tabTags} classes={{ selected: classes.tabSelected }} />
                            </Tabs>
                        </div>
                        {tabIndex === 0 ? (
                            // 利用可能ユーザーリスト
                            <MemberTable />
                        ) : tabIndex === 1 ? (
                            // 利用可能グループリスト
                            <GroupTable />
                        ) : (
                            {}
                        )}
                    </Paper>
                </Grid>
                <Prompt when={detailDeviceContainer.isEdit} message={locale.t(locale.keys.promptMessage)} />
            </Grid>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(Component));
