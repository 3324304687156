/**
 * メールアドレスの検証項目
 */
export const EmailValidate = {
    // Active Directory での最大長が64文字
    // 入力文字列に10文字追加して登録するので、入力可能な文字は54文字まで
    MAX_LENGTH: 54,
};

/**
 * アナウンスメールの検証
 */
export const AnnouncementMail = {
    SUBJECT_MAX_LENGTH: 80,
    TEXT_MAX_LENGTH: 2000,
};
