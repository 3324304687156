import { default as React, useState, useEffect } from 'react';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { NoticeContainer } from './NoticeContainer';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as errorHandler from '@/common/utils/errorHandler';
import { getStringByteCount } from '@/common/utils/webappUtil';
import { AnnouncementMail } from '@/common/constants/email';
import { TextField, withStyles, WithStyles, Fab, Grid, FormControl, Select, InputLabel, Typography, FilledInput } from '@material-ui/core';
import * as errorLocale from '@/common/utils/locale/error-locale';
import { styles } from './css/styles';

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const ui = useUI();
    const appContainer = AdminAppContainer.useContainer();
    const noticeContainer = NoticeContainer.useContainer();
    const { classes } = props;
    // 件名入力エラー
    const [titleError, setTitleError] = useState<string>('');
    // 本文入力エラー
    const [textError, setTextError] = useState<string>('');





    /**
     * 件名の編集時
     */
    const editMailTitle = (title: string) => {
        if (getStringByteCount(title) <= AnnouncementMail.SUBJECT_MAX_LENGTH) {
            noticeContainer.setSendInfo({ ...noticeContainer.sendInfo, mailtitle: title });
            setTitleError('');
        } else {
            setTitleError(locale.t(locale.keys.validation.tooLongByte, { num: AnnouncementMail.SUBJECT_MAX_LENGTH }));
        }
    };

    /**
     * 本文の編集時
     */
    const editMailText = (text: string) => {
        if (getStringByteCount(text) <= AnnouncementMail.TEXT_MAX_LENGTH) {
            noticeContainer.setSendInfo({ ...noticeContainer.sendInfo, mailtext: text });
            setTextError('');
        } else {
            setTextError(locale.t(locale.keys.validation.tooLongByte, { num: AnnouncementMail.TEXT_MAX_LENGTH }));
        }
    };

    /**
     * 送信ボタンを押したときの処理
     */
    const preRequest = () => {
        ui.update(UI.Loading);

        // 送信処理
        (async () => {
            try {
                const res = await noticeContainer.postSendAnouncementMail(appContainer.values.authorizationCode, noticeContainer.sendInfo);
                if (res.success) {
                    // 送信成功のメッセージを表示
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.pageTitle.announcement.sendresult.success),
                        variant: Variants.success,
                    });
                }
                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Error);
                // エラーの分析
                const error = errorHandler.handleApiError(appContainer, e);
                if (error) {
                    switch (error.code) {
                        // 'ServiceUnavailable'
                        case errorLocale.translate(errorLocale.keys.E00003).code:
                            // レートリミットの可能性があるので時間を空けて再送
                            break;
                    }
                }
            }
        })();
    };

    return (
        <Grid container item xs={12} direction="column" justify="space-around" alignItems="center" spacing={24}>
            <Grid container item xs={12} justify="space-around" alignItems="center">
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="filled-period" variant="filled">
                        {locale.t(locale.keys.pageTitle.announcement.destination.index)}
                    </InputLabel>
                    {/* 送信先の選択 */}
                    <Select
                        native
                        value={noticeContainer.sendInfo.destination}
                        onChange={(event) => noticeContainer.setSendInfo({ ...noticeContainer.sendInfo, destination: event.target.value as schema.V1ObjectsNoticeDestination })}
                        input={<FilledInput name="period" id="filled-period" />}
                    >
                        <option value={schema.V1ObjectsNoticeDestination.System}>{locale.t(locale.keys.pageTitle.announcement.destination.system_root)}</option>
                        <option value={schema.V1ObjectsNoticeDestination.Allusers}>{locale.t(locale.keys.pageTitle.announcement.destination.allmember)}</option>
                        <option value={schema.V1ObjectsNoticeDestination.Workspaces}>{locale.t(locale.keys.pageTitle.announcement.destination.workspace)}</option>
                        <option value={schema.V1ObjectsNoticeDestination.Resend}>{locale.t(locale.keys.pageTitle.announcement.destination.resend)}</option>
                    </Select>
                </FormControl>
            </Grid>
            <Grid container item xs={12} direction="row" justify="space-around" alignItems="center" spacing={24}>
                <Grid container item xs={12} sm={8} direction="column" justify="space-around" alignItems="center">
                    <Grid container item xs={12} direction="column" justify="space-around" alignItems="center">
                        <Typography className={classes.textTitle} align="left">
                            {locale.t(locale.keys.pageTitle.announcement.mailtitle)}
                        </Typography>
                        {/* 件名の入力 */}
                        <TextField
                            className={classes.textTitle}
                            required
                            value={noticeContainer.sendInfo.mailtitle}
                            variant="outlined"
                            defaultValue=""
                            onChange={(event) => editMailTitle(event.target.value)}
                            error={titleError !== ''}
                            helperText={titleError}
                            disabled={noticeContainer.sendInfo.destination === schema.V1ObjectsNoticeDestination.Resend}
                        />
                    </Grid>
                    <Grid container item xs={12} direction="column" justify="space-around" alignItems="center">
                        <Typography className={classes.textTitle} align="left">
                            {locale.t(locale.keys.pageTitle.announcement.mailtext)}
                        </Typography>
                        {/* メール本文の入力 */}
                        <TextField
                            className={classes.textBox}
                            required
                            value={noticeContainer.sendInfo.mailtext}
                            multiline
                            variant="outlined"
                            rows={15}
                            defaultValue=""
                            onChange={(event) => editMailText(event.target.value)}
                            error={textError !== ''}
                            helperText={textError}
                            disabled={noticeContainer.sendInfo.destination === schema.V1ObjectsNoticeDestination.Resend}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ width: '100%' }} justify="space-around" alignItems="center">
                {/* 送信ボタン */}
                <Fab
                    variant="extended"
                    className={classes.sendButton}
                    onClick={preRequest}
                    size="large"
                    color="secondary"
                    disabled={
                        noticeContainer.sendInfo.mailtitle === '' ||
                        noticeContainer.sendInfo.mailtext === '' ||
                        titleError !== '' ||
                        textError !== '' ||
                        appContainer.loadingState
                    }
                >
                    {locale.t(locale.keys.pageTitle.announcement.send)}
                </Fab>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(Component);
