import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@material-ui/core';
import locale from '@/common/utils/locale';

// TODO ./Confirm.tsxおよび./CopyConfirm.tsxとの共通化
// header footer bodyのスタイルを共通化

const styles = (theme: Theme) =>
    createStyles({
        actionButton: {
            marginRight: theme.spacing.unit * 2,
            '&& button': {
                textTransform: 'unset',
            },
        },
        okBtn: {
            color: '#ff7300',
            border: 'none',
        },
        body: {
            '& *': {
                fontSize: '1.25rem',
                color: 'black',
                fontWeight: 400,
            },
        },
        title: {
            '& h6': {
                fontWeight: 600,
            },
        },
    });

interface AlertActionsProps extends WithStyles<typeof styles> {
    onClose: () => void;
}

const AlertActions = withStyles(styles)((props: AlertActionsProps) => {
    return (
        <>
            <Button size="small" color="primary" onClick={props.onClose}>
                {locale.t(locale.keys.action.close)}
            </Button>
        </>
    );
});

interface ConfirmActionsProps extends WithStyles<typeof styles> {
    callBack: (value?: string) => void;
    onClose: () => void;
}

const ConfirmActions = withStyles(styles)((props: ConfirmActionsProps) => {
    const { classes } = props;
    return (
        <>
            <Button size="small" color="primary" onClick={props.onClose}>
                {locale.t(locale.keys.action.cancel)}
            </Button>
            <Button
                variant="outlined"
                className={classes.okBtn}
                onClick={() => {
                    props.callBack();
                }}
            >
                {locale.t(locale.keys.action.ok)}
            </Button>
        </>
    );
});

interface NoticeActionsProps extends WithStyles<typeof styles> {
    callBack: (value?: string) => void;
    onClose: () => void;
}

const NoticeActions = withStyles(styles)((props: NoticeActionsProps) => {
    const { classes } = props;
    return (
        <Button
            variant="outlined"
            className={classes.okBtn}
            onClick={() => {
                props.callBack();
            }}
        >
            {locale.t(locale.keys.action.ok)}
        </Button>
    );
});

export interface DialogBaseProps {
    callBack: (value?: string) => void;
    onClose: () => void;
    isOpen: boolean;
    title: string;
    renderChildren: () => JSX.Element;
    type?: 'alert' | 'confirm' | 'notice';
}

export interface Props extends WithStyles<typeof styles>, DialogBaseProps {}

const DialogBase: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    // 本文が空状態のコンポーネント表示を防ぐため
    if (!props.isOpen) return <></>;

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" className={classes.title}>
                {props.title}
            </DialogTitle>
            <DialogContent className={classes.body}>{props.renderChildren()}</DialogContent>
            <DialogActions>
                <div className={classes.actionButton}>
                    {props.type === 'alert' ? (
                        <AlertActions onClose={props.onClose} />
                    ) : props.type === 'notice' ? (
                        <NoticeActions callBack={props.callBack} onClose={props.onClose} />
                    ) : (
                        <ConfirmActions callBack={props.callBack} onClose={props.onClose} />
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(DialogBase);
