import * as schema from '@/bundles/schema/typescript/schema';

export const arrayMove = (array: schema.V1ObjectsEndpointInfo[], from: number, to: number): schema.V1ObjectsEndpointInfo[] => {
    if (!array || array.length === 0) {
        return [];
    }
    const newArray = array.slice();
    arrayMoveMutate(newArray, from, to);
    return newArray;
};

const arrayMoveMutate = (array: schema.V1ObjectsEndpointInfo[], from: number, to: number) => {
    const startIndex = to < 0 ? array.length + to : to;
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
};

/**
 * 配列が null or 空配列 の時true
 * @param array
 */
export const isNullOrEmptyArray = (array: object[] | string[]): boolean => {
    if (Array.isArray(array) && array['length'] > 0) {
        return false;
    }
    return true;
};
