import { default as React } from 'react';
import { Search, CalendarToday } from '@material-ui/icons';
import { Fab, Grid, withStyles, WithStyles, createStyles, Theme, Paper, IconButton, InputBase, Typography } from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EntryContainer } from './EntryContainer';
import { State as UI } from '@/common/components/hooks/useUI';
import DataPicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./calendar.css"
import moment from 'moment';
import { DateFormat } from '@/common/constants/dateFormat';
import locale from '@/common/utils/locale';

const SERCHE_INPUT_HEIGHT = "55px"

const searchUnitStyles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        search: {
            width: '80%',
        },
        searchPaper: {
            width: '100%',
            height: SERCHE_INPUT_HEIGHT,
            marginTop: theme.spacing.unit * 1.5,
            marginBottom: theme.spacing.unit * 1,
            overflowX: 'auto',
        },
        searchGrid: {
            height: "100%",
            width: "100%",
            overflow: "hidden",
            margin: "auto"
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        searchIconButton: {
            marginInlineStart: "8px"
        },
        titleGrid: {
            width: '20%',
            marginBlock: 'auto',
            paddingInlineStart: '8px',
        },
        title: {
            textAlign: 'start',
            fontSize: 12,
            fontWeight: 'bold',
            color: '#333333',
        }
    });

interface SearchUnitProps extends WithStyles<typeof searchUnitStyles> {
    title: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchText: string;
}

const StyledSearch = withStyles(searchUnitStyles, { withTheme: true })((props: SearchUnitProps) => {
    const { classes } = props;

    return (
        <Grid container>
            <Grid item container className={classes.root}>
                <Grid item className={classes.titleGrid}>
                    <Typography className={classes.title}>{props.title}</Typography>
                </Grid>
                <Grid item className={classes.search}>
                    <Paper className={classes.searchPaper}>
                        <Grid container className={classes.searchGrid} direction="row">
                            <IconButton className={classes.searchIconButton} aria-label="Search">
                                <Search />
                            </IconButton>
                            <InputBase
                                className={classes.searchInput}
                                value={props.searchText}
                                inputProps={{ style: { height: '100%' } }}
                                onChange={props.onChange}
                                fullWidth={true}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
});

const calendarSearchStyles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        column: {
            width: '80%',
        },
        search: {
            width: '45%',
        },
        betweenText: {
            width: '10%',
            marginBlock: 'auto',
        },
        betweenTextFont: {
            fontSize: '30px',
        },
        searchPaper: {
            width: '100%',
            height: SERCHE_INPUT_HEIGHT,
            marginTop: theme.spacing.unit * 1.5,
            marginBottom: theme.spacing.unit * 1,
            overflowX: 'auto',
        },
        searchGrid: {
            height: "100%",
            width: "100%",
            overflow: "hidden",
            margin: "auto",
            flexWrap: "inherit"
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        // カレンダーのフォームだけ入力できないことを示すためにキャレットを見えなくする。
        searchInputCalendar: {
            width: `calc(100% - 64px)`,
            padding: '8px',
            caretColor: "transparent",
        },
        searchOutput: {
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
            flexGrow: 1,
            margin: "auto",
            padding: "8px",
            fontSize: "1.0714285714285714rem" //MUIのInputBaseのデフォルトと同じ文字サイズ
        },
        searchIconButton: {
            margin: "auto",
            marginInlineStart: "8px",
        },
        titleGrid: {
            width: '20%',
            marginBlock: 'auto',
            paddingInlineStart: '8px',
        },
        title: {
            textAlign: 'start',
            fontSize: 12,
            fontWeight: 'bold',
            color: '#333333',
        },
        tips: {
            textAlign: 'end',
            width: '100%',
        },
        dataPicker: {
            margin: 'auto',
        }
    });

interface CalendarSearchProps extends WithStyles<typeof calendarSearchStyles> {}

const CalendarSearch = withStyles(calendarSearchStyles, { withTheme: true })((props: CalendarSearchProps) => {
    const { classes } = props;
    const container = EntryContainer.useContainer();
    let title;
    switch (container.tab) {
        case 'tab4':
            title = locale.t(locale.keys.applicationList.search.date.tab4);
            break;
        case 'tab5':
            title = locale.t(locale.keys.applicationList.search.date.tab5);
            break;
        default:
            title = locale.t(locale.keys.applicationList.search.date.default);
    }
 
    const Today = new Date()

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.titleGrid}>
                <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid item container className={classes.column}>
                <Grid item container>
                    <Grid item className={classes.search}>
                        <Paper className={classes.searchPaper}>
                            <Grid container className={classes.searchGrid} direction="row">
                                <IconButton className={classes.searchIconButton} aria-label="Search">
                                    <Search />
                                </IconButton>
                                <InputBase
                                    className={classes.searchInputCalendar}
                                    value={container.searchState.searchStartDate ? moment(container.searchState.searchStartDate).format(DateFormat.fullDateWithSlash) : ""}
                                    inputProps={{ style: { height: '100%' } }}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {(e.key === "Backspace" || e.key === "Delete") && container.handleChangeSearchStartDate(null)}}
                                    fullWidth={true}
                                />
                                <Grid item className={classes.dataPicker}>
                                    <DataPicker
                                        onChange={(selectDate) => selectDate && container.handleChangeSearchStartDate(selectDate)}
                                        selected={container.searchState.searchStartDate ? container.searchState.searchStartDate : Today}
                                        popperPlacement={"bottom-end"}
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["bottom"]
                                            },
                                            preventOverflow: {
                                                enabled: false
                                            },
                                            hide: {
                                                enabled: false
                                            }
                                        }}
                                        customInput={
                                            <Grid className={classes.searchIconButton}>
                                                <IconButton aria-label="CalendarStart">
                                                    <CalendarToday />
                                                </IconButton>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item className={classes.betweenText}>
                        <Typography variant="h2">~</Typography>
                    </Grid>
                    <Grid item className={classes.search}>
                        <Paper className={classes.searchPaper}>
                            <Grid container className={classes.searchGrid} direction="row">
                                <IconButton className={classes.searchIconButton} aria-label="Search">
                                    <Search />
                                </IconButton>
                                <InputBase
                                    className={classes.searchInputCalendar}
                                    value={container.searchState.searchEndDate ? moment(container.searchState.searchEndDate).format(DateFormat.fullDateWithSlash) : ""}
                                    inputProps={{ style: { height: '100%' } }}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {(e.key === "Backspace" || e.key === "Delete") && container.handleChangeSearchEndDate(null)}}
                                    fullWidth={true}
                                />
                                <Grid item className={classes.dataPicker}>
                                    <DataPicker
                                        onChange={(selectDate) => selectDate && container.handleChangeSearchEndDate(selectDate)}
                                        selected={container.searchState.searchEndDate ? container.searchState.searchEndDate : Today}
                                        popperPlacement={"bottom-end"}
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["bottom"]
                                            },
                                            preventOverflow: {
                                                enabled: false
                                            },
                                            hide: {
                                                enabled: false
                                            }
                                        }}
                                        customInput={
                                            <Grid className={classes.searchIconButton}>
                                                <IconButton aria-label="CalendarStart">
                                                    <CalendarToday />
                                                </IconButton>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
            paddingInline: '40px',
            paddingBlock: '32px',
        },
        column: {
            width: '47%',
        },
        columnSmall: {
            width: '100%',
        },
        searchFab: {
            color: 'white',
            width: "160px",
            right: theme.spacing.unit * 2,
            '&& span': {
                fontSize: '1rem',
            },
        }
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

const EntrySearchComponent: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const container = EntryContainer.useContainer();
    const matches = useMediaQuery('(min-width:1600px)');

    return (
        <Paper className={classes.root}>
            {
                matches ? (
                    <Grid container direction="row" justify="space-between">
                        <Grid item container direction="column" className={classes.column}>
                            <StyledSearch
                                title={locale.t(locale.keys.applicationList.search.entryNumber)}
                                searchText={container.searchState.searchEntryNumber}
                                onChange={container.handleChangeSearchEntryNumber}
                            />
                            <CalendarSearch/>
                        </Grid>
                        <Grid item container direction="column" className={classes.column}>
                            <StyledSearch title={locale.t(locale.keys.applicationList.search.company)} searchText={container.searchState.searchCompany} onChange={container.handleChangeSearchCompany} />
                            <StyledSearch title={locale.t(locale.keys.applicationList.search.manager)} searchText={container.searchState.searchName} onChange={container.handleChangeSearchName} />
                            <StyledSearch title={locale.t(locale.keys.applicationList.search.mail)} searchText={container.searchState.searchMail} onChange={container.handleChangeSearchMail} />
                        </Grid>
                    </Grid>
                ):(
                    <Grid container direction="column" justify="space-between">
                        <Grid item container direction="column" className={classes.columnSmall}>
                            <StyledSearch
                                title={locale.t(locale.keys.applicationList.search.entryNumber)}
                                searchText={container.searchState.searchEntryNumber}
                                onChange={container.handleChangeSearchEntryNumber}
                            />
                            <CalendarSearch/>
                        </Grid>
                        <Grid item container direction="column" className={classes.columnSmall}>
                            <StyledSearch title={locale.t(locale.keys.applicationList.search.company)} searchText={container.searchState.searchCompany} onChange={container.handleChangeSearchCompany} />
                            <StyledSearch title={locale.t(locale.keys.applicationList.search.manager)} searchText={container.searchState.searchName} onChange={container.handleChangeSearchName} />
                            <StyledSearch title={locale.t(locale.keys.applicationList.search.mail)} searchText={container.searchState.searchMail} onChange={container.handleChangeSearchMail} />
                        </Grid>
                    </Grid>
                )
            }
            <Grid style={{ textAlign: 'right', marginBlockStart: '16px', width: "100%"}}>
                {
                    container.ui.current === UI.Loading ? (
                        <Fab disabled={true} variant="extended" onClick={container.onClickSearch} className={classes.searchFab} size="large" color="secondary">
                            {locale.t(locale.keys.applicationList.search.button)}
                        </Fab>
                    ):(
                        <Fab disabled={false} variant="extended" onClick={container.onClickSearch} className={classes.searchFab} size="large" color="secondary">
                            {locale.t(locale.keys.applicationList.search.button)}
                        </Fab>
                    )
                }
            </Grid>
        </Paper>
    );
};

export const EntrySearch = withStyles(styles)(withRouter(EntrySearchComponent));
