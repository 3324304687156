import { Theme, createStyles } from '@material-ui/core';

export const detailStyles = (theme: Theme) =>
    createStyles({
        pageRoot: {
            padding: `0px ${theme.spacing.unit * 3}px`,
            marginBlockEnd: 20,
        },
        content: {
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing.unit * 3,
            },
        },
        movetoInviteFab: {
            color: 'white',
            position: 'absolute',
            top: '-23px',
            right: theme.spacing.unit * 2,
            '&& span': {
                fontSize: '1rem',
            },
            height: '44px',
        },
        modalWindow: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            outline: 'none',
        },
        searchRoot: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        searchIconButton: {
            padding: 10,
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        clientInformation: {
            textAlign: 'left',
            marginTop: '20px',
            fontWeight: 'bold',
        },
        sendGroup: {
            color: 'blue',
            cursor: 'pointer',
        },
    });
