import { GroupRow } from '@/common/api/groups';
import { getGroupList, handleChangeSortGroupByName, isGroupSelectedAll, mappingRow, selectGroup, selectGroupAll } from '@/common/models/groups/useGroup';
import * as schema from '@/bundles/schema/typescript/schema';
import { CovasGroupAvatar } from '@/common/components/CovasAvatar';
import { styles as baseTableStyles } from '@/common/components/Table/style';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';
import * as locale from '@/common/utils/locale/locale';
import { Checkbox, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, WithStyles, createStyles, withStyles } from '@material-ui/core';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { Search, UnfoldMore } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';

interface PresenterProps extends WithStyles<typeof styles> {
    formValue: schema.V1XStoragesOrderCreateRequest;
    setFormValue: React.Dispatch<React.SetStateAction<schema.V1XStoragesOrderCreateRequest>>;
    userData: schema.V1ObjectsXStoragesStatus[];
    groupRows: schema.V1WorkspaceGroupsIndexResponse;
    searchText: string;
    selectedGroups: string[];
    setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>;
}

const styles = () =>
    createStyles({
        ...baseTableStyles(),
        tableWrapper: {
            overflowX: 'auto',
        },
        table: {
            padding: 0,
        },

        checkCell: {
            width: 40,
            maxWidth: 40,
            margin: 4,
            wordBreak: 'break-all',
        },
        avatarCell: {
            width: 40,
            maxWidth: 40,
            margin: 4,
            wordBreak: 'break-all',
        },
        groupNameCell: {
            width: 'auto',
            margin: 4,
            wordBreak: 'break-all',
        },
        memberCountCell: {
            width: 160,
            margin: 4,
            wordBreak: 'break-all',
            textAlign: 'center',
        },

        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
    });

const Presenter: FC<PresenterProps> = (props) => {
    const [rows, setRows] = useState<GroupRow>(mappingRow(props.groupRows));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [sortByName, setSortByName] = useState<schema.V1ObjectsSort>(schema.V1ObjectsSort.None);
    const [filteredCount, setFilteredCount] = useState(getGroupList(rows, props.searchText, 0, 0, schema.V1ObjectsSort.None, false).length);

    useEffect(() => {
        setRows(mappingRow(props.groupRows));
    }, [props.groupRows]);

    useEffect(() => {
        setFilteredCount(getGroupList(rows, props.searchText, 0, 0, schema.V1ObjectsSort.None, false).length);
    }, [props.searchText, setFilteredCount, rows]);

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangeSortByName = () => {
        setSortByName(handleChangeSortGroupByName(sortByName));
    };

    const getRows = () => {
        return getGroupList(rows, props.searchText, rowsPerPage, page * rowsPerPage, sortByName, true);
    };

    const handleSelectGroup = (groupId: string) => {
        const updateSelected = selectGroup(props.selectedGroups, groupId);
        props.setSelectedGroups([...updateSelected]);
    };

    const handleSelectGroupAll = () => {
        const updateSelected = selectGroupAll(
            {
                wsGroups: [],
                groups: rows.groups,
            },
            props.selectedGroups,
            !isAllSelected(), // 全選択状態を反転
            props.searchText,
        );
        props.setSelectedGroups([...updateSelected]);
    };

    const isSelected = (groupId: string) => {
        return props.selectedGroups.indexOf(groupId) !== -1;
    };

    const isAllSelected = () => {
        return isGroupSelectedAll(
            {
                wsGroups: [],
                groups: rows.groups,
            },
            props.selectedGroups,
            props.searchText,
        );
    };

    return (
        <Grid className={props.classes.tableWrapper}>
            <Table className={props.classes.table}>
                <TableHead>
                    <TableRow className={props.classes.headerFont}>
                        <TableCell id="column-check" padding="none" className={props.classes.checkCell}>
                            <Checkbox
                                color="secondary"
                                inputProps={{
                                    // inputPropsにdata-testidがないので仕方なくts-ignoreを設定
                                    // TODO: MUIのバージョンアップで解決できるのなら「ts-ignore」は不要
                                    // @ts-ignore
                                    'data-testid': isAllSelected() ? 'header-checkbox-checked' : 'header-checkbox-notchecked',
                                }}
                                checked={isAllSelected()}
                                onChange={(e) => {
                                    handleSelectGroupAll();
                                }}
                            />
                        </TableCell>
                        <TableCell className={props.classes.avatarCell} />
                        <TableCell id="column-mail" padding="none" className={props.classes.groupNameCell}>
                            <Grid className={props.classes.gridInsideHeaderCell}>
                                <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.groupTable.header.groupName)}</p>
                                <Search style={{ marginBlock: 'auto' }} />
                                <IconButton onClick={handleChangeSortByName}>
                                    <UnfoldMore />
                                </IconButton>
                            </Grid>
                        </TableCell>
                        <TableCell className={props.classes.memberCountCell}>{locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.groupTable.header.memberCount)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getRows().map((row, index) => {
                        return (
                            <TableRow key={row.id} tabIndex={-1} selected={false} data-testid="row" className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                                <TableCell padding="none" className={props.classes.checkCell}>
                                    <Checkbox
                                        color="secondary"
                                        checked={isSelected(row.id)}
                                        onChange={(e) => {
                                            handleSelectGroup(row.id);
                                        }}
                                        inputProps={{
                                            // @ts-ignore
                                            'data-testid': `column-checkbox-${row.id}`,
                                        }}
                                    />
                                </TableCell>
                                <TableCell className={props.classes.avatarCell}>
                                    <CovasGroupAvatar alt="Workspace" size={40} src={row.avatarUrl} />
                                </TableCell>
                                <TableCell padding="none" className={props.classes.groupNameCell} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                    {row.name}
                                </TableCell>
                                <TableCell padding="none" className={props.classes.memberCountCell}>
                                    {row.memberCount}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredCount}
                labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                    <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                )}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.previousPage),
                }}
                nextIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.nextPage),
                }}
                onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
                data-testid="pagenation-root"
            />
        </Grid>
    );
};

export default withStyles(styles)(Presenter);
