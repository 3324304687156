import { default as React, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import Loading from '@/common/components/state/Loading';
import * as commonWorkspaceModel from '@/common/api/workspace/profile';
import routes from '@/admin/constants/routes';
import { SigninContainer, State } from './SigninContainer';
import selectWorkspace from './select-workspace';
import passwordAuth from './password-auth';
import * as auth from '@/common/utils/authManagement/authUtil';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';

interface Props extends RouteComponentProps {
    displayId?: string;
}

export const Component: React.FC<Props> = (props) => {
    const container = SigninContainer.useContainer();
    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI(UI.Loading);

    useEffect(() => {
        if (ui.current !== UI.Loading) {
            return;
        }
        if (!props.displayId) {
            ui.update(UI.Loaded);
            return;
        }
        const displayId = props.displayId!;
        const authCookie = auth.getShareAuth();
        (async () => {
            try {
                // cookieがある場合
                if (authCookie != null && authCookie.token !== '' && ui.current === UI.Loading) {
                    container.setState(State.SkipSignin);
                    appContainer.updateLoadingState(UI.Loading);
                    const state = await appContainer.skipSignin(displayId, authCookie, appContainer);
                    appContainer.setValues(state);
                    auth.removeShareAuth();
                    props.history.replace('../../');
                    return;
                }

                // ワークスペース選択
                appContainer.updateLoadingState(UI.Loading);
                const exists = await commonWorkspaceModel.showWorkspaceProfile(displayId);
                if (!exists) {
                    props.history.push(routes.auth.login.index);
                    return;
                }
                container.setValues({
                    ...container.values,
                    workspaceId: exists.id,
                    workspaceDisplayId: exists.displayId,
                    workspaceDisplayName: exists.displayName,
                    workspaceLogoUrl: exists.logoUrl,
                });
                ui.update(UI.Loaded);
                container.setState(State.Login);
                appContainer.updateLoadingState(UI.Loaded);
            } catch (e) {
                // ローディング状態を解除する
                ui.update(UI.Loaded);
                appContainer.updateLoadingState(UI.Loaded);

                // shareAuthはログインスキップの一瞬にしか使われないので、エラーが発生した場合は削除する
                auth.removeShareAuth();

                // ワークスペース選択画面に戻す
                container.setState(State.SelectWorkSpace);

                // エラーハンドリングをしてエラートーストを表示
                errorHandler.handleApiError(appContainer, e);

                // 認証エラー以外でもエラーが発生した場合はログイン画面に遷移する
                props.history.push(routes.auth.login.index);
            }
        })();
        ui.update(UI.Loaded);
    }, [appContainer, container, ui, props]);

    return (
        <>
            {ui.current === UI.Loading && (
                <div data-testid={UI.Loading}>
                    <Loading />
                </div>
            )}

            {ui.current === UI.Loaded && (
                <>
                    {container.state === State.SkipSignin && <Loading />}
                    {container.state === State.SelectWorkSpace && <selectWorkspace.List />}
                    {container.state === State.Login && <passwordAuth.List />}
                </>
            )}
        </>
    );
};

export default withRouter(Component);
