import * as React from 'react';
import locale from '@/common/utils/locale';
import environment from '@/common/constants/environment';

export const Component: React.FC = () => {
    function message() {
        const tos = locale.t(locale.keys.common.tos);
        const policy = locale.t(locale.keys.common.policy);

        // Must start with protocol (https://) to ensure both admin/app is reachable.
        const tosLink = `https://${environment.webapp.admin.hostname}${locale.t(locale.keys.common.tosURL)}`;
        const policyLink = `https://${environment.webapp.admin.hostname}${locale.t(locale.keys.common.policyURL)}`;

        return {
            __html: locale
                .t('newWorkspaceSetting.agreement.message')
                .replace('%tos%', `<a target="_blank" href="${tosLink}">${tos}</a >`)
                .replace('%policy%', `<a target="_blank" href="${policyLink}">${policy}</a >`),
        };
    }
    return (
        <>
            <span dangerouslySetInnerHTML={message()} />
        </>
    );
};

export default Component;
