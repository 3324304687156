import * as React from 'react';
import { Button, Grid, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { PanoramaRounded } from '@material-ui/icons';
import { default as Dropzone } from 'react-dropzone';
import locale from '@/common/utils/locale';

// signature only
const styles = () =>
    createStyles({
        dropzone: {},
        dropzoneText: {},
        button: {},
    });

interface Props extends WithStyles<typeof styles> {
    children?: React.ReactNode;
    onDropAction: (accepts: File[]) => void;
    width: number;
    height: number;
}

export const CovasDropzone: React.FC<Props> = (props) => {
    const { classes } = props;
    const onDrop = (accepts: File[]) => {
        props.onDropAction(accepts);
    };

    return (
        <Grid container spacing={8}>
            <Grid item xs={12} sm={12}>
                <Dropzone
                    multiple={false}
                    // 5MB limit due to Lambda.
                    maxSize={5242880}
                    onDrop={(accepts) => onDrop(accepts)}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            className={classes.dropzone}
                            {...getRootProps()}
                            style={{
                                width: props.width,
                                height: props.height,
                            }}
                        >
                            <input {...getInputProps()} />
                            <div
                                className={classes.dropzoneText}
                                style={{
                                    width: props.width,
                                    height: props.height,
                                    display: 'flex',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    border: '1px solid orange',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'inline-block',
                                        textAlign: 'center',
                                        margin: '0 auto',
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingTop: '5px',
                                            textAlign: 'center',
                                            margin: '0 auto',
                                            fontSize: '15px',
                                            fontWeight: 'bold',
                                            fontStyle: 'normal',
                                            fontStretch: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            color: '#666666',
                                        }}
                                    >
                                        {locale.t(locale.keys.action.dragAndDrop)}
                                    </div>
                                    <div
                                        style={{
                                            paddingTop: '10px',
                                            textAlign: 'center',
                                            margin: '0 auto',
                                            fontSize: '10px',
                                            fontWeight: 'bold',
                                            fontStyle: 'normal',
                                            fontStretch: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            color: '#666666',
                                        }}
                                    >
                                        {locale.t(locale.keys.action.or)}
                                    </div>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            margin: ' 10 auto 5 auto',
                                            textAlign: 'center',
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            fontStyle: 'normal',
                                            fontStretch: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            color: '#ff7300',
                                            borderRadius: '6px',
                                            backgroundColor: 'white',
                                            height: '28px',
                                            border: '1px solid orange',
                                            display: 'block',
                                        }}
                                        onClick={(e) => {}}
                                    >
                                        <PanoramaRounded style={{ display: 'inline-block', paddingBottom: '5px', verticalAlign: 'middle', transform: `scale(1.2)` }} />
                                        <span style={{ display: 'inline-block', paddingBottom: '5px', verticalAlign: 'middle' }}>{locale.t(locale.keys.action.selectFile)}</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </Dropzone>
                {props.children}
            </Grid>
        </Grid>
    );
};
export default withStyles(styles, { withTheme: true })(CovasDropzone);
