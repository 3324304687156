import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';

export enum EPlan {
    FREE = 'free',
    INTERNAL = 'internal',
}

export type Brand = EBrand | ECompanyBrand;
export enum EBrand {
    SYSTEM = 'system',
}
export enum ECompanyBrand {
    OTSUKA = 'otsuka',
    RICOH = 'ricoh',
}
export const CompanyBrandArray = [ECompanyBrand.OTSUKA, ECompanyBrand.RICOH];

export interface PlanItem {
    plan: EPlan;
    message: string;
}

export interface BrandPlanItem {
    brand: Brand;
    plan: EPlan;
    message: string;
}

// brandとlocale文言を組み合わせた情報を返す
export const computePlanItem = (): PlanItem[] => {
    return [
        {
            plan: EPlan.FREE,
            message: locale.t(locale.keys.common.plan.free),
        },
        {
            plan: EPlan.INTERNAL,
            message: locale.t(locale.keys.common.plan.internal),
        },
    ];
};

// brandとplanとlocale文言を組み合わせた情報を返す
export const computeBrandPlanItem = (): BrandPlanItem[] => {
    const companyPlans: BrandPlanItem[] = [];
    for (const company of CompanyBrandArray) {
        companyPlans.push({
            brand: company,
            plan: EPlan.FREE,
            message: `${locale.t(locale.keys.common.plan.free)}(${company})`,
        });
        companyPlans.push({
            brand: company,
            plan: EPlan.INTERNAL,
            message: `${locale.t(locale.keys.common.plan.internal)}(${company})`,
        });
    }
    return companyPlans;
};

// raw stringをenumに変換する
export const stringToBrand = (rawBrand: string): schema.Brand => {
    switch (rawBrand) {
        case schema.Brand.Ricoh:
            return schema.Brand.Ricoh;
        case schema.Brand.Otsuka:
            return schema.Brand.Otsuka;
        case schema.Brand.System:
            return schema.Brand.System;
        default:
            throw new Error('invalid workspaces brand');
    }
};
