import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { GoogleMigrationContainer } from './GoogleMigrationContainer';
import GoogleMigration from './GoogleMigration';
import { default as React } from 'react';
import SubHeader from '@/user/components/common/headers/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        createStyles: {
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.migrationEndpoint.index));

    return (
        <>
            <GoogleMigrationContainer.Provider>
                <GoogleMigration />
            </GoogleMigrationContainer.Provider>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
