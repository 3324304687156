import * as React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import locale from '@/common/utils/locale';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            display: 'none',
            '&& span': {
                color: theme.palette.grey[400],
                fontSize: '0.875rem',
            },
            [theme.breakpoints.up('lg')]: {
                display: 'inline-flex',
            },
            [theme.breakpoints.down('lg')]: {
                display: 'inline-flex',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    cancelFunc: () => void;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    return (
        <>
            <Button onClick={props.cancelFunc} className={classes.button}>
                {locale.t(locale.keys.action.cancel)}
            </Button>
        </>
    );
};

export default withStyles(styles)(Component);
