import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import logger from '@/common/utils/logger';
import { getSplitNewLineString, removeDoubleQuotation, csvPaser, splitString } from '@/common/utils/webappUtil';
import * as validator from '@/common/utils/validator';
import * as schema from '@/bundles/schema/typescript/schema';
import { csvFormat4Mfp } from '@/common/constants/csvFormat';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import locale from '@/common/utils/locale';
import * as errorHandler from '@/common/utils/errorHandler';
import * as encoding from 'encoding-japanese';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { isNullOrEmptyArray } from '@/common/utils/array-helper/arrayHelper';
import * as deviceModel from '@/common/api/workspace/mfp/mfp';
import * as errLocale from '@/common/utils/locale/error-locale';
import { validateGroupName } from '@/common/models/groups/useGroup';

/**
 * @description csv1行あたりのチェック
 */
export const csvRowCheck = (value: string[], index: number) => {
    // 機械番号
    const mfp = validator.Validate<{ mfpNumber: string }>({ mfpNumber: value[csvFormat4Mfp.COL_IDX_MFPNUMBER] }, deviceModel.mfpNumberValidations(), deviceModel.NewMfpNumberValidation);
    if (mfp && mfp.mfpNumber) {
        // エラー表示
        return {
            isSuccess: false,
            errorMessage: errLocale.translate(errLocale.keys.E05011, { row: csvFormat4Mfp.COL_IDX_MFPNUMBER + 1, index: index + 1 }).message,
            mfp: {
                // エラーなので適当なオブジェクトを返却
                groups: [],
                email: [],
                mfpName: '',
                mfpNumber: '',
                omitPin: false,
            } as schema.V1ObjectsCollectiveMfp,
        };
    }
    // 複合機名は特に制限はない。
    // pin省略のフラグチェック
    if (value.length < csvFormat4Mfp.COL_IDX_OMITPIN || !value[csvFormat4Mfp.COL_IDX_OMITPIN]) {
        // エラー表示
        return {
            isSuccess: false,
            errorMessage: errLocale.translate(errLocale.keys.E05012, { row: csvFormat4Mfp.COL_IDX_OMITPIN + 1, index: index + 1 }).message,
            mfp: {
                // エラーなので適当なオブジェクトを返却
                groups: [],
                email: [],
                mfpName: '',
                mfpNumber: '',
                omitPin: false,
            } as schema.V1ObjectsCollectiveMfp,
        };
    }
    if (typeof value[csvFormat4Mfp.COL_IDX_OMITPIN] !== 'string' || !csvFormat4Mfp.OMITPIN_TEMPLATE.includes(value[csvFormat4Mfp.COL_IDX_OMITPIN])) {
        // エラー表示
        return {
            isSuccess: false,
            errorMessage: errLocale.translate(errLocale.keys.E05012, { row: csvFormat4Mfp.COL_IDX_OMITPIN + 1, index: index + 1 }).message,
            mfp: {
                // エラーなので適当なオブジェクトを返却
                groups: [],
                email: [],
                mfpName: '',
                mfpNumber: '',
                omitPin: false,
            } as schema.V1ObjectsCollectiveMfp,
        };
    }
    // メールアドレス
    let emails: string[] = [];
    // メールアドレス欄にEveryoneを入力可能（1.10.0以前の仕様との互換性を保つため。）
    let isEveryoneFromEmails = false;
    if (value.length > csvFormat4Mfp.COL_IDX_EMAIL && value[csvFormat4Mfp.COL_IDX_EMAIL]) {
        // 配列に分割
        const splitRes = splitString(value[csvFormat4Mfp.COL_IDX_EMAIL], csvFormat4Mfp.EMAIL_SPLIT);
        for (let item of splitRes) {
            item = removeDoubleQuotation(item);
            // メールアドレスが'Everyone'だったとき
            if (item.toLowerCase() === schema.V1ObjectsDefaultGroup.Everyone.toLowerCase()) {
                // Everyoneのみグループとして取り入れる。
                isEveryoneFromEmails = true;
            } else {
                const email = validator.Validate<{ email: string }>({ email: item }, deviceModel.emailorEveryoneValidations(), deviceModel.NewEmailValidation);
                if (email && email.email) {
                    return {
                        isSuccess: false,
                        errorMessage: errLocale.translate(errLocale.keys.E05013, { row: csvFormat4Mfp.COL_IDX_EMAIL + 1, index: index + 1 }).message,
                        mfp: {
                            // エラーなので適当なオブジェクトを返却
                            groups: [],
                            email: [],
                            mfpName: '',
                            mfpNumber: '',
                            omitPin: false,
                        } as schema.V1ObjectsCollectiveMfp,
                    };
                }
                if (!emails.includes(item)) {
                    emails.push(item);
                }
            }
        }
    }

    // グループ名
    const groups: string[] = [];
    if (value.length > csvFormat4Mfp.COL_IDX_GROUP && value[csvFormat4Mfp.COL_IDX_GROUP]) {
        // 配列に分割
        const split = value[csvFormat4Mfp.COL_IDX_GROUP].split(csvFormat4Mfp.GROUP_SPLIT);
        const splitRes = split.map((item) => item.trim());
        // グループは省略可
        // グループが入力されていれば、バリデーションしてリクエストに追加
        if (splitRes) {
            for (let item of splitRes) {
                item = removeDoubleQuotation(item);
                const validateResult = validateGroupName(item, undefined, true);
                if (validateResult !== '') {
                    return {
                        isSuccess: false,
                        errorMessage: errLocale.translate(errLocale.keys.E05014, { row: csvFormat4Mfp.COL_IDX_GROUP + 1, index: index + 1 }).message,
                        mfp: {
                            // エラーなので適当なオブジェクトを返却
                            groups: [],
                            email: [],
                            mfpName: '',
                            mfpNumber: '',
                            omitPin: false,
                        } as schema.V1ObjectsCollectiveMfp,
                    };
                }
                if (!groups.includes(item)) {
                    if (item.toLowerCase() === schema.V1ObjectsDefaultGroup.Everyone.toLowerCase()) {
                        groups.push(schema.V1ObjectsDefaultGroup.Everyone);
                    } else {
                        groups.push(item);
                    }
                }
            }
        }
    }

    // メールアドレス欄に入力されたEveryoneグループとグループ欄に入力されたグループを結合して重複を削除
    const anyGroups = isEveryoneFromEmails ? groups.concat([schema.V1ObjectsDefaultGroup.Everyone]) : groups;
    const uniqueGroups = anyGroups.filter((x, i, self) => self.indexOf(x) === i);

    // エラーがなければリクエストに追加
    // 機械番号は全て大文字に変換
    // groupsとemailを合計して1000件を超えると、超過分はAPIに無視される（サーバー負荷軽減のため）。
    return {
        isSuccess: true,
        errorMessage: '',
        mfp: {
            groups: uniqueGroups,
            email: emails,
            mfpName: value[csvFormat4Mfp.COL_IDX_MFPNAME] ? value[csvFormat4Mfp.COL_IDX_MFPNAME] : '',
            mfpNumber: value[csvFormat4Mfp.COL_IDX_MFPNUMBER].toUpperCase(),
            omitPin: value[csvFormat4Mfp.COL_IDX_OMITPIN].toUpperCase() === 'TRUE' ? true : false,
        } as schema.V1ObjectsCollectiveMfp,
    };
};

const useCollectiveRegisterContainer = () => {
    const appContainer = AdminAppContainer.useContainer();
    const [csvFileName, setCsvFileName] = useState<string>(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));
    const [fileInput, setFileInput] = useState<Blob | null>(null);
    const [code, setCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [buttonDisable, setButtonDisable] = useState<boolean>(false);
    const ui = useUI();
    let blankLine: boolean = false;
    const mfps: schema.V1ObjectsCollectiveMfp[] = [];

    useEffect(() => {
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setButtonDisable(true);
        if (!loading && fileInput) {
            setButtonDisable(false);
        }
    }, [loading, fileInput]);

    /**
     * ファイル選択時の処理
     * @param file csvfile or null
     */
    const onSelectedCSV = (file: File[] | null) => {
        if (file && file.length > 0) {
            // ファイル名を取得
            const fileName = file[0].name;
            // 事前に文字コードの確認のためにファイルを開く
            const reader = new FileReader();
            reader.readAsBinaryString(file[0]);
            reader.onload = () => {
                const str = reader.result as string;
                // BOM付きかチェック
                if (csvFormat4Mfp.CSVFORMAT_BOM.test(str)) {
                    // BOM付きの場合UTF8
                    setCode('UTF8');
                } else {
                    // 文字コード判定
                    const detectResult = encoding.detect(str);
                    if (!detectResult) {
                        // 判定不能の文字コードはUTF8にしておく
                        logger.error(`onSelectedCSV() unknown encoding. fileName:${fileName}`);
                        setCode('UTF8');
                    } else {
                        const detectEncoding = detectResult as string;
                        setCode(detectEncoding);
                    }
                }
            };
            setCsvFileName(fileName);
            setFileInput(file[0]);
        } else {
            setCsvFileName(locale.t(locale.keys.manageShareDevice.collective.noneFile));
            setFileInput(null);
        }
    };

    /**
     * csvのチェック
     * @param value １行ずつ
     * @param index 何行目
     */
    const csvImportCheck = (value: string[], index: number) => {
        // 一行ずつチェック
        // カラム数があっているか
        if (value.length >= csvFormat4Mfp.COLUMNS) {
            if (blankLine) {
                // 空白行の次の行にデータがある場合
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvmincolumnerror, { row: index }),
                    variant: Variants.error,
                });
                return false;
            }

            // CSVの行に対するバリデーション
            const validateCsvResult = csvRowCheck(value, index);
            if (!validateCsvResult.isSuccess) {
                appContainer.updateMessage({
                    isOpen: true,
                    message: validateCsvResult.errorMessage,
                    variant: Variants.error,
                });
                return false;
            }

            mfps.push(validateCsvResult.mfp);
            return true;
        }

        if (value.length === 1 && !value[0]) {
            blankLine = true;
            return true;
        }
        // カラム数エラー
        appContainer.updateMessage({
            isOpen: true,
            message: locale.t(locale.keys.validation.csvmincolumnerror, { row: index + 1 }),
            variant: Variants.error,
        });
        return false;
    };

    /**
     * ファイル読み込み失敗時にファイルをクリアする
     */
    const clearSelectedFile = () => {
        setLoading(false);
        setCsvFileName(locale.t(locale.keys.manageShareDevice.collective.noneFile));
        setFileInput(null);
    };

    /**
     * リクエストの作成とレスポンスの処理
     */
    const sendCollectiveInviteRequest = async () => {
        try {
            setLoading(true);
            ui.update(UI.Loading);
            const request: schema.V1WorkspaceMfpsCollectiveCreateRequest = {
                user: appContainer.values.signinWorkspaceUserObject.id,
                req: mfps,
                fileName: csvFileName,
            };
            await deviceModel.createCollectiveMfpAndMfpUsers(appContainer.values.signinWorkspaceObject.id || '', request, appContainer.values.authorizationCode);

            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.requestTimeout),
                variant: Variants.success,
            });
            setLoading(false);
            ui.update(UI.Loaded);
        } catch (e) {
            // レスポンスエラーの時も選択ファイルクリア
            clearSelectedFile();
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * 「複合機を登録」ボタンを押したときの処理
     */
    const onImportCsv = async () => {
        setLoading(true);
        // ファイルサイズ取得(0byteの場合エラー)
        if (fileInput && fileInput.size) {
            // ファイルの読み込み
            const reader = new FileReader();
            // 読み込み成功
            reader.onload = async () => {
                const fileInput = reader.result as string;
                const lineInput = getSplitNewLineString(fileInput);
                blankLine = false;
                if (lineInput.length <= csvFormat4Mfp.CSV_HEADER) {
                    // ヘッダー行のみのファイルはエラー
                    appContainer.updateMessage({
                        isOpen: true,
                        message: locale.t(locale.keys.validation.csvemptyerror),
                        variant: Variants.error,
                    });
                }
                for (let i = csvFormat4Mfp.CSV_HEADER; i < lineInput.length; i += 1) {
                    // CSVパース
                    let spliteComma = [] as string[];
                    try {
                        spliteComma = csvPaser(lineInput[i], code);
                    } catch (e) {
                        appContainer.updateMessage({
                            isOpen: true,
                            message: locale.t(locale.keys.validation.csvemptyerror, { row: i }),
                            variant: Variants.error,
                        });
                        ui.update(UI.Loaded);
                        break;
                    }
                    // 入力形式チェック
                    const dataCheck = await csvImportCheck(spliteComma, i);
                    if (!dataCheck) {
                        ui.update(UI.Loaded);
                        break;
                    }
                    // 1000行読み込んだ場合
                    if (i >= csvFormat4Mfp.CSV_MAX_LINE + csvFormat4Mfp.CSV_HEADER - 1) {
                        if (!isNullOrEmptyArray(mfps)) {
                            // apiにリクエスト
                            sendCollectiveInviteRequest();
                            break;
                        }
                    }
                    // 最終行まで読み込んだ場合
                    if (i === lineInput.length - 1) {
                        if (blankLine) {
                            if (!isNullOrEmptyArray(mfps)) {
                                // apiにリクエスト
                                sendCollectiveInviteRequest();
                            } else {
                                // データ行なし
                                appContainer.updateMessage({
                                    isOpen: true,
                                    message: locale.t(locale.keys.validation.csvemptyerror),
                                    variant: Variants.error,
                                });
                            }
                        } else {
                            // qsのcsvインポート仕様に合わせる
                            // 最終行はEOFの前に改行がなければエラー
                            appContainer.updateMessage({
                                isOpen: true,
                                message: locale.t(locale.keys.validation.csvimporteoferror, { row: lineInput.length }),
                                variant: Variants.error,
                            });
                            ui.update(UI.Loaded);
                        }
                    }
                }
                clearSelectedFile();
            };
            // 読み込み失敗
            reader.onerror = () => {
                clearSelectedFile();
                ui.update(UI.Loaded);
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvemptyerror),
                    variant: Variants.error,
                });
                logger.error('ファイルが読み込めませんでした。', csvFileName);
            };

            // 読み込み開始
            if (code === 'SJIS' || code === 'UTF8') {
                reader.readAsText(fileInput, code);
            } else {
                clearSelectedFile();
                // それ以外対応していない文字コードとして扱う
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.invalidCharacterCode),
                    variant: Variants.error,
                });
            }
        } else {
            clearSelectedFile();
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.validation.csvemptyerror),
                variant: Variants.error,
            });
        }
    };

    return {
        csvFileName,
        fileInput,
        buttonDisable,
        ui,
        onSelectedCSV,
        onImportCsv,
    };
};

export const CollectiveRegisterContainer = createContainer(useCollectiveRegisterContainer);
