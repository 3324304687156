import { default as React } from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import useTitle from '@/common/components/hooks/useTitle';
import { AnalyticsContainer } from './AnalyticsContainer';
import Analytics from './Analytics';
import locale from '@/common/utils/locale';

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.analytics.index));
    return (
        <>
            <AnalyticsContainer.Provider>
                <Analytics />
            </AnalyticsContainer.Provider>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
