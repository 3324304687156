import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { UI, ImageFileSelectorProps } from '@/common/components/ImageFileSelector';
import * as locale from '@/common/utils/locale/locale';

const useContainer = () => {
    // PC
    const types = ['image/jpeg', 'image/png', 'image/gif'];
    const [state, setState] = useState({
        ui: UI.Default,
        datauri: '',
        errorMessage: '',
        isProcessed: false,
    });
    const onDrop = (accepts: File[], callback: (reader: FileReader) => void, errback: () => void) => {
        if (accepts.length !== 1) {
            setState({
                ...state,
                ui: UI.Error,
                errorMessage: locale.t(locale.keys.error.fileLengthFailure),
            });
            errback();
            return;
        }
        // Check for valid extension.
        // 拡張子の確認（有効：jpeg, png, gif）
        const file = accepts[0];
        if (!types.includes(file.type)) {
            setState({
                ...state,
                ui: UI.Error,
                errorMessage: locale.t(locale.keys.error.extensionFailure),
            });

            errback();
            return;
        }
        // Load image file using FileReader API.
        const reader = new FileReader();
        // Wait for FileReader event completes.
        // https://developer.mozilla.org/en/docs/Web/API/FileReader
        reader.onloadend = () => {
            setState({
                ...state,
                ui: UI.Crop,
                datauri: reader.result as string,
            });

            callback(reader);
            return null;
        };
        reader.readAsDataURL(file);
    };
    const onDropCancel = (callback: () => void) => {
        setState({
            ...state,
            ui: UI.Default,
        });
        callback();
    };

    const onCropCancel = (callback: () => void) => {
        setState({
            ...state,
            ui: UI.Default,
        });
        callback();
    };

    const onLoad = async (cropperRef: React.MutableRefObject<Cropper | null>, props: ImageFileSelectorProps, onUploading: () => void, onUploaded: (datauri: string) => void) => {
        setState({
            ...state,
            ui: UI.Uploading,
        });
        onUploading();
        let datauri = '';

        try {
            const cropper: Cropper = cropperRef.current!;
            // Resize image to 400x400 dimension to reduce bandwidth and avoid hitting Lambda limit.
            datauri = cropper.getCroppedCanvas({ width: props.uploadWidth, height: props.uploadHeight, fillColor: '#fff' }).toDataURL('image/jpeg', 0.85);
        } catch (e) {
            setState({
                ...state,
                ui: UI.Error,
            });
            return;
        }
        setState({
            ...state,
            ui: UI.Default,
            datauri: `${datauri}`,
            isProcessed: true,
        });

        onUploaded(datauri);
        props.onLoaded(datauri);
    };

    const onStart = (callback: () => void) => {
        callback();
    };

    return {
        state,
        setState,
        onLoad,
        onStart,
        onDrop,
        onDropCancel,
        onCropCancel,
    };
};
export const ImageFileSelectorContainer = createContainer(useContainer);
