import { default as React } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';

import Signin from './Signin';
import { SigninContainer } from './SigninContainer';

interface Props extends RouteComponentProps<{ id: string }> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.memberAuth.index));
    const { params } = props.match;
    return (
        <>
            <SigninContainer.Provider>
                <Signin displayId={params.id} />
            </SigninContainer.Provider>
        </>
    );
};

export default withRouter(Component);
