import { Theme, createStyles } from '@material-ui/core';

export const tableStyles = (theme: Theme) =>
    createStyles({
        tab: {
            width: '78px',
            height: '19px',
            fontSize: '13px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: ' normal',
            color: '#666666',
        },
        headerCell: {
            fontSize: '12px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#333333',
        },
        searchPager: {
            width: '95%',
            marginTop: theme.spacing.unit * 1.5,
            marginBottom: theme.spacing.unit * 1,
            overflowX: 'auto',
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        titleLabel: {
            textAlign: 'left',
        },
        table: {
            minWidth: 300,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        pencil: {
            color: 'orange',
        },
        tableCell: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        oddRow: {
            background: '#f2f4f5',
        },
        evenRow: {
            background: 'inherit',
        },
        modal: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
        },
        active: {
            fontSize: '15px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#43a047',
        },
        inActive: {
            fontSize: '15px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
        },
        enableSub: {
            width: '45px',
            height: '15px',
            fontSize: '15px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#43a047',
        },
        disableSub: {
            width: '60px',
            height: '15px',
            fontSize: '15px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#333333',
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        searchIconButton: {
            padding: 10,
        },
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px'
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: "auto 0"
        },
    });
