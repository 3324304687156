import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Theme, createStyles, withStyles, WithStyles, List } from '@material-ui/core';
import routes from '@/admin/constants/routes';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import HeaderMenuListItems from '@/common/components/HeaderMenuListItems';
import environment from '@/common/constants/environment';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case "ricoh":
            return '#FFF2F2';
        default:
            return '#e3eaed';
    }
}


const styles = (theme: Theme) =>
    createStyles({
        list: {
            '&& a': {
                color: theme.palette.text.primary,
            },
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            overflowX: 'auto',
            [theme.breakpoints.down('sm')]: {
                minHeight: '50vh',
                maxHeight: '50vh',
            },
            '&& li': {
                height: '44px',
                paddingLeft: '13px',
                paddingRight: '13px',
            },
            '&& hr': {
                marginBottom: '5px',
                marginTop: '5px',
            },
            borderRight: '1px solid',
            borderColor: '#bdbdbd',
            backgroundColor: selectColor(environment.tenant),
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    commonMenuCloseFunc: () => void;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();

    return (
        <List component="nav" className={classes.list}>
            <HeaderMenuListItems
                isAdminApp={true}
                signoutPath={routes.auth.logout.index}
                name={appContainer.values.signinWorkspaceUserObject.name}
                phoneticName={appContainer.values.signinWorkspaceUserObject.phoneticName}
                email={appContainer.values.signinWorkspaceUserObject.invitationEmail}
                avatarUrl={appContainer.values.signinWorkspaceUserObject.avatarUrl}
                commonMenuCloseFunc={props.commonMenuCloseFunc}
            />
        </List>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
