import { default as React, useEffect, useState, useCallback } from 'react';
import locale from '@/common/utils/locale';
import ModalOuter from '@/admin/components/common/ModalOuter';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { SigninContainer, State } from '@/admin/components/auth/signin/SigninContainer';
import * as login from '@/common/api/auth/login/Login';
import { useLocation, useHistory } from 'react-router-dom';
import * as validator from '@/common/utils/validator';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as errorHandler from '@/common/utils/errorHandler';
import Error from '@/common/components/state/Error';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import routes from '@/admin/constants/routes';
import CovasAvatar from '@/common/components/CovasAvatar';
import useTitle from '@/common/components/hooks/useTitle';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import { parse } from 'query-string';

const styles = (theme: Theme) =>
    createStyles({
        text: {
            margin: '11px',
            maxWidth: '30%',
            minWidth: '328px',
        },
        button: {
            color: 'white',
            margin: theme.spacing.unit,
            maxWidth: '20%',
            minWidth: '240px',
        },
        avatar: {
            margin: `0 auto ${theme.spacing.unit * 3}px`,
        },
        signinButtonDiv: {
            marginTop: '26px',
        },
        recoverButton: {
            color: '#0d47a1',
            fontWeight: 500,
        },
    });

export const useModal = () => {
    const [isOpen, setOpen] = React.useState(false);

    const open = () => setOpen(true);
    const close = () => setOpen(false);

    return { isOpen, open, close };
};

interface Props extends WithStyles<typeof styles> {
    ui?: UI;
    skipEffect?: boolean;
}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const ui = useUI(props.ui);
    const modalUI = useModal();
    const [form, setForm] = useState(login.New());
    const appContainer = AdminAppContainer.useContainer();
    const container = SigninContainer.useContainer();
    const lhc = LogoHeaderContainer.useContainer();
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const location = useLocation();

    useTitle(locale.t(locale.keys.memberAuth.index));

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        if (props.skipEffect) {
            return;
        }
        if (ui.current !== UI.Loading) {
            return;
        }
        ui.update(UI.Loaded);
    }, [props.skipEffect, ui]);

    useEffect(
        useCallback(() => {
            // 戻るボタンを表示
            if (ui.current !== UI.Saving) {
                lhc.setBackFunc(() => {
                    container.setState(State.SelectWorkSpace);
                });
            } else {
                lhc.setBackFunc();
            }
        }, [ui, lhc, container]),
        [ui.current],
    );

    const openPasswordResetModal = () => {
        const msg = validator.Validate<login.Form>(form, login.passwordResetValidations(), login.NewValidation);
        const dispMessage = msg === null ? '' : msg.email === null ? '' : msg!.email.toString();
        if (dispMessage !== '') {
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: dispMessage,
                variant: Variants.error,
            });
        } else {
            modalUI.open();
        }
    };
    const forget = () => {
        (async () => {
            try {
                await login.myPasswordReset({ workspaceId: container.values.workspaceDisplayId, email: form.email, admin: true });
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: `${locale.t(locale.keys.action.sent)}`,
                    variant: Variants.success,
                });
            } catch (e) {
                // 送信したメールアドレスの登録有無にかかわらず成功するので、このメッセージが表示されるのはAPIに異常が発生した場合またはパラメータ不足のみ
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: `${locale.t(locale.keys.action.failedToSend)}`,
                    variant: Variants.error,
                });
            }
        })();
    };

    const handleChangeEmail = (v: string) => {
        setForm({
            email: v,
            password: form.password,
            errors: form.errors,
            validateInit: {
                email: true,
                password: form.validateInit.password,
            },
        });
    };
    const handleChangePassword = (v: string) => {
        setForm({
            email: form.email,
            password: v,
            errors: form.errors,
            validateInit: {
                email: form.validateInit.email,
                password: true,
            },
        });
    };

    const handleValidateEmail = () => {
        if (!form.validateInit.email) {
            return '';
        }
        const msg = validator.Validate<login.Form>(form, login.validations(), login.NewValidation);
        const dispMessage = msg === null ? '' : msg.email === null ? '' : msg!.email.toString();
        return dispMessage;
    };

    const handleValidatePass = () => {
        if (!form.validateInit.password) {
            return '';
        }
        const msg = validator.Validate<login.Form>(form, login.validations(), login.NewValidation);
        const dispMessage = msg === null ? '' : msg.password === null ? '' : msg!.password.toString();
        return dispMessage;
    };

    const handleSignin = () => {
        if (handleValidateEmail() !== '' || handleValidatePass() !== '') {
            return;
        }
        ui.update(UI.Saving);
        (async () => {
            try {
                const result = await login.memberSignin(container.values.workspaceDisplayId, form.email, form.password);

                if (result === null) {
                    ui.update(UI.Loaded);
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: `${locale.t(locale.keys.error.loginFailure)}`,
                        variant: Variants.error,
                    });
                    return;
                }
                const sub = result.id;
                const workspaceObj = result.workspace;
                const workspaceUser = result.user;
                // 管理者権限を持っているユーザかチェック
                if (!login.roleCheck(workspaceObj, workspaceUser)) {
                    ui.update(UI.Loaded);
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: `${locale.t(locale.keys.error.loginRoleFailure)}`,
                        variant: Variants.error,
                    });
                    return;
                }
                appContainer.onSignin(appContainer, sub, workspaceObj, workspaceUser);
                ui.update(UI.Loaded);

                const parsedQuery = parse(location.search);
                if (parsedQuery.return_to != null && typeof parsedQuery.return_to === 'string') {
                    const returnTo = parsedQuery.return_to;
                    history.push(decodeURIComponent(returnTo));
                } else {
                    history.push(routes.dashboard.index);
                }
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
                return;
            }
        })();
    };

    return (
        <>
            <>
                {ui.current === UI.Loading && (
                    <div data-testid={UI.Loading}>
                        <Loading />
                    </div>
                )}
                {ui.current === UI.Loaded && (
                    <Grid container spacing={24}>
                        <Grid item sm />
                        <Grid item xs={12} sm={10}>
                            <CovasAvatar seed={container.values.workspaceId} size={160} alt="Workspace" src={container.values.workspaceLogoUrl} className={props.classes.avatar} />

                            <h3 style={{ wordWrap: 'break-word' }}>
                                {locale.t(locale.keys.memberAuth.signin, {
                                    workspaceName: `${container.values.workspaceDisplayName}`,
                                    workspaceId: `${container.values.workspaceDisplayId}`,
                                })}
                            </h3>
                            <div>
                                <TextField
                                    className={classes.text}
                                    id="email"
                                    label={locale.t(locale.keys.common.email)}
                                    placeholder=""
                                    margin="normal"
                                    variant="filled"
                                    value={form.email}
                                    inputProps={{ style: { height: '100%' } }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => handleChangeEmail(event.target.value)}
                                    error={handleValidateEmail() !== ''}
                                    helperText={handleValidateEmail()}
                                />
                            </div>
                            <div>
                                <TextField
                                    className={classes.text}
                                    type={showPassword ? 'text' : 'password'}
                                    label={locale.t(locale.keys.common.password)}
                                    variant="filled"
                                    value={form.password}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => handleChangePassword(event.target.value)}
                                    error={handleValidatePass() !== ''}
                                    helperText={handleValidatePass()}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="Toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        inputProps: { style: { height: '100%' } },
                                    }}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            handleSignin();
                                            ev.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            <div className={classes.signinButtonDiv}>
                                <Fab
                                    variant="extended"
                                    className={classes.button}
                                    size="large"
                                    color="secondary"
                                    onClick={() => handleSignin()}
                                    disabled={!form.validateInit.email || !form.validateInit.password || handleValidateEmail() !== '' || handleValidatePass() !== ''}
                                >
                                    {locale.t(locale.keys.memberAuth.signinButton)}
                                </Fab>
                            </div>
                            <div>
                                <Button data-testid="modalOpenPC" onClick={openPasswordResetModal} className={classes.recoverButton}>
                                    {locale.t(locale.keys.memberAuth.passwordReset)}
                                </Button>
                            </div>
                            <ModalOuter
                                title={locale.t(locale.keys.memberAuth.recovery.title)}
                                value={form.email}
                                modalOpen={modalUI.isOpen}
                                modalCloseFunc={modalUI.close}
                                admit={forget}
                                data-testid="modal"
                                headerStyle={{ backgroundColor: 'white', color: 'black' }}
                            />
                        </Grid>
                        <Grid item sm />
                    </Grid>
                )}
                {ui.current === UI.Saving && (
                    <div data-testid={UI.Saving}>
                        <Saving />
                    </div>
                )}

                {ui.current === UI.Error && (
                    <div data-testid={UI.Error}>
                        <Error />
                    </div>
                )}
            </>
        </>
    );
};

export default withStyles(styles)(Component);
