import React, { FC, useEffect, useState } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Paper, IconButton, InputBase, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import * as schema from '@/bundles/schema/typescript/schema';
import { indexOrderList } from '@/common/api/x-storages/order/management';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import jstz from 'jstz';
import Table from './Table';
import locale from '@/common/utils/locale';

const styles = (theme: Theme) =>
    createStyles({
        searchRoot: {
            width: '100%',
            marginTop: theme.spacing.unit * 5,
            overflowX: 'auto',
        },
        searchIconButton: {
            padding: 10,
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        tips: {
            textAlign: 'end',
        },
    });

interface Props extends WithStyles<typeof styles> {
    open: boolean;
}

const Page: FC<Props> = (props) => {
    const [search, setSearch] = useState({
        searchText: '',
    });
    const [reqData, setReqData] = useState<schema.V1XStoragesOrderIndexResponse>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI(UI.Loaded);
    const timeZone = jstz.determine().name();

    const onSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            fetchData();
        }
    };
    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };

    const fetchData = async () => {
        try {
            if (appContainer.values.signinWorkspaceObject.id) {
                updateUIStatus(UI.Loading);
                const data = await indexOrderList(
                    appContainer.values.authorizationCode,
                    (page * rowsPerPage).toString(),
                    rowsPerPage.toString(),
                    search.searchText,
                    search.searchText,
                    search.searchText,
                    search.searchText,
                    timeZone,
                );
                setReqData(data);
            } else {
                throw Error('Workspace ObjectId is not found. Caused by React State.');
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        } finally {
            updateUIStatus(UI.Loaded);
        }
    };

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        (async () => {
            if (!props.open) {
                fetchData();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, page, rowsPerPage]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={props.classes.searchRoot}>
                    <IconButton className={props.classes.searchIconButton} aria-label="Search">
                        <Search />
                    </IconButton>
                    <InputBase
                        className={props.classes.searchInput}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            setSearch({ ...search, searchText: e.target.value });
                        }}
                        onKeyDown={onSearch}
                    />
                </Paper>
                <Typography className={props.classes.tips}>{locale.t(locale.keys.memberAuthManagement.search.tips)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Table reqData={reqData} open={props.open} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(Page);
