import { default as React, useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import Grid from '@material-ui/core/Grid';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { State as UI } from '@/common/components/hooks/useUI';
import Error from '@/common/components/state/Error';

import SubWorkspaceMfpsTable from './SubWorkspaceMfpsTable';
import { SubWorkspaceMfpsContainer } from './SubWorkspaceMfpsContainer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import locale from '@/common/utils/locale';
import SubHeader from '@/admin/components/common/subheader/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        content: {
            position: 'relative',
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 5,
            overflowX: 'auto',
        },
        table: {
            minWidth: 650,
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        searchIconButton: {
            padding: 10,
        },
        movetoInviteFab: {
            color: 'white',
            position: 'absolute',
            top: '-23px',
            right: theme.spacing.unit * 2,
            '&& span': {
                fontSize: '1rem',
            },
            height: '44px',
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const container = SubWorkspaceMfpsContainer.useContainer();

    useEffect(() => {
        appContainer.updateLoadingState(container.ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.ui]);

    return (
        <>
            <SubHeader title={locale.t(locale.keys.subWorkspaceMfpManagement.index)} />
            <div className={props.classes.mainDiv}>
                {/* Loading, Saving中でも画面は出したままにしておく */}
                {container.ui.current !== UI.Error && (
                    <div>
                        <Grid container className={classes.content}>
                            <Grid item xs={12} />
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} />
                            <Grid item xs={12}>
                                <SubWorkspaceMfpsTable
                                    classes={{
                                        root: '',
                                        table: '',
                                        tableWrapper: '',
                                        enableSub: '',
                                        disableSub: '',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {container.ui.current === UI.Error && (
                    <div data-testid={UI.Error}>
                        <Error />
                    </div>
                )}
            </div>
        </>
    );
};

export default withRouter(withStyles(styles)(Component));
