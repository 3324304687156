import { default as React, useEffect } from 'react';
import { RouteComponentProps, withRouter, generatePath } from 'react-router';
import { default as UI } from '@/common/constants/ui';
import locale from '@/common/utils/locale';
import routes from '@/admin/constants/routes';
import {
    Theme,
    createStyles,
    WithStyles,
    withStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    Typography,
    Grid,
    Paper,
    IconButton,
    InputBase,
    TablePagination,
    Button,
} from '@material-ui/core';
import { Search, Edit, Add, Check, Block } from '@material-ui/icons';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { Variants } from '@/common/components/messages/CommonMessage';
import { DeviceContainer } from './DeviceContainer';
// import EditPopover from './EditPopover';
import dummyDeviceList from './dummyDeviceList';
import { AdminAppContainer } from '../AdminAppContainer';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        addButtonContainer: {
            display: 'flex',
            marginTop: -theme.spacing.unit * 6,
            [theme.breakpoints.down('sm')]: {
                marginTop: -theme.spacing.unit * 3,
            },
        },
        buttonAddNew: {
            marginLeft: 'auto',
            borderRadius: 50,
            right: theme.spacing.unit * 3,
            minWidth: 182,
            '&& span': {
                fontSize: '0.875rem',
            },
        },
        searchContent: {
            width: '100%',
            overflowX: 'auto',
            marginTop: 18,
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: 8,
        },
        searchIconButton: {
            float: 'left',
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        table: {
            minWidth: 500,
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
        deviceName: {
            textAlign: 'left',
            width: 220,
        },
        mfpName: {
            textAlign: 'left',
            width: 190,
        },
        mfpNumber: {
            textAlign: 'left',
            width: 180,
        },
        noMembers: {
            textAlign: 'right',
            width: 160,
        },
        status: {
            textAlign: 'left',
            width: 170,
        },
        action: {
            textAlign: 'left',
            width: 50,
        },
        iconEdit: {
            color: '#ff7300',
        },
        noRecord: {
            textAlign: 'right',
            color: '#666666',
            height: 50,
        },
        noResult: {
            textAlign: 'center',
            color: '#666666',
            height: 50,
        },
        oddRow: {
            background: '#f2f4f5',
            minHeight: 60,
        },
        evenRow: {
            background: 'inherit',
            minHeight: 60,
        },
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
        tips: {
            textAlign: 'end',
        },
    });

type TParams = { id: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    skipEffect?: boolean;
    onLoadingState?: (flag: boolean) => void;
}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const deviceContainer = DeviceContainer.useContainer();
    const appContainer = AdminAppContainer.useContainer();

    useEffect(() => {
        appContainer.updateLoadingState(deviceContainer.ui);
        if (isError(deviceContainer.ui)) {
            appContainer.setOnBackError(true);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.error),
                variant: Variants.error,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceContainer.ui]);

    useEffect(() => {
        if (props.skipEffect) {
            deviceContainer.loadDummyData(dummyDeviceList);
            deviceContainer.setUI(UI.state.Loaded);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            appContainer.setOnBackError(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (deviceContainer.goToNewDevice) {
            props.history.push(generatePath(routes.device.new));
            deviceContainer.setGoToNewDevice(false);
            return;
        }
        if (deviceContainer.goToDetailDevice) {
            props.history.push(generatePath(routes.device.edit, { id: deviceContainer.idSelected }));
            deviceContainer.setGoToDetailDevice(false);
            return;
        }
        void (async () => {
            try {
                if (!deviceContainer.isReloaded) {
                    deviceContainer.setUI(UI.state.Loading);
                    await deviceContainer.getDeviceList();
                    deviceContainer.setUI(UI.state.Loaded);
                }
            } catch (e) {
                deviceContainer.setUI(UI.state.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceContainer.goToNewDevice, deviceContainer.goToDetailDevice, deviceContainer.isReloaded]);

    return (
        <>
            {isLoading(deviceContainer.ui) && <div data-testid={UI.state.Loading} />}
            {isError(deviceContainer.ui) && <div data-testid={UI.state.Error} />}
            {(isLoaded(deviceContainer.ui) || props.skipEffect) && (
                <>
                    <Grid className={classes.addButtonContainer}>
                        <Button variant="contained" className={classes.buttonAddNew} onClick={deviceContainer.handleClickGoToNewDevice}>
                            <Add /> {locale.t(locale.keys.manageShareDevice.registerNewDevice.index)}
                        </Button>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper className={classes.searchContent}>
                                <div onClick={deviceContainer.handleClickIconSearch}>
                                    <IconButton className={classes.searchIconButton} aria-label="Search">
                                        <Search />
                                    </IconButton>
                                </div>
                                <InputBase
                                    className={classes.searchInput}
                                    value={deviceContainer.searchText}
                                    onChange={deviceContainer.handleChangeSearchText}
                                    onKeyPress={deviceContainer.handlePressEnterSearch}
                                />
                            </Paper>
                            <Typography className={classes.tips}>{locale.t(locale.keys.manageShareDevice.search.tips)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.root}>
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow className={classes.headerFont}>
                                                <TableCell className={classes.mfpNumber}>
                                                    <div className={classes.gridInsideHeaderCell}>
                                                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.manageShareDevice.mfpNumber.index)}</p>
                                                        <Search />
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.deviceName}>
                                                    <div className={classes.gridInsideHeaderCell}>
                                                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.manageShareDevice.deviceName.index)}</p>
                                                        <Search />
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.mfpName}>{locale.t(locale.keys.manageShareDevice.mfpName.index)}</TableCell>
                                                <TableCell className={classes.noMembers}>{locale.t(locale.keys.manageShareDevice.noMembers.index)}</TableCell>
                                                <TableCell className={classes.status}>{locale.t(locale.keys.manageShareDevice.deviceStatus.index)}</TableCell>
                                                <TableCell className={classes.action}>{locale.t(locale.keys.manageShareDevice.action.index)}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deviceContainer.showRows().map((row, index) => (
                                                <TableRow key={row.id} hover={true} className={index % 2 ? classes.evenRow : classes.oddRow}>
                                                    <TableCell className={classes.mfpNumber}>{row.mfpNumber}</TableCell>
                                                    <TableCell className={classes.deviceName}>{row.deviceName}</TableCell>
                                                    {/* May use in furture */}
                                                    {/* <TableCell className={classes.mfpNumber} onClick={deviceContainer.handleClickPopover(row.mfpNumber, row.id)}>
                                                        {row.mfpNumber}
                                                    </TableCell> */}
                                                    <TableCell className={classes.mfpName}>{row.mfpName}</TableCell>
                                                    <TableCell className={classes.noMembers}>{row.noMembers}</TableCell>
                                                    <TableCell className={classes.status}>
                                                        {row.status === 'active' && (
                                                            <>
                                                                <Check style={{ marginBottom: -5 }} />
                                                                <span>{locale.t(locale.keys.manageShareDevice.status.active)}</span>
                                                            </>
                                                        )}
                                                        {row.status === 'inactive' && (
                                                            <>
                                                                <Block style={{ marginBottom: -5 }} />
                                                                <span>{locale.t(locale.keys.manageShareDevice.status.suspended)}</span>
                                                            </>
                                                        )}
                                                    </TableCell>
                                                    <TableCell className={classes.action}>
                                                        <IconButton onClick={() => deviceContainer.handleClickGoToDetailDevice(row.id)}>
                                                            <Edit className={classes.iconEdit} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {/* <EditPopover /> */}
                                        </TableBody>
                                        {deviceContainer.totalCount > 0 && (
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25]}
                                                        colSpan={6}
                                                        count={deviceContainer.totalCount === undefined ? 0 : deviceContainer.totalCount}
                                                        rowsPerPage={deviceContainer.rowsPerPage}
                                                        page={deviceContainer.page}
                                                        labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                                                        labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                                                            <span>
                                                                {locale.t(locale.keys.table.displayedRowsArgs, {
                                                                    from: paginationInfo.from,
                                                                    to: paginationInfo.to,
                                                                    count: paginationInfo.count,
                                                                })}
                                                            </span>
                                                        )}
                                                        backIconButtonProps={{ 'aria-label': locale.t(locale.keys.table.previousPage) }}
                                                        nextIconButtonProps={{ 'aria-label': locale.t(locale.keys.table.nextPage) }}
                                                        onChangePage={deviceContainer.handleChangePage}
                                                        onChangeRowsPerPage={deviceContainer.handleChangeRowsPerPage}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                        {deviceContainer.totalCount === 0 && deviceContainer.searchText !== '' && (
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={7} className={classes.noResult}>
                                                        <span>{locale.t(locale.keys.manageShareDevice.noResult.index)}</span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                        {deviceContainer.totalCount === 0 && deviceContainer.searchText === '' && (
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={7} className={classes.noRecord}>
                                                        <span>{locale.t(locale.keys.manageShareDevice.noRecord.index)}</span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                    </Table>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(Component));
