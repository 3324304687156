import React, { FC, useState } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { Theme, createStyles, WithStyles, withStyles, Grid, Fab, Modal } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import ModalHeader from '@/common/components/headers/ModalHeader';
import Page from './Page';
import CreateOrderPage from './create-order-modal/Page';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import ErrorBoundary from '@/common/utils/ErrorBoundary';

const styles = (theme: Theme) =>
    createStyles({
        pageRoot: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        content: {
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing.unit * 3,
            },
        },
        movetoInviteFab: {
            color: 'white',
            position: 'absolute',
            top: '-23px',
            right: theme.spacing.unit * 2,
            '&& span': {
                fontSize: '1rem',
            },
            height: '44px',
        },
        modalWindow: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            // overflow: 'scroll',
            outline: 'none',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const AuthManagementPage: FC<Props> = (props) => {
    const [open, setOpen] = useState(false);
    const appContainer = AdminAppContainer.useContainer();
    useTitle(locale.t(locale.keys.pageTitle.admin.authManagement));

    const switchModal = () => {
        setOpen(!open);
    };

    return (
        <>
            <SubHeader title={locale.t(locale.keys.pageTitle.admin.authManagement)} />

            <Grid container className={props.classes.content}>
                <Grid item xs={12}>
                    <Fab variant="extended" onClick={switchModal} className={props.classes.movetoInviteFab} size="large" color="secondary">
                        <Add />
                        {locale.t(locale.keys.memberAuthManagement.link.createAuthOrder)}
                    </Fab>
                </Grid>
            </Grid>
            <div className={props.classes.pageRoot}>
                <Page open={open} />
            </div>
            <Modal open={open} onClose={switchModal}>
                <Grid className={props.classes.modalWindow}>
                    <ModalHeader title={locale.t(locale.keys.memberAuthManagement.orderCreate.index)} onClose={switchModal} isLoading={appContainer.loadingState} />
                    <ErrorBoundary container={appContainer}>
                        <CreateOrderPage onClosePage={switchModal} />
                    </ErrorBoundary>
                </Grid>
            </Modal>
        </>
    );
};

export default withStyles(styles)(AuthManagementPage);
