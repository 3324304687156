import * as schema from '@/bundles/schema/typescript/schema';
import * as validator from '@/common/utils/validator';
import userRole from '@/common/constants/userRole';
import * as Login from '@/common/api/auth/login';

/**
 * 管理者権限を持っているユーザーかどうかを返す.
 *
 * システム管理者, 管理者, 外部管理者 の場合は、trueを返す.
 * それ以外の場合は、falseを返す.
 *
 * @param workspace 未使用
 * @param user 管理者権限を持っているかどうかを調べたいユーザー（現在,ログイン中のユーザー）
 * @return true:管理者権限を持っている,false:管理者権限を持っていない
 */
export const roleCheck = (workspace: schema.V1ObjectsWorkspace, user: schema.V1ObjectsWorkspaceuserLarge) => {
    switch (user.role) {
        case userRole.systemAdmin: // システム管理者は無条件でok
            return true;
        case userRole.admin: // 管理者は無条件でok
            return true;
        case userRole.externalAdmin: // 外部管理者はok
            return true;
        case userRole.member: // メンバはng
            return false;
        /*
            if (workspace.enableSubWorkspace != null && workspace.enableSubWorkspace) {
                return true; // サブワークスペース作れる場合はok
            }
            return false; // サブワークスペース作れない場合はng
            */
        default:
            return false; // 想定外のrole
    }
};

export const getWorkspaceObject = async (displayId: string, auth: string): Promise<schema.V1ObjectsWorkspace> => {
    return Login.getWorkspaceObject(displayId, auth);
};

export const memberSignin = async (workspaceId: string, email: string, pass: string): Promise<schema.V1UserCreateResponse | null> => {
    return Login.memberSignin(workspaceId, email, pass);
};

export const myPasswordReset = async (req: schema.V1WorkspaceuserPasswordResetCreateRequest): Promise<schema.V1WorkspaceuserPasswordResetCreateResponse> => {
    return Login.myPasswordReset(req);
};

export interface Form {
    email: string;
    password: string;
    validateInit: {
        email: boolean;
        password: boolean;
    };
    errors: validator.ValidationOutput;
}
export const New = () => Login.New();
export const validations = Login.validations;
export const passwordResetValidations = Login.passwordResetValidations;
export const NewValidation: () => validator.ValidationOutput = Login.NewValidation;
