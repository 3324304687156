import { default as React, useEffect, useContext } from 'react';
import { Theme, createStyles, WithStyles, withStyles, Table, TableHead, TableRow, TableCell, TableBody, Grid, Paper, IconButton, Chip } from '@material-ui/core';
import { CloudConnectionContainer } from './CloudSettingContainer';
import { Edit } from '@material-ui/icons';
import { __RouterContext, generatePath } from 'react-router';
import { default as UI } from '@/common/constants/ui';
import routes from '@/admin/constants/routes';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { AdminAppContainer } from '../AdminAppContainer';
import { default as cloudImages } from '@/common/img/cloud-setting/index';
import { Variants } from '@/common/components/messages/CommonMessage';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';
import checkCloudSetting from './checkCloudSetting';
import * as webappUtil from '@/common/utils/webappUtil';
import environment from '@/common/constants/environment';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        table: {
            minWidth: 500,
        },
        icon: {
            width: 60,
        },
        serverName: {
            width: 240,
        },
        rowContent: {
            width: 620,
        },
        operation: {
            width: 80,
        },
        iconButton: {
            color: '#ff7300',
        },
        chip: {
            marginRight: 10,
            backgroundColor: '#ffffff',
            border: '1px solid #cccccc',
        },
        invalidLabel: {},
        iconImage: {
            width: '32.5px',
            height: '28px',
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
        oddRow: {
            background: '#f2f4f5',
            minHeight: 60,
        },
        evenRow: {
            background: 'inherit',
            minHeight: 60,
        },
    });

export const hiddenSetting = (service: schema.EndpointType, billingPlan: schema.BillingPlan, tenant: schema.Brand) => {
    switch (service) {
        case schema.EndpointType.Hotprofile:
        case schema.EndpointType.Kintone: {
            return true;
        }
        case schema.EndpointType.Email: {
            if (billingPlan !== schema.BillingPlan.Internal) {
                return true;
            }
            return false;
        }
        case schema.EndpointType.Docard:
        case schema.EndpointType.Docab: {
            if (billingPlan !== schema.BillingPlan.Internal || tenant === schema.Brand.Ricoh) {
                return true;
            }
            return false;
        }
        default: {
            return false;
        }
    }
};

interface Props extends WithStyles<typeof styles> {
    skipEffect?: boolean;
}

const CloudConnectionComponent: React.FC<Props> = (props) => {
    const { classes } = props;
    const routerContext = useContext(__RouterContext);
    const cloudConnectionContainer = CloudConnectionContainer.useContainer();
    const appContainer = AdminAppContainer.useContainer();
    const billingPlan = appContainer.values.signinWorkspaceObject.billingPlan as schema.BillingPlan;

    useEffect(() => {
        appContainer.updateLoadingState(cloudConnectionContainer.ui);
        if (isError(cloudConnectionContainer.ui)) {
            appContainer.setOnBackError(true);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.error),
                variant: Variants.error,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cloudConnectionContainer.ui]);
    useEffect(() => {
        if (props.skipEffect) {
            cloudConnectionContainer.setUI(UI.state.Loaded);
            return;
        }
        void (async () => {
            try {
                cloudConnectionContainer.setUI(UI.state.Loading);
                await cloudConnectionContainer.getCloudList();
                cloudConnectionContainer.setUI(UI.state.Loaded);
            } catch (err) {
                cloudConnectionContainer.setUI(UI.state.Error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        return () => {
            appContainer.setOnBackError(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const linkToEdit = (key: string) => {
        const editPath = generatePath(routes.cloudSetting.edit, { id: key });
        routerContext.history.push(editPath);
    };

    const getCloudIcon = (row: schema.V1ObjectsWorkspacesCloudSetting) => {
        if (checkCloudSetting.isOAuthAvailable(row) && checkCloudSetting.isBothUploadDownloadAvailable(row)) {
            return <img src={cloudImages.cloudImages.full} className={props.classes.iconImage} alt="img" />;
        }
        if (checkCloudSetting.isOAuthAvailable(row) && checkCloudSetting.isOnlyUploadAvailable(row)) {
            return <img src={cloudImages.cloudImages.upload} className={props.classes.iconImage} alt="img" />;
        }
        if (checkCloudSetting.isOAuthAvailable(row) && checkCloudSetting.isOnlyDownloadAvailable(row)) {
            return <img src={cloudImages.cloudImages.download} className={props.classes.iconImage} alt="img" />;
        }
        if (checkCloudSetting.isOAuthAvailable(row) && checkCloudSetting.isUploadDownloadProhibited(row)) {
            return <img src={cloudImages.cloudImages.none} className={props.classes.iconImage} alt="img" />;
        }
        if (checkCloudSetting.isOAuthProhibited(row)) {
            return <img src={cloudImages.cloudImages.none} className={props.classes.iconImage} alt="img" />;
        }
    };

    const getCloudContent = (row: schema.V1ObjectsWorkspacesCloudSetting) => {
        if (checkCloudSetting.isTwoLeggedOAuthAvailable(row)) {
            return <Chip className={props.classes.chip} label={locale.t(locale.keys.adminCloudDasboard.label2L)} />;
        }
        if (checkCloudSetting.isThreeLeggedOAuthAvailable(row)) {
            return <Chip className={props.classes.chip} label={locale.t(locale.keys.adminCloudDasboard.label3L)} />;
        }
        if (checkCloudSetting.isBothOAuthAvailable(row)) {
            return (
                <>
                    <Chip className={props.classes.chip} label={locale.t(locale.keys.adminCloudDasboard.label2L)} />
                    <Chip className={props.classes.chip} label={locale.t(locale.keys.adminCloudDasboard.label3L)} />
                </>
            );
        }
        if (checkCloudSetting.isOAuthProhibited(row)) {
            return <label className={classes.invalidLabel}>{locale.t(locale.keys.adminCloudDasboard.invalid)}</label>;
        }
    };

    return (
        <>
            {isLoading(cloudConnectionContainer.ui) && <div data-testid={UI.state.Loading} />}
            {isError(cloudConnectionContainer.ui) && <div data-testid={UI.state.Error} />}
            {isLoaded(cloudConnectionContainer.ui) && (
                <Grid container>
                    <Grid item xs={12}>
                        <Paper className={classes.root}>
                            <div className={classes.tableWrapper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow className={classes.headerFont}>
                                            <TableCell className={classes.icon} />
                                            <TableCell align="left" className={classes.serverName}>
                                                {locale.t(locale.keys.adminCloudDasboard.serviceName)}
                                            </TableCell>
                                            <TableCell align="left" className={classes.rowContent}>
                                                {locale.t(locale.keys.adminCloudDasboard.serviceConnect)}
                                            </TableCell>
                                            <TableCell align="left" className={classes.operation}>
                                                {locale.t(locale.keys.adminCloudDasboard.operation)}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cloudConnectionContainer.cloudList.settings &&
                                            cloudConnectionContainer.cloudList.settings.map(
                                                (row, index) =>
                                                    !hiddenSetting(row.serviceName, billingPlan, environment.tenant) && (
                                                        <TableRow key={row.serviceId} hover={true} className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                                                            <TableCell align="left" className={classes.icon}>
                                                                {getCloudIcon(row)}
                                                            </TableCell>
                                                            <TableCell align="left" className={classes.serverName}>
                                                                {webappUtil.getServiceText(row.serviceName)}
                                                            </TableCell>
                                                            <TableCell align="left" className={classes.rowContent}>
                                                                {getCloudContent(row)}
                                                            </TableCell>
                                                            <TableCell align="left" className={classes.operation}>
                                                                <IconButton aria-label="Edit" className={classes.iconButton} onClick={() => linkToEdit(row.serviceId)}>
                                                                    <Edit />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ),
                                            )}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default withStyles(styles)(CloudConnectionComponent);
