import * as schema from '@/bundles/schema/typescript/schema';

// TODO: Show 2L again in the future
// const isOAuthAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => cloudDetail.is2L || cloudDetail.is3L;

// const isTwoLeggedOAuthAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => cloudDetail.is2L && !cloudDetail.is3L;

// const isThreeLeggedOAuthAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => !cloudDetail.is2L && cloudDetail.is3L;

// const isBothOAuthAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => cloudDetail.is2L && cloudDetail.is3L;

// const isOAuthProhibited = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => !cloudDetail.is2L && !cloudDetail.is3L;

const isOAuthAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => cloudDetail.is3L;

const isTwoLeggedOAuthAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => false;

const isThreeLeggedOAuthAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => cloudDetail.is3L;

const isBothOAuthAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => cloudDetail.is3L;

const isOAuthProhibited = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => !cloudDetail.is3L;

const isOnlyUploadAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => cloudDetail.isUploadable && !cloudDetail.isDownloadable;

const isOnlyDownloadAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => !cloudDetail.isUploadable && cloudDetail.isDownloadable;

const isBothUploadDownloadAvailable = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => cloudDetail.isUploadable && cloudDetail.isDownloadable;

const isUploadDownloadProhibited = (cloudDetail: schema.V1ObjectsWorkspacesCloudSetting) => !cloudDetail.isUploadable && !cloudDetail.isDownloadable;

export default {
    isOAuthAvailable,
    isTwoLeggedOAuthAvailable,
    isThreeLeggedOAuthAvailable,
    isBothOAuthAvailable,
    isOAuthProhibited,
    isOnlyUploadAvailable,
    isOnlyDownloadAvailable,
    isBothUploadDownloadAvailable,
    isUploadDownloadProhibited,
};
