import { default as React } from 'react';
import { WorkspaceSignupContainer } from './WorkspaceSignupContainer';
import WorkspaceSignup from './WorkspaceSignup';

interface Props {}

export const Component: React.FC<Props> = (props) => {
    return (
        <>
            <WorkspaceSignupContainer.Provider>
                <WorkspaceSignup />
            </WorkspaceSignupContainer.Provider>
        </>
    );
};

export default Component;
