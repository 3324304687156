import * as React from 'react';
import { AppBar, Fab, IconButton, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { default as Cropper } from 'react-cropper';
import { UI } from '@/common/components/ImageFileSelector';
import { CovasDropzone } from '@/common/components/file/CovasDropzone';
import { CloseOutlined } from '@material-ui/icons';
import { CovasCropper } from '@/common/components/file/CovasCropper';
import locale from '@/common/utils/locale';
import { StateContainer } from '@/common/components/AppContainerBase';
import environment from '@/common/constants/environment';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case "ricoh":
            return { primary: '#CC0033', secondary: '#E06666', tertiary: '#FFE6E6' };
        default:
            return { primary: '#143b4e', secondary: '#607e8c', tertiary: '#d1dde3' };
    }
}

const colors = selectColor(environment.tenant);



export const cropAndDropStyles = () =>
    createStyles({
        dropzone: {
            color: '#666666',
            backgroundColor: colors.tertiary,
            margin: '0 auto 24px',
            cursor: 'pointer',
        },
        dropzoneText: {
            margin: '0 auto 24px',
        },
        button: {
            color: 'white',
            float: 'right',
            cursor: 'pointer',
        },
        content: {
            '&& div': {
                borderBottom: '1px solid #ccc',
            },
        },
    });

export interface CovasDropAndCropProps extends WithStyles<typeof cropAndDropStyles> {
    ui: UI;
    visibility?: 'show' | 'hidden';
    datauri?: string;
    onClose?: () => void;
    onDropAction: (accepts: File[]) => void;
    onDropCancelAction: () => void;
    onLoadAction: (ref: React.MutableRefObject<Cropper | null>) => void;
    onCropCancelAction: () => void;
    dependAppContainer?: () => StateContainer;
    isAdminApp?: boolean;
}

const CovasDropAndCrop: React.FC<CovasDropAndCropProps> = (props) => {
    const appContainer = props.dependAppContainer ? props.dependAppContainer() : null;
    const cropperRef = React.useRef<Cropper | null>(null);
    const onDropCancel = () => {
        if (props) {
            props.onDropCancelAction!();
        }
    };
    const handleClose = () => {
        if (props && props.onClose) {
            props!.onClose!();
        }
        onDropCancel();
    };

    const onLoad = () => {
        if (appContainer) {
            appContainer.values.cropAndDropProps.onLoadAction(cropperRef);
            // const state = appContainer.initStateWithCache(appContainer.getPrefix());
            // state.cropAndDropProps.onLoadAction(cropperRef);
        }
        if (props.onClose) {
            props.onClose!();
        }
    };

    const header = () => {
        return (
            <AppBar position="static" style={{ width: '100%', height: '48px', textAlign: 'center', backgroundColor: props.isAdminApp ? colors.primary : colors.secondary }}>
                <div>
                    <div style={{ display: 'inline-block', padding: '10px' }}>
                        <span style={{ height: '24px', margin: '0 auto', padding: '14px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>{locale.t(locale.keys.action.selectImage)}</span>
                    </div>
                    <IconButton color="inherit" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', right: 0 }}>
                        <CloseOutlined />
                    </IconButton>
                </div>
            </AppBar>
        );
    };

    if (props && props.ui !== UI.Crop) {
        return (
            <div>
                <div
                    style={{
                        height: '100%',
                        padding: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        zIndex: 2000, // モーダルから表示する場合、モーダルよりも前面に表示する必要がある。
                        backgroundColor: 'white',
                    }}
                >
                    {header()}
                    <br />
                    <CovasDropzone {...props} width={400} height={400}>
                        <div style={{ textAlign: 'center' }}>
                            <Fab
                                variant="extended"
                                color="secondary"
                                disabled={true}
                                style={{
                                    width: '240px',
                                    height: '44px',
                                    marginTop: '20px',
                                    marginBottom: '5px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontStretch: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    backgroundColor: '#cccccc',
                                    color: 'white',
                                    verticalAlign: 'middle',
                                }}
                                onClick={handleClose}
                            >
                                {locale.t(locale.keys.action.save)}
                            </Fab>
                        </div>
                    </CovasDropzone>
                </div>
            </div>
        );
    }
    if (props && props.ui === UI.Crop) {
        return (
            <div>
                <div
                    style={{
                        height: '100%',
                        padding: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        zIndex: 2000, // モーダルから表示する場合、モーダルよりも前面に表示する必要がある。
                        backgroundColor: 'white',
                    }}
                >
                    <AppBar position="static" style={{ width: '100%', height: '48px', textAlign: 'center' }}>
                        <div>
                            <div style={{ display: 'inline-block', padding: '10px' }}>
                                <span style={{ height: '24px', margin: '0 auto', padding: '14px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                                    {locale.t(locale.keys.action.selectImage)}
                                </span>
                            </div>
                            <IconButton color="inherit" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', right: 0 }}>
                                <CloseOutlined />
                            </IconButton>
                        </div>
                    </AppBar>
                    <CovasCropper {...props} cropperRef={cropperRef} width={400} height={400}>
                        <div style={{ textAlign: 'center' }}>
                            <Fab
                                variant="extended"
                                color="secondary"
                                style={{
                                    width: '240px',
                                    height: '44px',
                                    marginTop: '55px',
                                    marginBottom: '5px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontStretch: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    color: 'white',
                                    backgroundColor: '#ff7300',
                                    verticalAlign: 'middle',
                                }}
                                onClick={() => onLoad()}
                            >
                                {locale.t(locale.keys.action.save)}
                            </Fab>
                        </div>
                    </CovasCropper>
                </div>
            </div>
        );
    }
    return <></>;
};

export default withStyles(cropAndDropStyles)(CovasDropAndCrop);
