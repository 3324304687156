import * as React from 'react';
import { Button, Typography, createStyles, Theme, WithStyles, withStyles, Grid, IconButton, InputAdornment, TextField, FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import Cancel from '@/common/components/Button/Cancel';
import dayjs from 'dayjs';
import { CalendarToday } from '@material-ui/icons';
import { MultiLineText } from '@/common/components/messages/MultiLineText';
import locale from '@/common/utils/locale';
import DataPicker, { registerLocale } from 'react-datepicker';
import { ja } from 'date-fns/locale'; // react-datepickerの依存でインストールされる日時ライブラリ。react-datepickerを削除するとdate-fnsも削除される。
import { DateFormat } from '@/common/constants/dateFormat';
import { AnalyticsContainer, initialDownloadDate, isDisableAnalyticsDownload } from '@/admin/components/analytics/AnalyticsContainer';

const styles = (theme: Theme) =>
    createStyles({
        mb20: {
            marginBottom: 20,
        },
        button: {
            fontSize: '0.875rem',
            [theme.breakpoints.up('md')]: {
                width: 'auto',
            },
        },
        buttonCase: {
            textAlign: 'right',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        tips: {
            paddingLeft: 20,
            fontSize: '0.775rem',
        },
        parameterCheckList: {
            height: 47,
            overflow: 'auto',
        },
        label: {
            fontSize: theme.typography.body2.fontSize,
            color: 'black',
        },
    });

interface Props extends WithStyles<typeof styles> {
    onClose: () => void;
    admit?: (propId: string, propName: string) => void;
    value?: string;
}

export const ReportExportModal: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const initialDate = initialDownloadDate(new Date());
    const [fromDate, setFromDate] = React.useState(initialDate.startDate);
    const [toDate, setToDate] = React.useState(initialDate.endDate);

    const container = AnalyticsContainer.useContainer();

    if (locale.current() === locale.Language.Japanese) {
        registerLocale('ja', ja);
    }

    const admitPush = () => {
        if (typeof props.admit === 'function') {
            props.admit('', '');
        }
        container.downloadAnalyticsCSV(fromDate, toDate);
        props.onClose();
    };

    return (
        <Grid container spacing={16}>
            <Grid item>
                <MultiLineText value={locale.t(locale.keys.analytics.usageLog.modal.desc)} />
            </Grid>
            <Grid item container direction="column">
                <FormControl error={isDisableAnalyticsDownload(fromDate, toDate)}>
                    <Grid item>
                        <FormLabel className={classes.label}>{locale.t(locale.keys.analytics.usageLog.modal.selectedDuration.title)}</FormLabel>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row">
                            <TextField
                                style={{ width: '49%', caretColor: 'transparent' }}
                                value={locale.current() === locale.Language.Japanese ? dayjs(fromDate).format(DateFormat.fullJpDate) : dayjs(fromDate).format(DateFormat.fullDateWithSlash)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DataPicker
                                                locale={locale.current() === locale.Language.Japanese ? 'ja' : undefined}
                                                onChange={(selectDate) => {
                                                    setFromDate(selectDate!);
                                                }}
                                                selected={fromDate}
                                                popperPlacement={'bottom-end'}
                                                popperModifiers={{
                                                    flip: {
                                                        behavior: ['bottom'],
                                                    },
                                                    preventOverflow: {
                                                        enabled: false,
                                                    },
                                                    hide: {
                                                        enabled: false,
                                                    },
                                                }}
                                                customInput={
                                                    <IconButton style={{ height: 35, width: 35 }}>
                                                        <CalendarToday style={{ color: 'gray', height: 20, width: 20 }} />
                                                    </IconButton>
                                                }
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Typography style={{ width: '2%', fontWeight: 'bold', margin: 'auto' }}>{'-'}</Typography>
                            <TextField
                                style={{ width: '49%', caretColor: 'transparent' }}
                                value={locale.current() === locale.Language.Japanese ? dayjs(toDate).format(DateFormat.fullJpDate) : dayjs(toDate).format(DateFormat.fullDateWithSlash)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DataPicker
                                                locale={locale.current() === locale.Language.Japanese ? 'ja' : undefined}
                                                onChange={(selectDate) => {
                                                    setToDate(selectDate!);
                                                }}
                                                selected={toDate}
                                                popperPlacement={'bottom-end'}
                                                popperModifiers={{
                                                    flip: {
                                                        behavior: ['bottom'],
                                                    },
                                                    preventOverflow: {
                                                        enabled: false,
                                                    },
                                                    hide: {
                                                        enabled: false,
                                                    },
                                                }}
                                                customInput={
                                                    <IconButton style={{ height: 35, width: 35 }}>
                                                        <CalendarToday style={{ color: 'gray', height: 20, width: 20 }} />
                                                    </IconButton>
                                                }
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>{isDisableAnalyticsDownload(fromDate, toDate) && <FormHelperText>{locale.t(locale.keys.analytics.usageLog.modal.selectedDuration.helperMessage)}</FormHelperText>}</Grid>
                </FormControl>
            </Grid>
            <Grid item className={classes.buttonCase}>
                <Cancel cancelFunc={props.onClose} />
                <Button disabled={isDisableAnalyticsDownload(fromDate, toDate)} variant="contained" fullWidth onClick={admitPush} className={classes.button}>
                    {locale.t(locale.keys.analytics.usageLog.modal.button.download)}
                </Button>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(ReportExportModal);
