import { default as React, useEffect } from 'react';
import { Theme, Grid, Card, CardContent, RadioGroup, Radio, FormControl, Fab, Typography, FormControlLabel, TextField, createStyles, withStyles, WithStyles } from '@material-ui/core';

import ImageFileSelector from '@/common/components/ImageFileSelector';
import userRole from '@/common/constants/userRole';
import locale from '@/common/utils/locale';
import { MemberDetailContainer } from './MemberDetailContainer';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import LanguageSelect from '@/common/components/LanguageSelect';
import { MultiLineText } from '@/common/components/messages/MultiLineText';

const styles = (theme: Theme) =>
    createStyles({
        logo: {
            textAlign: 'left',
            color: '#666666',
        },
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        selectLanguage: {
            marginTop: '17px',
        },
        imageFileSelectorCardContent: {
            textAlign: 'center',
            paddingBottom: 0,
            height: '160px',
        },
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        saveButton: {
            color: 'white',
            margin: theme.spacing.unit,
            minWidth: '240px',
        },
        avatar: {
            margin: 'auto',
        },
    });
interface Props extends WithStyles<typeof styles> {
    handleSave: Function;
    modifiable: Function;
}

export const Component: React.FC<Props> = (props) => {
    const container = MemberDetailContainer.useContainer();
    const { classes } = props;
    const modifiable = props.modifiable();

    useEffect(() => {
        container.setInitEmail(container.form.email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {container.values.user ? (
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container spacing={32}>
                            <Grid item xs={12} sm={5} style={{ paddingBottom: 0 }}>
                                <Typography align="left" color="textSecondary">
                                    {locale.t(locale.keys.memberDetail.memberSetting.avatarImage)}
                                </Typography>
                                <CardContent className={classes.imageFileSelectorCardContent}>
                                    <div>
                                        <ImageFileSelector
                                            seed={container.values.user.invitationEmail}
                                            uploadWidth={512}
                                            uploadHeight={512}
                                            mobileWidth={160}
                                            mobileHeight={160}
                                            pcWidth={160}
                                            pcHeight={160}
                                            defaultUrl={container.form.avatarUrl}
                                            logoDataUri={container.avatarDataUri}
                                            isAvatar={true}
                                            onLoaded={(datauri) => {
                                                container.handleChangeAvatar(datauri);
                                            }}
                                            dependAppContainer={AdminAppContainer.useContainer}
                                            editable={true}
                                        />
                                    </div>
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} sm={7} style={{ paddingBottom: 0 }}>
                                <CardContent style={{ paddingBottom: 0 }}>
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.common.email)}
                                        placeholder=""
                                        margin="normal"
                                        defaultValue={container.form.email}
                                        inputProps={{
                                            style: { height: '100%' },
                                        }}
                                        onChange={container.handleChange('email')}
                                        error={container.handleValidateEmail() !== '' || container.duplicateCheck() !== ''}
                                        helperText={container.handleValidateEmail() || container.duplicateCheck()}
                                    />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.common.name)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={container.form.name}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={container.handleChange('name')}
                                        error={container.handleValidateName() !== ''}
                                        helperText={container.handleValidateName()}
                                    />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.common.phonetic)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={container.form.phonetic}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={container.handleChange('phonetic')}
                                        error={container.handleValidatePhonetic() !== ''}
                                        helperText={container.handleValidatePhonetic()}
                                    />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.common.deviceLoginUser)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={container.form.deviceLoginUser}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={container.handleChange('deviceLoginUser')}
                                        error={container.handleValidateDeviceLoginUser() !== ''}
                                        helperText={container.handleValidateDeviceLoginUser()}
                                    />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.modalLabel.mfp.pin)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={container.form.pin}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={container.handleChange('pin')}
                                        error={container.handleValidatePin() !== ''}
                                        helperText={container.handleValidatePin()}
                                    />
                                    <div className={classes.selectLanguage}>
                                        <LanguageSelect
                                            disabled={false}
                                            value={container.form.language}
                                            label={locale.t(locale.keys.newWorkspaceSetting.userLanguage)}
                                            handleChange={(event) => container.handleChangeLanguage(event.currentTarget.value)}
                                            handleValidate={() => container.handleValidateLanguage()}
                                        />
                                    </div>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label={locale.t(locale.keys.common.contactEmail)}
                                        value={container.form.contactEmail}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={container.handleChange('contactEmail')}
                                        error={container.handleValidateContactEmail() !== ''}
                                        helperText={container.handleValidateContactEmail()}
                                    />
                                    <MultiLineText value={locale.t(locale.keys.common.contactEmailInfo)} align="left" variant="caption" color="textSecondary" />
                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <div className={classes.logo}>{locale.t(locale.keys.memberDetail.memberSetting.role)}</div>
                                        <RadioGroup value={container.form.admin} onChange={container.handleChangeAdmin} row>
                                            <FormControlLabel
                                                value={String(userRole.member)}
                                                control={<Radio />}
                                                disabled={!modifiable}
                                                label={locale.t(locale.keys.memberDetail.memberSetting.member)}
                                            />
                                            {container.values.user.role === userRole.systemAdmin ? (
                                                <FormControlLabel
                                                    value={String(userRole.systemAdmin)}
                                                    control={<Radio />}
                                                    disabled={!modifiable}
                                                    label={locale.t(locale.keys.memberDetail.memberSetting.admin)}
                                                />
                                            ) : (
                                                <FormControlLabel
                                                    value={String(userRole.admin)}
                                                    control={<Radio />}
                                                    disabled={!modifiable}
                                                    label={locale.t(locale.keys.memberDetail.memberSetting.admin)}
                                                />
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Fab
                                    variant="extended"
                                    className={classes.saveButton}
                                    disabled={container.duplicateCheck() !== '' || container.buttonDisabled()}
                                    size="large"
                                    color="secondary"
                                    onClick={props.handleSave()}
                                >
                                    {locale.t(locale.keys.memberDetail.memberSetting.save)}
                                </Fab>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container spacing={32}>
                            <Grid item xs={12}>
                                user not found
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default withStyles(styles)(Component);
