import { default as React, useState } from 'react';
import { default as Avatar, AvatarProps } from '@material-ui/core/Avatar';
import { CameraAlt, Group } from '@material-ui/icons';
import CardActionArea from '@material-ui/core/CardActionArea';
import locale from '@/common/utils/locale';

import jdenticon from 'jdenticon';
import logger from '@/common/utils/logger';

interface Props extends AvatarProps {
    // アバター: email
    // ワークスペース: ワークスペースid
    seed?: any;
    // width、height のピクセルを指定
    size: number;
    // 操作可能かどうか
    editable?: boolean;
}

// src がない場合、seedの値を使用してjdenticonのアイコンを表示するAvatar
export const CovasAvatar: React.FC<Props> = (props) => {
    const { ...other } = props;
    const [hover, isHover] = useState(false);
    const handleMouseOver = () => {
        isHover(true);
    };
    const handleMouseLeave = () => {
        isHover(false);
    };

    // logger.debug(`avatar seed: ${props.seed}`);

    if (props.src) {
        return (
            <Avatar
                {...other}
                src=""
                style={{
                    width: props.size,
                    height: props.size,
                }}
            >
                {props.editable !== undefined && props.editable ? (
                    <span
                        onMouseOver={() => {
                            handleMouseOver();
                        }}
                        onMouseLeave={() => {
                            handleMouseLeave();
                        }}
                    >
                        <CardActionArea
                            classes={{
                                focusHighlight: '',
                            }}
                            style={{
                                width: props.size,
                                height: props.size,
                                borderRadius: '50%',
                                color: 'black',
                                backgroundColor: hover ? 'gray' : 'inherit',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    width: props.size!,
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    display: hover ? 'inline-block' : 'none',
                                    zIndex: 'auto',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                <CameraAlt
                                    style={{
                                        color: 'white',
                                        transform: `scale(${props.size != null ? props.size / 100 : 2.5})`,
                                        display: 'block',
                                        margin: hover ? '10px auto' : '',
                                    }}
                                />
                                <div
                                    style={{
                                        color: 'white',
                                        fontSize: '15px',
                                        textShadow: '1px 1px 0 gray',
                                        margin: '0px auto',
                                    }}
                                >
                                    {locale.t(locale.keys.action.change)}
                                </div>
                            </div>
                            <img
                                alt=""
                                src={props.src}
                                style={{
                                    width: props.size,
                                    height: props.size,
                                    color: 'gray',
                                }}
                            />
                        </CardActionArea>
                    </span>
                ) : (
                    <img
                        alt=""
                        src={props.src}
                        style={{
                            width: props.size,
                            height: props.size,
                            color: 'gray',
                        }}
                    />
                )}
            </Avatar>
        );
    }

    const svg = jdenticon.toSvg(props.seed, props.size!, { padding: 0.15 });
    if (!other.style) {
        other.style = {
            backgroundColor: '#fafafa',
            width: props.size,
            height: props.size,
        };
    } else {
        other.style = {
            ...other.style,
            backgroundColor: '#fafafa',
            width: props.size,
            height: props.size,
        };
    }

    return (
        <Avatar {...other}>
            {props.editable !== undefined && props.editable ? (
                <span
                    onMouseOver={() => {
                        handleMouseOver();
                    }}
                    onMouseLeave={() => {
                        handleMouseLeave();
                    }}
                >
                    <CardActionArea
                        classes={{
                            focusHighlight: '',
                        }}
                        style={{
                            width: props.size!,
                            height: props.size!,
                            borderRadius: '50%',
                            color: 'black',
                            backgroundColor: hover ? 'gray' : 'inherit',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                width: props.size!,
                                textAlign: 'center',
                                alignItems: 'center',
                                display: hover ? 'inline-block' : 'none',
                                zIndex: 'auto',
                                top: '50%',
                                transform: 'translateY(-50%)',
                            }}
                        >
                            <CameraAlt
                                style={{
                                    color: 'white',
                                    transform: `scale(${props.size != null ? props.size / 100 : 2.5})`,
                                    display: 'block',
                                    margin: hover ? '10px auto' : '',
                                }}
                            />
                            <div
                                style={{
                                    color: 'white',
                                    fontSize: '15px',
                                    textShadow: '1px 1px 0 gray',
                                    margin: '0 auto',
                                }}
                            >
                                {locale.t(locale.keys.action.change)}
                            </div>
                        </div>
                        <span dangerouslySetInnerHTML={{ __html: svg }} style={{ width: props.size, height: props.size }} />
                    </CardActionArea>
                </span>
            ) : (
                <span dangerouslySetInnerHTML={{ __html: svg }} style={{ width: props.size, height: props.size }} />
            )}
        </Avatar>
    );
};

interface GroupAvatarProps extends AvatarProps {
    size: number;
    src?: string;
}

/**
 * グループアバターコンポーネント
 * @description ロゴがあればロゴを表示、なければグループアイコンを表示
 */
export const CovasGroupAvatar: React.FC<GroupAvatarProps> = React.memo(
    (props) => {
        const { ...other } = props;

        return (
            <Avatar
                {...other}
                src=""
                style={{
                    width: props.size,
                    height: props.size,
                }}
            >
                {props.src ? (
                    <img
                        alt=""
                        src={props.src}
                        style={{
                            width: props.size,
                            height: props.size,
                        }}
                    />
                ) : (
                    <Group
                        style={{
                            width: '60%',
                            height: '60%',
                            color: 'white',
                        }}
                    />
                )}
            </Avatar>
        );
    },
    (prevProps, nextProps) => {
        // src が変わったときのみ再描画する
        return prevProps.src === nextProps.src;
    },
);

export default React.memo(CovasAvatar, (prevProps, nextProps) => {
    // srcまたはseedが変わったときのみ再描画する
    return prevProps.src === nextProps.src && prevProps.seed === nextProps.seed;
});
