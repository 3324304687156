import { default as React, useEffect } from 'react';
import { Theme, Typography, createStyles, withStyles, WithStyles, Grid, Button } from '@material-ui/core';
import { WorkspaceSignupContainer } from './WorkspaceSignupContainer';
import locale from '@/common/utils/locale';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import * as model from '@/common/api/auth/workspace-signup/WorkspaceSignup';
import FormOrganization from '@/admin/components/common/FormOrganization';

const styles = (theme: Theme) =>
    createStyles({
        mt20: {
            marginTop: 20,
        },
        mb20: {
            marginBottom: 20,
        },
        formControl: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
                minWidth: 240,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const container = WorkspaceSignupContainer.useContainer();
    const { classes } = props;
    const lhc = LogoHeaderContainer.useContainer();

    useEffect(() => {
        lhc.setBackFunc(() => {
            container.setScreenState(model.WorkspaceSignupState.ProfileSetting);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 次へ
    const handleNext = () => {
        container.setScreenState(model.WorkspaceSignupState.AdminSetting);
        container.setValues({
            ...container.values,
            organizationName: container.organization.name,
            zipCode: container.organization.zipCode,
            country: container.organization.country,
            address1: container.organization.address1,
            address2: container.organization.address2,
            phoneNumber: container.organization.phoneNumber,
            customerId: container.organization.customerId,
        });
    };

    return (
        <>
            <Grid container spacing={32}>
                <Grid item xs={12} className={`${classes.mb20} ${classes.mt20}`}>
                    <Typography align="center" variant="h3" className={classes.mb20} style={{ wordWrap: 'break-word' }}>
                        {locale.t(locale.keys.newWorkspaceSetting.workspace, { workspaceName: container.values.workspaceName, workspaceId: container.values.workspaceId })}
                    </Typography>
                    <Typography align="center">{locale.t(locale.keys.newWorkspaceSetting.organizationSetting)}</Typography>
                </Grid>
                <FormOrganization organization={container.organization} setOrganization={container.setOrganization} disabled={false} />
                <Grid item xs={12}>
                    <Button className={classes.button} fullWidth variant="contained" disabled={container.organizationSettingNextButtonDisabled()} onClick={() => handleNext()}>
                        {locale.t(locale.keys.action.next)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(Component);
