import { useState } from 'react';
import { createContainer } from 'unstated-next';
import * as services from '@/common/api/workspace/cloud-setting/services';
import * as schema from '@/bundles/schema/typescript/schema';
import { AdminAppContainer } from '../AdminAppContainer';
import { default as UI } from '@/common/constants/ui';
import * as errorHandler from '@/common/utils/errorHandler';

const defaultCloudList: schema.V1WorkspaceCloudSettingIndexResponse = {
    settings: [
        {
            serviceId: 'adipisicing proident',
            serviceName: schema.EndpointType.Docab,
            isUploadable: true,
            isDownloadable: true,
            is2L: false,
            is3L: false,
            apiKey: 'Duis dolor in Excepteur',
        },
    ],
};

const useCloudConnectionContainer = () => {
    const [cloudList, setCloudList] = useState(defaultCloudList);
    const [ui, setUI] = useState(UI.state.Loading);
    const appContainer = AdminAppContainer.useContainer();

    const getCloudList = async () => {
        try {
            const data = await services.getServices(appContainer.values.signinWorkspaceObject.id || '', appContainer.values.authorizationCode);
            if (!data) {
                throw new Error('Fail to fetch');
            }
            setCloudList(data);
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    return {
        getCloudList,
        cloudList,
        ui,
        setUI,
    };
};
export const CloudConnectionContainer = createContainer(useCloudConnectionContainer);
