import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import * as uploader from '@/common/utils/uploader';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';
import querystring from 'querystring';

/**
 * @param req schema.V1WorkspaceGroupsCreateRequest
 * @param workspaceId ワークスペースID
 * @param imageDataUri 画像データ
 * @param auth token
 * @returns void
 * @description
 * グループ作成リクエスト
 */
export const createGroup = async (req: schema.V1WorkspaceGroupsCreateRequest, workspaceId: string, imageDataUri: string, auth: string) => {
    const response = await axiosFactory.post<schema.V1WorkspaceGroupsShowResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/groups`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });

    // グループロゴをアップロードする場合は、blobにアップロードした後に取得したURLをグループ情報に追加する。
    if (imageDataUri !== '') {
        const avatarRes = await uploader.Upload(`${response.data.id}.png`, schema.BlobType.GroupLogo, auth, imageDataUri, response.data.id);
        await updateGroup(
            {
                groupName: req.groupName, // グループ名を更新しない場合は同名のグループ名を入れる。
                avatarUrl: avatarRes.publicUrl,
            },
            workspaceId,
            response.data.id,
            auth,
        );
        return;
    }
    return;
};

export const indexStreamGroupData = async (indexGroupRequest: (offset: number, limit: number) => Promise<schema.V1WorkspaceGroupsIndexResponse>) => {
    const result: schema.V1WorkspaceGroupsShowResponse[] = [];
    let INCREMENT = 25;
    let offset = 0;

    // 初回フェッチでグループとWS内のグループ数を取得
    const group = await indexGroupRequest(offset, INCREMENT);
    offset += INCREMENT;
    result.push(...group.groups);

    const LIMIT = group.count; // 取得できる最大グループ数
    // INCREMENTずつずらしながらLIMITちょうどになるまでグループを取得
    while (true) {
        // グループ数がLIMITを超えたら終了
        if (LIMIT <= offset) {
            break;
        }
        // offsetがLIMITを超えたらエラーになるので、最後のリクエストはoffsetからLIMITまで取得する。
        if (LIMIT <= offset + INCREMENT) {
            const lastgroup = await indexGroupRequest(offset, LIMIT - offset);
            result.push(...lastgroup.groups);
            break;
        } else {
            const nextgroup = await indexGroupRequest(offset, INCREMENT);
            offset += INCREMENT;
            result.push(...nextgroup.groups);
            continue;
        }
    }

    return {
        count: result.length,
        groups: result,
    };
};

/**
 * @param workspaceId ワークスペースID
 * @param auth token
 * @returns schema.V1WorkspaceGroupsIndexResponse
 * @description
 * グループ一覧取得リクエスト
 */
export const indexGroup = async (workspaceId: string, auth: string, offset?: number, limit?: number, groupName?: string): Promise<schema.V1WorkspaceGroupsIndexResponse> => {
    const query = querystring.stringify({
        offset,
        limit,
        includeMember: true,
        groupName,
    });
    const response = await axiosFactory.get<schema.V1WorkspaceGroupsIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/groups?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * @param req schema.V1WorkspaceGroupsUpdateRequest
 * @param workspaceId ワークスペースID
 * @param groupId グループID
 * @param auth token
 * @param imageDataUri 新規にアップロードする場合の画像データ
 * @returns schema.V1WorkspaceGroupsShowResponse
 * @description グループ更新リクエスト
 */
export const updateGroup = async (
    req: schema.V1WorkspaceGroupsUpdateRequest,
    workspaceId: string,
    groupId: string,
    auth: string,
    imageDataUri?: string,
): Promise<schema.V1WorkspaceGroupsShowResponse> => {
    let request = {
        ...req,
    };

    // グループロゴを新規にアップロードする場合は、blobにアップロードした後に取得したURLをグループ情報に追加する。
    if (imageDataUri !== undefined && imageDataUri !== null && imageDataUri !== '') {
        const avatarRes = await uploader.Upload(`${groupId}.png`, schema.BlobType.GroupLogo, auth, imageDataUri, groupId);
        request.avatarUrl = avatarRes.publicUrl;
    }

    if (req.groupName) {
        request.groupName = groupNameTrim(req.groupName);
    }

    const response = await axiosFactory.patch<schema.V1WorkspaceGroupsShowResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/groups/${groupId}`, request, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * @param groupId グループID
 * @param auth token
 * @returns schema.V1WorkspaceGroupsShowResponse
 * @description グループ削除リクエスト
 */
export const deleteGroup = async (groupId: string, workspaceId: string, auth: string): Promise<schema.V1WorkspaceGroupsShowResponse> => {
    const response = await axiosFactory.delete<schema.V1WorkspaceGroupsShowResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/groups/${groupId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

/**
 * @param workspaceId ワークスペースID
 * @param auth token
 * @description Everyoneを最新の状態に更新する。DBの更新ミスなどでEveryoneにメンバーが登録されていない場合に、外部管理者がメンテナンスで使用する。
 */
export const updateEveryone = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceGroupsShowResponse> => {
    const responce = await axiosFactory.put<schema.V1WorkspaceGroupsShowResponse>(
        `https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/groups`,
        {},
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return responce.data;
};

// 画面で表示するグループリストデータの型
export type GroupRow = {
    wsGroups: {
        id: string;
        name: string;
        avatarUrl: string;
        memberCount: number;
    }[];
    groups: {
        id: string;
        name: string;
        avatarUrl: string;
        memberCount: number;
    }[];
};

/**
 * @param viewModel フォームに入力している値（オブジェクト）
 * @returns schema.V1WorkspaceGroupsCreateRequest(リクエストに乗せる値)
 * @description
 * グループ作成リクエストのデータ加工
 */
export const groupNameTrim = (groupName: string): string => {
    return groupName.trim();
};
