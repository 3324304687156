import { default as React, useEffect } from 'react';
import { RouteComponentProps, withRouter, Link as RouterLink } from 'react-router-dom';
import { Theme, createStyles, withStyles, WithStyles, IconButton, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { default as TablePagination, LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Email from '@material-ui/icons/Email';
import Edit from '@material-ui/icons/Edit';
import * as InvitationMember from '@/common/api/workspaceuser/invite/InvitationMember';
import locale from '@/common/utils/locale';
import { InvitationMemberContainer } from '@/admin/components/member/invite/InvitationMemberContainer';
import routes from '@/admin/constants/routes';

const styles = (theme: Theme) =>
    createStyles({
        icon: {
            margin: theme.spacing.unit * 2,
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        titleLabel: {
            textAlign: 'left',
        },
        table: {
            minWidth: 700,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        pencil: {
            color: 'orange',
        },
        tableCell: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        oddRow: {
            background: '#f2f4f5',
        },
        evenRow: {
            background: 'inherit',
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
    });

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
    invitationRow: InvitationMember.Row[];
    reloadCallback: () => void;
}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = InvitationMemberContainer.useContainer();

    useEffect(() => {
        const transformedInvitationRow = container.invitationRow.map(row => ({
            id: row.userId,
            active: row.invited,
            avatarUrl: "", // 必要に応じて適切な値を設定
            invitationEmail: row.email,
            language: "ja", // 必要に応じて適切な値を設定
            name: row.invitationUser,
            phoneticName: row.invitationUser, // 必要に応じて適切な値を設定
            role: 10 // 必要に応じて適切な値を設定
        }));
        sessionStorage.setItem('detailFlag', 'inviteDetail');
        sessionStorage.setItem('invitationMembers', JSON.stringify(transformedInvitationRow));
    }, [container.invitationRow]);


    return (
        <>
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.headerFont}>
                                <TableCell align="left">{locale.t(locale.keys.memberInvitation.memberEmailAddress.index)}</TableCell>
                                <TableCell align="left">{locale.t(locale.keys.memberInvitation.invidationUser.index)}</TableCell>
                                <TableCell align="left">{locale.t(locale.keys.memberInvitation.invidationDate.index)}</TableCell>
                                <TableCell align="left">{locale.t(locale.keys.memberInvitation.period.index)}</TableCell>
                                <TableCell align="left">{locale.t(locale.keys.memberInvitation.reInvitation.index)}</TableCell>
                                <TableCell align="center">{locale.t(locale.keys.memberInvitation.operation)}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {container
                                .getRows(props.invitationRow)
                                .slice(container.page * container.rowsPerPage, container.page * container.rowsPerPage + container.rowsPerPage)
                                .map((row, index) => (
                                    <TableRow key={row.id} className={index % 2 ? classes.evenRow : classes.oddRow}>
                                        <TableCell className={classes.tableCell} align="left">
                                            {row.email}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="left" component="th" scope="row">
                                            {row.invitationUser}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="left">
                                            {row.invitationDate}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="left">
                                            {row.limit}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="left">
                                            {row.invited ? (
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        marginTop: '5px',
                                                        marginLeft: '20px',
                                                        marginBottom: '5px',
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        fontStyle: 'normal',
                                                        fontStretch: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: 'normal',
                                                        color: '#ff7300',
                                                        backgroundColor: 'white',
                                                        verticalAlign: 'middle',
                                                        height: '28px',
                                                        width: '106px',
                                                        border: '1px solid orange',
                                                    }}
                                                    onClick={(event: React.MouseEvent) => {
                                                        container.handleReSend(row, props.reloadCallback);
                                                    }}
                                                >
                                                    <Email style={{ transform: `scale(1.0)` }} />
                                                    <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.action.invitationReSend)}</span>
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        marginTop: '5px',
                                                        marginLeft: '20px',
                                                        marginBottom: '5px',
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        fontStyle: 'normal',
                                                        fontStretch: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: 'normal',
                                                        color: 'white',
                                                        backgroundColor: '#ff3d00',
                                                        verticalAlign: 'middle',
                                                        height: '28px',
                                                        width: '106px',
                                                        border: '1px solid orange',
                                                    }}
                                                    onClick={() => container.handleReSend(row, props.reloadCallback)}
                                                >
                                                    <Email style={{ transform: `scale(1.0)` }} />
                                                    <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.action.invitationSend)}</span>
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                            <RouterLink to={`${routes.user.index}/${row.userId}?invite=true`}>
                                                <IconButton aria-label="Edit">
                                                    <Edit className={classes.pencil} />
                                                </IconButton>
                                            </RouterLink>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {container.getEmptyRowCount(props.invitationRow) > 0 && (
                                <TableRow style={{ height: 49 * container.getEmptyRowCount(props.invitationRow) }}>
                                    <TableCell colSpan={100} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={props.invitationRow.length}
                        labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                        labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                            <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                        )}
                        rowsPerPage={container.rowsPerPage}
                        page={container.page}
                        backIconButtonProps={{
                            'aria-label': locale.t(locale.keys.table.previousPage),
                        }}
                        nextIconButtonProps={{
                            'aria-label': locale.t(locale.keys.table.nextPage),
                        }}
                        onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => container.handlePageChange(event, p)}
                        onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => container.handleChangeRowsPerPage(event)}
                    />
                </div>
            </Paper>
        </>
    );
};

export default withRouter(withStyles(styles)(Component));
