import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getServiceDetail = async (wId: string, wsId: string, auth: string): Promise<schema.V1WorkspaceCloudSettingShowResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceCloudSettingShowResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/cloud-setting/${wsId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const update = async (workspaceId: string, id: string, req: schema.V1WorkspaceCloudSettingUpdateRequest, auth: string): Promise<schema.V1WorkspaceCloudSettingUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1WorkspaceCloudSettingUpdateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/cloud-setting/${id}`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};
export const getServices = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceCloudSettingIndexResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceCloudSettingIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/cloud-setting`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
