import List from './List';
import InvitaitionTable from './InvitationTable';
import Invitation from './Invitation';
import MailButton from './MailButton';
import mail from './mail';

export default {
    List,
    InvitaitionTable,
    Invitation,
    MailButton,
    mail,
};
