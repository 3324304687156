import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';

import '@/common/css/app.css';
import { default as commonConsts } from '@/common/constants';
import routes from '@/admin/constants/routes';
import components from '@/admin/components';
import LogoHeader from '@/common/components/headers/LogoHeader';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { default as CovasDropAndCrop } from '@/common/components/file/CovasDropAndCrop';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            paddingTop: commonConsts.ui.headerHeight,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const onCropAndDropCallback = () => {
        appContainer.setValues({
            ...appContainer.values,
            cropAndDropProps: {
                ...appContainer.values.cropAndDropProps,
                visibility: 'hidden',
            },
        });
    };

    return (
        <div className="App">
            {appContainer.values.cropAndDropProps && appContainer.values.cropAndDropProps.visibility && appContainer.values.cropAndDropProps.visibility === 'show' ? (
                <CovasDropAndCrop {...appContainer.values.cropAndDropProps} onClose={onCropAndDropCallback} visibility={'show'} dependAppContainer={AdminAppContainer.useContainer} isAdminApp />
            ) : (
                <></>
            )}
            <div
                style={{ display: appContainer.values.cropAndDropProps && appContainer.values.cropAndDropProps.visibility && appContainer.values.cropAndDropProps.visibility === 'show' ? 'none' : '' }}
            >
                <LogoHeaderContainer.Provider>
                    <LogoHeader loadingState={appContainer.loadingState} isAdminApp={true} />
                    <div className={props.classes.root}>
                        <main className={props.classes.content}>
                            <Switch>
                                <Route exact path={routes.auth.login.selectedWorkspace} component={components.auth.signin.List} />
                                <Route exact path={routes.auth.login.index} component={components.auth.signin.List} />
                                <Route exact path={routes.auth.logout.index} component={components.auth.logout.List} />
                                <Route exact path={routes.auth.recover.index} component={components.auth.recover.List} />
                                <Route exact path={routes.auth.signup.index} component={components.auth.verify.List} />
                                <Route exact path={routes.auth.workspaceSignup.index} component={components.auth.verify.List} />
                            </Switch>
                        </main>
                    </div>
                </LogoHeaderContainer.Provider>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
