import { default as React, useState, useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTitle from '@/common/components/hooks/useTitle';
import Invitation from './Invitation';
import InvitationTable from './InvitationTable';
import * as invitationModel from '@/common/api/sub-workspace/inivite/Invitation';
import logger from '@/common/utils/logger';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import Error from '@/common/components/state/Error';
import locale from '@/common/utils/locale';
import moment from 'moment';
import { InvitationContainer } from '@/admin/components/sub-workspace/invite/InvitationContainer';
import { DateFormat } from '@/common/constants/dateFormat';
import * as errorHandler from '@/common/utils/errorHandler';
import SubHeader from '@/admin/components/common/subheader/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });
interface Props extends WithStyles<typeof styles> {}
export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.invitationNewWorkspace.index));

    const appContainer = AdminAppContainer.useContainer();
    const [data, setData] = useState<invitationModel.Row[]>([]);
    const [init, setInit] = useState(false);
    const ui = useUI();

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        (async () => {
            if (init || data.length !== 0) {
                return;
            }
            setInit(true);

            try {
                const initData = await invitationModel.createData(appContainer.values.signinWorkspaceObject.displayId, appContainer.values.authorizationCode);
                logger.debug(initData);
                setData(initData);
                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onMailSend = (limit: number, rowId: number) => {
        const modified = data.map((e) => {
            if (e.id === rowId) {
                e.invitationDate = moment(new Date()).format(DateFormat.fullDateTimeWithDot);
                let limitString: string;
                const endDate = moment(new Date())
                    .add(limit, 'days')
                    .format(DateFormat.fullDateTimeWithDot);
                const dayDiff = moment(endDate).diff(moment.now(), 'days');
                const minuteDiff = moment(endDate).diff(moment.now(), 'minutes');
                limitString =
                    dayDiff > 0
                        ? // day diff(remain n days)
                          invitationModel.dayLimitString(dayDiff)
                        : minuteDiff > 0
                        ? minuteDiff > 60
                            ? // hour minute diff(remain x hours and y minutes)
                              invitationModel.hourMinuteLimitString(minuteDiff)
                            : // minute diff(remain n minutes)
                              invitationModel.minuteLimitString(minuteDiff)
                        : // expired
                          invitationModel.expiredString();
                e.limit = limitString;
            }
            return e;
        });
        setData(modified);
        onInvite(null);
    };
    const onMailError = (rowId: number) => {
        const modified = data.map((e) => {
            if (e.id === rowId) {
                e.failure = true;
            }
            return e;
        });
        setData(modified);
    };
    // 招待後に一覧を更新
    const onInvite = (row: invitationModel.OnInviteRow | null) => {
        logger.debug('onInvite');
        ui.update(UI.Loading);
        if (row != null) {
            data.push({
                id: data.length + 1,
                mailState: '',
                workspaceName: row.displayId,
                displayId: row.displayId,
                email: row.contactEmail,
                name: '',
                invitationDate: '',
                limit: '',
                lock: true,
            });
            setData(data);
        }
        (async () => {
            try {
                const initData = await invitationModel.createData(appContainer.values.signinWorkspaceObject.displayId, appContainer.values.authorizationCode);
                logger.debug(initData);
                setData(initData);
                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    return (
        <>
            <SubHeader title={locale.t(locale.keys.invitationNewWorkspace.index)} />
            <div className={props.classes.mainDiv}>
                {/* Loading, Saving中でも画面は出したままにしておく */}
                {ui.current !== UI.Error && (
                    <InvitationContainer.Provider>
                        <div>
                            <div className={props.classes.root}>
                                <Invitation onInvite={onInvite} />
                                <InvitationTable invitationRow={data} mailSendErrorBack={onMailError} mailSendBack={onMailSend} />
                            </div>
                        </div>
                    </InvitationContainer.Provider>
                )}
                {ui.current === UI.Error && (
                    <div data-testid={UI.Error}>
                        <Error />
                    </div>
                )}
            </div>
        </>
    );
};

export default withStyles(styles)(Component);
