export enum DateFormat {
    fullDateTimeWithHyphen = 'YYYY-MM-DD HH:mm:ss',
    fullDateTimeWithDot = 'YYYY.MM.DD HH:mm',
    fullDateTimeWithSlash = 'YYYY/MM/DD HH:mm',
    fullDateWithSlash = 'YYYY/MM/DD',
    fullDateTimeWithNonSeparate = 'YYYYMMDDHHmmss',
    fullJpDate = 'YYYY年M月DD日',
    fullDateWithHyphen = 'YYYY-MM-DD',
    fullYearMonthWithHyphen = 'YYYY-MM',
    fullYearDateWithHyphen = 'YYYY-DD',
    fullYear = 'YYYY',
    fullMonthDateWithHyphen = 'MM-DD',
    fullMonth = 'MM',
    fullDate = 'DD',
    fullYearMonthTime = 'YYYY-MM HH:mm:ss',
    fullYearDayTime = 'YYYY-DD HH:mm:ss',
    fullYearTime = 'YYYY HH:mm:ss',
    fullMonthDayTime = 'MM-DD HH:mm:ss',
    fullMonthTime = 'MM HH:mm:ss',
    fullDayTime = 'DD HH:mm:ss',
    fullTime = 'HH:mm:ss',
    fullDateWithNoneSeparate = 'YYYYMMDD',
}

export const regexEndpointPw = '^[\x20-\x7F]{0,32}$';
