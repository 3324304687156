import { default as React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Theme, createStyles, withStyles, WithStyles, Grid, Paper, Button, ListItemText, ListItem, Collapse, List, Divider, FormControl, TextField, Fab, Typography } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { EntryDetailContainer } from './EntryDetailContainer';
import { State as UI } from '@/common/components/hooks/useUI';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';

const menuStyles = (theme: Theme) =>
    createStyles({
        root: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        text: {
            maxWidth: '90%',
            minWidth: '90%',
            marginBlock: '0px',
        },
        caption: {
            maxWidth: '100%',
            minWidth: '100%',
            marginBlock: '16px',
        },
        button: {
            maxWidth: '90%',
            minWidth: '90%',
            marginBlock: '16px',
        },
        buttonGrid: {
            textAlign: 'left',
        },
        removeButton: {
            color: 'white',
            marginBlock: '16px',
            marginLeft: '5%',
        },
        buttonText: {
            margin: '0 8px',
        },
        dividerMargin: {
            marginInline: '12px',
        },
    });

interface WorkSpaceButtonProps extends WithStyles<typeof menuStyles> {}
const WorkSpaceButton = withStyles(menuStyles, { withTheme: true })((props: WorkSpaceButtonProps) => {
    const container = EntryDetailContainer.useContainer();
    const { classes } = props;
    let RenderButton = <></>;
    switch (container.entryDetail.entryState) {
        // 申込中ならワークスペース作成しかできない(ローディング中はこのボタンが非活性状態で表視されるので他のケースではローディングを考慮しない)
        case schema.V1ObjectsEntryState.Entry:
            RenderButton = (
                <Button className={classes.button} disabled={container.ui.current === UI.Loading} variant="outlined" size="large" color="secondary" onClick={container.onClickWorkspaceCreate}>
                    {locale.t(locale.keys.applicationList.applicationDetails.workspaceOperation.create)}
                </Button>
            );
            break;
        // 作成中なら招待画面へのリンクを表示（レアケースではあるが、ワークスペースが削除されていたら再度作成できるようにする）
        case schema.V1ObjectsEntryState.Create:
            if(container.entryDetail.workspace){
                RenderButton = (
                    <Link to={`/sub-workspace/detail/${container.entryDetail.workspace.displayId}?entrydetail=${container.entryDetail.id}`}>
                        <Button className={classes.button} variant="outlined" size="large" color="secondary">
                            {locale.t(locale.keys.applicationList.applicationDetails.workspaceOperation.detail)}
                        </Button>
                    </Link>
                );
            }else {
                RenderButton = (
                    <Button className={classes.button} variant="outlined" size="large" color="secondary" onClick={container.onClickWorkspaceCreate}>
                        {locale.t(locale.keys.applicationList.applicationDetails.workspaceOperation.create)}
                    </Button>
                );
            }
            break;
        // 完了、解約、削除ステータスならワークスペース詳細を見ることしかできない
        default:
            if(container.entryDetail.workspace) {
                // ワークスペースが論理削除されていなければ活性化される
                if(!container.entryDetail.workspace.deleteFlag){
                    RenderButton = (
                        <Link to={`/sub-workspace/detail/${container.entryDetail.workspace.displayId}?entrydetail=${container.entryDetail.id}`}>
                            <Button className={classes.button} variant="outlined" size="large" color="secondary">
                                {locale.t(locale.keys.applicationList.applicationDetails.workspaceOperation.detail)}
                            </Button>
                        </Link>
                    );
                    break;
                }
            }
            RenderButton = (
                <Button className={classes.button} disabled={true} variant="outlined" size="large" color="secondary">
                    {locale.t(locale.keys.applicationList.applicationDetails.workspaceOperation.detail)}
                </Button>
            );
    }
    return <Paper>{RenderButton}</Paper>;
});

interface EntryPullDownProps extends WithStyles<typeof menuStyles> {}
const EntryPullDown = withStyles(menuStyles, { withTheme: true })((props: EntryPullDownProps) => {
    const [cancelPullDown, setCancelPullDown] = useState(false);
    const [deletePullDown, setDeletePullDown] = useState(false);
    const [cancelEntry, setCancelEntry] = useState('');
    const [deleteEntry, setDeleteEntry] = useState('');
    const { classes } = props;
    const container = EntryDetailContainer.useContainer();

    useEffect(() => {
        if(container.ui.current === UI.Loading) {
            setCancelEntry('')
            setDeleteEntry('')
            setCancelPullDown(false);
            setDeletePullDown(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.ui]);

    const handleCancelClick = () => {
        setCancelPullDown(!cancelPullDown);
    };

    const handleDeleteClick = () => {
        setDeletePullDown(!deletePullDown);
    };

    const handleCancel = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setCancelEntry(event.target.value);
    };

    const handleDelete = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setDeleteEntry(event.target.value);
    };

    return (
        <Paper>
            <List>
                {/* サイドメニューの上のプルダウン
                　　作成中、申込中、削除、ローディング中はdisabled */}
                {container.ui.current === UI.Loading ||
                container.entryDetail.entryState === schema.V1ObjectsEntryState.Create ||
                container.entryDetail.entryState === schema.V1ObjectsEntryState.Entry ||
                container.entryDetail.entryState === schema.V1ObjectsEntryState.Delete ? (
                    <ListItem disabled button onClick={handleCancelClick}>
                        <ListItemText primary={locale.t(locale.keys.applicationList.applicationDetails.applicationCancel.index)} />
                        {cancelPullDown ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                ) : container.entryDetail.entryState === schema.V1ObjectsEntryState.Complete ? (
                    // 完了のときは解約プルダウンの表示
                    <ListItem button onClick={handleCancelClick}>
                        <ListItemText primary={locale.t(locale.keys.applicationList.applicationDetails.applicationCancel.index)} />
                        {cancelPullDown ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                ) : (
                    // 解約のときは解約取消プルダウンの表示
                    <ListItem button onClick={handleCancelClick}>
                        <ListItemText primary={locale.t(locale.keys.applicationList.applicationDetails.applicationCancel.cancel)} />
                        {cancelPullDown ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                )}
                {/* プルダウンが開いたときのUI */}
                <Collapse in={cancelPullDown} timeout="auto" unmountOnExit>
                    <Grid>
                        <FormControl className={classes.text}>
                            {container.entryDetail.entryState === schema.V1ObjectsEntryState.Cancel ? (
                                <Typography className={classes.caption} align="left" inline variant="caption" color="error">
                                    {locale.t(locale.keys.applicationList.applicationDetails.applicationCancel.attention2)}
                                </Typography>
                            ) : (
                                <Typography className={classes.caption} align="left" inline variant="caption" color="error">
                                    {locale.t(locale.keys.applicationList.applicationDetails.applicationCancel.attention1)}
                                </Typography>
                            )}
                        </FormControl>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.text}
                                label={locale.t(locale.keys.applicationList.applicationDetails.placeholder)}
                                placeholder=""
                                margin="normal"
                                variant="filled"
                                value={cancelEntry}
                                inputProps={{ style: { height: '100%' } }}
                                onChange={handleCancel}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.buttonGrid}>
                            {container.entryDetail.entryState === schema.V1ObjectsEntryState.Cancel ? (
                                <Fab variant="extended" className={classes.removeButton} size="small" color="secondary" disabled={cancelEntry !== container.entryDetail.entryNumber } onClick={() => container.onClieckWithdrawCancel()}>
                                    <span className={classes.buttonText}>{locale.t(locale.keys.applicationList.applicationDetails.applicationCancel.button2)}</span>
                                </Fab>
                            ) : (
                                <Fab variant="extended" className={classes.removeButton} size="small" color="secondary" disabled={cancelEntry !== container.entryDetail.entryNumber} onClick={() => container.onClickCancelSave()}>
                                    <span className={classes.buttonText}>{locale.t(locale.keys.applicationList.applicationDetails.applicationCancel.button1)}</span>
                                </Fab>
                            )}
                        </Grid>
                    </Grid>
                </Collapse>
                <Divider className={classes.dividerMargin} />
                {/* サイドメニューの下のプルダウン
                　　完了、削除、ローディング中はdisabled */}
                {container.ui.current === UI.Loading ||
                container.entryDetail.entryState === schema.V1ObjectsEntryState.Delete ||
                container.entryDetail.entryState === schema.V1ObjectsEntryState.Complete ? (
                    <ListItem disabled button onClick={handleDeleteClick}>
                        <ListItemText primary={locale.t(locale.keys.applicationList.applicationDetails.applicationDelete.index)} />
                        {deletePullDown ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                ) : (
                    // 作成中、申込中、解約時は削除できる。
                    <ListItem button onClick={handleDeleteClick}>
                        <ListItemText primary={locale.t(locale.keys.applicationList.applicationDetails.applicationDelete.index)} />
                        {deletePullDown ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                )}
                <Collapse in={deletePullDown} timeout="auto" unmountOnExit>
                    <Grid>
                        <FormControl className={classes.text}>
                            <Typography className={classes.caption} align="left" inline variant="caption" color="error">
                                {locale.t(locale.keys.applicationList.applicationDetails.applicationDelete.attention)}
                            </Typography>
                        </FormControl>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.text}
                                label={locale.t(locale.keys.applicationList.applicationDetails.placeholder)}
                                placeholder=""
                                margin="normal"
                                variant="filled"
                                value={deleteEntry}
                                inputProps={{ style: { height: '100%' } }}
                                onChange={handleDelete}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.buttonGrid}>
                            <Fab variant="extended" className={classes.removeButton} size="small" color="secondary" disabled={deleteEntry !== container.entryDetail.entryNumber} onClick={() => container.onClickDeleteSave()}>
                                <span className={classes.buttonText}>{locale.t(locale.keys.applicationList.applicationDetails.applicationDelete.button)}</span>
                            </Fab>
                        </Grid>
                    </Grid>
                </Collapse>
            </List>
        </Paper>
    );
});

export const Menu = withStyles(menuStyles, { withTheme: true })(() => {
    return (
        <Grid container direction="column" spacing={16}>
            <Grid item>
                <WorkSpaceButton />
            </Grid>
            <Grid item>
                <EntryPullDown />
            </Grid>
        </Grid>
    );
});
