import { default as React, useEffect } from 'react';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { NoticeContainer } from './NoticeContainer';
import locale from '@/common/utils/locale';
import { showStringWithLimitByte } from '@/common/utils/webappUtil';
import { withStyles, WithStyles, Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination } from '@material-ui/core';
import { Edit, InfoOutlined } from '@material-ui/icons';
import { styles } from './css/styles';
import { DateFormat } from '@/common/constants/dateFormat';
import * as schema from '@/schema/typescript/schema';
import dayjs from 'dayjs';

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const noticeContainer = NoticeContainer.useContainer();
    const { classes } = props;

    useEffect(() => {
        const fetchNoticeList = async () => {
            appContainer.updateLoadingState(UI.Loading);
            const auth = appContainer.values.authorizationCode;
            if (auth) {
                const response = await noticeContainer.getNoticeList(auth, 0, 5);
                noticeContainer.setNoticeList(response);
            }
            appContainer.updateLoadingState(UI.Loaded);
        };
        if (noticeContainer.isSend) {
            fetchNoticeList();
            noticeContainer.setIsSend(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noticeContainer.isSend]);

    return (
        <Grid container item xs={12} style={{ width: '100%' }} justify="space-around" alignItems="center" spacing={24}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{locale.t(locale.keys.pageTitle.announcement.header.senddate)}</TableCell>
                        <TableCell>
                            <Grid container direction="row">
                                <Typography>{locale.t(locale.keys.pageTitle.announcement.header.status.index)}</Typography>
                                <Tooltip title={locale.t(locale.keys.pageTitle.announcement.header.status.description)} disableFocusListener disableTouchListener className={classes.column5}>
                                    <InfoOutlined />
                                </Tooltip>
                            </Grid>
                        </TableCell>
                        <TableCell>{locale.t(locale.keys.pageTitle.announcement.header.done)}</TableCell>
                        <TableCell>{locale.t(locale.keys.pageTitle.announcement.header.title)}</TableCell>
                        <TableCell>{locale.t(locale.keys.pageTitle.announcement.header.preview)}</TableCell>
                        <TableCell>{locale.t(locale.keys.pageTitle.announcement.header.edit)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {noticeContainer.noticeList &&
                        noticeContainer.noticeList.notices.map((row) => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell>{row.createdAt ? dayjs(new Date(row.createdAt)).format(DateFormat.fullDateTimeWithDot) : ''}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>
                                        {row.complete}/{row.totalCount}
                                    </TableCell>
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell>{showStringWithLimitByte(row.text || '', 20)}</TableCell>
                                    <TableCell className={classes.column5}>
                                        <IconButton aria-label="Edit" onClick={() => noticeContainer.setSendInfo({ destination: schema.V1ObjectsNoticeDestination.Resend, mailtitle: row.title, mailtext: row.text, noticeId: row.id })}>
                                            <Edit className={classes.pencil} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={noticeContainer.noticeList ? noticeContainer.noticeList.notices.length : 0}
                labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                labelDisplayedRows={(paginationInfo: any) => (
                    <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                )}
                rowsPerPage={noticeContainer.paging.limit}
                page={noticeContainer.paging.page}
                backIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.previousPage),
                }}
                nextIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.nextPage),
                }}
                onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => {}}
                onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {}}
            />
        </Grid>
    );
};

export default withStyles(styles)(Component);
