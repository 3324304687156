import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import * as deviceModel from '@/common/api/workspace/mfp/mfp';
import { Variants } from '@/common/components/messages/CommonMessage';
import { default as UI } from '@/common/constants/ui';
import { AdminAppContainer } from '../../AdminAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import usePreventWindowUnload from '@/common/components/hooks/usePreventWindowUnload';
import { useDevice, Member, searchDeviceMemberSuggest, UpdateDeviceForm } from '@/common/models/device/useDevice';

/**
 * @description 利用可能メンバーにEveryoneが入力されている場合、Everyoneチェックボックスを無効にする。
 */
export const disabledEveryoneCheck = (allowdMember: schema.V1WorkspaceMfpsUsersIndexResponse) => {
    const everyone = allowdMember.groups.find((item) => item.groupName === schema.V1ObjectsDefaultGroup.Everyone);
    if (everyone) {
        return true;
    }
    return false;
};

/**
 *
 * @param check チェックボックスの状態
 * @param form 更新用に入力したフォーム
 * @param wsGroupData 登録可能グループか判断するための元データ
 * @returns Everyoneチェックボックスの状態を更新したフォーム
 */
export const updateEveryoneCheck = (check: boolean, form: UpdateDeviceForm, wsGroupData: schema.V1WorkspaceGroupsIndexResponse): UpdateDeviceForm => {
    const everyone = wsGroupData.groups.find((item) => item.groupName === schema.V1ObjectsDefaultGroup.Everyone);
    // Everyoneグループが見つからない場合はチェックボックスを動作させない（Everyoneは全てのグループに存在するのであり得ない。）。
    if (everyone === undefined) return form;

    // フォームにEveryoneが入力済みかどうかを確認するためのフラグ
    const hasEveryone = form.allowedMember.findIndex((item) => item.isGroup && item.id === everyone.id);

    // Everyoneチェックボックスがチェックされた場合と、チェックが外された場合で処理を分ける。
    if (check) {
        // Everyoneがフォームに入力済みでない場合に、追加してチェックを入れる。
        if (hasEveryone === -1) {
            return {
                allowedMember: [
                    ...form.allowedMember,
                    {
                        id: everyone.id!, // id, groupNameは必ず存在する。
                        name: everyone.groupName!,
                        isGroup: true,
                    },
                ],
            };
        }
    } else {
        // Everyoneがフォームに入力済みの場合に、削除してチェックを外す。
        if (hasEveryone !== -1) {
            const newAllowedMember = form.allowedMember.filter((item) => item.id !== everyone.id);
            return {
                allowedMember: newAllowedMember,
            };
        }
    }

    // チェックボックスの状態に応じた処理を行う必要がなかった場合はそのまま返す。
    return form;
};

/**
 * @param form 更新用に入力したフォーム
 * @param wsGroupData 登録可能グループか判断するための元データ
 * @returns Everyoneチェックボックスの状態
 */
export const everyoneChecked = (form: UpdateDeviceForm, wsGroupData: schema.V1WorkspaceGroupsIndexResponse): boolean => {
    const everyone = wsGroupData.groups.find((item) => item.groupName === schema.V1ObjectsDefaultGroup.Everyone);
    // Everyoneグループが見つからない場合はチェックボックスを動作させない（Everyoneは全てのグループに存在するのであり得ない。）。
    if (everyone === undefined) return false;

    // Everyoneがフォームに入力済みかどうかを確認するためのフラグ
    const hasEveryone = form.allowedMember.findIndex((item) => item.isGroup && item.id === everyone.id);

    return hasEveryone !== -1;
};

/**
 * @description 利用可能ユーザー、グループ登録フォームの登録ボタンのdisable判定
 */
export const disableRegisterDeviceMemberButton = (inputMember: Member[]) => {
    if (inputMember.length === 0) {
        return true;
    }
    return false;
};

/**
 * @params inputText フォームに入力されたテキスト
 * @params inputMember フォームに入力されたメンバーのリスト
 * @params wsUserData ワークスペースに登録されているユーザーのリスト
 * @params wsGroupData ワークスペースに登録されているグループのリスト
 * @params ignoreAllowedMember 利用可能ユーザー、グループのリスト
 * @description 利用可能ユーザー、グループ登録フォームに入力して、Enterを押下ときのフォームの更新
 */
export const addMember = (
    inputText: string,
    inputMember: Member[],
    wsUserData: schema.V1WorkspaceuserIndexResponse | null,
    wsGroupData: schema.V1WorkspaceGroupsIndexResponse | null,
    ignoreAllowedMember?: schema.V1WorkspaceMfpsUsersIndexResponse,
): Member[] => {
    // カンマが含まれていなければ、入力されたテキストをそのまま検証して登録する
    if (!inputText.includes(',')) {
        // 1. 入力済みのテキストと重複して入力した場合は空配列を返却
        if (inputMember.find((member) => member.name === inputText) && inputMember.find((member) => member.email === inputText)) {
            return [];
        }

        // 2. 入力されたテキストに一致するユーザーまたはグループを追加
        const member = addMemberfromText(inputText, inputMember, wsUserData, wsGroupData, ignoreAllowedMember, true);
        if (member) {
            return [member];
        }

        // 3. 1が無ければ、サジェスト最上部のユーザーまたはグループを追加
        const suggestedList = getSuggestions(inputText, inputMember, wsUserData, wsGroupData, ignoreAllowedMember);
        const suggestedMember = addMemberFromSuggest(inputText, suggestedList);
        if (suggestedMember) {
            return [suggestedMember];
        }

        // 4. 2が無ければ、エラーを表示する(実際にはエラーを表示させるためのダミーのオブジェクトを返却する)
        return [{ email: '', id: '', name: '', avatarUrl: '', isGroup: false }];
        // カンマ区切りでテキストが入力されていた場合、それぞれのメールアドレスまたはグループ名を登録する
    } else {
        let inputList: string[] = [];
        // カンマ区切りで入力されたテキストを配列に変換する
        inputList = inputText.split(',').map((input) => input.trim());

        let updateMemberList = [] as Member[];
        for (const i in inputList) {
            const result = addMemberfromText(inputList[i].trim(), [...inputMember, ...updateMemberList], wsUserData, wsGroupData, ignoreAllowedMember, false);
            if (result) {
                updateMemberList.push(result);
            }
        }

        if (updateMemberList.length === 0) {
            return [{ email: '', id: '', name: '', avatarUrl: '', isGroup: false }];
        }

        return updateMemberList;
    }
};

/**
 * @description サジェストで表示するユーザー/グループを取得する
 */
export const getSuggestions = (
    inputText: string,
    inputMember: Member[],
    wsUserData: schema.V1WorkspaceuserIndexResponse | null,
    wsGroupData: schema.V1WorkspaceGroupsIndexResponse | null,
    ignoreAllowedMember?: schema.V1WorkspaceMfpsUsersIndexResponse,
): { groups: Member[]; users: Member[] } => {
    if (wsGroupData === null || wsUserData === null) return { groups: [], users: [] };
    const suggestedData = searchDeviceMemberSuggest(inputText, wsGroupData, wsUserData, inputMember, 3, 5, ignoreAllowedMember);
    return {
        groups: suggestedData.suggestedGroups,
        users: suggestedData.suggestedUsers,
    };
};

/**
 * @description フォームに入力されたテキストに一致するユーザーまたはグループを追加する。ユーザーおよびグループ両方で一致する場合は、ユーザーを優先する。
 */
const addMemberfromText = (
    inputText: string,
    inputMember: Member[],
    wsUserData: schema.V1WorkspaceuserIndexResponse | null,
    wsGroupData: schema.V1WorkspaceGroupsIndexResponse | null,
    ignoreAllowedMember?: schema.V1WorkspaceMfpsUsersIndexResponse,
    skipDuplicateRegisteredMember?: boolean,
): Member | null => {
    // 入力済みのフォームに含まれていないか確認用の変数
    const inputUser = inputMember.filter((member) => !member.isGroup);
    const inputGroup = inputMember.filter((member) => member.isGroup);
    const allowedUser = ignoreAllowedMember ? ignoreAllowedMember.users : [];
    const allowedGroup = ignoreAllowedMember ? ignoreAllowedMember.groups : [];

    const foundedUser = wsUserData
        ? wsUserData.users.find((user) => {
              // 入力済みのフォームに含まれていないか確認する
              if (inputUser.find((member) => member.id === user.id)) {
                  return false;
              }
              // 利用可能ユーザーに含まれていないか確認する
              // Covasに登録可能なEメールアドレスは一意であるので、Eメールアドレスで重複チェックを行う。
              if (!skipDuplicateRegisteredMember && allowedUser.find((member) => member.email === user.invitationEmail)) {
                  return false;
              }

              if (user.invitationVerified && user.invitationEmail === inputText) {
                  return true;
              } else {
                  return false;
              }
          })
        : null;
    if (foundedUser !== null && foundedUser !== undefined) {
        return { email: foundedUser.invitationEmail, id: foundedUser.id, name: foundedUser.name, avatarUrl: foundedUser.avatarUrl, isGroup: false };
    }
    const foundedGroup = wsGroupData
        ? wsGroupData.groups.find((group) => {
              // 入力済みのフォームに含まれていないか確認する
              if (inputGroup.find((member) => member.id === group.id)) {
                  return false;
              }
              // 利用可能グループに含まれていないか確認する
              if (!skipDuplicateRegisteredMember && allowedGroup.find((member) => member.id === group.id)) {
                  return false;
              }

              if (group.groupName === inputText) {
                  return true;
              } else {
                  return false;
              }
          })
        : null;
    if (foundedGroup !== null && foundedGroup !== undefined) {
        return { email: '', id: foundedGroup.id, name: foundedGroup.groupName, avatarUrl: foundedGroup.avatarUrl, isGroup: true };
    }

    // 登録可能なグループ、ユーザーが存在しない場合は、ダミーのオブジェクトを返却してエラーを表示する
    return null;
};

/**
 * @description 入力したテキストに対して、サジェストされている最上部のユーザーまたはグループを追加する。サジェストはグループ > ユーザーの順番で表示されているので、グループを優先する。
 */
const addMemberFromSuggest = (inputText: string, suggestedList: { groups: Member[]; users: Member[] }): Member | null => {
    const foundedGroup = suggestedList.groups.find((group) => group.name.toLowerCase().includes(inputText.toLowerCase()));
    if (foundedGroup !== null && foundedGroup !== undefined) {
        return { email: '', id: foundedGroup.id, name: foundedGroup.name, avatarUrl: foundedGroup.avatarUrl, isGroup: true };
    }

    const foundedUser = suggestedList.users.find((user) => {
        if (user.email) {
            return user.email.toLowerCase().includes(inputText.toLowerCase());
        } else {
            return false;
        }
    });
    if (foundedUser !== null && foundedUser !== undefined) {
        return { email: foundedUser.email, id: foundedUser.id, name: foundedUser.name, avatarUrl: foundedUser.avatarUrl, isGroup: false };
    }

    // 登録可能なグループ、ユーザーが存在しない場合は、ダミーのオブジェクトを返却してエラーを表示する
    return null;
};

// unstaged-nextの仕様上、引数はinitialStateという名前でしか渡せない。
// 本来はdeviceIdのような命名にしたい。
const useDetailDeviceContainer = (initialState: string = '') => {
    const [ui, setUI] = useState(UI.state.Loading);
    const [workspaceMfpId, setWorkspaceMfpId] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [mfpNumber, setMfpNumber] = useState('');
    const [mfpName, setMfpName] = useState('');
    const [registrationPin, setRegistrationPin] = useState('');
    const [status, setStatus] = useState<schema.V1ObjectsWorkspacemfpStatus>(schema.V1ObjectsWorkspacemfpStatus.Active);
    const [isRegistered, setIsRegistered] = useState(false);
    const [omitPin, setOmitPin] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const appContainer = AdminAppContainer.useContainer();
    const [isDisable, setIsDisable] = useState(false);
    const [errors, setErrors] = useState({
        mfpNumber: '',
    });
    const [isEdit, setIsEdit] = useState(false);
    const device = useDevice();

    usePreventWindowUnload(isEdit);

    // 複合機詳細画面で使用するデータの取得、初期化
    useEffect(() => {
        (async () => {
            try {
                setUI(UI.state.Loading);
                // getDeviceInfoもdevice.initにまとめたい
                await getDeviceInfo(initialState);
                setUI(UI.state.Loading);

                await device.init(appContainer.values.signinWorkspaceObject.id || '', initialState, appContainer.values.authorizationCode);
                setUI(UI.state.Loaded);
            } catch (e) {
                setUI(UI.state.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        appContainer.updateLoadingState(ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    /**
     * @description 複合機情報の取得（利用可能メンバー以外）
     */
    const getDeviceInfo = async (workspaceMfpId: string) => {
        try {
            setUI(UI.state.Loading);
            const dataResponse = await deviceModel.getWorkspaceMfpInfo(appContainer.values.signinWorkspaceObject.id || '', workspaceMfpId, appContainer.values.authorizationCode);

            setDeviceName(dataResponse.deviceName);
            setMfpNumber(dataResponse.mfpNumber);
            setMfpName(dataResponse.mfpName);
            setOmitPin(dataResponse.omitPin);
            setRegistrationPin(dataResponse.registrationPin);
            setStatus(dataResponse.status);
            setIsRegistered(dataResponse.isRegistered);

            setUI(UI.state.Loaded);
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleChangeDeviceName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceName(event.target.value);
        setIsEdit(true);
    };

    const handleChangeMfpNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMfpNumber(event.target.value.toUpperCase());
        setIsEdit(true);
        if (event.target.value.match(/(^[A-Za-z0-9]{4}-[0-9]{6}$)/g)) {
            setIsDisable(false);
            setErrors({
                mfpNumber: '',
            });
        } else {
            setIsDisable(true);
            setErrors({
                mfpNumber: locale.t(locale.keys.validation.mfpNo),
            });
        }
    };

    const handleChangeOmitPin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOmitPin(event.target.checked);
        setIsEdit(true);
    };

    const buttonDisabled = () => {
        if (!deviceName || isDisable) return true;
        return false;
    };

    /**
     * @description フォームでのEnterキー押下時、複合機情報の更新（利用可能メンバー以外）
     */
    const handlePressEnterUpdate = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        try {
            if (!(event.key === 'Enter' && deviceName && mfpNumber && !isDisable)) {
                return;
            }
            setIsEdit(false);
            setUI(UI.state.Loading);
            const dataResponse = await deviceModel.updateWorkspaceMfp(
                appContainer.values.signinWorkspaceObject.id || '',
                workspaceMfpId,
                { deviceName, mfpNumber, omitPin },
                appContainer.values.authorizationCode,
            );
            if (!dataResponse) {
                throw new Error('force to fail');
            } else {
                setRegistrationPin(dataResponse.registrationPin);
                setStatus(dataResponse.status);
                setIsRegistered(dataResponse.isRegistered);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.updated),
                    variant: Variants.success,
                });
            }

            setUI(UI.state.Loaded);
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @description 変更を保存ボタン押下時、複合機情報の更新（利用可能メンバー以外）
     */
    const handleClickUpdateDevice = async () => {
        setIsEdit(false);
        try {
            const dataResponse = await deviceModel.updateWorkspaceMfp(
                appContainer.values.signinWorkspaceObject.id || '',
                workspaceMfpId,
                { deviceName, mfpNumber, omitPin },
                appContainer.values.authorizationCode,
            );
            if (!dataResponse) {
                throw new Error('force to fail');
            } else {
                setRegistrationPin(dataResponse.registrationPin);
                setStatus(dataResponse.status);
                setIsRegistered(dataResponse.isRegistered);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.updated),
                    variant: Variants.success,
                });
            }
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @description 複合機利用を一時停止
     */
    const handleClickSuspendDevice = async () => {
        try {
            setUI(UI.state.Loading);
            let newStatus = status;
            if (status === schema.V1ObjectsWorkspacemfpStatus.Active) {
                newStatus = schema.V1ObjectsWorkspacemfpStatus.Inactive;
                setStatus(newStatus);
            } else {
                newStatus = schema.V1ObjectsWorkspacemfpStatus.Active;
                setStatus(newStatus);
            }
            const dataResponse = await deviceModel.updateStatusWorkspaceMfp(
                appContainer.values.signinWorkspaceObject.id || '',
                workspaceMfpId,
                { status: newStatus },
                appContainer.values.authorizationCode,
            );
            if (!dataResponse) {
                throw new Error('force to fail');
            } else {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.updated),
                    variant: Variants.success,
                });
            }

            setUI(UI.state.Loaded);
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const confirmRemoveDevice = (isYes: boolean, value?: string) => {
        if (!isYes) {
            return;
        }
        if (typeof value === 'string') {
            handleClickRemoveDevice();
        }
    };

    /**
     * @description 複合機の削除
     */
    const handleClickRemoveDevice = async () => {
        try {
            setUI(UI.state.Loading);
            const dataResponse = await deviceModel.destroyWorkspaceMfp(appContainer.values.signinWorkspaceObject.id || '', workspaceMfpId, appContainer.values.authorizationCode);
            if (dataResponse) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.deleted),
                    variant: Variants.success,
                });
                setDeviceName('');
                setMfpNumber('');
                setIsDeleted(true);
            }
            setUI(UI.state.Loaded);
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const confirmRemoveMember = (isYes: boolean, value?: string) => {
        if (!isYes) {
            return;
        }
        // valueがundefinedになることはない
        handleClickRemoveMember(value!, '');
    };

    const confirmRemoveGroup = (isYes: boolean, value?: string) => {
        if (!isYes) {
            return;
        }
        // valueがundefinedになることはない
        handleClickRemoveMember('', value!);
    };

    /**
     * @description 利用可能メンバーの削除
     */
    const handleClickRemoveMember = async (mfpUserId: string, groupId: string) => {
        try {
            setUI(UI.state.Loading);
            await device.deleteDeviceMember(appContainer.values.signinWorkspaceObject.id || '', workspaceMfpId, mfpUserId, groupId, appContainer.values.authorizationCode);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.deleted),
                variant: Variants.success,
            });
            setUI(UI.state.Loaded);
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @description 利用可能メンバーの登録
     */
    const handleClickRegisterMember = async () => {
        try {
            setUI(UI.state.Loading);
            await device.updateDeviceMember(appContainer.values.signinWorkspaceObject.id || '', workspaceMfpId, appContainer.values.authorizationCode);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.resistered),
                variant: Variants.success,
            });
            setUI(UI.state.Loaded);
        } catch (e) {
            setUI(UI.state.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @param event チェックボックスの状態
     * @description Everyoneチェックボックスの状態に応じてフォームを更新する
     */
    const handleEveryoneCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const check = event.target.checked;
        if (device.suggestData.wsGroupData === null) return; // データのフェッチが完了していない場合はチェックボックスを動作させない。
        const newForm = updateEveryoneCheck(check, device.form, device.suggestData.wsGroupData);
        device.handleUpdateDeviceForm(newForm);
    };

    /**
     * @returns Everyoneチェックボックスの状態
     * @description Everyoneチェックボックスの状態を返す
     */
    const isEveryoneChecked = () => {
        if (device.suggestData.wsGroupData === null) return false; // データのフェッチが完了していない場合はチェックが入っているか不明なので外した状態で返す。
        return everyoneChecked(device.form, device.suggestData.wsGroupData);
    };

    return {
        ui,
        setUI,
        workspaceMfpId,
        setWorkspaceMfpId,
        deviceName,
        handleChangeDeviceName,
        mfpNumber,
        handleChangeMfpNumber,
        omitPin,
        handleChangeOmitPin,
        mfpName,
        registrationPin,
        status,
        isRegistered,
        buttonDisabled,
        handleClickUpdateDevice,
        handleClickSuspendDevice,
        handlePressEnterUpdate,
        handleClickRegisterMember,
        isDeleted,
        setIsDeleted,
        confirmRemoveDevice,
        confirmRemoveMember,
        confirmRemoveGroup,
        isDisable,
        setIsDisable,
        errors,
        isEdit,
        setIsEdit,
        device,
        handleEveryoneCheck,
        isEveryoneChecked,
    };
};
export const DetailDeviceContainer = createContainer(useDetailDeviceContainer);
