import React, { FC, useState, useEffect } from 'react';
import { WithStyles, withStyles, Grid, Table, TableHead, TableCell, TableBody, TableRow, TablePagination, Checkbox, Typography, TextField, IconButton, Theme, createStyles } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { UnfoldMore, Search } from '@material-ui/icons';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import CovasAvatar from '@/common/components/CovasAvatar';
import { styles as baseTableStyles } from '@/common/components/Table/style';
import { AnalyticsContainer } from '../AnalyticsContainer';
import { getMemberList } from '@/common/models/member/useMember';

const styles = (_: Theme) =>
    createStyles({
        ...baseTableStyles(),
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const ToTable: FC<Props> = (props) => {
    const { classes } = props;
    const container = AnalyticsContainer.useContainer();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [memberList, setMemberList] = useState({
        ...getMemberList(
            container.member.memberList.rows,
            container.member.form.searchText,
            rowsPerPage,
            page * rowsPerPage,
            container.member.form.sortByName,
            container.member.form.selectedMemberIdList,
        ),
    });

    useEffect(() => {
        const list = getMemberList(
            container.member.memberList.rows,
            container.member.form.searchText,
            rowsPerPage,
            page * rowsPerPage,
            container.member.form.sortByName,
            container.member.form.selectedMemberIdList,
        );
        setMemberList({
            ...list,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.member.memberList, container.member.form, page, rowsPerPage]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const getEmptyRowCount = () => {
        return rowsPerPage - Math.min(rowsPerPage, memberList.rows.length);
    };

    return (
        <>
            <Typography style={{ fontSize: 13 }}>{locale.t(locale.keys.analytics.usageReport.table.desc)}</Typography>

            <TextField
                placeholder={locale.t(locale.keys.analytics.usageReport.table.search)}
                variant="outlined"
                margin={'dense'}
                fullWidth
                style={{ marginBlock: '4px' }}
                InputLabelProps={{ style: { fontSize: 14, transform: 'translate(14px, 10px) scale(1)' } }}
                InputProps={{ style: { fontSize: 14, height: 36 } }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    container.member.setForm({ ...container.member.form, searchText: e.target.value });
                }}
            />
            <Typography style={{ fontSize: 13 }}>
                {locale.t(locale.keys.analytics.usageReport.table.selected, { n: container.member.form.selectedMemberIdList.length === 0 ? '0' : container.member.form.selectedMemberIdList.length })}
            </Typography>
            <Grid style={{ width: '100%' }}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.headerFont}>
                            <TableCell className={classes.tableCell} style={{ width: 40 }} align="center">
                                <Checkbox
                                    checked={container.member.isSelectAllMember()}
                                    onChange={() => {
                                        container.handleSelectMember(true);
                                    }}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{ width: 60 }} />
                            <TableCell className={classes.tableCell}>
                                <Grid className={classes.gridInsideHeaderCell}>
                                    <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.analytics.usageReport.table.cell.name)}</p>
                                    <Search style={{ marginBlock: 'auto' }} />
                                    <IconButton onClick={() => container.member.changeSortByName(container.member.form.sortByName)}>
                                        <UnfoldMore />
                                    </IconButton>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberList.rows.map((row, index) => (
                            <TableRow className={index % 2 ? classes.evenRow : classes.oddRow}>
                                <TableCell className={classes.tableCell} style={{ width: 40 }} align="center">
                                    <Checkbox checked={row.isChecked} onChange={() => container.handleSelectMember(false, row.objId)} />
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{ width: 60 }} align="center">
                                    <CovasAvatar size={40} seed={row.email} src={row.avatarUrl} onClick={() => {}} color="disabled" />
                                </TableCell>
                                <TableCell className={classes.tableCell} align="left">
                                    <Grid container direction="column">
                                        <Grid item style={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {row.name}
                                        </Grid>
                                        <Grid item style={{ color: 'gray' }}>
                                            {row.email}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                        {getEmptyRowCount() > 0 && (
                            <TableRow style={{ height: 49 * getEmptyRowCount() }}>
                                <TableCell colSpan={100} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={memberList.memberTotal}
                    labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                    labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                        <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                    )}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.previousPage),
                    }}
                    nextIconButtonProps={{
                        'aria-label': locale.t(locale.keys.table.nextPage),
                    }}
                    onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                    onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
                />
            </Grid>
        </>
    );
};

export default withStyles(styles)(ToTable);
