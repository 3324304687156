import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Theme, createStyles, withStyles, WithStyles, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import routes from '@/admin/constants/routes';
import * as locale from '@/common/utils/locale/locale';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import userRole from '@/common/constants/userRole';
import * as auth from '@/common/utils/authManagement/authUtil';
import environment from '@/common/constants/environment';
import * as schema from '@/bundles/schema/typescript/schema';
import HeaderMenuListItems from '@/common/components/HeaderMenuListItems';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { useGlobalEntrySearch } from '@/admin/hooks/useGlobalEntrySearch';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case 'ricoh':
            return { primary: '#FFF2F2', secondary: '#FFE6E6' };
        default:
            return { primary: '#e3eaed', secondary: '#d1dde3' };
    }
};

const colors = selectColor(environment.tenant);

const styles = (theme: Theme) =>
    createStyles({
        root: {
            '&& div': {
                backgroundColor: 'transparent',
            },
        },
        list: {
            // このaタグのcolorを黒くするの全体で指定していいかも
            '&& a': {
                color: theme.palette.text.primary,
            },
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            '&& li': {
                height: '44px',
                paddingLeft: '13px',
                paddingRight: '13px',
            },
            '&& +hr': {
                marginBottom: '5px',
                marginTop: '5px',
            },
            borderRight: '1px solid',
            borderColor: '#bdbdbd',
            overflowX: 'auto',
            [theme.breakpoints.down('sm')]: {
                minHeight: '50vh',
                maxHeight: '50vh',
            },
            [theme.breakpoints.up('md')]: {
                minHeight: '95vh',
            },
            backgroundColor: colors.primary,
        },
        paper: {
            borderRadius: 5,
            position: 'fixed',
            top: 60,
            right: theme.spacing.unit,
            width: '80%',
            minWidth: 250,
            maxWidth: 250,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: 'none',
            transition: '0.2s',
            minHeight: '95vh',
        },
        collapse: {
            backgroundColor: colors.primary,
        },
        nested: {},
        navItem: {
            fontSize: '16px',
            // lineHeight: 10,
            color: '#333333',
            borderRadius: '4px',
        },
        context: {
            fontSize: '12px',
            letterSpacing: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '3.5',
            height: '22px',
            '& > span': {
                fontSize: '12px',
                color: '#666666',
                height: '22px',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '3.5',
            },
        },
        subcontext: {
            letterSpacing: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            '&& li': {
                marginTop: '13px',
                height: '12px',
            },
            '&& span': {
                fontSize: '12px',
                lineHeight: 'normal',
                color: '#666666',
            },
        },
    });

const CustomListItem = withStyles({
    root: {
        '&$selected': {
            backgroundColor: colors.secondary,
        },
    },
    selected: {},
})(ListItem);

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    isAdminUser: boolean;
    theme: Theme;
    commonMenuOpen: boolean;
    commonMenuCloseFunc: () => void;
}

const isSelected = (refs: string) => {
    return refs === window.location.pathname;
};

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const matchesBreakPoint = useMediaQuery(props.theme.breakpoints.up('md'));
    const globalEntrySearchInit = useGlobalEntrySearch().actions.init;

    const handleWebappLinkClick = () => {
        auth.setShareAuth({
            token: appContainer.values.authorizationCode,
            workspaceUserId: appContainer.values.signinWorkspaceUserObject.id!,
        });
    };
    const handClose = () => {
        props.commonMenuCloseFunc();
    };
    return (
        <>
            <List component="nav" className={classes.list}>
                <CustomListItem onClick={handClose} className={classes.navItem} selected={isSelected(routes.dashboard.index)} style={{ paddingTop: '25px' }}>
                    <Link to={routes.dashboard.index}>{locale.t(locale.keys.pageTitle.dashboard.adminIndex)}</Link>
                </CustomListItem>
                {/* システム管理者にだけアナウンス機能を表示 */}
                {appContainer.values.signinWorkspaceUserObject.role === userRole.systemAdmin ? (
                    <CustomListItem onClick={handClose} className={classes.navItem} selected={isSelected(routes.announcement.index)} style={{ paddingTop: '25px' }}>
                        <Link to={routes.announcement.index}>{locale.t(locale.keys.pageTitle.announcement.index)}</Link>
                    </CustomListItem>
                ) : null}
                <CustomListItem onClick={handClose} className={classes.navItem} selected={isSelected(routes.analytics.index)} style={{ paddingTop: '25px' }}>
                    <Link to={routes.analytics.index}>{locale.t(locale.keys.analytics.index)}</Link>
                </CustomListItem>
                {appContainer.values.signinWorkspaceObject.canViewEntryList && (
                    <>
                        <ListItem>
                            <ListItemText className={classes.context}>{locale.t(locale.keys.pageTitle.admin.menuEntry)}</ListItemText>
                        </ListItem>
                        <div className={classes.navItem}>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.entry.index)}>
                                <Link to={routes.entry.index} onClick={globalEntrySearchInit}>
                                    {locale.t(locale.keys.pageTitle.admin.entryList)}
                                </Link>
                            </CustomListItem>
                        </div>
                    </>
                )}
                {appContainer.values.signinWorkspaceObject.enableSubWorkspace ? (
                    <>
                        <ListItem>
                            <ListItemText className={classes.context}>{locale.t(locale.keys.pageTitle.admin.allMenu)}</ListItemText>
                        </ListItem>
                        <div className={classes.navItem}>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.subWorkspace.invite.index)}>
                                <Link to={routes.subWorkspace.invite.index}>{locale.t(locale.keys.pageTitle.admin.subWorkspaceInvite)}</Link>
                            </CustomListItem>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.subWorkspace.index)}>
                                <Link to={routes.subWorkspace.index}>{locale.t(locale.keys.pageTitle.admin.subWorkspaceManagement)}</Link>
                            </CustomListItem>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.subWorkspace.mfps)}>
                                <Link to={routes.subWorkspace.mfps}>{locale.t(locale.keys.pageTitle.admin.subWorkspaceMfps)}</Link>
                            </CustomListItem>
                        </div>
                    </>
                ) : null}
                {appContainer.values.signinWorkspaceUserObject.role === userRole.admin ||
                appContainer.values.signinWorkspaceUserObject.role === userRole.externalAdmin ||
                appContainer.values.signinWorkspaceUserObject.role === userRole.systemAdmin ? (
                    <>
                        <ListItem>
                            <ListItemText className={classes.context}>{locale.t(locale.keys.pageTitle.admin.menu)}</ListItemText>
                        </ListItem>
                        <div className={classes.navItem}>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.workspace.index)}>
                                <Link to={routes.workspace.index}>{locale.t(locale.keys.pageTitle.admin.workspaceSetting)}</Link>
                            </CustomListItem>
                            {/* v1.6.0からインターナルプラン、フリープランで新規メンバー招待を表示 */}
                            {appContainer.values.signinWorkspaceObject != null &&
                                (appContainer.values.signinWorkspaceObject.billingPlan === schema.BillingPlan.Free ||
                                    appContainer.values.signinWorkspaceObject.billingPlan === schema.BillingPlan.Internal) && (
                                    <CustomListItem onClick={handClose} selected={isSelected(routes.user.invite.index)}>
                                        <Link to={routes.user.invite.index}>{locale.t(locale.keys.pageTitle.admin.memberInvite)}</Link>
                                    </CustomListItem>
                                )}
                            <CustomListItem onClick={handClose} selected={isSelected(routes.user.index)}>
                                <Link to={routes.user.index}>{locale.t(locale.keys.pageTitle.admin.memberManagement)}</Link>
                            </CustomListItem>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.authManagement.index)}>
                                <Link to={routes.authManagement.index}>{locale.t(locale.keys.pageTitle.admin.authManagement)}</Link>
                            </CustomListItem>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.registrationEndpoint.index)}>
                                <Link to={routes.registrationEndpoint.index}>{locale.t(locale.keys.pageTitle.admin.registrationEndpoint)}</Link>
                            </CustomListItem>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.cloudSetting.index)}>
                                <Link to={routes.cloudSetting.index}>{locale.t(locale.keys.pageTitle.admin.cloudConnect)}</Link>
                            </CustomListItem>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.device.index)}>
                                <Link to={routes.device.index}>{locale.t(locale.keys.pageTitle.admin.shareDevice)}</Link>
                            </CustomListItem>
                            <CustomListItem onClick={handClose} selected={isSelected(routes.device.new)}>
                                <Link to={routes.device.new}>{locale.t(locale.keys.pageTitle.admin.registerDevice)}</Link>
                            </CustomListItem>
                        </div>
                    </>
                ) : null}
                {/*
                 * システム管理者または管理者のみメンバーサイトへのリンク表示
                 * 外部管理者はメンバーサイトにログインできないので表示しない
                 * メンバーは管理者サイトにログインできないのでここでは条件に必要ない
                 */}
                {appContainer.values.signinWorkspaceUserObject.role === userRole.systemAdmin || appContainer.values.signinWorkspaceUserObject.role === userRole.admin ? (
                    <>
                        <div className={classes.subcontext}>
                            <ListItem>
                                <ListItemText>{locale.t(locale.keys.pageTitle.admin.memberSite)}</ListItemText>
                            </ListItem>
                        </div>
                        <div className={classes.navItem}>
                            <CustomListItem
                                button
                                component="a"
                                onClick={handleWebappLinkClick}
                                target="covas_webapp"
                                href={`https://${environment.webapp.user.hostname!}/auth/login/${appContainer.values.signinWorkspaceObject.displayId}`}
                                style={{ height: '48px' }}
                            >
                                <ListItemIcon style={{ marginRight: '8px', color: '#333' }}>
                                    <Launch />
                                </ListItemIcon>
                                {locale.t(locale.keys.pageTitle.admin.toApp)}
                            </CustomListItem>
                        </div>
                    </>
                ) : null}
                {!matchesBreakPoint && (
                    <>
                        <Divider />
                        <HeaderMenuListItems
                            isAdminApp={true}
                            signoutPath={routes.auth.logout.index}
                            name={appContainer.values.signinWorkspaceUserObject.name}
                            phoneticName={appContainer.values.signinWorkspaceUserObject.phoneticName}
                            email={appContainer.values.signinWorkspaceUserObject.invitationEmail}
                            avatarUrl={appContainer.values.signinWorkspaceUserObject.avatarUrl}
                            commonMenuCloseFunc={props.commonMenuCloseFunc}
                        />
                    </>
                )}
            </List>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
