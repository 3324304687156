import { default as React, useEffect, useState } from 'react';
import { WithStyles, Theme, createStyles, withStyles, Avatar, Button, Card, CardContent, Fab, Grid, Typography } from '@material-ui/core';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { showEllipsisString } from '@/common/utils/webappUtil';
import { CollectiveRegisterContainer } from './CollectiveRegisterContainer';
import { csvFormat } from '@/common/constants/csvFormat';
import { default as Dropzone } from 'react-dropzone';
import { MultiLineText } from '@/common/components/messages/MultiLineText';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
            textAlign: 'left',
            marginTop: '8px',
            marginBottom: '16px',
        },
        inviteButton: {
            color: 'white',
            minWidth: 240,
        },
        text: {
            marginTop: 50,
        },
        avatarIcon: {
            borderRadius: 0,
            width: 20,
            height: 20,
        },
        dropzone: {
            backgroundColor: '#eeeeee',
            border: '2px dashed #bdbdbd',
            borderRadius: 6,
            padding: 16,
            marginBlock: '16px',
            maxWidth: '100%',
            minWidth: '100%',
            cursor: 'pointer',
        },
        dropButton: {
            backgroundColor: 'white',
        },
        dropzoneInnerText: {
            paddingTop: '5px',
            textAlign: 'center',
            margin: '0 auto',
            fontSize: '15px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#666666',
        },
        dropzoneInnerDetailText: {
            paddingTop: '10px',
            textAlign: 'center',
            margin: '0 auto',
            fontSize: '10px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#666666',
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const { csvFileName, buttonDisable, ui, onSelectedCSV, onImportCsv } = CollectiveRegisterContainer.useContainer();
    const [displayFileName, setDisplayFileName] = useState<string>(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        setDisplayFileName(showEllipsisString(csvFileName, csvFormat.CSVFILENAME_MAX));
    }, [csvFileName]);

    const onDrop = (accepts: File[]) => {
        // multiple={false}プロパティを指定しているので、必ず要素数は1になる。
        onSelectedCSV(accepts);
    };

    return (
        <div>
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    <Typography align="left" variant="h6">
                        {locale.t(locale.keys.manageShareDevice.collective.desc1)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left" variant="h6" color="error">
                        {locale.t(locale.keys.manageShareDevice.collective.desc2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left" variant="h6">
                        {locale.t(locale.keys.manageShareDevice.collective.desc3)}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.text}>
                    <Typography align="left" variant="h5">
                        {locale.t(locale.keys.manageShareDevice.collective.upload)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Dropzone multiple={false} onDrop={(accepts) => onDrop(accepts)} accept={'.csv'}>
                        {({ getRootProps, getInputProps }) => (
                            <div className={classes.dropzone} {...getRootProps()}>
                                <Grid container spacing={8} direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerText}>{locale.t(locale.keys.manageShareDevice.collective.csvtitle)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerDetailText}>{locale.t(locale.keys.action.or)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <input {...getInputProps()} />
                                        <Button variant="outlined" type="button" component="label" className={classes.dropButton}>
                                            {locale.t(locale.keys.manageShareDevice.collective.select)}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerText}>{displayFileName}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </Grid>

                <Grid item container xs={12} justify={'flex-start'}>
                    <Fab disabled={buttonDisable} variant="extended" onClick={() => onImportCsv()} className={classes.inviteButton} size="large" color="secondary">
                        {locale.t(locale.keys.manageShareDevice.collective.register)}
                    </Fab>
                </Grid>

                <Grid item xs={12} className={classes.text}>
                    <Typography align="left" variant="h6">
                        {locale.t(locale.keys.manageShareDevice.collective.process)}
                    </Typography>
                </Grid>
                <Grid container spacing={0} direction="row" alignItems="center" justify="flex-start">
                    <Avatar className={classes.avatarIcon} src="/assets/images/logo/csvfileicon.svg" />
                    {appContainer.values.signinWorkspaceUserObject.language === schema.Language.Ja ? (
                        // 日本語なら日本語のテンプレートのダウンロードリンク
                        <a href="/csv/template/ImportMFP_template_ja.csv" download>
                            {locale.t(locale.keys.manageShareDevice.collective.template)}
                        </a>
                    ) : (
                        // 日本語以外なら英語テンプレートのダウンロードリンク
                        <a href="/csv/template/ImportMFP_template_en.csv" download>
                            {locale.t(locale.keys.manageShareDevice.collective.template)}
                        </a>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left">{locale.t(locale.keys.manageShareDevice.collective.please)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">{locale.t(locale.keys.manageShareDevice.collective.title)}</Typography>
                            <MultiLineText value={locale.t(locale.keys.manageShareDevice.collective.description)} align="left" variant="body1" color="default" />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(Component);
