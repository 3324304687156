import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, TextField } from '@material-ui/core';
import { EntryDetailContainer } from './EntryDetailContainer';
import locale from '@/common/utils/locale';

const clientInfoStyles = (theme: Theme) =>
    createStyles({
        root: {
            padding: `0px ${theme.spacing.unit * 3}px`,
            marginBottom: '60px',
        },
    });

interface ClientInfoProps extends WithStyles<typeof clientInfoStyles> {}
export const ClientInfo = withStyles(clientInfoStyles, { withTheme: true })((props: ClientInfoProps) => {
    const container = EntryDetailContainer.useContainer();
    const { classes } = props;
    const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, clientCompany: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, clientDepartment: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, clientLastName: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, clientFirstName: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeMail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, clientMail: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, clientPhone: event.target.value };
        container.handleChangeEntryDetail(change);
    };

    return (
        <Grid container spacing={16} className={classes.root}>
            <Grid item xs={12}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.companyName)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.clientCompany}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangeCompany}
                    error={container.error.clientCompany ? true : false}
                    helperText={container.error.clientCompany}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.departmentName)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.clientDepartment}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangeDepartment}
                    error={container.error.clientDepartment ? true : false}
                    helperText={container.error.clientDepartment}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.lastName)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.clientLastName}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangeLastName}
                    error={container.error.clientLastName ? true : false}
                    helperText={container.error.clientLastName}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.firstName)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.clientFirstName}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangeFirstName}
                    error={container.error.clientFirstName ? true : false}
                    helperText={container.error.clientFirstName}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.email)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.clientMail}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangeMail}
                    error={container.error.clientMail ? true : false}
                    helperText={container.error.clientMail}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.customerInfomation.phoneNumber)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.clientPhone}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangePhone}
                    error={container.error.clientPhone ? true : false}
                    helperText={container.error.clientPhone}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
});
