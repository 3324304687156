export const keys = {
    signinWorkspaceObject: 'signinWorkspaceObject', // schema.V1ObjectsWorkspaceのデータのキャッシュ
    signinWorkspaceUserObject: 'signinWorkspaceUserObject', // schema.V1ObjectsWorkspaceuserLargeのデータのキャッシュ
    cropAndDropProps: 'cropAndDropProps', // アバター画像選択時に使用するコンポーネントのプロパティのキャッシュ
    lang: 'lang', // ユーザーが選択している言語情報
};

export const prefix = {
    userApp: 'userApp-', // LocalStorageのキーのプレフィックス。ユーザーサイトか管理者サイトを判別するために使用。
};

/**
 *
 * @param prefix 管理者サイト：なし、ユーザーサイト：'userApp-'
 * @param displayId
 * @returns サインイン済みWSのWS情報を保存するLocalStorageのキー
 */
export const getHistoryLocalStorageKey = (prefix: string, displayId: string) => {
    return `${prefix}${displayId}-History`;
};
