import { default as React } from 'react';
import { default as Downshift } from 'downshift';
import { Theme, createStyles, WithStyles, withStyles, TextField, Paper, Chip, MenuItem, Grid, Typography, List, ListSubheader } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import { DetailDeviceContainer, addMember, getSuggestions } from './DetailDeviceContainer';
import { CovasAvatar, CovasGroupAvatar } from '@/common/components/CovasAvatar';
import locale from '@/common/utils/locale';
import { Member } from '@/common/models/device/useDevice';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            height: 250,
        },
        memberListContainer: {
            width: 740,
            minHeight: 60,
            marginLeft: 20,
            marginRight: 40,
            marginBottom: 20,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
        },
        container: {
            flexGrow: 1,
            width: 740,
            padding: 15,
        },
        paper: {
            position: 'absolute',
            zIndex: 10,
            marginTop: theme.spacing.unit * 3,
            left: 0,
            right: 0,
            width: 328,
            marginLeft: 20,
        },
        chip: {
            margin: theme.spacing.unit * 0.5,
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
        inputInput: {
            width: 'auto',
            flexGrow: 1,
            '&::placeholder': {
                fontSize: 15,
            },
        },
        divider: {
            height: theme.spacing.unit * 2,
        },
        errorMessage: {
            textAlign: 'left',
            paddingLeft: 20,
            color: 'red',
        },
    });

interface SuggestedListProps extends WithStyles<typeof styles> {
    index: number;
    itemProps: MenuItemProps;
    suggestion: Member;
    highlightedIndex: number | null;
}

const SuggestedList = withStyles(styles)((props: SuggestedListProps) => {
    const { suggestion, index, itemProps, highlightedIndex } = props;
    const key = suggestion.isGroup ? suggestion.name : suggestion.email!;
    const isHighlighted = highlightedIndex === index;
    return (
        <MenuItem
            {...itemProps}
            key={key}
            component="div"
            selected={isHighlighted}
            style={{
                fontWeight: 400,
                height: 'auto',
            }}
        >
            <Grid container spacing={16} style={{ flexWrap: 'nowrap', gap: 16 }}>
                <Grid item style={{ width: 60 }} container>
                    {// グループの場合はグループアイコンを表示する
                    suggestion.isGroup ? <CovasGroupAvatar alt="Workspace" size={60} src={suggestion.avatarUrl} /> : <CovasAvatar alt="Workspace" size={60} seed={key} src={suggestion.avatarUrl} />}
                </Grid>
                <Grid item style={{ width: 235 }} container>
                    <Grid item xs>
                        <Typography style={{ whiteSpace: 'pre', overflow: 'hidden', textOverflow: 'ellipsis' }}>{suggestion.name}</Typography>
                        <Typography style={{ whiteSpace: 'pre', overflow: 'hidden', textOverflow: 'ellipsis' }}>{suggestion.email}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </MenuItem>
    );
});

type RenderInputProps = TextFieldProps & {
    classes: Record<'root' | 'container' | 'paper' | 'chip' | 'inputRoot' | 'inputInput' | 'divider', string>;
    ref?: React.Ref<HTMLDivElement>;
};

interface Props extends WithStyles<typeof styles> {}

const DownshiftMultiple: React.FC<Props> = (props) => {
    const { classes } = props;
    const detailDeviceContainer = DetailDeviceContainer.useContainer();
    const [inputValue, setInputValue] = React.useState('');

    const renderInput = (inputProps: RenderInputProps) => {
        const { InputProps, classes, ref, ...other } = inputProps;
        return (
            <TextField
                style={{
                    width: '100%',
                }}
                placeholder={detailDeviceContainer.device.form.allowedMember.length === 0 ? locale.t(locale.keys.manageShareDevice.emailTitle.placeholder) : ''}
                InputProps={{
                    inputRef: ref,
                    classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    },
                    ...InputProps,
                }}
                {...other}
            />
        );
    };

    // フォームからユーザー/グループを入力する場合のハンドラー
    // isSuggestSelected: サジェストで選択されているかどうか(hightlightedIndexに選択中のindexが記録されている場合は選択中)
    const handleKeyDown = (event: React.KeyboardEvent, isSuggestSelected: boolean) => {
        // 何かしらのキーが押下されたらエラーメッセージをクリアする
        // クリアしないとサジェストが表示されない
        // Backspaceでフォームに入力されているユーザーまたはグループの削除
        if (detailDeviceContainer.device.form && detailDeviceContainer.device.form.allowedMember.length && !inputValue.length && event.key === 'Backspace') {
            detailDeviceContainer.device.handleUpdateDeviceForm({
                allowedMember: [...detailDeviceContainer.device.form.allowedMember.slice(0, detailDeviceContainer.device.form.allowedMember.length - 1)],
            });
            return;
        }

        // Enter押下時以外で入力値のバリデーション結果を表示させたくないので、入力値がない場合は何もしない
        if (!inputValue) {
            return;
        }

        // Enter押下時にフォームを更新する
        if (event.key === 'Enter') {
            // サジェストで選択されている項目を登録したい場合は、テキストからの登録は行わず入力状態を破棄してサジェストで選択している項目の登録のみにする
            if (isSuggestSelected) {
                setInputValue('');
                return;
            }

            const updateMember = addMember(
                inputValue,
                detailDeviceContainer.device.form.allowedMember,
                detailDeviceContainer.device.suggestData.wsUserData,
                detailDeviceContainer.device.suggestData.wsGroupData,
                detailDeviceContainer.device.deviceMemberData,
            );

            // addMemberで無効な値が返ってきた場合、エラーを表示してフォーム（ステート）は更新しない
            detailDeviceContainer.device.handleUpdateDeviceForm({
                allowedMember: [...detailDeviceContainer.device.form.allowedMember, ...updateMember],
            });

            // メンバーをフォームに追加したら、入力中のテキストをクリアする
            setInputValue('');
            return;
        }

        return;
    };

    const handleInputChange = (event: React.ChangeEvent<{ value: string }>) => {
        setInputValue(event.target.value);
    };

    // サジェストで表示されるユーザー/グループを選択した場合のハンドラー
    const handleChange = (item: Member) => {
        // ESCキーでサジェストを閉じるときにitemがnullになるので、その場合は入力中の文字列をクリアしてサジェストを閉じる
        // 入力中文字列をクリアするのは過去からの仕様
        if (!item) {
            setInputValue('');
            return;
        }
        detailDeviceContainer.device.handleUpdateDeviceForm({
            ...detailDeviceContainer.device.form,
            allowedMember: [...detailDeviceContainer.device.form.allowedMember, item],
        });
        setInputValue('');
    };

    const handleDelete = (removeIndex: number) => () => {
        detailDeviceContainer.device.handleUpdateDeviceForm({
            ...detailDeviceContainer.device.form,
            allowedMember: [...detailDeviceContainer.device.form.allowedMember.slice(0, removeIndex), ...detailDeviceContainer.device.form.allowedMember.slice(removeIndex + 1)],
        });
    };

    return (
        <Paper className={classes.memberListContainer}>
            <Downshift id="downshift-multiple" inputValue={inputValue} onChange={handleChange} selectedItem={detailDeviceContainer.device.form.allowedMember}>
                {({ getInputProps, getItemProps, getLabelProps, isOpen, highlightedIndex }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        onKeyDown: (event: React.KeyboardEvent) => handleKeyDown(event, highlightedIndex !== null),
                    });

                    return (
                        <div className={classes.container}>
                            {renderInput({
                                classes,
                                inputProps,
                                fullWidth: true,
                                InputLabelProps: getLabelProps(),
                                InputProps: {
                                    onFocus,
                                    onBlur,
                                    disableUnderline: true,
                                    startAdornment: detailDeviceContainer.device.form.allowedMember.map((item, index) => (
                                        <Chip
                                            key={index}
                                            avatar={
                                                item.isGroup ? (
                                                    <CovasGroupAvatar alt="Workspace" size={24} src={item.avatarUrl} />
                                                ) : (
                                                    <CovasAvatar alt="Workspace" size={24} seed={item.email} src={item.avatarUrl} />
                                                )
                                            }
                                            tabIndex={-1}
                                            label={item.name}
                                            className={classes.chip}
                                            onDelete={handleDelete(index)}
                                        />
                                    )),
                                    onChange: (event) => {
                                        handleInputChange(event);
                                        onChange!(event as React.ChangeEvent<HTMLInputElement>);
                                    },
                                },
                            })}
                            {(() => {
                                const suggestedData = getSuggestions(
                                    inputValue,
                                    detailDeviceContainer.device.form.allowedMember,
                                    detailDeviceContainer.device.suggestData.wsUserData,
                                    detailDeviceContainer.device.suggestData.wsGroupData,
                                    detailDeviceContainer.device.deviceMemberData,
                                );

                                if (!isOpen || inputValue === '' || (suggestedData.groups.length === 0 && suggestedData.users.length === 0)) {
                                    return <></>;
                                }

                                return (
                                    <Paper className={classes.paper} square>
                                        <List>
                                            {suggestedData.groups.length > 0 ? (
                                                <ListSubheader style={{ display: 'flex', alignItems: 'center', height: 35 }}>
                                                    <Typography style={{ fontSize: 12, textAlign: 'left', color: 'gray' }}>{locale.t(locale.keys.common.model.group)}</Typography>
                                                </ListSubheader>
                                            ) : null}
                                            {suggestedData.groups.map((group, index) => {
                                                return (
                                                    <SuggestedList
                                                        suggestion={group}
                                                        index={index}
                                                        itemProps={getItemProps({
                                                            item: {
                                                                email: group.email,
                                                                name: group.name,
                                                                id: group.id,
                                                                avatarUrl: group.avatarUrl,
                                                                isGroup: group.isGroup,
                                                            },
                                                        })}
                                                        highlightedIndex={highlightedIndex}
                                                    />
                                                );
                                            })}
                                            {suggestedData.users.length > 0 ? (
                                                <ListSubheader style={{ display: 'flex', alignItems: 'center', height: 35 }}>
                                                    <Typography style={{ fontSize: 12, textAlign: 'left', color: 'gray' }}>{locale.t(locale.keys.common.model.user)}</Typography>
                                                </ListSubheader>
                                            ) : null}
                                            {suggestedData.users.map((user, index) => {
                                                return (
                                                    <SuggestedList
                                                        suggestion={user}
                                                        index={index + suggestedData.groups.length} // グループの下にユーザーが表示されるため、表示順序はグループの数だけずらす（でないとサジェストからの選択が上手くいかない）
                                                        itemProps={getItemProps({
                                                            item: {
                                                                email: user.email,
                                                                name: user.name,
                                                                id: user.id,
                                                                avatarUrl: user.avatarUrl,
                                                                isGroup: user.isGroup,
                                                            },
                                                        })}
                                                        highlightedIndex={highlightedIndex}
                                                    />
                                                );
                                            })}
                                        </List>
                                    </Paper>
                                );
                            })()}
                        </div>
                    );
                }}
            </Downshift>
            {/**
             * エラーメッセージの表示
             * 「バリデーション結果がある」、「エラー（不正な入力）かつそのエラーに対応したエラーメッセージがある」場合にエラーメッセージを表示する。
             *
             * 現状ではワークスペースに存在しないユーザー、グループを登録しようとしたときのみにエラーメッセージを表示する。
             */}
            {detailDeviceContainer.device.validateUpdateResult === null ? (
                <></>
            ) : detailDeviceContainer.device.validateUpdateResult.allowedMember.isError && detailDeviceContainer.device.validateUpdateResult.allowedMember.message !== '' ? (
                <p className={classes.errorMessage}>{detailDeviceContainer.device.validateUpdateResult.allowedMember.message}</p>
            ) : (
                <></>
            )}
        </Paper>
    );
};

export default withStyles(styles)(DownshiftMultiple);
