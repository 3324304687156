// 認証情報に関するCookieとLocalStorageを扱うモジュール
import * as cookieInterfaces from '@/common/utils/authManagement/cookieInterfaces';
import * as cookieConstants from '@/common/utils/authManagement/cookieConstants';
import * as localStorageConstants from '@/common/utils/authManagement/localStorageConstants';
import * as cookies from '@/common/utils/browser/cookies';
import * as store from '@/common/utils/browser/store';

/**
 * @description 認証済みワークスペースをlocalstorageまたはcookieから取得。
 * 過去サインイン済みのWSリストはcookieで保存していた経緯があり、それをlocalstorageに移行するための関数。
 * APIのリクエストに乗せるトークンなどはsecureパラメータやHttpOnlyパラメータが付与でき、XSS攻撃を防ぐことができるCookieに保存するのが良いと考えるが、
 * このalreadySigninWorkspaceの目的はUIの表示に使用されるのみなのでどちらかというと永続で保存できるLocalstorageが良い。
 * alreadySigninWorkpsaceはサインインするたびに更新されるので、2025年あたりになったらほぼすべてのアクティブユーザーのブラウザ環境ではlocalstorageに保存されているはずである。
 * なので2025年あたりになったら削除して完全にlocalstorageに移行しても良いだろう(記載は2024年3月)。
 */
const getAlreadySignWorkspaceFromCookieOrLocalStorage = () => {
    // localstorageに保存されている情報を優先で取得
    let workspaces = store.getValue<cookieInterfaces.AlreadySigninWorkspace[]>(`${cookieConstants.keys.alreadySigninWorkpsace}`);
    if (!workspaces) {
        // localstorageになければcookieから取得
        workspaces = cookies.get<cookieInterfaces.AlreadySigninWorkspace[]>(`${cookieConstants.keys.alreadySigninWorkpsace}`);
    }
    if (workspaces) {
        return workspaces;
    }

    return [] as cookieInterfaces.AlreadySigninWorkspace[];
};

/**
 * @param prefix 管理者サイト：なし、ユーザーサイト：'userApp-'
 * @description 認証済みワークスペースをcookieから取得
 */
export const getAlreadySignWorkspaceFromCookie = (prefix: string = ''): cookieInterfaces.AlreadySigninWorkspace[] => {
    const workspaces = getAlreadySignWorkspaceFromCookieOrLocalStorage();

    if (workspaces) {
        // cookieに保存されているサインイン済みWSの中で、サインインに必要な情報が正しくCookieに保存されているかを検証
        // 必要な情報は下記の通り。
        // - <workspaces.displayId>-HistoryのCookie(サインイン済みの認証トークンが保存されている)
        const result = workspaces.filter((ws) => {
            // cookieに<workspaces.displayId>-Historyがあるかどうか検証
            const alreadySigninWSToken = cookies.get<string>(cookieConstants.getHistoryCookieKey(ws.displayId));
            if (!alreadySigninWSToken) {
                // なければalreadySigninWorkpsaceの値を更新し、localstorageのキャッシュを削除する
                removeCookieSigninWorkspace(ws.displayId);
                store.remove(localStorageConstants.getHistoryLocalStorageKey(prefix, ws.displayId));
                return false;
            } else {
                return true;
            }
        });

        return result;
    }
    const empty: cookieInterfaces.AlreadySigninWorkspace[] = [];
    return empty;
};

export const getShareAuth = () => {
    const info = cookies.get<cookieInterfaces.ShareAuthInfo>(cookieConstants.keys.shareAuth);
    return info;
};
export const removeShareAuth = () => {
    const parts = document.location.hostname.split('.');
    parts.shift();
    const upperleveldomain = parts.join('.');
    cookies.remove(cookieConstants.keys.shareAuth, {
        domain: upperleveldomain,
    });
};

export const setShareAuth = (info: cookieInterfaces.ShareAuthInfo) => {
    const parts = document.location.hostname.split('.');
    parts.shift();
    const upperleveldomain = parts.join('.');
    cookies.set<cookieInterfaces.ShareAuthInfo>(cookieConstants.keys.shareAuth, info, {
        domain: upperleveldomain,
    });
};

/**
 * @description サインイン済みワークスペースリストをcookieに保存。サインイン画面はここの情報から下部のサインイン済みワークスペースリストを作成する。
 */
export const setCookieSigninWorkspace = (ws: cookieInterfaces.AlreadySigninWorkspace) => {
    const workspaces = getAlreadySignWorkspaceFromCookieOrLocalStorage();
    // cookieあり
    if (workspaces) {
        // cookieの中にワークスペースがなければpush
        if (workspaces.find((w: cookieInterfaces.AlreadySigninWorkspace) => w.displayId === ws.displayId) === undefined) {
            workspaces.push(ws);
            store.setValue<cookieInterfaces.AlreadySigninWorkspace[]>(`${cookieConstants.keys.alreadySigninWorkpsace}`, workspaces);
            return;
        } else {
            // 既にあるワークスペースなら最新のデータに更新して保存
            const newWorkspaces = workspaces.map((w: cookieInterfaces.AlreadySigninWorkspace) => {
                if (w.displayId === ws.displayId) {
                    return ws;
                }
                return w;
            });
            store.setValue<cookieInterfaces.AlreadySigninWorkspace[]>(`${cookieConstants.keys.alreadySigninWorkpsace}`, newWorkspaces);
        }
        // cookieない
    } else {
        store.setValue<cookieInterfaces.AlreadySigninWorkspace[]>(`${cookieConstants.keys.alreadySigninWorkpsace}`, [ws]);
    }
};

export const removeCookieSigninWorkspace = (displayId: string) => {
    const workspaces = getAlreadySignWorkspaceFromCookieOrLocalStorage();
    // cookieあり
    if (workspaces) {
        // cookieの中にワークスペースがなければpush
        const newWorkspaces = workspaces.filter((w: cookieInterfaces.AlreadySigninWorkspace) => w.displayId !== displayId);
        store.setValue<cookieInterfaces.AlreadySigninWorkspace[]>(`${cookieConstants.keys.alreadySigninWorkpsace}`, newWorkspaces);
        // cookieない
    }
};
