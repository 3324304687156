import { default as React, useEffect } from 'react';
import { Grid, createStyles, withStyles, WithStyles } from '@material-ui/core';

import { State as UI } from '@/common/components/hooks/useUI';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import Error from '@/common/components/state/Error';
import locale from '@/common/utils/locale';
import * as errorHandler from '@/common/utils/errorHandler';

import * as workspace from '@/common/api/workspace/workspace';

import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { WorkspaceContainer } from '@/admin/components/workspace/WorkspaceContainer';

import Config from './setting/Config';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import SubHeader from '@/admin/components/common/subheader/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });
interface Props extends WithStyles<typeof styles> {
    ui?: UI;
    skipEffect?: boolean;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const container = WorkspaceContainer.useContainer();

    useEffect(() => {
        appContainer.updateLoadingState(container.ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.ui]);

    useEffect(() => {
        if (props.skipEffect || container.ui.current !== UI.Loading) {
            return;
        }
        (async () => {
            try {
                const wsRes = await workspace.showWorkspace(appContainer.values.signinWorkspaceObject.displayId, appContainer.values.authorizationCode);
                if (!wsRes.workspace) {
                    container.updateUIStatus(UI.Error);
                }
                appContainer.setValues({
                    ...appContainer.values,
                    signinWorkspaceObject: wsRes.workspace,
                });
                container.setForm({
                    ...container.form,
                    objId: wsRes.workspace.id!,
                    displayId: wsRes.workspace.displayId,
                    displayName: wsRes.workspace.displayName,
                    logoUrl: wsRes.workspace.logoUrl,
                    language: wsRes.workspace.language,
                    enableSubWorkspace: wsRes.workspace.enableSubWorkspace,
                    contactEmail: wsRes.workspace.contactEmail,
                });
                if (wsRes.workspace.organizationDetail) {
                    container.setOrganization({
                        ...container.organization,
                        name: wsRes.workspace.organizationDetail.name ? wsRes.workspace.organizationDetail.name : '',
                        zipCode: wsRes.workspace.organizationDetail.zipCode ? wsRes.workspace.organizationDetail.zipCode : '',
                        country: wsRes.workspace.organizationDetail.country ? wsRes.workspace.organizationDetail.country : '',
                        address1: wsRes.workspace.organizationDetail.address1 ? wsRes.workspace.organizationDetail.address1 : '',
                        address2: wsRes.workspace.organizationDetail.address2 ? wsRes.workspace.organizationDetail.address2 : '',
                        phoneNumber: wsRes.workspace.organizationDetail.phoneNumber ? wsRes.workspace.organizationDetail.phoneNumber : '',
                        customerId: wsRes.workspace.organizationDetail.customerId ? wsRes.workspace.organizationDetail.customerId : '',
                    });
                }

                // const extensionList = await workspace.getExtensions(appContainer.values.signinWorkspaceObject.id || '', appContainer.values.authorizationCode);
                // container.setExtensions(extensionList.extensions);

                container.updateUIStatus(UI.Loaded);
            } catch (e) {
                container.updateUIStatus(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SubHeader title={locale.t(locale.keys.workspaceSetting.index)} />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    {container.ui.current === UI.Loading && (
                        <div data-testid={UI.Loading}>
                            <Loading />
                        </div>
                    )}
                    {container.ui.current === UI.Loaded && (
                        <div>
                            <Grid container spacing={24}>
                                <Grid item xs={12} md={8}>
                                    {/** 「プロファイル」 */}
                                    <Config />
                                </Grid>
                                {/* 拡張機能は停止中なのでコメントアウトで非表示 */}
                                {/* <Grid item xs={12} md={4}> */}
                                {/* {appContainer.values.signinWorkspaceObject != null && appContainer.values.signinWorkspaceObject.billingPlan !== schema.BillingPlan.Free && ( */}
                                {/* <> */}
                                {/* 「ご利用中の拡張機能」 */}
                                {/* <Extension /> */}
                                {/* </> */}
                                {/* )} */}
                                {/* </Grid> */}
                            </Grid>
                        </div>
                    )}
                    {container.ui.current === UI.Saving && (
                        <div data-testid={UI.Saving}>
                            <Saving />
                        </div>
                    )}

                    {container.ui.current === UI.Error && (
                        <div data-testid={UI.Error}>
                            <Error />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
