import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { MemberDetailContainer } from './MemberDetailContainer';
import routes from '@/admin/constants/routes';
import { parse } from 'query-string';
import MemberDetail from './MemberDetail';
import { MemberContainer } from '@/admin/components/member/MemberContainer';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{ id: string }> {}

export const Component: React.FC<Props> = (props) => {
    const { params } = props.match;
    useTitle(locale.t(locale.keys.memberDetail.index));

    return (
        <>
            <SubHeader title={locale.t(locale.keys.memberDetail.index)} isBack={parse(window.location.search).invite ? routes.user.invite.index : routes.user.index} />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    <MemberContainer.Provider>
                        <MemberDetailContainer.Provider>
                            <MemberDetail userId={params.id} />
                        </MemberDetailContainer.Provider>
                    </MemberContainer.Provider>
                </div>
            </div>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
