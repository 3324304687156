import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { default as consts } from '@/common/constants';
import * as schema from '@/bundles/schema/typescript/schema';
import { axiosFactory } from '@/common/utils/axiosFactory';

export interface Props {
    // VerifyToken
    token: string;
    // auth data
    // signin workspace
    signinWorkspaceObject: schema.V1ObjectsWorkspace | undefined;
    // login user email
    signinWorkspaceUserObject: schema.V1ObjectsWorkspaceuserLarge | undefined;

    // state
    showPassword: boolean;
    showPasswordConfirm: boolean;
    errors: validator.ValidationOutput | null;
}
export const NewProps = (): Props => {
    return {
        token: '',

        signinWorkspaceObject: undefined,
        signinWorkspaceUserObject: undefined,

        showPassword: false,
        showPasswordConfirm: false,
        errors: NewValidation(),
    };
};

export interface Form {
    password: string;
    passwordConfirm: string;
}
export const NewForm = (): Form => {
    return {
        password: '',
        passwordConfirm: '',
    };
};

export const validations = (): validator.Constraints => {
    return {
        password: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            passwordPolicy: {
                message: locale.t(locale.keys.validation.passwordPolicy),
            },
        },
        passwordConfirm: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            equality: {
                attribute: 'password',
                message: locale.t(locale.keys.validation.notEnough),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        password: null,
        passwordConfirm: null,
    };
};

// パスワードリセット検証API
export const passwordResetVerify = async (req: schema.V1WorkspaceuserPasswordResetVerifyCreateRequest): Promise<schema.V1WorkspaceuserPasswordResetVerifyCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceuserPasswordResetVerifyCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/passwordreset/verify`, req);
    return response.data;
};
// パスワード設定API
export const resetPassword = async (req: schema.V1WorkspaceuserPasswordResetUpdateRequest): Promise<schema.V1WorkspaceuserInviteVerifyUpdateResponse> => {
    const response = await axiosFactory.patch<schema.V1WorkspaceuserInviteVerifyUpdateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/passwordreset`, req);
    return response.data;
};
