import * as schema from '@/bundles/schema/typescript/schema';
import { CovasAvatar } from '@/common/components/CovasAvatar';
import { styles as baseTabStyles } from '@/common/components/Tab/style';
import { styles as baseTableStyles } from '@/common/components/Table/style';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';
import userRole from '@/common/constants/userRole';
import * as locale from '@/common/utils/locale/locale';
import {
    Checkbox,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Theme,
    Tooltip,
    WithStyles,
    createStyles,
    withStyles,
} from '@material-ui/core';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { ArrowDropDown, Block, Check, Search, UnfoldMore } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';

const styles = (theme: Theme) =>
    createStyles({
        ...baseTableStyles(),
        ...baseTabStyles(theme),
        tableWrapper: {
            overflowX: 'auto',
        },
        table: {
            padding: 0,
        },

        checkCell: {
            width: 40,
            maxWidth: 40,
            margin: 4,
            wordBreak: 'break-all',
        },
        avatarCell: {
            width: 40,
            maxWidth: 40,
            margin: 4,
            wordBreak: 'break-all',
        },
        mailCell: {
            width: 'auto',
            margin: 4,
            wordBreak: 'break-all',
            paddingRight: 8,
        },
        roleCell: {
            width: 70,
            margin: 4,
            wordBreak: 'break-all',
        },
        authCell: {
            width: 80,
            margin: 4,
            wordBreak: 'break-all',
        },
        statusCell: {
            width: 120,
            margin: 4,
            wordBreak: 'break-all',
        },

        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
    });

type Row = {
    id: string;
    mail: string;
    contactEmail: string;
    name: string;
    role: number;
    avatarUrl: string;
    authStatus: string;
    accountStatus: string;
};

const roleToString = (role: number): string => {
    switch (role) {
        case userRole.systemAdmin:
            return locale.t(locale.keys.common.role.systemAdmin);
        case userRole.admin:
            return locale.t(locale.keys.common.role.admin);
        case userRole.externalAdmin:
            return locale.t(locale.keys.common.role.externalAdmin);
        case userRole.member:
            return locale.t(locale.keys.common.role.user);
    }
    return '';
};

enum AuthStatus {
    ALL = '999',
    AUTHED = '1',
    STILL = '0',
}

const getRoleMenuList = () => {
    return [
        // システム管理者は除く
        userRole.admin,
        userRole.member,
    ];
};

const getAuthStatusMenu = (authStatus: AuthStatus) => {
    switch (authStatus) {
        case AuthStatus.STILL:
            return locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.still);
        case AuthStatus.AUTHED:
            return locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.authed);
        case AuthStatus.ALL:
            return locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.all);
        default:
            return locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.all);
    }
};

const getAuthStatusMenuList = () => {
    return Object.values(AuthStatus).map((status) => {
        return getAuthStatusMenu(status);
    });
};

enum AccountStatus {
    ALL = '999',
    ACTIVE = '1',
    STOP = '0',
}

const getAccountStatusMenu = (authStatus: AccountStatus) => {
    switch (authStatus) {
        case AccountStatus.STOP:
            return locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.account.inActive);
        case AccountStatus.ACTIVE:
            return locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.account.active);
        case AccountStatus.ALL:
            return locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.account.all);
        default:
            return locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.account.all);
    }
};

const getAccountStatusMenuList = () => {
    return Object.values(AccountStatus).map((status) => {
        return getAccountStatusMenu(status);
    });
};

// 表示用に取得したデータを加工
const convertRows = (workspaceUser: schema.V1ObjectsXStoragesStatus[]): Row[] => {
    const rows: Row[] = [];
    workspaceUser.forEach((val) => {
        rows.push({
            id: val.user.id,
            mail: val.user.invitationEmail,
            contactEmail: val.user.contactEmail,
            name: val.user.name,
            role: val.user.role,
            avatarUrl: val.user.avatarUrl,
            authStatus: getAuthStatusMenu(val.serviceStatus ? AuthStatus.AUTHED : AuthStatus.STILL),
            accountStatus: getAccountStatusMenu(val.user.active ? AccountStatus.ACTIVE : AccountStatus.STOP),
        });
    });
    return rows;
};

interface PresenterProps extends WithStyles<typeof styles> {
    formValue: schema.V1XStoragesOrderCreateRequest;
    setFormValue: React.Dispatch<React.SetStateAction<schema.V1XStoragesOrderCreateRequest>>;
    userData: schema.V1ObjectsXStoragesStatus[];
    responseData: schema.V1ObjectsXStoragesStatus[];
    searchText: string;
    selectedUsers: string[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    selectedOwnerUser: string;
    setSelectedOwnerUser: React.Dispatch<React.SetStateAction<string>>;
    senderSelect: boolean;
}

const MemberTable: FC<PresenterProps> = (props) => {
    // user idの配列
    const [rows, setRows] = useState<Row[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [sortByUserName, setSortByUserName] = useState<schema.V1ObjectsSort>(schema.V1ObjectsSort.None);
    // MenuのAnchor。クリックした要素の上に表示させたいため。
    const [authMenuAnchor, setAuthMenuAnchor] = useState<null | HTMLElement>(null);
    const [accountMenuAnchor, setAccountMenuAnchor] = useState<null | HTMLElement>(null);
    const [roleMenuAnchor, setRoleMenuAnchor] = useState<null | HTMLElement>(null);
    const isAuthMenuOpen = Boolean(authMenuAnchor);
    const isAccountMenuOepn = Boolean(accountMenuAnchor);
    const isRoleMenuOepn = Boolean(roleMenuAnchor);

    // 選択済みグループおよびレスポンスデータが変更されるたびにテーブルの表示を更新
    useEffect(() => {
        setRows(convertRows(props.responseData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedUsers, props.responseData]);

    // 検索条件にマッチしたRowを取得
    const getRows = (rows: Row[]) => {
        const sortedByUserNameRows = getSortByUserNameRows(rows, sortByUserName);
        // Eメールアドレス || 名前
        return getSearchTextRows(sortedByUserNameRows);
    };

    // メンバーセレクト
    const setSelectMember = (selectedMember: string[]) => {
        props.setSelectedUsers(selectedMember);
    };

    const handleSortByUserName = (currentSortState: schema.V1ObjectsSort): schema.V1ObjectsSort => {
        if (currentSortState === schema.V1ObjectsSort.None) {
            return schema.V1ObjectsSort.Asc;
        } else if (currentSortState === schema.V1ObjectsSort.Asc) {
            return schema.V1ObjectsSort.Desc;
        } else {
            return schema.V1ObjectsSort.None;
        }
    };

    // ユーザ名でソートするDecorator
    const getSortByUserNameRows = (rows: Row[], sortName: schema.V1ObjectsSort): Row[] => {
        const currentRows = [...rows]; // 複製しないと元のrows（ステートの方）が変更されて、schema.V1ObjectsSort.Noneの時のソートが上手くいかない
        const sortedRows = currentRows.sort((a, b) => {
            if (sortName === schema.V1ObjectsSort.Asc) {
                return a.name.localeCompare(b.name); // localeCompareは大文字小文字を考慮したソート
            } else if (sortName === schema.V1ObjectsSort.Desc) {
                return b.name.localeCompare(a.name);
            } else {
                return 0;
            }
        });
        return sortedRows;
    };

    // テキストフォームの内容で絞り込むDecorator
    const getSearchTextRows = (rows: Row[]): Row[] => {
        return rows.filter((val) => {
            return (
                val.mail.toLowerCase().indexOf(props.searchText.toLowerCase()) !== -1 ||
                val.contactEmail.toLocaleLowerCase().indexOf(props.searchText.toLowerCase()) !== -1 ||
                val.name.toLowerCase().indexOf(props.searchText.toLowerCase()) !== -1
            );
        });
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    /**
     * 全てのチェックボックスを選択する。
     * @param event
     * @returns void
     */
    const handleSelectAllCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = getRows(rows).map((row) => row.id);
            setSelectMember(Array.from(new Set([...props.selectedUsers, ...newSelected])));
            return;
        }
        const delSelected = getRows(rows).map((row) => row.id);
        const newSelected = props.selectedUsers.filter((val) => !delSelected.includes(val));
        setSelectMember(newSelected);
    };

    /**
     * チェックボックスを選択したときの処理。
     * OneUser: 一人のみを選択する。
     * EachUser: 複数選択する。
     * @param _
     * @param id
     */
    const handleSelectCheck = (_: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (props.senderSelect) {
            const selectedIndex = props.selectedUsers.indexOf(id);
            let newSelected: string[] = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(props.selectedUsers, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(props.selectedUsers.slice(1));
            } else if (selectedIndex === props.selectedUsers.length - 1) {
                newSelected = newSelected.concat(props.selectedUsers.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(props.selectedUsers.slice(0, selectedIndex), props.selectedUsers.slice(selectedIndex + 1));
            }

            setSelectMember(newSelected);
        } else {
            props.setSelectedOwnerUser(id);
        }
    };

    /**
     * @param id ワークスペースユーザーのID
     * @returns boolean 選択されているかどうか
     */
    const isSelected = (id: string) => {
        if (props.senderSelect) {
            // 送信先ユーザーの選択の場合
            return props.selectedUsers.indexOf(id) !== -1;
        } else {
            // 登録先ユーザーの選択の場合
            return props.selectedOwnerUser === id;
        }
    };

    const isAllSelected = () => {
        // メンバー一覧の準備ができていないときや検索にヒットしていない場合はチェックを外す
        if (getRows(rows).length === 0) return false;

        let result = true;
        getRows(rows).forEach((val) => {
            if (props.selectedUsers.indexOf(val.id) === -1) result = false;
        });
        return result;
    };

    const handleRoleMenu = (e: React.MouseEvent<HTMLInputElement>) => {
        if (isRoleMenuOepn) {
            setRoleMenuAnchor(null);
        } else {
            setRoleMenuAnchor(e.currentTarget);
        }
    };

    const handleSelectRoleMenuCheck = (e: React.MouseEvent<HTMLElement>, role: number) => {
        switch (role) {
            case userRole.systemAdmin:
            case userRole.admin:
            case userRole.externalAdmin:
            case userRole.member:
                // 選択済みのユーザで検索条件に当てはまらないユーザはチェックを外さない
                const searchedId = getRows(rows).map((v) => v.id);
                const newSelectedWithSearch: string[] = props.selectedUsers.filter((selectId) => searchedId.indexOf(selectId) === -1);
                // 検索条件に当てはまるユーザ内で選択したステータスと一致するユーザのみにチェックを付ける
                getRows(rows).forEach((row) => {
                    if (row.role === role) newSelectedWithSearch.push(row.id);
                });
                setSelectMember(newSelectedWithSearch);
                break;
            default:
                const newSelected: string[] = [...props.selectedUsers];
                // 全てのユーザ
                getRows(rows).forEach((row) => {
                    if (props.selectedUsers.indexOf(row.id) === -1) newSelected.push(row.id);
                });
                setSelectMember(newSelected);
        }
        setRoleMenuAnchor(null);
        return;
    };

    const handleAuthStatusMenu = (e: React.MouseEvent<HTMLInputElement>) => {
        if (isAuthMenuOpen) {
            setAuthMenuAnchor(null);
        } else {
            setAuthMenuAnchor(e.currentTarget);
        }
    };

    const handleSelectAuthMenuCheck = (e: React.MouseEvent<HTMLElement>, status: string) => {
        if (status === getAuthStatusMenu(AuthStatus.ALL)) {
            const newSelected: string[] = [...props.selectedUsers];
            getRows(rows).forEach((row) => {
                if (props.selectedUsers.indexOf(row.id) === -1) newSelected.push(row.id);
            });
            setSelectMember(newSelected);
        } else {
            // 選択済みのユーザで検索条件に当てはまらないユーザはチェックを外さない
            const searchedId = getRows(rows).map((v) => v.id);
            const newSelected: string[] = props.selectedUsers.filter((selectId) => searchedId.indexOf(selectId) === -1);
            // 検索条件に当てはまるユーザ内で選択したステータスと一致するユーザのみにチェックを付ける
            getRows(rows).forEach((row) => {
                if (row.authStatus === status) newSelected.push(row.id);
            });
            setSelectMember(newSelected);
        }
        setAuthMenuAnchor(null);
        return;
    };

    const handleAccountStatusMenu = (e: React.MouseEvent<HTMLInputElement>) => {
        if (isAccountMenuOepn) {
            setAccountMenuAnchor(null);
        } else {
            setAccountMenuAnchor(e.currentTarget);
        }
    };

    const handleSelectAccountMenuCheck = (_: React.MouseEvent<HTMLElement>, status: string) => {
        if (status === getAccountStatusMenu(AccountStatus.ALL)) {
            const newSelected: string[] = [...props.selectedUsers];
            getRows(rows).forEach((row) => {
                if (props.selectedUsers.indexOf(row.id) === -1) newSelected.push(row.id);
            });
            setSelectMember(newSelected);
        } else {
            // 選択済みのユーザで検索条件に当てはまらないユーザはチェックを外さない
            const searchedId = getRows(rows).map((v) => v.id);
            const newSelected: string[] = props.selectedUsers.filter((selectId) => searchedId.indexOf(selectId) === -1);
            // 検索条件に当てはまるユーザ内で選択したステータスと一致するユーザのみにチェックを付ける
            getRows(rows).forEach((row) => {
                if (row.accountStatus === status) newSelected.push(row.id);
            });
            setSelectMember(newSelected);
        }
        setAccountMenuAnchor(null);
        return;
    };

    return (
        <Grid className={props.classes.tableWrapper}>
            <Table className={props.classes.table}>
                <TableHead>
                    <TableRow className={props.classes.headerFont}>
                        <TableCell id="column-check" padding="none" className={props.classes.checkCell}>
                            {/* 全選択チェック。登録先選択の場合、表示しない */}
                            {props.senderSelect && (
                                <Checkbox
                                    color="secondary"
                                    inputProps={{
                                        // inputPropsにdata-testidがないので仕方なくts-ignoreを設定
                                        // TODO: MUIのバージョンアップで解決できるのなら「ts-ignore」は不要
                                        // @ts-ignore
                                        'data-testid': isAllSelected() ? 'header-checkbox-checked' : 'header-checkbox-notchecked',
                                    }}
                                    checked={isAllSelected()}
                                    onChange={(e) => handleSelectAllCheck(e)}
                                />
                            )}
                        </TableCell>
                        <TableCell className={props.classes.avatarCell} />
                        <TableCell id="column-mail" padding="none" className={props.classes.mailCell}>
                            <Grid className={props.classes.gridInsideHeaderCell}>
                                {/* 送信先選択の場合連絡先Eメールアドレスを項目名に表示 */}
                                <p className={props.classes.gridInsideHeaderCellText}>
                                    {props.senderSelect
                                        ? locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.withContact)
                                        : locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.mail)}
                                </p>
                                <Search style={{ marginBlock: 'auto' }} />
                                <IconButton
                                    onClick={() => {
                                        setSortByUserName(handleSortByUserName(sortByUserName));
                                    }}
                                >
                                    <UnfoldMore />
                                </IconButton>
                            </Grid>
                        </TableCell>
                        <TableCell id="column-name" padding="none" className={props.classes.roleCell}>
                            {/* 権限 */}
                            {props.senderSelect ? (
                                <>
                                    <Tooltip title={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.role)} placement="bottom-end" data-testid="role-menu-tooltip">
                                        <TableSortLabel active IconComponent={ArrowDropDown} onClick={handleRoleMenu}>
                                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.role)}
                                        </TableSortLabel>
                                    </Tooltip>
                                    <Menu open={isRoleMenuOepn} anchorEl={roleMenuAnchor} onClose={handleRoleMenu}>
                                        {/**handleSelectRoleMenuCheckに渡す第2引数は存在しないRoleにする必要がある。 */}
                                        <MenuItem key={10000} value={10000} onClick={(e) => handleSelectRoleMenuCheck(e, 10000)}>
                                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.role.all)}
                                        </MenuItem>
                                        {getRoleMenuList().map((status) => {
                                            return (
                                                <MenuItem key={status} value={status} onClick={(e) => handleSelectRoleMenuCheck(e, status)}>
                                                    {roleToString(status)}
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </>
                            ) : (
                                <p>{locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.role)}</p>
                            )}
                        </TableCell>
                        <TableCell id="column-auth" padding="none" className={props.classes.authCell}>
                            {/* 認可状況 */}
                            {props.senderSelect ? (
                                <>
                                    <Tooltip title={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.authStatus)} placement="bottom-end" data-testid="auth-menu-tooltip">
                                        <TableSortLabel active IconComponent={ArrowDropDown} onClick={handleAuthStatusMenu}>
                                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.authStatus)}
                                        </TableSortLabel>
                                    </Tooltip>
                                    <Menu open={isAuthMenuOpen} anchorEl={authMenuAnchor} onClose={handleAuthStatusMenu}>
                                        {getAuthStatusMenuList().map((status) => {
                                            return (
                                                <MenuItem key={status} value={status} onClick={(e) => handleSelectAuthMenuCheck(e, status)}>
                                                    {status}
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </>
                            ) : (
                                <p>{locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.authStatus)}</p>
                            )}
                        </TableCell>
                        <TableCell id="column-account" padding="none" className={props.classes.statusCell}>
                            {/* アカウントステータス */}
                            {props.senderSelect ? (
                                <>
                                    <Tooltip
                                        title={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.accountStatus)}
                                        placement="bottom-end"
                                        data-testid="account-menu-tooltip"
                                    >
                                        <TableSortLabel active IconComponent={ArrowDropDown} onClick={handleAccountStatusMenu}>
                                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.accountStatus)}
                                        </TableSortLabel>
                                    </Tooltip>
                                    <Menu open={isAccountMenuOepn} anchorEl={accountMenuAnchor} onClose={handleAccountStatusMenu}>
                                        {getAccountStatusMenuList().map((status) => {
                                            return (
                                                <MenuItem key={status} value={status} onClick={(e) => handleSelectAccountMenuCheck(e, status)}>
                                                    {status}
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </>
                            ) : (
                                <p>{locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.haeder.accountStatus)}</p>
                            )}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getRows(rows)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            return (
                                <TableRow key={row.id} tabIndex={-1} selected={isItemSelected} data-testid="row" className={index % 2 ? props.classes.evenRow : props.classes.oddRow}>
                                    <TableCell padding="none" className={props.classes.checkCell}>
                                        {props.senderSelect ? (
                                            // 送信先ユーザーの選択の場合、チェックボックスを表示
                                            <Checkbox
                                                color="secondary"
                                                checked={isItemSelected}
                                                onChange={(e) => handleSelectCheck(e, row.id)}
                                                inputProps={{
                                                    // @ts-ignore
                                                    'data-testid': `column-checkbox-${row.id}`,
                                                }}
                                            />
                                        ) : (
                                            // 登録先ユーザーの選択の場合、ラジオボタンを表示
                                            <Radio
                                                color="secondary"
                                                checked={isItemSelected}
                                                onChange={(e) => handleSelectCheck(e, row.id)}
                                                inputProps={{
                                                    // @ts-ignore
                                                    'data-testid': `column-radio-${row.id}`,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell className={props.classes.avatarCell}>
                                        <CovasAvatar alt="Workspace" size={40} seed={row.mail} src={row.avatarUrl} />
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.mailCell}>
                                        <Grid container direction="column">
                                            <Grid item style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                                {row.name}
                                            </Grid>
                                            <Grid item style={{ color: 'gray' }}>
                                                {/* 送信先選択時は連絡先Eメールアドレスを追加 */}
                                                {props.senderSelect && row.contactEmail ? row.mail + '/' + row.contactEmail : row.mail}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.roleCell}>
                                        {roleToString(row.role)}
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.authCell}>
                                        {row.authStatus}
                                    </TableCell>
                                    <TableCell padding="none" className={props.classes.statusCell}>
                                        <Grid className={props.classes.gridInsideHeaderCell}>
                                            {row.accountStatus === getAccountStatusMenu(AccountStatus.STOP) ? (
                                                <Block />
                                            ) : row.accountStatus === getAccountStatusMenu(AccountStatus.ACTIVE) ? (
                                                <Check />
                                            ) : (
                                                <></>
                                            )}
                                            <p className={props.classes.gridInsideHeaderCellText}>{row.accountStatus}</p>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getRows(rows).length}
                labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                    <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                )}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.previousPage),
                }}
                nextIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.nextPage),
                }}
                onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
                data-testid="pagenation-root"
            />
        </Grid>
    );
};

export default withStyles(styles)(MemberTable);
