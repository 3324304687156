import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, TextField } from '@material-ui/core';
import { EntryDetailContainer } from './EntryDetailContainer';
import locale from '@/common/utils/locale';

const managerInfoStyles = (theme: Theme) =>
    createStyles({
        root: {
            padding: `0px ${theme.spacing.unit * 3}px`,
            maxWidth: '1050px',
        },
        itemName1: {
            marginLeft: '26px',
            marginBottom: '-20px',
        },
        itemName2: {
            marginLeft: '26px',
            marginBottom: '-40px',
        },

        footer: {
            marginBottom: '60px',
        },
    });

interface ManagerSalesInfoProps extends WithStyles<typeof managerInfoStyles> {}
const ManagerSalesInfo = withStyles(managerInfoStyles, { withTheme: true })((props: ManagerSalesInfoProps) => {
    const container = EntryDetailContainer.useContainer();
    const { classes } = props;

    const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, otsSalesDepartment: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, otsSalesLastName: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, otsSalesFirstName: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeMail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, otsSalesMail: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, otsSalesPhone: event.target.value };
        container.handleChangeEntryDetail(change);
    };

    return (
        <Grid item className={classes.root}>
            <Grid item xs={12}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.managerInformation.salesInfo.departmentName)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.otsSalesDepartment}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangeDepartment}
                    error={container.error.otsSalesDepartment ? true : false}
                    helperText={container.error.otsSalesDepartment}
                    fullWidth
                />
            </Grid>
            <Grid container spacing={32}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={locale.t(locale.keys.applicationList.applicationDetails.managerInformation.salesInfo.lastName)}
                        placeholder=""
                        margin="normal"
                        variant="filled"
                        value={container.entryDetail.otsSalesLastName}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeLastName}
                        error={container.error.otsSalesLastName ? true : false}
                        helperText={container.error.otsSalesLastName}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={locale.t(locale.keys.applicationList.applicationDetails.managerInformation.salesInfo.firstName)}
                        placeholder=""
                        margin="normal"
                        variant="filled"
                        value={container.entryDetail.otsSalesFirstName}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeFirstName}
                        error={container.error.otsSalesFirstName ? true : false}
                        helperText={container.error.otsSalesFirstName}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.managerInformation.salesInfo.email)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.otsSalesMail}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangeMail}
                    error={container.error.otsSalesMail ? true : false}
                    helperText={container.error.otsSalesMail}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.managerInformation.salesInfo.phoneNumber)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.otsSalesPhone}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangePhone}
                    error={container.error.otsSalesPhone ? true : false}
                    helperText={container.error.otsSalesPhone}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
});

interface ManagerEngineerInfoProps extends WithStyles<typeof managerInfoStyles> {}

const ManagerEngineerInfo = withStyles(managerInfoStyles, { withTheme: true })((props: ManagerEngineerInfoProps) => {
    const container = EntryDetailContainer.useContainer();
    const { classes } = props;

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, otsEngineerLastName: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, otsEngineerFirstName: event.target.value };
        container.handleChangeEntryDetail(change);
    };
    const handleChangeMail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const change = { ...container.entryDetail, otsEngineerMail: event.target.value };
        container.handleChangeEntryDetail(change);
    };

    return (
        <Grid item className={classes.root}>
            <Grid container spacing={32}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={locale.t(locale.keys.applicationList.applicationDetails.managerInformation.engineerInfo.lastName)}
                        placeholder=""
                        margin="normal"
                        variant="filled"
                        value={container.entryDetail.otsEngineerLastName}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeLastName}
                        error={container.error.otsEngineerLastName ? true : false}
                        helperText={container.error.otsEngineerLastName}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={locale.t(locale.keys.applicationList.applicationDetails.managerInformation.engineerInfo.firstName)}
                        placeholder=""
                        margin="normal"
                        variant="filled"
                        value={container.entryDetail.otsEngineerFirstName}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={handleChangeFirstName}
                        error={container.error.otsEngineerFirstName ? true : false}
                        helperText={container.error.otsEngineerFirstName}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={locale.t(locale.keys.applicationList.applicationDetails.managerInformation.engineerInfo.email)}
                    placeholder=""
                    margin="normal"
                    variant="filled"
                    value={container.entryDetail.otsEngineerMail}
                    inputProps={{ style: { height: '100%' } }}
                    onChange={handleChangeMail}
                    error={container.error.otsEngineerMail ? true : false}
                    helperText={container.error.otsEngineerMail}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
});

interface ManagerInfoProps extends WithStyles<typeof managerInfoStyles> {}

export const ManagerInfo = withStyles(managerInfoStyles, { withTheme: true })((props: ManagerInfoProps) => {
    const { classes } = props;

    return (
        <Grid item className={classes.footer}>
            <Grid container className={classes.itemName1}>
                <p>担当営業様情報</p>
            </Grid>
            <ManagerSalesInfo />
            <Grid container className={classes.itemName2}>
                <p>担当エンジニア様情報</p>
            </Grid>
            <ManagerEngineerInfo />
        </Grid>
    );
});
