import locale from '@/common/utils/locale';
import { Chip, createStyles, Grid, InputLabel, Paper, WithStyles, withStyles } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import React, { FC, useState } from 'react';
import * as validator from '@/common/utils/validator';
import blue from '@material-ui/core/colors/blue';
import * as schema from '@/bundles/schema/typescript/schema';
import { EmailValidate } from '@/common/constants/email';

const styles = () =>
    createStyles({
        root: {
            width: '100%',
            gap: '16px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    formValue: schema.V1XStoragesOrderCreateRequest;
    externalMails: { error: string; input: string[] };
    setExternalMails: React.Dispatch<React.SetStateAction<{ error: string; input: string[] }>>;
}

/**
 * 認可依頼の外部送信先メールアドレスの入力欄
 */
const InputEmail: FC<Props> = (props) => {
    /**
     * メールアドレスの検証
     * 空文字，数字，パターンマッチの検証を行う
     */
    const validate = (email: string): validator.ValidationOutput | null => {
        const msg = validator.Validate<{ to: string }>(
            { to: email },
            {
                to: {
                    userEmail: {
                        allowEmpty: false,
                        allowPlus: true,
                        message: locale.t(locale.keys.validation.email),
                    },
                    length: {
                        maximum: EmailValidate.MAX_LENGTH,
                        message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
                    },
                },
            },
            (): validator.ValidationOutput => {
                return {
                    to: null,
                };
            },
        );

        return msg;
    };

    /**
     * 入力中のメールアドレスの重複チェック
     * @param inputEmails 新しく入力された全てのメールアドレス
     * @param inputOne 新しく入力されたメールアドレス
     * @param index 元の入力データ配列のインデックス(小文字変換後の配列のインデックスと比較)
     * @return 重複しているとき true それ以外 false
     */
    const DuplicateCheck = (inputEmails: string[], inputOne: string, index: number) => {
        let duplicate = false;
        // 選択済みワークスぺースユーザーと重複しているかチェック
        if (props.formValue.info) {
            props.formValue.info.forEach((value) => {
                if (!value.toUser) {
                    return;
                }
                const lowerInput = inputOne.toLowerCase();
                // ログインIDと比較
                if (lowerInput === value.toUser.invitationEmail.toLowerCase()) {
                    duplicate = true;
                }
                // 連絡先メールアドレスと比較
                if (value.toUser.contactEmail && lowerInput === value.toUser.contactEmail.toLowerCase()) {
                    duplicate = true;
                }
            });
        }
        // 既に入力が確定しているメールアドレスと比較
        props.externalMails.input.forEach((value) => {
            if (inputOne.toLowerCase() === value.toLowerCase()) {
                duplicate = true;
            }
        });
        // 入力データと比較
        const lowerCaseEmails: string[] = [];
        for (const data of inputEmails) {
            // 全部小文字に変換
            lowerCaseEmails.push(data.toLowerCase());
        }
        if (lowerCaseEmails.indexOf(inputOne.toLowerCase()) !== index) {
            duplicate = true;
        }
        return duplicate;
    };

    /**
     * 入力データを「,」で分解しメールアドレスか検証する
     * 入力(Enterを押した時)確定するたびに呼び出される
     * @param chip 入力データ
     */
    const handleAdd = (chip: string) => {
        if (!chip) {
            return;
        }
        // 確定している入力と入力データを合わせて比較
        const inputArray = chip.split(',').filter((elem, index, self) => self.indexOf(elem) === index && elem.length !== 0 && !DuplicateCheck(self, elem, index));

        const newValue = props.externalMails.input.concat(inputArray);
        let e = '';
        // メールアドレスの検証
        newValue.forEach((value, index) => {
            const res = validate(value);
            if (res && res.to) {
                e = res.to.toString();
            }
        });
        props.setExternalMails({ error: e, input: newValue });
    };

    /**
     * 入力データの削除
     * @param data 入力データ
     * @param index 削除するデータのインデックス
     */
    const handleDelete = (data: string, index: number) => {
        const filteredEmails = props.externalMails.input.filter((it) => data !== it);
        let e = '';
        // メールアドレスの検証
        filteredEmails.forEach((value, index) => {
            const res = validate(value);
            if (res && res.to) {
                e = res.to.toString();
            }
        });
        props.setExternalMails({ error: e, input: filteredEmails });
    };
    /**
     * エラー表示用の色を返す
     * @param key メールアドレスのインデックス
     */
    const errorChipColor = (key: number) => {
        // 配列の長さがkeyより小さい場合はエラーがないためfalseを返す
        if (props.externalMails.input.length <= key) {
            return false;
        }
        // メール形式チェック
        const msg = validate(props.externalMails.input[key]);
        return msg && msg.to ? true : false;
    };

    /**
     * メールアドレスの表示
     * @param args
     * @param key
     * @returns
     */
    const customChip = (args: any, key: number) => (
        <Chip
            key={key}
            className={args.className}
            style={{ pointerEvents: args.isDisabled ? 'none' : undefined, backgroundColor: args.isFocused ? blue[300] : errorChipColor(key) ? '#FF9999' : undefined }}
            onClick={args.handleClick}
            onDelete={args.handleDelete}
            label={args.text}
        />
    );

    return (
        <div style={{ marginTop: '20px' }}>
            <Grid item>
                <InputLabel htmlFor="steptwo-registeredUser" id="steptwo-registeredUser-label">
                    {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.mailAddress, { n: String(props.externalMails.input.length) })}
                </InputLabel>
            </Grid>
            <Grid item>
                <Paper className={props.classes.root} style={{ padding: '24px' }}>
                    <ChipInput
                        chipRenderer={customChip}
                        fullWidth={true}
                        fullWidthInput={true}
                        disableUnderline={true}
                        error={props.externalMails.error !== ''}
                        value={props.externalMails.input}
                        onAdd={(chip: string) => handleAdd(chip)}
                        onDelete={(chip: string, index: number) => handleDelete(chip, index)}
                        helperText={props.externalMails.error}
                    />
                </Paper>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(InputEmail);
