import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { NewDeviceContainer } from './NewDeviceContainer';
import NewForm from './NewForm';
import NewDeviceTable from './NewDeviceTable';
import SubHeader from '@/admin/components/common/subheader/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            position: 'relative',
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.manageShareDevice.new));

    return (
        <>
            <SubHeader title={locale.t(locale.keys.manageShareDevice.new)} />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    <NewDeviceContainer.Provider>
                        <NewForm />
                        <NewDeviceTable />
                    </NewDeviceContainer.Provider>
                </div>
            </div>
        </>
    );
};

export default withStyles(styles)(Component);
