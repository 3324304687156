import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as schema from '@/bundles/schema/typescript/schema';
import { csvFormat } from '@/common/constants/csvFormat';
import { EmailValidate } from '@/common/constants/email';

export interface Props {
    user: schema.V1ObjectsWorkspaceuserLarge | undefined;

    errors: validator.ValidationOutput | null;
}

export const NewProps = (): Props => ({
    user: undefined,
    errors: NewValidation(),
});

export interface Form {
    avatarUrl: string;
    email: string;
    name: string;
    phonetic: string;
    admin: string;
    language: string;
    deviceLoginUser: string;
    pin: string;
    contactEmail: string;
}
export const NewForm = (): Form => {
    return {
        avatarUrl: '',
        email: '',
        name: '',
        phonetic: '',
        admin: '',
        language: '',
        deviceLoginUser: '',
        pin: '',
        contactEmail: '',
    };
};
export interface RemoveForm {
    email: string;
}
export const NewRemoveForm = (): RemoveForm => {
    return {
        email: '',
    };
};

export const validations = (): validator.Constraints => {
    return {
        name: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            length: {
                maximum: csvFormat.NAME_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.NAME_MAX_LENGTH }),
            },
        },
        phonetic: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            length: {
                maximum: csvFormat.PHONETIC_NAME_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.PHONETIC_NAME_MAX_LENGTH }),
            },
        },
        email: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            /*email: {
                message: locale.t(locale.keys.validation.email),
            },*/
            userEmail: {
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
        admin: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        language: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        deviceLoginUser: {
            length: {
                maximum: csvFormat.DEVICELOGINUSER_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.DEVICELOGINUSER_MAX_LENGTH }),
            },
            format: {
                pattern: csvFormat.DEVICELOGINUSER,
                flags: 'g', // 正規表現の検索オプション
                message: locale.t(locale.keys.validation.deviceLoginUser),
            },
        },
        pin: {
            pinPolicy: {
                message: locale.t(locale.keys.validation.pinPolicy),
            },
        },
        contactEmail: {
            userEmail: {
                allowEmpty: true,
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        email: null,
        name: null,
        phonetic: null,
        admin: null,
        language: null,
        deviceLoginUser: null,
        pin: null,
        contactEmail: null,
    };
};
