import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { EmailValidate } from '@/common/constants/email';
import { default as consts } from '@/common/constants';
import * as schema from '@/bundles/schema/typescript/schema';
import * as workspace from '@/common/api/workspace/workspace';
import logger from '@/common/utils/logger';
import moment from 'moment';
import { DateFormat } from '@/common/constants/dateFormat';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

// 新規ワークスペース招待
export const inviteNewWorkspace = async (req: schema.V1WorkspaceInviteCreateRequest, auth: string): Promise<schema.V1WorkspaceInviteCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceInviteCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/invite`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
// メンバ招待再送信
export const resendInviteNewMember = async (req: schema.V1WorkspaceuserInviteResendCreateRequest, auth: string): Promise<schema.V1WorkspaceuserInviteResendCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceuserInviteResendCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/invite/resend`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// 自身をワークスペースへ招待するURL取得
export const getInviteMyselfURL = async (req: schema.V1WorkspaceInviteURLCreateRequest, auth: string): Promise<schema.V1WorkspaceInviteURLCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceInviteURLCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/invite/myself`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// ワークスペース招待URL取得
export const getInviteWorkspaceURL = async (req: schema.V1WorkspaceInviteURLCreateRequest, auth: string): Promise<schema.V1WorkspaceInviteURLCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceInviteURLCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/invite/url`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// ログイン中ワークスペース取得
export const findWorkspaceByDisplayId = async (displayId: string, auth: string): Promise<schema.V1WorkspaceShowResponse> => {
    logger.debug('findWorkspaceByDisplayId');
    return workspace.showWorkspace(displayId, auth);
};

// 招待中ワークスペース一覧取得
export const findInvitingWorkspaces = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceIndexResponse> => {
    logger.debug('findInvitingWorkspaces');
    return workspace.findSubWorkspaces(workspaceId, auth);
};

// 招待中ワークスペース一覧取得
export const findInvitingWorkspaceUsers = async (id: string, auth: string): Promise<schema.V1WorkspaceuserIndexResponse> => {
    return workspace.findWorkspaceUser(id, auth);
};

export interface Form {
    invitedUser: string;
    email: string;
    emailCC?: string[];
    displayId: string;
    planBrand: string;
    parentWorkspace: string;
    lock?: boolean;
    validateInit: {
        email: boolean;
        displayId: boolean;
        planBrand: boolean;
    };
    errors: validator.ValidationOutput;
}

export interface OnInviteRow {
    contactEmail: string;
    displayId: string;
    invitedUser: string;
}
export interface Row {
    displayId: string;
    workspaceName: string;
    workspaceLanguage?: string;
    email: string;
    emailCC?: string[];
    id: number;
    name: string;
    mailState?: string;
    invitationDate: string;
    limit: string;
    failure?: true;
    lock?: true;
    workspaceId?: string;
}

export const New = (): Form => ({
    invitedUser: '',
    email: '',
    emailCC: [],
    displayId: '',
    planBrand: '',
    parentWorkspace: '',
    validateInit: {
        email: false,
        displayId: false,
        planBrand: false,
    },

    // Initialize error holder.
    errors: NewValidation(),
});

export const validations = (): validator.Constraints => {
    return {
        planBrand: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        displayId: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            workspaceId: {
                message: locale.t(locale.keys.validation.workspaceIdFormat),
            },
        },
        email: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        invitedUser: null,
        email: null,
        parentWorkspace: null,
    };
};

export const emailValidations = (): validator.Constraints => {
    return {
        email: {
            userEmail: {
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewEmailValidation = (): validator.ValidationOutput => {
    return {
        email: null,
        displayId: null,
        planBrand: null,
    };
};

const userAll = async (wss: schema.V1ObjectsWorkspace[], authorization: string): Promise<schema.V1WorkspaceuserIndexResponse[]> => {
    const result: schema.V1WorkspaceuserIndexResponse[] = [];
    for (let i = 0; i < wss.length; i += 10) {
        const chunk = wss.slice(i, i + 10);
        const requests = chunk.map((ws) => findInvitingWorkspaceUsers(ws.id!, authorization));
        const user = await Promise.all(requests);
        result.push(...user);
    }
    return result;
};

export const createData = async (displayId: string, authorization: string): Promise<Row[]> => {
    const workspaceRes = await findWorkspaceByDisplayId(displayId, authorization);
    const workspaces = await findInvitingWorkspaces(workspaceRes.workspace.id!, authorization);
    const rows: Row[] = [];
    const notVerifiedWs = workspaces.workspaces.filter((ws) => ws.verified !== true);
    const usersList = await userAll(notVerifiedWs, authorization);
    let cnt = 0;
    for (const ws of notVerifiedWs) {
        logger.debug('ws', ws);
        // const users = await findInvitingWorkspaceUsers(ws.id!, authorization);
        const users: schema.V1ObjectsWorkspaceuserLarge[] = usersList != null ? usersList[cnt].users : [];
        const notVerifiedUsers = users.filter((user) => !user.invitationVerified);
        for (const user of notVerifiedUsers) {
            const invitationUser = ws.invitationUser;
            let limitString: string;
            if (user.invitationEndDate !== '') {
                const endDate = new Date(user.invitationEndDate);
                const dayDiff = moment(endDate).diff(moment.now(), 'days');
                const minuteDiff = moment(endDate).diff(moment.now(), 'minutes');
                const isIndefinite = user.invitationEndDate && moment('9999-12-31').isSame(user.invitationEndDate, 'day');
                limitString = isIndefinite
                    ? infinityString()
                    : dayDiff > 0
                    ? // day diff(remain n days)
                      dayLimitString(dayDiff)
                    : minuteDiff > 0
                    ? minuteDiff > 60
                        ? // hour minute diff(remain x hours and y minutes)
                          hourMinuteLimitString(minuteDiff)
                        : // minute diff(remain n minutes)
                          minuteLimitString(minuteDiff)
                    : // expired
                      expiredString();
            } else {
                limitString = '';
            }
            rows.push({
                id: rows.length + 1,
                displayId: ws.displayId,
                mailState: undefined,
                workspaceName: workspace.isInitialValue(ws.displayName) ? '' : ws.displayName,
                workspaceLanguage: ws.language,
                email: user.invitationEmail,
                emailCC: ws.contactCCEmail,
                name: invitationUser != null ? invitationUser.name : '',
                invitationDate: user.invitationDate === '' ? '' : moment(new Date(user.invitationDate)).format(DateFormat.fullDateTimeWithDot),
                limit: limitString,
                workspaceId: ws.id!,
            });
        }
        cnt += 1;
    }
    return rows;
};

export const expiredString = () => locale.t(locale.keys.invitationNewWorkspace.tab3.invitationLimit.expired);
export const infinityString = () => locale.plural('invitationNewWorkspace.tab3.invitationUnit.infinity', 1, { n: 0 });
export const dayLimitString = (dayDiff: number) => {
    return `${locale.t(locale.keys.invitationNewWorkspace.tab3.invitationLimit.remain) + locale.plural('invitationNewWorkspace.tab3.invitationLimit.day', dayDiff, { n: dayDiff })}`;
};
export const hourMinuteLimitString = (minuteDiff: number) => {
    return `${locale.t(locale.keys.invitationNewWorkspace.tab3.invitationLimit.remain) +
        locale.plural('invitationNewWorkspace.tab3.invitationLimit.hour', (minuteDiff / 60) | 0, { n: (minuteDiff / 60) | 0 }) +
        locale.plural('invitationNewWorkspace.tab3.invitationLimit.minute', minuteDiff % 60 | 0, { n: minuteDiff % 60 | 0 })}`;
};
export const minuteLimitString = (minuteDiff: number) => {
    return `${locale.t(locale.keys.invitationNewWorkspace.tab3.invitationLimit.remain) + locale.plural('invitationNewWorkspace.tab3.invitationLimit.minute', minuteDiff, { n: minuteDiff })}`;
};
