import * as React from 'react';
import { Theme, createStyles, WithStyles, Snackbar, withStyles } from '@material-ui/core';
import MessageContent from './MessageContent';
import { StateContainer } from '@/common/components/AppContainerBase';

const useStyles = (theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing.unit,
            maxWidth: 600,
        },
    });
export enum Variants {
    success = 'success',
    icon = 'icon',
    error = 'error',
    info = 'info',
    warning = 'warning',
    iconVariant = 'iconVariant',
    message = 'message',
}
interface Props extends WithStyles<typeof useStyles> {
    theme: Theme;
    isOpen: boolean;
    variant: Variants;
    message: string;
    resultCode?: string;
    autoHideDuration?: number;
    onClose: () => void;
    dependAppContainer?: () => StateContainer;
}

export interface IMessage {
    isOpen: boolean;
    message: string;
    resultCode?: string;
    autoHideDuration?: number;
    variant: Variants;
}
export const IsJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
const CommonMessage: React.FC<Props> = (props: Props) => {
    const appContainer = props.dependAppContainer != null ? props.dependAppContainer() : null;
    const msg =
        appContainer != null ? appContainer.message : { variant: props.variant, message: props.message, isOpen: props.isOpen, resultCode: props.resultCode, autoHideDuration: props.autoHideDuration };
    const handleClose = (event: React.SyntheticEvent<any, Event>, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }

        if (appContainer != null) {
            appContainer.updateMessage({
                autoHideDuration: 3000,
                ...msg,
                isOpen: false,
            });
        }
        props.onClose();
    };
    const handleCloseByContent = () => {
        if (appContainer != null) {
            appContainer.updateMessage({
                autoHideDuration: 3000,
                ...msg,
                isOpen: false,
            });
        }
        props.onClose();
    };
    // https://mui.com/components/snackbars/
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                autoHideDuration={msg.autoHideDuration}
                open={msg.isOpen}
                onClose={handleClose}
            >
                <MessageContent onClose={handleCloseByContent} variant={msg.variant} className={props.classes.margin} message={msg.message} resultCode={msg.resultCode} />
            </Snackbar>
        </div>
    );
};

export default withStyles(useStyles, { withTheme: true })(CommonMessage);
