import { useState } from 'react';
import * as schema from '@/bundles/schema/typescript/schema';
import locale from '@/common/utils/locale';
import userRole from '@/common/constants/userRole';
import * as memberModel from '@/common/api/workspace/mfp/user/user';
import * as memberInviteModel from '@/common/api/workspaceuser/invite/InvitationMember';
import * as groupModel from '@/common/api/groups';

interface Row {
    id: string;
    avatarUrl: string;
    name: string;
}

interface GroupRow extends Row {
    groupMemberCount: number;
}

interface UserRow extends Row {
    email: string;
    phonetic: string;
    role: {
        roleNumber: number;
        roleName: string;
    };
    active: boolean;
}

/**
 * @param input schema.V1ObjectsWorkspacemfpuser[]
 * @description APIレスポンスをテーブル表示用に変換する。ユーザーを登録日順（APIからは登録日順で返ってくるので特にソートする必要なし）で表示する。
 */
const mappingUserRow = (input: schema.V1ObjectsWorkspacemfpuser[]): UserRow[] => {
    const rows: UserRow[] = [];
    input.forEach((user) => {
        rows.push({
            id: user.id,
            avatarUrl: user.avatarUrl,
            name: user.name,
            email: user.email,
            phonetic: user.phoneticName,
            role: {
                roleNumber: user.role,
                roleName: roleToString(user.role),
            },
            active: user.isActive,
        });
    });
    return rows;
};

/**
 * @param input schema.V1ObjectsGroup[]
 * @description APIレスポンスをテーブル表示用に変換する。グループを登録日順（APIからは登録日順で返ってくるので特にソートする必要なし）で表示する。
 */
const mappingGroupRow = (input: schema.V1ObjectsGroup[]): GroupRow[] => {
    const rows: GroupRow[] = [];
    input.forEach((group) => {
        if (group.id !== undefined && group.avatarUrl !== undefined && group.groupName !== undefined) {
            rows.push({
                id: group.id,
                avatarUrl: group.avatarUrl,
                name: group.groupName,
                groupMemberCount: group.memberCount ? group.memberCount : 0,
            });
        }
    });
    return rows;
};

/**
 *
 * @param role number
 * @description 権限番号を権限名に変換する
 */
const roleToString = (role: number): string => {
    switch (role) {
        case userRole.systemAdmin:
            return locale.t(locale.keys.common.role.systemAdmin);
        case userRole.admin:
            return locale.t(locale.keys.common.role.admin);
        case userRole.externalAdmin:
            return locale.t(locale.keys.common.role.externalAdmin);
        case userRole.member:
            return locale.t(locale.keys.common.role.user);
    }
    return '';
};

/**
 * @param rows
 * @param rowPerPage 1ページあたりの表示件数
 * @param offset 表示開始位置(rowPerPage * page)
 * @description ページング処理を行う
 */
const pagingDeviceMemberList = <T extends UserRow | GroupRow>(rows: T[], rowPerPage: number, offset: number): T[] => {
    const pagedRows = rows.slice(offset, offset + rowPerPage);
    return pagedRows;
};

/**
 * @param rows
 * @param sortName schema.V1ObjectsSort
 * @description ソート処理を行う
 */
const sortDeviceMemberList = <T extends UserRow | GroupRow>(rows: T[], sortName: schema.V1ObjectsSort): T[] => {
    const currentRows = [...rows];
    const sortedRows = currentRows.sort((a, b) => {
        if (sortName === schema.V1ObjectsSort.Asc) {
            return a.name.localeCompare(b.name); // localeCompareは大文字小文字を考慮したソート
        } else if (sortName === schema.V1ObjectsSort.Desc) {
            return b.name.localeCompare(a.name);
        } else {
            return 0;
        }
    });
    return sortedRows;
};

/**
 * 複合機利用可能メンバーの表示
 * @param data apiから受け取った利用可能ユーザーのデータ
 * @param rowPerPage 1ページあたりの表示件数
 * @param offset 表示開始位置
 * @param sort schema.V1ObjectsSort
 * @returns テーブルに表示される複合機利用可能メンバー（グループ）リスト
 */
export const getDeviceMember = (data: schema.V1ObjectsWorkspacemfpuser[], rowPerPage: number, offset: number, sort: schema.V1ObjectsSort): UserRow[] => {
    const rows = mappingUserRow(data);
    const sortedRows = sortDeviceMemberList(rows, sort);
    const pagedRows = pagingDeviceMemberList(sortedRows, rowPerPage, offset);
    return pagedRows;
};

/**
 * 複合機利用可能グループの表示
 * @param data apiから受け取った利用可能グループのデータ
 * @param rowPerPage 1ページあたりの表示件数
 * @param offset 表示開始位置
 * @param sort schema.V1ObjectsSort
 * @returns テーブルに表示される複合機利用可能メンバー（グループ）リスト
 */
export const getDeviceGroup = (data: schema.V1ObjectsGroup[], rowPerPage: number, offset: number, sort: schema.V1ObjectsSort): GroupRow[] => {
    const rows = mappingGroupRow(data);
    const sortedRows = sortDeviceMemberList(rows, sort);
    const pagedRows = pagingDeviceMemberList(sortedRows, rowPerPage, offset);
    return pagedRows;
};

export type Suggested = {
    suggestedGroups: Member[];
    suggestedUsers: Member[];
};

/**
 * @param searchText 検索文字列
 * @param wsGroupData schema.V1WorkspaceGroupsIndexResponse
 * @param wsUserData schema.V1WorkspaceuserIndexResponse
 * @param selectedItem Member[] 選択済みのユーザー、グループを除外するために使用する
 * @param groupLimit グループのサジェスト件数の上限
 * @param userLimit ユーザーのサジェスト件数の上限
 * @param ignoreAllowedMember schema.V1WorkspaceMfpsUsersIndexResponse
 * @description 複合機利用可能メンバー（グループ）に登録できるグループ、ユーザーリストを検索する。Everyone > グループ > ユーザーの順に表示。
 */
export const searchDeviceMemberSuggest = (
    searchText: string,
    wsGroupData: schema.V1WorkspaceGroupsIndexResponse,
    wsUserData: schema.V1WorkspaceuserIndexResponse,
    selectedItem?: Member[],
    groupLimit?: number,
    userLimit?: number,
    ignoreAllowedMember?: schema.V1WorkspaceMfpsUsersIndexResponse,
): Suggested => {
    // spliceを使用するため、実引数に影響を与えないために配列のコピーを作成して操作する。
    const groupList = [...wsGroupData.groups];
    const userList = [...wsUserData.users];
    const suggestedGroups: Member[] = [];
    const suggestedUsers: Member[] = [];
    const allowedUser = ignoreAllowedMember ? ignoreAllowedMember.users : [];
    const allowedGroup = ignoreAllowedMember ? ignoreAllowedMember.groups : [];

    // Everyoneグループを配列から取り出して先頭にpush
    if (searchText === '' || schema.V1ObjectsDefaultGroup.Everyone.toLowerCase().includes(searchText.toLowerCase())) {
        // Everyoneグループが選択済みの場合はサジェストから除外する
        let everyoneSelected = false;
        if (selectedItem !== undefined) {
            const everyoneIndex = selectedItem.findIndex((group) => group.name === schema.V1ObjectsDefaultGroup.Everyone);
            if (everyoneIndex !== -1) {
                everyoneSelected = true;
            }
        }

        // グループのサジェスト件数の上限以上の場合はEveryoneグループをサジェストに追加しない
        let isLimit = false;
        if (groupLimit !== undefined) {
            isLimit = suggestedGroups.length >= groupLimit;
        }

        const isAllowed = allowedGroup.findIndex((item) => item.groupName === schema.V1ObjectsDefaultGroup.Everyone) !== -1;
        const everyoneIndex = groupList.findIndex((group) => group.groupName === schema.V1ObjectsDefaultGroup.Everyone);
        // Everyoneグループが存在し、Everyoneグループが選択されていないかつEveryoneが利用可能メンバーに登録されていない場合はサジェストに追加する
        if (everyoneIndex !== -1 && !everyoneSelected && !isLimit && !isAllowed) {
            const everyone = groupList.splice(everyoneIndex, 1);
            // EveryoneはWSに一つしか存在しないため、配列の要素数は1になる。
            suggestedGroups.push({
                id: everyone[0].id,
                avatarUrl: everyone[0].avatarUrl,
                name: everyone[0].groupName,
                isGroup: true,
            });
        }
    }

    groupList.forEach((group) => {
        // グループのサジェスト件数の上限以上の場合はサジェストに追加しない
        if (groupLimit !== undefined && suggestedGroups.length >= groupLimit) return;
        // グループ名に検索文字列が含まれているかつ、選択済みのグループではないかつ、利用可能グループに存在しない場合はサジェストに追加する
        const isSelected = selectedItem !== undefined && selectedItem.findIndex((item) => item.name === group.groupName) !== -1;
        if (isSelected) return;
        const isAllowed = allowedGroup.findIndex((item) => item.groupName === group.groupName) !== -1;
        if (isAllowed) return;
        if (group.groupName.toLowerCase().includes(searchText.toLowerCase())) {
            suggestedGroups.push({
                id: group.id,
                avatarUrl: group.avatarUrl,
                name: group.groupName,
                isGroup: true,
            });
        }
    });
    userList.forEach((user) => {
        if (user.invitationVerified === false) return;
        // ユーザーのサジェスト件数の上限以上の場合はサジェストに追加しない
        if (userLimit !== undefined && suggestedUsers.length >= userLimit) return;
        // ユーザー名またはEメールアドレスに検索文字列が含まれているかつ、選択済みのユーザーではない場合はサジェストに追加する
        const isSelected = selectedItem !== undefined && selectedItem.findIndex((item) => item.name === user.name) !== -1;
        if (isSelected) return;
        const isAllowed = allowedUser.findIndex((item) => item.email === user.invitationEmail) !== -1;
        if (isAllowed) return;
        if (user.invitationEmail.toLowerCase().includes(searchText.toLowerCase())) {
            suggestedUsers.push({
                id: user.id,
                avatarUrl: user.avatarUrl,
                name: user.name,
                email: user.invitationEmail,
                isGroup: false,
            });
        }
    });
    return {
        suggestedGroups,
        suggestedUsers,
    };
};

/**
 * @param allowedGroup 更新予定メンバー
 * @param wsGroupData 登録可能グループか判断するための元データ
 * @param wsUserData 登録可能ユーザーか判断するための元データ
 * @returns エラーメッセージ
 * @description 複合機の更新フォームのバリデーションを行う（登録可能グループか？）
 */
const validateAllowedMember = (
    allowedMember: Member[],
    wsGroupData: schema.V1WorkspaceGroupsIndexResponse,
    wsUserData: schema.V1WorkspaceuserIndexResponse,
    registeredGroup: schema.V1ObjectsGroup[],
    registeredUser: schema.V1ObjectsWorkspacemfpuser[],
) => {
    const groups = allowedMember.filter((member) => member.isGroup);
    const users = allowedMember.filter((member) => !member.isGroup);

    if (groups.length === 0 && users.length === 0) {
        return {
            message: '',
            isError: true,
            shouldUpdateForm: true,
        };
    }

    // 存在しないグループは登録できない
    const notExistGroup = groups.find((group) => {
        const index = wsGroupData.groups.findIndex((groupData) => groupData.id === group.id);
        return index === -1;
    });
    if (notExistGroup !== undefined) {
        return {
            message: locale.t(locale.keys.validation.memberEmail),
            isError: true,
            shouldUpdateForm: false,
        };
    }

    // 重複したグループは登録できない
    const duplicatesGroup = groups.filter((group, index, self) => {
        return self.findIndex((groupData) => groupData.id === group.id) !== index;
    });
    if (duplicatesGroup.length > 0) {
        return {
            message: locale.t(locale.keys.validation.memberEmail),
            isError: true,
            shouldUpdateForm: false,
        };
    }

    // 登録済みグループは登録できない
    const invalidGroup = groups.filter((group) => {
        const index = registeredGroup.findIndex((groupData) => groupData.id === group.id);
        return index !== -1;
    });
    if (invalidGroup.length > 0) {
        return {
            message: locale.t(locale.keys.validation.memberEmail),
            isError: true,
            shouldUpdateForm: false,
        };
    }

    // 存在しないユーザーは登録できない
    const userList = wsUserData.users;
    const notExistUser = users.find((user) => {
        const index = userList.findIndex((userData) => userData.id === user.id);
        return index === -1;
    });
    if (notExistUser !== undefined) {
        return {
            message: locale.t(locale.keys.validation.memberEmail),
            isError: true,
            shouldUpdateForm: false,
        };
    }

    // サインアップ済みのユーザーのみ登録できる
    const notVerifiedUser = users.find((user) => {
        const index = userList.findIndex((userData) => userData.id === user.id);
        return userList[index].invitationVerified === false;
    });
    if (notVerifiedUser !== undefined) {
        return {
            message: locale.t(locale.keys.validation.memberEmail),
            isError: true,
            shouldUpdateForm: false,
        };
    }

    // 重複したユーザーは登録できない
    const duplicatesUser = users.filter((user, index, self) => {
        return self.findIndex((userData) => userData.id === user.id) !== index;
    });
    if (duplicatesUser.length > 0) {
        return {
            message: '',
            isError: true,
            shouldUpdateForm: false,
        };
    }

    // 登録済みユーザーは登録できない
    const invalidUser = users.filter((user) => {
        const index = registeredUser.findIndex((userData) => user.email === userData.email);
        return index !== -1;
    });
    if (invalidUser.length > 0) {
        return {
            message: locale.t(locale.keys.validation.memberEmail),
            isError: true,
            shouldUpdateForm: false,
        };
    }

    return {
        message: '',
        isError: false,
        shouldUpdateForm: true,
    };
};

/**
 * フォームのサジェストに使用する型。
 */
export type Member = {
    email?: string;
    id: string;
    name: string;
    avatarUrl?: string;
    isGroup: boolean;
};

/**
 * 複合機の更新フォームに使用する型
 */
export type UpdateDeviceForm = {
    allowedMember: Member[];
};

/**
 * 複合機更新時のバリデーション結果に使用する型
 */
type UpdateDeviceFormValidateResult = {
    allowedMember: {
        message: string;
        isError: boolean;
        shouldUpdateForm: boolean;
    };
};

/**
 * @param form  更新用に入力したフォーム
 * @param wsGroupData 登録可能グループか判断するための元データ
 * @param wsUserData 登録可能ユーザーか判断するための元データ
 * @returns バリデーション結果
 * @description 複合機の更新フォームのバリデーションを行う
 */
export const validateUpdateDevice = (
    form: UpdateDeviceForm,
    wsGroupData: schema.V1WorkspaceGroupsIndexResponse,
    wsUserData: schema.V1WorkspaceuserIndexResponse,
    registeredGroup: schema.V1ObjectsGroup[],
    registeredUser: schema.V1ObjectsWorkspacemfpuser[],
): UpdateDeviceFormValidateResult => {
    const result: UpdateDeviceFormValidateResult = {
        allowedMember: {
            message: '',
            isError: false,
            shouldUpdateForm: false,
        },
    };

    result.allowedMember = validateAllowedMember(form.allowedMember, wsGroupData, wsUserData, registeredGroup, registeredUser);
    return result;
};

export const useDevice = () => {
    const [deviceMemberData, setDeviceMemberData] = useState<schema.V1WorkspaceMfpsUsersIndexResponse>();
    // サジェストするために必要なオリジナルデータを保持する
    const [suggestData, setSuggestData] = useState<{
        wsGroupData: schema.V1WorkspaceGroupsIndexResponse | null;
        wsUserData: schema.V1WorkspaceuserIndexResponse | null;
    }>({
        wsGroupData: null,
        wsUserData: null,
    });
    // 複合機の設定に関わる条件は全てこのオブジェクトで管理したい
    // (現在は利用可能メンバーだけを保持するが、将来的に他のフォームも保持したい)
    const [form, setForm] = useState<UpdateDeviceForm>({
        allowedMember: [],
    });
    const [validateUpdateResult, setValidateUpdateResult] = useState<UpdateDeviceFormValidateResult | null>(null);
    // 複合機の表示に関わる条件は全てこのオブジェクトで管理したい
    const [viewSettings, setViewSettings] = useState({
        sortByName: schema.V1ObjectsSort.None,
    });

    /**
     * @param workspaceId
     * @param deviceId
     * @param auth
     * @description 複合機詳細画面で使用するために必要な情報をAPIから取得する
     */
    const init = async (workspaceId: string, deviceId: string, auth: string) => {
        const users = await memberInviteModel.findWorkspaceUser(workspaceId, auth);
        const groups = await groupModel.indexGroup(workspaceId, auth);

        setSuggestData({
            wsGroupData: groups,
            wsUserData: users,
        });
        const res = await memberModel.getWorkspaceMfpUser(workspaceId, deviceId, auth, '0', '0', '0', '0');
        setDeviceMemberData(res);
    };

    /**
     * @param workspaceId ワークスペースID
     * @param deviceId 複合機ID
     * @param auth 認証トークン
     * @description 複合機利用可能メンバー（グループ）の一覧を取得する
     */
    const indexDeviceMembers = async (workspaceId: string, deviceId: string, auth: string) => {
        const res = await memberModel.getWorkspaceMfpUser(workspaceId, deviceId, auth, '0', '0', '0', '0');
        setDeviceMemberData(res);
    };

    /**
     * @param workspaceId ワークスペースID
     * @param deviceId 複合機ID
     * @param deviceMemberId 複合機利用可能グループID
     * @param deviceGroupId 複合機利用可能グループID
     * @param auth 認証トークン
     * @description 複合機利用可能メンバー（グループ）を削除する
     */
    const deleteDeviceMember = async (workspaceId: string, deviceId: string, deviceMemberId: string, deviceGroupId: string, auth: string) => {
        await memberModel.destroyMfpUser(deviceId, auth, deviceMemberId, deviceGroupId);
        indexDeviceMembers(workspaceId, deviceId, auth);
    };

    /**
     * @param workspaceId ワークスペースID
     * @param deviceId 複合機ID
     * @param auth 認証トークン
     * @description 複合機利用可能メンバー（グループ）を更新する
     */
    const updateDeviceMember = async (workspaceId: string, deviceId: string, auth: string) => {
        const allowedMember = form.allowedMember;
        const groups: string[] = [];
        const users: string[] = [];
        allowedMember.forEach((member) => {
            if (member.isGroup) return groups.push(member.name);
        });
        allowedMember.forEach((member) => {
            if (!member.isGroup) return users.push(member.email!); // ユーザの場合は必ずemailが存在する
        });

        await memberModel.createWorkspaceMfpUser(workspaceId, deviceId, { groups, users }, auth);
        // フォームを初期化する
        setForm({
            allowedMember: [],
        });
        // バリデーション結果を初期化する(バリデーション結果でボタンの有効無効を制御しているため)
        setValidateUpdateResult(null);
        indexDeviceMembers(workspaceId, deviceId, auth); // 更新後のデータを取得する
    };

    /**
     * @description 名前でソートする
     */
    const changeSortByName = () => {
        switch (viewSettings.sortByName) {
            case schema.V1ObjectsSort.None:
                setViewSettings({
                    ...viewSettings,
                    sortByName: schema.V1ObjectsSort.Asc,
                });
                return;
            case schema.V1ObjectsSort.Asc:
                setViewSettings({
                    ...viewSettings,
                    sortByName: schema.V1ObjectsSort.Desc,
                });
                return;
            case schema.V1ObjectsSort.Desc:
                setViewSettings({
                    ...viewSettings,
                    sortByName: schema.V1ObjectsSort.None,
                });
                return;
        }
    };

    /**
     * @param userEmail 登録したいユーザーのメールアドレス
     * @returns true: 登録可能, false: 登録不可
     * @description ユーザー登録可能かどうかを判定する
     */
    const isAllowedToRegisterUser = (userEmail: string) => {
        if (suggestData.wsUserData === null) return false;
        const found = suggestData.wsUserData.users.some((user) => user.invitationEmail === userEmail);
        return found;
    };

    /**
     * @param groupName 登録したいグループ名
     * @returns true: 登録可能, false: 登録不可
     * @description グループ登録可能かどうかを判定する
     */
    const isAllowedToRegisterGroup = (groupName: string) => {
        if (suggestData.wsGroupData === null) return false;
        const found = suggestData.wsGroupData.groups.some((group) => group.groupName === groupName);
        return found;
    };

    /**
     * @param inputForm UpdateDeviceForm
     * @description 複合機の設定に関するフォーム情報の更新とバリデーション
     */
    const handleUpdateDeviceForm = (inputForm: UpdateDeviceForm) => {
        if (suggestData.wsGroupData === null || suggestData.wsUserData === null) return;
        if (!deviceMemberData) return;
        const validateResult = validateUpdateDevice(inputForm, suggestData.wsGroupData, suggestData.wsUserData, deviceMemberData.groups, deviceMemberData.users);

        let updateForm = {} as UpdateDeviceForm;
        // 複合機利用可能メンバー登録フォームのバリデーションエラーの場合は、フォームのステートを更新しない(従来の仕様に合わせるため)。
        if (validateResult.allowedMember.shouldUpdateForm) {
            updateForm.allowedMember = inputForm.allowedMember;
        } else {
            updateForm.allowedMember = form.allowedMember;
        }
        setForm(updateForm);
        setValidateUpdateResult(validateResult);
    };

    return {
        deviceMemberData,
        indexDeviceMembers,
        updateDeviceMember,
        deleteDeviceMember,
        changeSortByName,
        viewSettings,
        form,
        validateUpdateResult,
        handleUpdateDeviceForm,
        init,
        suggestData,
        isAllowedToRegisterUser,
        isAllowedToRegisterGroup,
    };
};
