import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

// ユーザーエクスポート用のデータの取得
export const createUserCollectiveExport = async (req: schema.V1WorkspaceuserInviteCollectiveExportCreateRequest, auth: string): Promise<schema.V1WorkspaceuserInviteCollectiveExportCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceuserInviteCollectiveExportCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/collective/export`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
