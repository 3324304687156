import { default as React, useEffect, useState } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Typography, Button, TextField } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import ModalHeader from '@/common/components/headers/ModalHeader';
import ImageFileSelector from '@/common/components/ImageFileSelector';
import { MemberContainer, disabledSettingGroupButton } from '../MemberContainer';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: `0px ${theme.spacing.unit * 3}px`,
            height: '100%',
            gap: '12px',
            paddingTop: 24,
        },
        listRoot: {
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        okBtn: {
            color: '#ff7300',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
    });

interface Props extends WithStyles<typeof styles> {
    groupId: string;
    groupName: string;
    avatarUrl: string;
}

const SettingGroup: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const memberContainer = MemberContainer.useContainer();
    const [groupName, setGroupName] = useState(props.groupName);
    const [avatarUrl, setAvatarUrl] = useState('');
    const [validateResult, setValidateResult] = useState({
        groupName: '',
    });

    useEffect(() => {
        memberContainer.group.handleUpdateGroupForm([
            {
                id: props.groupId,
                name: groupName,
                avatarUrl: props.avatarUrl,
                addMembers: [] as string[],
                removeMembers: [] as string[],
                imageDataUri: avatarUrl, // 画像の更新がある場合は、ここに設定したデータをAzure blob storageに格納した後でavatarUrlに設定する。
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupName, avatarUrl]);

    // バリデーション結果の表示
    useEffect(() => {
        if (memberContainer.group.validateUpdateResult && props.groupName !== groupName) {
            setValidateResult({
                groupName: memberContainer.group.validateUpdateResult.groupNameHelperText,
            });
        } else {
            setValidateResult({
                groupName: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberContainer.group.validateUpdateResult]);

    /**
     * @description モーダルを閉じる時の処理をまとめた関数
     */
    const closeModal = () => {
        memberContainer.closeModal();
        // 更新しようとしていた情報を保持するステートを初期化しないと、他画面にステートを引き継いで意図しない更新が発生する。
        memberContainer.group.handleUpdateGroupForm([]);
    };

    return (
        <>
            <ModalHeader
                title={locale.t(locale.keys.memberManagement.modal.settingGroup.title)}
                onClose={() => {
                    closeModal();
                }}
                isLoading={appContainer.loadingState}
            />
            <Grid container direction="column" style={{ height: 'calc(100% - 48px)', justifyContent: 'space-between', flexWrap: 'nowrap' }} className={classes.root}>
                <Grid item container direction="column" style={{ gap: '8px', overflow: 'auto', flexWrap: 'nowrap', flexGrow: 1 }}>
                    <Grid item container direction="column" style={{ gap: '8px' }}>
                        <Typography>{locale.t(locale.keys.memberManagement.modal.settingGroup.groupAvatar.title)}</Typography>
                        <ImageFileSelector
                            seed={appContainer.values.signinWorkspaceObject ? appContainer.values.signinWorkspaceObject.id : ''}
                            uploadWidth={512}
                            uploadHeight={512}
                            mobileWidth={160}
                            mobileHeight={160}
                            pcWidth={160}
                            pcHeight={160}
                            defaultUrl={props.avatarUrl}
                            logoDataUri={avatarUrl}
                            isAvatar={true} // trueでdefaultUrlがsrcの初期値に設定される。
                            onLoaded={(datauri) => {
                                setAvatarUrl(datauri);
                            }}
                            dependAppContainer={AdminAppContainer.useContainer}
                            editable={true}
                            isGroup={true}
                        />
                    </Grid>

                    <Grid item container direction="column" style={{ gap: '8px' }}>
                        <Typography>{locale.t(locale.keys.memberManagement.modal.settingGroup.groupName.title)}</Typography>
                        <TextField
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setGroupName(e.target.value);
                            }}
                            value={groupName}
                            placeholder={locale.t(locale.keys.memberManagement.modal.addGroup.groupName.title)}
                            variant="outlined"
                            margin={'dense'}
                            fullWidth
                            style={{ marginBlock: '4px' }}
                            // グループ名が初期値（自身）の場合はバリデーションを無視する。
                            // でないと、グループロゴのみの更新の場合にバリデーションエラーが出てしまう。
                            error={validateResult.groupName !== '' ? true : false}
                            helperText={validateResult.groupName}
                        />
                    </Grid>
                </Grid>

                <Grid item container direction="row" justify="flex-end" id="emailmodal-footer" style={{ width: '100%' }}>
                    <Button onClick={() => closeModal()} size="small" color="primary">
                        {locale.t(locale.keys.action.cancel)}
                    </Button>
                    <Button
                        onClick={() => {
                            closeModal();
                            memberContainer.updateGroup();
                        }}
                        variant="outlined"
                        className={classes.okBtn}
                        disabled={validateResult.groupName !== '' && disabledSettingGroupButton(memberContainer.group.validateUpdateResult) ? true : false}
                        style={{ border: 'none' }}
                    >
                        {locale.t(locale.keys.action.ok)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(SettingGroup);
