import locale from '@/common/utils/locale';
import { Button, Divider, Fab, Grid, Theme, Typography, withStyles, WithStyles, Card, CardContent, RadioGroup, FormControlLabel, Radio, FormControl, Checkbox } from '@material-ui/core';
import { GoogleMigrationContainer } from './GoogleMigrationContainer';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { default as React, useEffect, useState } from 'react';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import Dropzone from 'react-dropzone';
import { styles } from './styles';
import { MultiLineText } from '@/common/components/messages/MultiLineText';
import * as schema from '@/bundles/schema/typescript/schema';
import { showEllipsisString } from '@/common/utils/webappUtil';
import { csvFormat } from '@/common/constants/csvFormat';
import environment from '@/common/constants/environment';

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const ui = useUI();
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const googleMigrationContainer = GoogleMigrationContainer.useContainer();
    const [displayFileName, setDisplayFileName] = useState<string>(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        setDisplayFileName(showEllipsisString(googleMigrationContainer.csvFileName, csvFormat.CSVFILENAME_MAX));
    }, [googleMigrationContainer.csvFileName]);

    return (
        <div>
            <section className="importPanel">
                <h3 className={classes.heading}>{locale.t(locale.keys.migrationEndpoint.index)}</h3>
                <h4 className={classes.heading}>{locale.t(locale.keys.migrationEndpoint.desc1)}</h4>
                <Grid item xs={12}>
                    <Typography className={classes.heading} variant="h4">
                        {locale.t(locale.keys.migrationEndpoint.desc2)}
                    </Typography>
                </Grid>
                <Dropzone
                    multiple={false}
                    onDrop={async (accepts) => {
                        await googleMigrationContainer.onDrop(accepts);
                    }}
                    accept={'.csv'}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className={classes.dropzone} {...getRootProps()}>
                            <Grid container spacing={8} direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <Typography className={classes.dropzoneInnerText}>{locale.t(locale.keys.migrationEndpoint.csvtitle)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.dropzoneInnerDetailText}>{locale.t(locale.keys.action.or)}</Typography>
                                </Grid>
                                <Grid item>
                                    <input {...getInputProps()} />
                                    <Button variant="outlined" type="button" component="label">
                                        {locale.t(locale.keys.migrationEndpoint.select)}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.dropzoneInnerText}>{displayFileName}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Dropzone>
                <div>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        {locale.t(locale.keys.migrationEndpoint.migration.desc)}
                    </Typography>
                    <FormControl>
                        <RadioGroup aria-label="mode" name="mode" value={googleMigrationContainer.mode} onChange={googleMigrationContainer.handleModeChange}>
                            <FormControlLabel
                                value={schema.MigrateImportMode.Mode1}
                                control={<Radio />}
                                label={<MultiLineText value={locale.t(locale.keys.migrationEndpoint.migration.mode1)} align="left" variant="body1" color="default" />}
                            />
                            <FormControlLabel
                                value={schema.MigrateImportMode.Mode2}
                                control={<Radio />}
                                label={<MultiLineText value={locale.t(locale.keys.migrationEndpoint.migration.mode2)} align="left" variant="body1" color="default" />}
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={googleMigrationContainer.authChecked} onChange={googleMigrationContainer.handleCheckboxChange} />}
                        label={locale.t(locale.keys.migrationEndpoint.authMail)}
                        style={{ position: 'relative', left: '18px' }}
                    />
                </div>
                <Fab
                    className={classes.inviteButton}
                    disabled={googleMigrationContainer.droppedFile[0] == null}
                    variant="extended"
                    onClick={() => googleMigrationContainer.onAnalyze()}
                    size="large"
                    color="secondary"
                    style={{ marginTop: '20px' }}
                >
                    {locale.t(locale.keys.migrationEndpoint.import)}
                </Fab>
            </section>
            <Divider className={classes.divider} />
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h6">{locale.t(locale.keys.migrationEndpoint.title)}</Typography>
                        <Typography>
                            {locale.t(locale.keys.migrationEndpoint.description1)}
                            <a target="admin_manual" href={environment.webapp.admin.descriptionUrl}>
                                {locale.t(locale.keys.migrationEndpoint.descriptionLink)}
                            </a>
                            {locale.t(locale.keys.migrationEndpoint.description2)}
                        </Typography>
                        <MultiLineText value={locale.t(locale.keys.migrationEndpoint.description)} align="left" variant="body1" color="default" />
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(Component);
