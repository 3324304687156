import * as React from 'react';
import { Grid, Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';

import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';

import { RecoverContainer } from './RecoverContainer';
import Recover from './Recover';

const styles = (theme: Theme) =>
    createStyles({
        link: {
            color: '#0d47a1',
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.passwordChange.index));
    return (
        <>
            <RecoverContainer.Provider>
                <Grid container>
                    <Grid item lg={3} />
                    <Grid item xs={12} lg={6}>
                        <Recover />
                    </Grid>
                    <Grid item lg={3} />
                </Grid>
            </RecoverContainer.Provider>
        </>
    );
};

export default withStyles(styles)(Component);
