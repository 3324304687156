import { default as consts } from '@/common/constants';
import * as schema from '@/bundles/schema/typescript/schema';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export type SortType = {
    entryNumber: schema.V1ObjectsSort;
    entryDate: schema.V1ObjectsSort;
};

export type Search = {
    searchEntryNumber: string;
    searchStartDate: Date | null;
    searchEndDate: Date | null;
    searchCompany: string;
    searchName: string;
    searchMail: string;
};

// 申込一覧取得
export const findEntryList = async (
    offset: number,
    sortType: SortType,
    pageAmount: number,
    entryState: schema.V1ObjectsEntryState,
    search: Search,
    auth: string,
): Promise<schema.V1EntryShowListResponse> => {
    const req: schema.V1EntryShowListRequest = {
        clientCompany: search.searchCompany,
        clientName: search.searchName,
        clientMail: search.searchMail,
        entryNumber: search.searchEntryNumber,
        from: search.searchStartDate ? search.searchStartDate.toString() : '',
        to: search.searchEndDate ? search.searchEndDate.toString() : '',
        limit: pageAmount,
        offset: offset,
        sortType: {
            date: sortType.entryDate,
            entryNumber: sortType.entryNumber,
        },
        viewTab: entryState,
    };

    const response = await axiosFactory.post<schema.V1EntryShowListResponse>(`https://${consts.environment.api.hostname}/v1/entry/list`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

// 申込一覧取得（CSVダウンロード用）：offset, limit, viewTabは全件取得のため無効
export const findCsvEntryList = async (offset: number, sortType: SortType, pageAmount: number, entryState: schema.V1ObjectsEntryState, auth: string): Promise<schema.V1ObjectsEntryIndex[]> => {
    const req: schema.V1EntryShowListRequest = {
        limit: pageAmount,
        offset: offset,
        sortType: {
            date: sortType.entryDate,
            entryNumber: sortType.entryNumber,
        },
        viewTab: entryState,
    };

    const response = await axiosFactory.post<schema.V1ObjectsEntryIndex[]>(`https://${consts.environment.api.hostname}/v1/entry/csv`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export interface Row {
    id: number;
    objId: string;
    logoUrl: string;
    entry: schema.V1ObjectsEntryListIndex;
}

export interface ResponseFindEntryDetail extends Entry {
    workspaceId: string;
    workspaceStatus: string;
}

export interface Entry {
    id: string;
    entryNumber: string;
    category: string;
    products: {
        qs: boolean;
        easyFax: boolean;
    };
    cloud: {
        box: boolean;
        googleDrive: boolean;
        googleShareDrive: boolean;
        dropbox: boolean;
        oneDrive: boolean;
        sharePoint: boolean;
    };
    clientCompany: string;
    clientDepartment: string;
    clientFirstName: string;
    clientLastName: string;
    clientMail: string;
    clientPhone: string;
    otsSalesDepartment: string;
    otsSalesFirstName: string;
    otsSalesLastName: string;
    otsSalesMail: string;
    otsSalesPhone: string;
    otsEngineerFirstName: string;
    otsEngineerLastName: string;
    otsEngineerMail: string;
    cancelDate?: string;
    deleteDate?: string;
    entryState: string;
    createdAt: string;
    updatedAt: string;
}
