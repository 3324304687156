import { default as React, useEffect } from 'react';
import { Theme, TextField, Typography, createStyles, withStyles, WithStyles, Grid, Button } from '@material-ui/core';

import { WorkspaceSignupContainer } from './WorkspaceSignupContainer';

import ImageFileSelector from '@/common/components/ImageFileSelector';
import locale from '@/common/utils/locale';
import LanguageSelect from '@/common/components/LanguageSelect';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import * as model from '@/common/api/auth/workspace-signup/WorkspaceSignup';
import { MultiLineText } from '@/common/components/messages/MultiLineText';

const styles = (theme: Theme) =>
    createStyles({
        mt20: {
            marginTop: 20,
        },
        mb20: {
            marginBottom: 20,
        },
        formControl: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
                minWidth: 240,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const container = WorkspaceSignupContainer.useContainer();
    const { classes } = props;
    const lhc = LogoHeaderContainer.useContainer();

    useEffect(() => {
        lhc.setBackFunc(() => {
            container.setScreenState(model.WorkspaceSignupState.OrganizationSetting);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 次へ
    const handleNext = () => {
        container.setScreenState(model.WorkspaceSignupState.PasswordSetting);
    };

    return (
        <>
            <Grid container spacing={32}>
                <Grid item xs={12} className={`${classes.mb20} ${classes.mt20}`}>
                    <Typography align="center" variant="h3" className={classes.mb20} style={{ wordWrap: 'break-word' }}>
                        {locale.t(locale.keys.newWorkspaceSetting.workspace, { workspaceName: container.values.workspaceName, workspaceId: container.values.workspaceId })}
                    </Typography>
                    <Typography align="center">{locale.t(locale.keys.newWorkspaceSetting.adminSetting)}</Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Typography align="left" color="textSecondary">
                        {locale.t(locale.keys.newWorkspaceSetting.avatar)}
                    </Typography>
                    <ImageFileSelector
                        seed={container.values.mail}
                        uploadWidth={512}
                        uploadHeight={512}
                        mobileWidth={160}
                        mobileHeight={160}
                        pcWidth={160}
                        pcHeight={160}
                        defaultUrl={container.values.avatarUrl}
                        logoDataUri={container.avatarDataUri}
                        isAvatar={true}
                        onLoaded={(datauri) => {
                            container.handleChangeAvatarDataUri(datauri);
                        }}
                        dependAppContainer={AdminAppContainer.useContainer}
                        editable={true}
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField
                        className={classes.mb20}
                        fullWidth
                        label={locale.t(locale.keys.common.email)}
                        margin="normal"
                        value={container.values.mail}
                        inputProps={{ readOnly: true, style: { height: '100%' } }}
                    />
                    <TextField
                        className={classes.mb20}
                        variant="filled"
                        fullWidth
                        label={locale.t(locale.keys.common.name)}
                        value={container.values.name}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangeName(event.target.value)}
                        error={container.handleValidateName() !== ''}
                        helperText={container.handleValidateName()}
                    />
                    <TextField
                        className={classes.mb20}
                        variant="filled"
                        fullWidth
                        label={locale.t(locale.keys.common.phonetic)}
                        value={container.values.phoneticName}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangePhoneticName(event.target.value)}
                        error={container.handleValidatePhoneticName() !== ''}
                        helperText={container.handleValidatePhoneticName()}
                    />
                    <TextField
                        className={classes.mb20}
                        fullWidth
                        variant="filled"
                        label={locale.t(locale.keys.common.deviceLoginUser)}
                        value={container.values.deviceLoginUser}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangeDeviceLoginUser(event.target.value)}
                        error={container.handleValidateDeviceLoginUser() !== ''}
                        helperText={container.handleValidateDeviceLoginUser()}
                    />
                    <TextField
                        className={classes.mb20}
                        fullWidth
                        variant="filled"
                        label={locale.t(locale.keys.modalLabel.mfp.pin)}
                        value={container.values.pin}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangePin(event.target.value)}
                        error={container.handleValidatePin() !== ''}
                        helperText={container.handleValidatePin()}
                    />

                    <LanguageSelect
                        disabled={false}
                        value={container.values.userLang}
                        label={locale.t(locale.keys.newWorkspaceSetting.userLanguage)}
                        handleChange={(event) => container.handleChangeUserLang(event.currentTarget.value)}
                        handleValidate={() => container.handleValidateUserLang()}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        label={locale.t(locale.keys.common.contactEmail)}
                        value={container.values.contactEmail}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangeContactEmail(event.target.value)}
                        error={container.handleValidateContactEmail() !== ''}
                        helperText={container.handleValidateContactEmail()}
                    />
                    <MultiLineText value={locale.t(locale.keys.common.contactEmailInfo)} align="left" color="textSecondary" />
                </Grid>
                <Grid item xs={12}>
                    <Button className={classes.button} fullWidth variant="contained" disabled={container.adminSettingNextButtonDisabled()} onClick={() => handleNext()}>
                        {locale.t(locale.keys.action.next)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(Component);
