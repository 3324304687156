import * as schema from '@/bundles/schema/typescript/schema';

/**
 * Enum of available environments.
 */
export enum AvailableEnvironments {
    Develop = 'develop',
    Test = 'test',
    Staging = 'staging',
    Production = 'production',
    Canary = 'canary',
}

const publics = {
    env: process.env.REACT_APP_ENV,
    availableEnvs: AvailableEnvironments,
    tenant: process.env.REACT_APP_TENANT as schema.Brand,
    gitRevision: process.env.REACT_APP_GIT_REVISION,
    target: process.env.REACT_APP_TARGET,
    api: {
        hostname: process.env.REACT_APP_API_HOSTNAME,
        email: {
            noreply: process.env.REACT_APP_API_EMAIL_NOREPLY,
        },
    },
    azure: {
        b2c: {
            directoryName: process.env.REACT_APP_AZURE_AD_DIRECTORY_NAME,
            appId: process.env.REACT_APP_AZURE_AD_APP_ID,
            policy: {
                signin: process.env.REACT_APP_AZURE_AD_POLICY_SIGN_IN,
                signup: process.env.REACT_APP_AZURE_AD_POLICY_SIGN_UP,
            },
            redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI,
            grantType: `authorization_code`,
            scope: `user_impersonation openid`,
        },
        storage: {
            host: process.env.REACT_APP_AZURE_STORAGE_URL,
            url: {
                uploads: process.env.REACT_APP_AZURE_STORAGE_URL_FOR_UPLOADS,
            },
        },
    },
    google: {
        serviceId: process.env.REACT_APP_WEBAPP_ADMIN_GOOGLE_SERVICE_ACCOUNT,
        serviceGoogleTeamDriveId: process.env.REACT_APP_WEBAPP_ADMIN_GOOGLE_TEAMDRIVE_SERVICE_ACCOUNT,
    },
    webapp: {
        admin: {
            hostname: process.env.REACT_APP_WEBAPP_ADMIN_HOSTNAME,
            sentryDsn: process.env.REACT_APP_WEBAPP_ADMIN_SENTRY_DSN,
            url: process.env.REACT_APP_WEBAPP_ADMIN_URL,
            supportUrl: process.env.REACT_APP_WEBAPP_SUPPORT_URL,
            descriptionUrl: process.env.REACT_APP_WEBAPP_DESCRIPTION_URL,
        },
        user: {
            hostname: process.env.REACT_APP_WEBAPP_HOSTNAME,
            sentryDsn: process.env.REACT_APP_WEBAPP_USER_SENTRY_DSN,
            url: process.env.REACT_APP_WEBAPP_USER_URL,
        },
    },
};

export default publics;
