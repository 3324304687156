export const keys = {
    authorizationCode: 'authorizationCode', // 認証トークン
    alreadySigninWorkpsace: 'alreadySigninWorkpsace', // サインイン済みワークスペースリスト
    /**
     * shareAuthはサインインスキップ用に保存されるトークン。ここには認証トークンが保存されており
     * このCookieが設定された状態でサインイン画面に遷移されると、このトークンを使用し
     * て自動的にサインインされる。
     */
    shareAuth: 'shareAuth',
    /**
     * agentSourceObjectは代理ログイン後に元のユーザーに戻るために使用する、元のユーザーの認証トークンと遷移元のURLを保存する。
     */
    agentSourceObject: 'agentSourceObject',
};

/**
 * @param displayId
 * @returns サインイン済みWSのトークンを保存するcookieのキー
 */
export const getHistoryCookieKey = (displayId: string) => {
    return `${displayId}-History`;
};

/**
 * @description 各Cookieのoptionをここで一元管理する
 */
export const options = {
    authorizationCode: {
        expires: 7, // トークンの期限は７日間
    },
};
