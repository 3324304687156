import List from './List';
import Signin from './Signin';
import selectWorkspace from './select-workspace';
import passwordAuth from './password-auth';

export default {
    List,
    Signin,
    selectWorkspace,
    passwordAuth,
};
