import { default as React } from 'react';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, Typography, Grid, Paper, Button } from '@material-ui/core';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import DownshiftMultiple from './DownshiftMultiple';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        heading: {
            fontSize: 14,
            lineHeight: '1.43',
            color: '#666666',
            marginTop: 15,
            marginBottom: 15,
            marginLeft: 20,
        },
        button: {
            minWidth: 96,
            maxWidth: 246,
        },
        everyone: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: 20,
        },
    });

interface Props extends WithStyles<typeof styles> {
    handleClickRegisterMember: () => Promise<void>;
    buttonRegisterDisabled: boolean;
    everyoneChecked: boolean;
    handleChangeEveryoneChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabledEveryoneCheck: boolean;
}
const MemberResister = (props: Props) => {
    return (
        <Paper className={props.classes.root}>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                <Typography gutterBottom variant="h5" component="h4" className={props.classes.heading}>
                    {locale.t(locale.keys.manageShareDevice.emailTitle.index)}
                </Typography>
            </Grid>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                <DownshiftMultiple />
                <Button className={props.classes.button} fullWidth variant="contained" onClick={props.handleClickRegisterMember} disabled={props.buttonRegisterDisabled} data-testid={'button'}>
                    {locale.t(locale.keys.manageShareDevice.registerMember.index)}
                </Button>
            </Grid>
            <Grid className={props.classes.everyone} item xs={12} direction="row" justify="flex-start">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.everyoneChecked}
                            onChange={props.handleChangeEveryoneChecked}
                            inputProps={{
                                // @ts-ignore
                                'data-testid': 'check',
                            }}
                            disabled={props.disabledEveryoneCheck}
                        />
                    }
                    label={locale.t(locale.keys.manageShareDevice.everyoneCheck.index)}
                />
            </Grid>
        </Paper>
    );
};

export default withStyles(styles)(MemberResister);
