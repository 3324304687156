import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
            textAlign: 'center',
        },
        content: {
            textAlign: 'center',
        },
        sendButton: {
            color: 'white',
            borderRadius: 50,
            margin: theme.spacing.unit,
            minWidth: '240px',
        },
        textTitle: {
            width: '100%',
        },
        textBox: {
            width: '100%',
            fontSize: 12,
        },
        formControl: {
            margin: theme.spacing.unit,
            minWidth: '30%',
        },
        sendError: {
            width: '100%',
        },
        sendErrorText: {
            width: '100%',
        },
        pencil: {
            color: 'orange',
        },
        column5: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        tooltipButton: {
            // color: theme.palette.common.white,
            padding: 0,
            marginInline: '4px',
        },
    });
