import { default as React } from 'react';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { State as UI } from '@/common/components/hooks/useUI';
import { Variants } from '@/common/components/messages/CommonMessage';
import { Theme, createStyles, withStyles, WithStyles, Tabs, Tab, Typography } from '@material-ui/core';
import locale from '@/common/utils/locale';
import LanguageSelect from '@/common/components/LanguageSelect';
import * as workspace from '@/common/api/workspace/workspace';
import ImageFileSelector from '@/common/components/ImageFileSelector';
import FormOrganization from '@/admin/components/common/FormOrganization';
import * as schema from '@/bundles/schema/typescript/schema';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { WorkspaceContainer } from '@/admin/components/workspace/WorkspaceContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import logger from '@/common/utils/logger';
import * as uploader from '@/common/utils/uploader';

const styles = (theme: Theme) =>
    createStyles({
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        imageFileSelectorCardContent: {
            textAlign: 'center',
            paddingBottom: 0,
            height: '160px',
        },
        selectLanguage: {
            marginTop: '17px',
        },
        creatableSubWorkspace: {
            marginTop: '10px',
            alignItems: 'center',
            textAlign: 'left',
        },
        typoIcon: {
            color: '#43a047',
            fontSize: 20,
            margin: '5px 5px -5px 5px',
        },
        saveButton: {
            color: 'white',
            margin: theme.spacing.unit,
            minWidth: '240px',
        },

        content: {
            backgroundColor: theme.palette.background.paper,
            textAlign: 'left',
            padding: 0,
        },
        tabTags: {},
        tabGrid: {
            padding: '24px 16px 0 16px',
            backgroundColor: theme.palette.background.paper,
            minHeight: '500px',
            [theme.breakpoints.down('sm')]: {
                minHeight: '700px',
            },
        },
    });
interface Props extends WithStyles<typeof styles> {}

const StyledTabs = withStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '16px 0',
            minHeight: 32,
            display: 'inline-block',
            borderBottom: '2px solid #ff7300',
        },
        indicator: {
            display: 'none',
        },
    }),
)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 32,
            minWidth: 107,
            padding: 0,
            margin: '0 5px',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '&:hover': {
                opacity: 1,
            },
            '&$selected': {
                color: 'white',
                backgroundColor: theme.palette.secondary.main,
            },
            '&$selected :active': {
                color: 'white',
            },
            '&:active': {
                color: theme.palette.secondary.main,
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab {...props} />);

interface StyledTabProps {
    label: string;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const container = WorkspaceContainer.useContainer();

    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const onSave = async () => {
        logger.debug('onSave');
        container.updateUIStatus(UI.Saving);
        try {
            let req: schema.V1WorkspaceUpdateRequest = {
                displayId: container.form.displayId,
                displayName: container.form.displayName,
                enableSubWorkspace: container.form.enableSubWorkspace!,
                language: container.form.language == null ? schema.Language.En : container.form.language!,
                logoUrl: container.form.logoUrl != null ? container.form.logoUrl! : '',
                contactEmail: container.form.contactEmail,
                organizationDetail: container.organization,
            };
            // ロゴ更新
            if (container.logoDataUri != null && container.logoDataUri !== '') {
                const workspaceRes = await uploader.Upload(`${container.form.objId}.png`, schema.BlobType.WorkspaceLogo, appContainer.values.authorizationCode, container.logoDataUri);
                req = {
                    ...req,
                    logoUrl: workspaceRes.publicUrl,
                };
            }
            const res = await workspace.updateWorkspace(container.form.objId, appContainer.values.authorizationCode, req);
            container.setForm({
                ...container.form,
                logoUrl: req.logoUrl,
            });
            logger.debug(res);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: `${locale.t(locale.keys.action.saved)}`,
                variant: Variants.success,
            });

            // 言語設定
            locale.set(appContainer.values.signinWorkspaceUserObject.language, res.language);

            container.updateUIStatus(UI.Loaded);
            // ヘッダのワークスペース情報を切り替える。反映が遅い場合は updateWorkspace の値を設定する
            const showRes = await workspace.showWorkspace(container.form.displayId, appContainer.values.authorizationCode);
            appContainer.setValues({
                ...appContainer.values,
                signinWorkspaceObject: showRes.workspace,
            });
            container.setForm({
                ...container.form,
                logoUrl: showRes.workspace.logoUrl,
            });
        } catch (e) {
            container.updateUIStatus(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const { classes } = props;
    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <StyledTabs value={tabIndex} onChange={handleTabChange} className={classes.tabTags}>
                        <StyledTab label={locale.t(locale.keys.workspaceSetting.index)} />
                        <StyledTab label={locale.t(locale.keys.organizationInfo.index)} />
                    </StyledTabs>
                    {tabIndex === 0 && (
                        // プロファイル
                        <Grid container spacing={16} className={classes.tabGrid}>
                            <Grid item xs={12} sm={5} style={{ paddingBottom: 0 }}>
                                <Typography align="left" color="textSecondary">
                                    {locale.t(locale.keys.workspaceSetting.image)}
                                </Typography>
                                <CardContent className={classes.imageFileSelectorCardContent}>
                                    <ImageFileSelector
                                        seed={appContainer.values.signinWorkspaceObject ? appContainer.values.signinWorkspaceObject.id : ''}
                                        uploadWidth={512}
                                        uploadHeight={512}
                                        mobileWidth={160}
                                        mobileHeight={160}
                                        pcWidth={160}
                                        pcHeight={160}
                                        defaultUrl={container.form.logoUrl != null ? container.form.logoUrl! : ''}
                                        logoDataUri={container.logoDataUri}
                                        isAvatar={true}
                                        onLoaded={container.fileChanged}
                                        dependAppContainer={AdminAppContainer.useContainer}
                                        editable={true}
                                    />
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} sm={7} style={{ paddingBottom: 0 }}>
                                <CardContent style={{ paddingBottom: 0 }}>
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.workspaceSetting.workspaceDisplayId.index)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={container.form.displayId}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
                                            container.setForm({
                                                ...container.form,
                                                validateInit: {
                                                    displayId: true,
                                                    displayName: container.form.validateInit.displayName,
                                                    language: container.form.validateInit.language,
                                                    contactEmail: container.form.validateInit.contactEmail,
                                                },
                                                displayId: event.currentTarget.value,
                                            });
                                        }}
                                        error={container.handleValidateDispId() !== ''}
                                        helperText={container.handleValidateDispId()}
                                    />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.workspaceSetting.workspaceDisplayName.index)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={container.form.displayName}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
                                            container.setForm({
                                                ...container.form,
                                                validateInit: {
                                                    displayId: container.form.validateInit.displayId,
                                                    displayName: true,
                                                    language: container.form.validateInit.language,
                                                    contactEmail: container.form.validateInit.contactEmail,
                                                },
                                                displayName: event.currentTarget.value,
                                            });
                                        }}
                                        error={container.handleValidateDispName() !== ''}
                                        helperText={container.handleValidateDispName()}
                                    />
                                    <div className={classes.selectLanguage}>
                                        <LanguageSelect
                                            value={container.form.language === schema.Language.Ja ? locale.Language.Japanese : locale.Language.English}
                                            label={locale.t(locale.keys.newWorkspaceSetting.workspaceLanguage)}
                                            handleChange={container.handleChange}
                                            handleValidate={container.handleValidateLanguage}
                                            disabled={false}
                                        />
                                    </div>

                                    {/* {container.form.enableSubWorkspace ? (
                                        <Typography className={classes.creatableSubWorkspace}>
                                            <CheckCircle className={classes.typoIcon} />
                                            {locale.t(locale.keys.subWorkspaceDetail.settingPanel.enableSubWorkspace.index)}
                                        </Typography>
                                    ) : (
                                        <br />
                                    )} */}

                                    <br />
                                    <br />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.subWorkspaceDetail.settingPanel.contactEmail.index)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={container.form.contactEmail}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
                                            container.setForm({
                                                ...container.form,
                                                validateInit: {
                                                    displayId: container.form.validateInit.displayId,
                                                    displayName: container.form.validateInit.displayName,
                                                    language: container.form.validateInit.language,
                                                    contactEmail: true,
                                                },
                                                contactEmail: event.currentTarget.value,
                                            });
                                        }}
                                        error={container.handleValidateContactEmail() !== ''}
                                        helperText={container.handleValidateContactEmail()}
                                    />
                                </CardContent>
                            </Grid>
                        </Grid>
                    )}
                    {tabIndex === 1 && (
                        // 組織情報
                        <FormOrganization organization={container.organization} setOrganization={container.setOrganization} disabled={false} />
                    )}
                </CardContent>
                <CardContent>
                    <Grid container spacing={32}>
                        <Grid item xs={12} style={{ width: '100%' }}>
                            <Fab variant="extended" className={classes.saveButton} size="large" color="secondary" disabled={container.buttonDisabled()} onClick={() => onSave()}>
                                {locale.t(locale.keys.action.save)}
                            </Fab>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default withStyles(styles)(Component);
