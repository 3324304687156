import { default as React } from 'react';
import { Theme, createStyles, WithStyles, withStyles, Typography, Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { AnalyticsContainer } from '@/admin/components/analytics/AnalyticsContainer';
import ModalOuter from '@/admin/components/common/ModalOuter';
import ToTable from './ToTable';
import locale from '@/common/utils/locale';

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const container = AnalyticsContainer.useContainer();
    const [isOpenModal, setOpenModal] = React.useState(false);

    return (
        <>
            <Grid item container justify="flex-start" direction="column" style={{ width: '100%', gap: '8px' }}>
                <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#333', textAlign: 'left' }}>{locale.t(locale.keys.analytics.usageLog.subTitle)}</Typography>
                <Button variant="outlined" color="secondary" style={{ fontSize: 12, width: 220, height: 30 }} onClick={() => setOpenModal(true)}>
                    {locale.t(locale.keys.analytics.usageLog.downloadUsageLog)}
                </Button>
                <Grid item container justify="flex-start">
                    <FormControlLabel
                        control={<Checkbox checked={container.analytics.isNotification} onChange={() => container.handleIsNotificationReport()} name="checkedB" color="secondary" />}
                        label={locale.t(locale.keys.analytics.usageReport.enable)}
                    />
                </Grid>
                {container.analytics.isNotification && (
                    <Grid item container justify="flex-start">
                        <ToTable />
                    </Grid>
                )}
            </Grid>
            <ModalOuter
                title={locale.t(locale.keys.analytics.usageLog.downloadUsageLog)}
                modalOpen={isOpenModal}
                modalCloseFunc={() => setOpenModal(false)}
                headerStyle={{ backgroundColor: 'white', color: 'black' }}
            />
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
