import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        dropzone: {
            backgroundColor: '#eeeeee',
            border: '2px dashed #bdbdbd',
            borderRadius: 6,
            padding: 16,
            marginBlock: '16px',
            maxWidth: '100%',
            minWidth: '100%',
            cursor: 'pointer',
        },
        dropButton: {
            backgroundColor: 'white',
        },
        dropzoneInnerText: {
            paddingTop: '5px',
            textAlign: 'center',
            margin: '0 auto',
            fontSize: '15px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#666666',
        },
        dropzoneInnerDetailText: {
            paddingTop: '10px',
            textAlign: 'center',
            margin: '0 auto',
            fontSize: '10px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#666666',
        },
        inviteButton: {
            color: 'white',
            minWidth: 240,
        },
        divider: {
            margin: '16px 0 16px 0',
        },
        heading: {
            textAlign: 'left',
            marginLeft: '20px',
        },
        paperContainer: {
            overflowX: 'auto',
            maxHeight: '400px',
        },
        tableContainer: {
            display: 'block',
            width: '100%',
            overflowX: 'auto',
        },
        tableHeader: {
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
        },
        tableRowEven: {
            backgroundColor: '#fafafa',
        },
        tableRowOdd: {
            backgroundColor: '#ffffff',
        },
        tableCell: {
            padding: '12px',
            height: '100px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        },
        headerCell: {
            height: '230px',
            whiteSpace: 'nowrap',
            writingMode: 'vertical-rl',
            textAlign: 'center',
            color: '#000000',
            fontWeight: 'bold',
        },
        card: {
            maxWidth: '100%',
            minWidth: '100%',
            textAlign: 'left',
            marginTop: '8px',
            marginBottom: '16px',
        },
    });
