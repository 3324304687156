import querystring from 'querystring';
import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getWorkspaceMfpUser = async (
    wId: string,
    wMfpId: string,
    auth: string,
    offset?: string,
    limit?: string,
    groupOffset?: string,
    groupLimit?: string,
): Promise<schema.V1WorkspaceMfpsUsersIndexResponse> => {
    const query = querystring.stringify({
        offset,
        limit,
        groupOffset,
        groupLimit,
    });

    const response = await axiosFactory.get<schema.V1WorkspaceMfpsUsersIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps/${wMfpId}/users?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const createWorkspaceMfpUser = async (
    wId: string,
    wMfpId: string,
    createWorkspaceMfpUserDto: schema.V1WorkspaceMfpsUsersCreateRequest,
    auth: string,
): Promise<schema.V1WorkspaceMfpsUsersCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceMfpsUsersCreateResponse>(
        `https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps/${wMfpId}/users`,
        createWorkspaceMfpUserDto,
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return response.data;
};

export const destroyMfpUser = async (mfpId: string, auth: string, mfpUserId?: string, groupId?: string): Promise<schema.V1MfpUsersStatusShowResponse> => {
    const query = querystring.stringify({
        mfpUserId,
        groupId,
    });

    const response = await axiosFactory.delete<schema.V1MfpUsersStatusShowResponse>(`https://${consts.environment.api.hostname}/v1/mfp/users/${mfpId}?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
