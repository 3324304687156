import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import useTitle from '@/common/components/hooks/useTitle';
import { EntryDetailContainer } from './EntryDetailContainer';
import Detail from './Detail';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import routes from '@/admin/constants/routes';
import locale from '@/common/utils/locale';

const styles = (theme: Theme) => createStyles({});
interface Props extends RouteComponentProps<{ id: string }>, WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.applicationList.applicationDetails.index));
    const { params } = props.match;

    return (
        <>
            <EntryDetailContainer.Provider initialState={params.id}>
                <SubHeader title={locale.t(locale.keys.applicationList.applicationDetails.index)} isBack={routes.entry.index} />
                <Detail displayId={params.id} />
            </EntryDetailContainer.Provider>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
