import { useState } from 'react';

/**
 * State enum represents component's common UI state.
 * A typical UI lifecycle of REST-based API client would be:
 *
 * 1. App sends GET request to API to fetch response (Loading)
 * 2. App renders component with the given response (Loaded)
 * 3. App sends POST/PUT/DELETE request, then wait for the result (Saving)
 * 4. Some error happened (Error)
 *
 * It is important to note that external APIs are not always stable.
 * Their internet connection may be slow or down, therefore the app must give a
 * reasonable UI feedbacks to user.
 *
 * See more:
 * https://developers.google.com/web/fundamentals/performance/poor-connectivity/
 */
export enum State {
    // Must be string enum, for testing.
    Loading = 'loading',
    Loaded = 'loaded',
    Saving = 'saving',
    Error = 'error',
}

export const isLoading = (given: State) => given === State.Loading;
export const isLoaded = (given: State) => given === State.Loaded;
export const isSaving = (given: State) => given === State.Saving;
export const isError = (given: State) => given === State.Error;

/**
 * useUI is a React Hook to switch a common UI state.
 * See State for more information.
 *
 * @param given defaults to State.Loading
 */
const useUI = (given = State.Loading) => {
    const [current, setCurrent] = useState(given);

    const update = (to: State) => setCurrent(to);

    return { current, update };
};

export default useUI;
