import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import * as cookies from '@/common/utils/browser/cookies';
import { keys } from '@/common/utils/authManagement/cookieConstants';
import * as auth from '@/common/utils/authManagement/authUtil';
import { parse } from 'query-string';
import routes from '@/user/constants/routes';
import * as cookieInterfaces from '@/common/utils/authManagement/cookieInterfaces';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

interface AgentSourceObject {
    // 代理ログイン後に元のユーザに戻るため、トークンとURLを保存しておく
    authorizationCode: string;
    transitionSourceURL: string;
}

// (管理者サイト用)agentSignIn関数で代理ログインを行う
export const agentSignIn = async (authorizationCode: string, diplayId: string, targetEmail: string, targetMemberId: string, transitionSourceURL: string) => {
    //代理ログイン先のトークン（期限は1時間）を取得
    const result = await findAgent(authorizationCode, {
        targetEmail: targetEmail,
        targetMemberId: targetMemberId,
    });

    setAgentCookie(result.id, authorizationCode, result.user.id, transitionSourceURL);

    // メンバーサイトでログインスキップをするためには、/auth/loginに遷移する必要がある
    // agentCookieObserverUser関数で/auth以下の画面に遷移できないようにしたいので、判別するためのクエリパラメータを付ける
    document.location = `https://${consts.environment.webapp.user.hostname}${routes.auth.login.index}${diplayId}?agent=true`;
};

// (管理者サイト用)
const findAgent = async (authorizationCode: string, target: schema.V1AuthAgentCreateRequest): Promise<schema.V1UserCreateResponse> => {
    const response = await axiosFactory.post<schema.V1UserCreateResponse>(`https://${consts.environment.api.hostname}/v1/auth/agent`, target, {
        headers: { Authorization: `${AvailableMethod.Bearer}${authorizationCode}` },
    });
    return response.data;
};

// (管理者サイト用)
export const setAgentCookie = (targetAuthorizationCode: string, agentAuthorizationCode: string, targetWorkspaceUserId: string, transitionSourceURL: string) => {
    cookies.set<AgentSourceObject>(keys.agentSourceObject, { authorizationCode: agentAuthorizationCode, transitionSourceURL: transitionSourceURL });
    cookies.set<string>(keys.authorizationCode, targetAuthorizationCode);

    // メンバーサイトでログインスキップするためには、/auth/loginでcookieに保存されたshareAuthを使用する必要がある
    auth.setShareAuth({
        token: targetAuthorizationCode,
        workspaceUserId: targetWorkspaceUserId,
    });
};

// (メンバーサイト用)メンバーサイトから管理者サイトに戻るために使用する
export const resetAgentCookie = () => {
    const agentSourceObject = cookies.get<AgentSourceObject>(keys.agentSourceObject);
    // 期限切れて参照できない場合は、エラーメッセージを出してメンバーログイン画面に戻る
    // 代理ログイン中にagentSouceObjectのCookieがなくなることはないのでレアケース
    // devtoolとかで操作しない限りは通らない
    if (!agentSourceObject) throw Error('Invalid link');

    cookies.set<string>(keys.authorizationCode, agentSourceObject.authorizationCode);

    const parts = document.location.hostname.split('.');
    parts.shift();
    const upperleveldomain = parts.join('.');

    cookies.remove(keys.agentSourceObject, {
        domain: upperleveldomain,
    });

    document.location = agentSourceObject.transitionSourceURL;
};

// (メンバーサイト用)
export const isAgent = () => {
    return cookies.get<AgentSourceObject>(keys.agentSourceObject) ? true : false;
};

// (管理者サイト用)agentSouceObjectのCookieを保存したまま管理画面に遷移することはないので、Observerで監視する
export const agentCookieObserverAdmin = () => {
    const agentSourceObject = cookies.get<AgentSourceObject>(keys.agentSourceObject);

    if (agentSourceObject) {
        cookies.set<string>(keys.authorizationCode, agentSourceObject.authorizationCode);

        const parts = document.location.hostname.split('.');
        parts.shift();
        const upperleveldomain = parts.join('.');

        cookies.remove(keys.agentSourceObject, {
            domain: upperleveldomain,
        });
    }
};

// (メンバーサイト用)代理ログイン時に遷移できる画面の絞り込み
export const isInvalidAgentLocation = (
    agentSourceObject: AgentSourceObject | null,
    isAgentQuery: string | string[] | null | undefined,
    pathname: string,
    shareAuth: cookieInterfaces.ShareAuthInfo | null,
) => {
    // 代理ログインによるユーザ以外の遷移の場合は/auth以下の遷移を許可する
    if (!agentSourceObject) {
        return false;
    } else {
        // 代理ログインをした後は/auth以下の遷移を許可しない（メンバーサイトルートにリダイレクトされる）
        if (pathname.startsWith(routes.auth.index)) {
            // 代理ログイン処理(/auth/login/:displayId?agent=true)の為の遷移のみ許可する
            if (isAgentQuery && shareAuth) return false;
            return true;
        }
    }
};

// (メンバーサイト用)メンバー画面は、/auth以下の画面に遷移しないように監視する
export const useObserverUserContainer = () => {
    const [inInvalidPath, setInInvalidPath] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const agentSourceObject = cookies.get<AgentSourceObject>(keys.agentSourceObject);
        const isAgentQuery = parse(window.location.search).agent;
        const inInvalidPath = isInvalidAgentLocation(agentSourceObject, isAgentQuery, document.location.pathname, auth.getShareAuth());
        if (inInvalidPath) {
            setInInvalidPath(true);
        } else {
            setInInvalidPath(false);
        }
    }, [location]);

    return inInvalidPath;
};
