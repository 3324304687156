import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import locale from '@/common/utils/locale';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import { Variants } from '@/common/components/messages/CommonMessage';
import { useGroup, mappingRow, FormValidateResult, UpdateGroupFormValidateResult } from '../../../common/models/groups/useGroup';
import { useMember } from '@/common/models/member/useMember';
import * as schema from '@/bundles/schema/typescript/schema';

/**
 * @param validateResult バリデーション結果
 * @returns boolean
 * @description
 * バリデーション結果をもとに、グループ作成ボタンのdisabledを返す
 */
export const diabledCreateButton = (validateResult: FormValidateResult | null): boolean => {
    if (validateResult === null) {
        return true;
    }
    if (validateResult.groupNameHelperText !== '') {
        return true;
    }
    return false;
};

/**
 * @description バリデーション結果からグループメンバー追加ボタンのdisabledを返す
 */
export const diabledAddMemberButton = (validateResult: UpdateGroupFormValidateResult | null): boolean => {
    if (validateResult === null) {
        return true;
    }
    if (validateResult.selectedGroupHelperText !== '') {
        return true;
    }
    return false;
};

/**
 * @description バリデーション結果からグループ設定更新ボタンのdisabledを返す
 */
export const disabledSettingGroupButton = (validateResult: UpdateGroupFormValidateResult | null): boolean => {
    if (validateResult === null) {
        return true;
    }
    if (validateResult.groupNameHelperText !== '') {
        return true;
    }
    return false;
};

type Modal = {
    addGroupModal: boolean;
    addGroupMemberModal: boolean;
    settingGroupModal: {
        open: boolean;
        groupId: string;
        groupName: string;
        avatarUrl: string;
    };
    deleteGroupModal: {
        open: boolean;
        groupId: string;
        groupName: string;
        memberCount: number;
        avatarUrl?: string;
    };
    deleteGroupMemberModal: boolean;
    deleteWorkspaceUserModal: boolean;
};

// 各モーダルの状態と引数を渡す。
export const initialModalState: Modal = {
    addGroupModal: false,
    addGroupMemberModal: false,
    settingGroupModal: {
        open: false,
        groupId: '',
        groupName: '',
        avatarUrl: '',
    },
    deleteGroupModal: {
        open: false,
        groupId: '',
        groupName: '',
        memberCount: 0,
    },
    deleteGroupMemberModal: false,
    deleteWorkspaceUserModal: false,
};

const useMemberContainer = () => {
    const appContainer = AdminAppContainer.useContainer();
    const [modalTarget, setModalTarget] = useState<Modal>(initialModalState);
    const ui = useUI();
    const group = useGroup();
    const member = useMember();

    // 初回ロード
    // グループリスト取得、バリデーション用のWSメンバーリスト取得（未サインアップメンバーも含む）
    useEffect(() => {
        // グループリスト取得
        (async () => {
            try {
                ui.update(UI.Loading);
                const val = await group.indexGroupData(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                group.setDefaultGroupRows(mappingRow(val));
                member.setMemberListByGroup(val.groups);

                // WSメンバー（未サインアップメンバーも含む）取得（フォームのバリデーション用）
                // ローディングステータスは変更しない
                const members = await member.indexWorkspaceuser(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                member.setAllMemberWithNotVerified(members);

                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 選択グループ変更時にフォームをリセットする
    useEffect(() => {
        member.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group.selectedGroup]);

    /**
     * @param userList ユーザーIDの配列
     * @description ワークスペースユーザー削除
     */
    const deleteWorkspaceusers = async (userList: string[]) => {
        try {
            ui.update(UI.Loading);
            if (!appContainer.values.signinWorkspaceObject.id) {
                throw new Error(locale.t(locale.keys.error.unknown));
            }
            await member.deleteWorkspaceusers(userList, appContainer.values.authorizationCode);
            const val = await group.indexGroupData(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            group.setDefaultGroupRows(mappingRow(val));
            member.setMemberListByGroup(val.groups);
            // メンバーの選択状態をリセットする
            member.setForm({
                ...member.form,
                selectedMemberIdList: [] as string[],
            });
            ui.update(UI.Loaded);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.deleted),
                variant: Variants.success,
            });
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @description グループ作成
     */
    const createGroup = async () => {
        // グループ作成 && グループ一覧取得
        try {
            ui.update(UI.Loading);
            if (!appContainer.values.signinWorkspaceObject.id) {
                throw new Error(locale.t(locale.keys.error.unknown));
            }
            await group.createGroupData(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            const val = await group.indexGroupData(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            group.setDefaultGroupRows(mappingRow(val));
            member.setMemberListByGroup(val.groups);
            ui.update(UI.Loaded);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.created),
                variant: Variants.success,
            });
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @param groupId
     * @description グループ削除
     */
    const deleteGroup = async (groupId: string) => {
        try {
            ui.update(UI.Loading);
            if (!appContainer.values.signinWorkspaceObject.id) {
                throw new Error(locale.t(locale.keys.error.unknown));
            }
            const deletedGroup = await group.deleteGroupData(groupId, appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            const val = await group.indexGroupData(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            group.setDefaultGroupRows(mappingRow(val));
            member.setMemberListByGroup(val.groups);

            // 選択中のGroupを削除したときにEveryoneグループを選択するようにする
            if (deletedGroup.id === group.selectedGroup.groupId) {
                const everyone = val.groups.find((v) => v.groupName === schema.V1ObjectsDefaultGroup.Everyone);
                // Everyoneグループが存在しないことは基本的にない。
                if (!everyone) {
                    throw new Error(locale.t(locale.keys.error.unknown));
                }
                group.setSelectedGroup({
                    groupId: everyone.id,
                    groupName: everyone.groupName,
                    avatarUrl: everyone.avatarUrl ? everyone.avatarUrl : '',
                });
            }

            ui.update(UI.Loaded);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.deleted),
                variant: Variants.success,
            });
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     *
     * @param workspaceId ワークスペースID
     * @description グループ更新
     */
    const updateGroup = async () => {
        try {
            ui.update(UI.Loading);
            if (!appContainer.values.signinWorkspaceObject.id) {
                throw new Error(locale.t(locale.keys.error.unknown));
            }
            await group.updateGroupData(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            const val = await group.indexGroupData(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            // グループリスト、メンバーリスト、選択中のグループのステートを更新
            group.setDefaultGroupRows(mappingRow(val));
            member.setMemberListByGroup(val.groups);
            const updateSelectedGroup = val.groups.find((v) => v.id === group.selectedGroup.groupId);
            // 更新時に更新したグループが存在しなくなることはない
            if (!updateSelectedGroup) {
                throw new Error(locale.t(locale.keys.error.unknown));
            }
            group.setSelectedGroup({
                groupId: updateSelectedGroup.id,
                groupName: updateSelectedGroup.groupName,
                avatarUrl: updateSelectedGroup.avatarUrl ? updateSelectedGroup.avatarUrl : '',
            });
            ui.update(UI.Loaded);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.updated),
                variant: Variants.success,
            });
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @description Everyoneグループの更新
     */
    const updateEveryone = async () => {
        try {
            ui.update(UI.Loading);
            if (!appContainer.values.signinWorkspaceObject.id) {
                throw new Error(locale.t(locale.keys.error.unknown));
            }
            await group.updateEveryone(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            const val = await group.indexGroupData(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
            group.setDefaultGroupRows(mappingRow(val));
            member.setMemberListByGroup(val.groups);
            ui.update(UI.Loaded);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.updated),
                variant: Variants.success,
            });
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * @param openModalTarget どのモーダルを開くか、どれか一つのみtrueにする
     * @description
     * 複数のモーダルから選択して開くための関数
     */
    const openModal = (openModalTarget: Modal) => {
        setModalTarget(openModalTarget);
    };

    /**
     * @description
     * モーダルを閉じるための関数
     */
    const closeModal = () => {
        setModalTarget(initialModalState);
    };

    /**
     * @returns モーダルが開いているかどうか
     * @description
     * モーダルが開いているかどうかを返す
     */
    const isModalOpen = () => {
        if (
            modalTarget.addGroupModal ||
            modalTarget.addGroupMemberModal ||
            modalTarget.settingGroupModal.open ||
            modalTarget.deleteGroupModal.open ||
            modalTarget.deleteGroupMemberModal ||
            modalTarget.deleteWorkspaceUserModal
        ) {
            return true;
        }
        return false;
    };

    return {
        ui,
        group,
        modalTarget,
        openModal,
        closeModal,
        isModalOpen,
        member,
        deleteWorkspaceusers,
        createGroup,
        deleteGroup,
        updateGroup,
        updateEveryone,
    };
};
export const MemberContainer = createContainer(useMemberContainer);
