import * as azureStorage from 'azure-storage';
import { default as consts } from '@/common/constants';
import * as schema from '@/bundles/schema/typescript/schema';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export interface UploadResponse {
    publicUrl: string;
}

const dataUriToBuffer = (uri: string): Buffer => {
    if (!/^data/i.test(uri)) {
        throw new TypeError('`uri` does not appear to be a Data URI (must begin with "data:")');
    }

    // strip newlines
    const replacedUri = uri.replace(/\r?\n/g, '');

    // split the URI up into the "metadata" and the "data" portions
    const firstComma = replacedUri.indexOf(',');
    if (-1 === firstComma || firstComma <= 4) {
        throw new TypeError('malformed data: URI');
    }

    // remove the "data:" scheme and parse the metadata
    const meta = uri.substring(5, firstComma).split(';');

    // const type = meta[0] || 'text/plain';
    // let typeFull = type;
    let base64 = false;
    let charset = '';
    for (let i = 1; i < meta.length; ) {
        if ('base64' === meta[i]) {
            base64 = true;
        } else {
            //        typeFull += `';${meta[i]}`;
            if (0 === meta[i].indexOf('charset=')) {
                charset = meta[i].substring(8);
            }
        }
        i += 1;
    }
    // defaults to US-ASCII only if type is not provided
    if (!meta[0] && !charset.length) {
        //    typeFull += ';charset=US-ASCII';
        charset = 'US-ASCII';
    }

    // get the encoded data portion and decode URI-encoded chars
    const data = unescape(uri.substring(firstComma + 1));

    const encoding = base64 ? 'base64' : 'ascii';
    const buffer = new Buffer(data, encoding);

    // set `.type` and `.typeFull` properties to MIME type
    // buffer.type = type;
    // buffer.typeFull = typeFull;

    // set the `.charset` property
    // buffer.charset = charset;

    return buffer;
};

const getSasToken = async (name: string, blobType: schema.BlobType, auth: string, groupId?: string) => {
    const req: schema.V1BlobTokenCreateRequest = {
        name,
        blobType,
        groupId,
    };

    const response = await axiosFactory.post<schema.V1BlobTokenCreateResponse>(`https://${consts.environment.api.hostname}/v1/blob/token`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const Upload = async (fileName: string, blobType: schema.BlobType, auth: string, datauri: string, groupId?: string): Promise<UploadResponse> => {
    if (!datauri) {
        return {
            publicUrl: '',
        };
    }

    // WebappからAPIにSASトークンをリクエストし、APIからSASトークン付きのターゲットURLを取得
    const sasTokenRes = await getSasToken(fileName, blobType, auth, groupId);
    // ターゲットURL (Azure Blob Storage) にazure blob storageを使ってアップロード
    // 成功したら成功通知API呼び出し
    const host = consts.environment.azure.storage.host!;
    const blobService: azureStorage.BlobService = azureStorage.createBlobServiceWithSas(host, sasTokenRes.sas).withFilter(new azureStorage.ExponentialRetryPolicyFilter());
    const buff = dataUriToBuffer(datauri);
    const options: azureStorage.BlobService.CreateBlobRequestOptions = {
        contentSettings: {
            contentType: 'application/octet-stream',
        },
        // export interface CreateFileRequestOptions extends common.RequestOptions {
        //    speedsummary?: common.streams.speedsummary.SpeedSummary;
        //    metadata?: { [key: string]: string; };
        //    contentSettings?: {
        //        contentType?: string;
        //        contentEncoding?: string;
        //        contentLanguage?: string;
        //        contentMD5?: string;
        //        cacheControl?: string;
        //        contentDisposition?: string;
        //    };
        //    useTransactionalMD5?: boolean;
        //    storeFileContentMD5?: boolean;
        // }
    };

    return new Promise((resolve, reject) => {
        blobService.createBlockBlobFromText(
            sasTokenRes.container,
            sasTokenRes.filePath,
            buff,
            options,
            (error: Error, result: azureStorage.BlobService.BlobResult, response: azureStorage.ServiceResponse) => {
                if (error) {
                    reject('createBlockBlobFromText error');
                    return;
                }
                // キャッシュ対策
                const time = new Date().getTime();
                resolve({
                    publicUrl: `${sasTokenRes.destination.replace(/\?.*$/, '')}?${time}`,
                });
            },
        );
    });
};
