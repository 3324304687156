import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Search } from '@material-ui/icons';
import { Theme, createStyles, withStyles, WithStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, TablePagination, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { DateFormat } from '@/common/constants/dateFormat';
import moment from 'moment';
import * as webappUtil from '@/common/utils/webappUtil';

type Row = {
    id: string;
    createDate: string;
    ownerName: string;
    service: string;
    allowedDomain: string;
    orderSum: number;
    authorizedSum: number;
};

// TableHeader
const headerStyles = (theme: Theme) =>
    createStyles({
        avatarRow: {
            width: '0.5%',
            padding: 0,
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
    });

interface HeaderProps extends WithStyles<typeof headerStyles> {}

const Header: FC<HeaderProps> = (props) => {
    return (
        <TableHead>
            <TableRow className={props.classes.headerFont}>
                <TableCell align="left">
                    <div className={props.classes.gridInsideHeaderCell}>
                        <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.memberAuthManagement.createDate.index)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell>
                    <div className={props.classes.gridInsideHeaderCell}>
                        <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.memberAuthManagement.client.index)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell>
                    <div className={props.classes.gridInsideHeaderCell}>
                        <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.memberAuthManagement.service.index)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell>
                    <div className={props.classes.gridInsideHeaderCell}>
                        <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.memberAuthManagement.allowDomain.index)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell align="center">{locale.t(locale.keys.memberAuthManagement.totalMember.index)}</TableCell>
                <TableCell align="center">{locale.t(locale.keys.memberAuthManagement.detail.index)}</TableCell>
            </TableRow>
        </TableHead>
    );
};

const StylesHeader = withStyles(headerStyles)(Header);

// TableBody
const bodyStyles = (theme: Theme) =>
    createStyles({
        iconEdit: {
            color: '#ff7300',
        },
    });

interface BodyProps extends WithStyles<typeof bodyStyles> {
    rows: Row[];
    page: number;
    rowsPerPage: number;
}

// // Humble Object
// // APIをモックするのが大変なので、画面側で重要な部分だけをBodyとして切り出し
const Body: FC<BodyProps> = (props) => {
    return (
        <TableBody>
            {props.rows.map((row) => (
                <TableRow style={{ height: 49 * 1 }}>
                    <TableCell align="left">{row.createDate}</TableCell>
                    <TableCell>{row.ownerName}</TableCell>
                    <TableCell>{webappUtil.getServiceText(row.service)}</TableCell>
                    <TableCell>{row.allowedDomain}</TableCell>
                    <TableCell align="center">{row.authorizedSum + '/' + row.orderSum}</TableCell>
                    <TableCell align="center">
                        <Link to={`/auth-management/detail/${row.id}`}>
                            <IconButton>
                                <Edit className={props.classes.iconEdit} />
                            </IconButton>
                        </Link>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};
const StylesBody = withStyles(bodyStyles)(Body);

// TablePagenation
interface PagenationProps {
    rows: Row[];
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    rowsPerPage: number;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
    totalCount: number;
}

// // Humble Object
// // APIをモックするのが大変なので、画面側で重要な部分だけをBodyとして切り出し
const Pagenation: FC<PagenationProps> = (props) => {
    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        props.setPage(page);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const maxPage = Math.floor(props.totalCount / newRowsPerPage);
        props.setRowsPerPage(newRowsPerPage);
        if (props.page > maxPage) {
            props.setPage(maxPage);
        }
    };
    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.totalCount}
            labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
            labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
            )}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            backIconButtonProps={{
                'aria-label': locale.t(locale.keys.table.previousPage),
            }}
            nextIconButtonProps={{
                'aria-label': locale.t(locale.keys.table.nextPage),
            }}
            onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
            data-testid="pagenation-root"
        />
    );
};

// TableRoot
const rootStyles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        table: {
            minWidth: 700,
            padding: 0,
        },
    });

interface RootProps extends WithStyles<typeof rootStyles> {
    reqData: schema.V1XStoragesOrderIndexResponse | undefined;
    open: boolean;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    rowsPerPage: number;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}

const Root: FC<RootProps> = (props) => {
    // 表示用に取得したデータを加工
    const convertRows = (authManagementUser: schema.V1ObjectsXStoragesOrderMaster[]): Row[] => {
        const rows: Row[] = [];
        authManagementUser.forEach((val) => {
            rows.push({
                id: val.id,
                createDate: moment(val.createdAt).format(DateFormat.fullDateTimeWithDot),
                /**
                 * COVAS-2703 【1_11_0】：認可依頼画面において、物理削除されたユーザーが参照された場合画面がクラッシュする
                 * 上記課題において、val.clientがnullになる場合があるため、val.clientが存在する場合のみval.client.nameを取得するように修正
                 */
                ownerName: val.client ? (val.client.name ? val.client.name : '') : '',
                service: val.service,
                allowedDomain: val.allowedDomain,
                orderSum: val.max,
                authorizedSum: val.done,
            });
        });

        return rows;
    };

    return (
        <Paper className={props.classes.root}>
            <div className={props.classes.tableWrapper}>
                <Table className={props.classes.table}>
                    <StylesHeader />
                    <StylesBody rows={props.reqData ? convertRows(props.reqData.orders) : []} page={props.page} rowsPerPage={props.rowsPerPage} />
                </Table>
            </div>
            <Pagenation
                rows={props.reqData ? convertRows(props.reqData.orders) : []}
                page={props.page}
                setPage={props.setPage}
                rowsPerPage={props.rowsPerPage}
                setRowsPerPage={props.setRowsPerPage}
                totalCount={props.reqData ? (props.reqData.pagination ? (props.reqData.pagination.totalCount ? props.reqData.pagination.totalCount : 0) : 0) : 0}
            />
        </Paper>
    );
};

export default withStyles(rootStyles)(Root);
