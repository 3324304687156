import { useEffect } from 'react';
const usePreventWindowUnload = (preventDefault: boolean) => {
    useEffect(() => {
        if (!preventDefault) {
            return;
        }
        const handleBeforeUnload = (event: any) => {
            event.returnValue = 'You quit without saving.';
            event.preventDefault();
            return 'unloading';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [preventDefault]);
};
export default usePreventWindowUnload;
