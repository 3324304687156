import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { csvFormat } from '@/common/constants/csvFormat';
import { EmailValidate } from '@/common/constants/email';

export interface Form {
    mail: string;
    avatarUrl: string;
    password: string;
    showPassword: boolean;
    passwordConfirm: string;
    showPasswordConfirm: boolean;
    name: string;
    phoneticName: string;
    userLang: string;
    logoUrl: string;
    workspaceId: string;
    workspaceName: string;
    workspaceLang: string;
    terms: boolean;
    organizationName: string;
    zipCode: string;
    country: string;
    address1: string;
    address2: string;
    phoneNumber: string;
    customerId: string;
    withoutEmail: boolean;
    contactEmail: string;

    profileSettingValidateInit: {
        workspaceId: boolean;
        workspaceName: boolean;
        workspaceLang: boolean;
        terms: boolean;
    };
    adminSettingValidateInit: {
        name: boolean;
        phoneticName: boolean;
        userLang: boolean;
        deviceLoginUser: boolean;
        pin: boolean;
        contactEmail: boolean;
    };
    passwordSettingValidateInit: {
        password: boolean;
        passwordConfirm: boolean;
    };

    token: string;
    active: boolean;
    role: number;
    deviceLoginUser: string;
    pin: string;
    enableSubWorkspace: boolean;
}

export enum WorkspaceSignupState {
    ProfileSetting = 'profileSetting',
    OrganizationSetting = 'organizationSetting',
    AdminSetting = 'adminSetting',
    PasswordSetting = 'passwordSetting',
}

export const New = (): Form => ({
    mail: '',
    avatarUrl: '',
    password: '',
    showPassword: false,
    passwordConfirm: '',
    showPasswordConfirm: false,
    name: '',
    phoneticName: '',
    userLang: '',
    logoUrl: '',
    workspaceId: '',
    workspaceName: '',
    workspaceLang: '',
    terms: false,
    organizationName: '',
    zipCode: '',
    country: '',
    address1: '',
    address2: '',
    phoneNumber: '',
    customerId: '',
    withoutEmail: false,
    contactEmail: '',

    profileSettingValidateInit: {
        workspaceId: false,
        workspaceName: false,
        workspaceLang: false,
        terms: false,
    },
    adminSettingValidateInit: {
        name: false,
        phoneticName: false,
        userLang: false,
        deviceLoginUser: false,
        pin: false,
        contactEmail: false,
    },
    passwordSettingValidateInit: {
        password: false,
        passwordConfirm: false,
    },

    token: '',
    active: false,
    role: 0,
    enableSubWorkspace: false,
    deviceLoginUser: '',
    pin: '',
});

export const profileSettingValidations = (): validator.Constraints => {
    return {
        workspaceId: {
            workspaceId: {
                message: locale.t(locale.keys.validation.workspaceIdFormat),
            },
        },
        workspaceName: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        workspaceLang: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        terms: {
            inclusion: {
                within: [true],
                message: locale.t(locale.keys.validation.required),
            },
        },
    };
};

export const NewProfileSettingValidation = (): validator.ValidationOutput => {
    return {
        workspaceId: null,
        workspaceName: null,
        workspaceLang: null,
        terms: null,
    };
};

export const adminSettingValidations = (): validator.Constraints => {
    return {
        name: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        phoneticName: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        userLang: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        deviceLoginUser: {
            length: {
                maximum: csvFormat.DEVICELOGINUSER_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.DEVICELOGINUSER_MAX_LENGTH }),
            },
            format: {
                pattern: csvFormat.DEVICELOGINUSER_OPTIONAL,
                flags: 'g', // 正規表現の検索オプション
                message: locale.t(locale.keys.validation.deviceLoginUser),
            },
        },
        pin: {
            pinPolicy: {
                message: locale.t(locale.keys.validation.pinPolicy),
            },
        },
        contactEmail: {
            userEmail: {
                allowEmpty: true,
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewAdminSettingValidation = (): validator.ValidationOutput => {
    return {
        name: null,
        phoneticName: null,
        userLang: null,
        deviceLoginUser: null,
        pin: null,
        contactEmail: null,
    };
};

export const passwordSettingValidations = (): validator.Constraints => {
    return {
        password: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            passwordPolicy: {
                message: locale.t(locale.keys.validation.passwordPolicy),
            },
        },
        passwordConfirm: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            equality: {
                attribute: 'password',
                message: locale.t(locale.keys.validation.notEnough),
            },
        },
    };
};

export const NewPasswordSettingValidation = (): validator.ValidationOutput => {
    return {
        password: null,
        passwordConfirm: null,
    };
};
