import * as React from 'react';
import { Grid, Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { SignupContainer } from './SignupContainer';
import Signup from './Signup';

const styles = (theme: Theme) =>
    createStyles({
        link: {
            color: '#0d47a1',
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    return (
        <SignupContainer.Provider>
            <Grid container>
                <Grid item sm />
                <Grid item xs={12} sm={4}>
                    <Signup />
                </Grid>
                <Grid item sm />
            </Grid>
        </SignupContainer.Provider>
    );
};

export default withStyles(styles)(Component);
