import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as cookieInterfaces from '@/common/utils/authManagement/cookieInterfaces';
import { axiosFactory } from '@/common/utils/axiosFactory';
import * as authUtil from '@/common/utils/authManagement/authUtil';

export type SelectWorkspace = {
    WorkspaceInput: string;
};

export interface Form {
    workspaceId: string;
    alreadySigninWorkpspaces: cookieInterfaces.AlreadySigninWorkspace[];
    errors: validator.ValidationOutput;
}

export const New = (prefix: string = ''): Form => ({
    workspaceId: '',
    alreadySigninWorkpspaces: authUtil.getAlreadySignWorkspaceFromCookie(prefix),

    // Initialize error holder.
    errors: NewValidation(),
});

export const memberSignin = async (workspaceId: string, email: string, pass: string): Promise<schema.V1UserCreateResponse> => {
    const response = await axiosFactory.post<schema.V1UserCreateResponse>(`https://${consts.environment.api.hostname}/v1/user/signin`, { workspace: workspaceId, email: `${email}`, password: pass });
    return response.data;
};

export const validations = (): validator.Constraints => {
    return {
        workspaceId: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        workspaceId: null,
    };
};
