/**
 * @description 機械番号のバリデーション
 * @returns true: 正しいフォーマット, false: 不正なフォーマット
 */
export const validateMfpNumberFormat = (mfpNumber: string): boolean => {
    if (!/(^[A-Z0-9]{4}-[0-9]{6}$)/g.test(mfpNumber)) {
        return false;
    }
    return true;
};
