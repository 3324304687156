import List from './List';
import mfps from './mfps';
import invite from './invite';
import detail from './detail';

export default {
    List,
    mfps,
    invite,
    detail,
};
