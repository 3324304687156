import * as validator from '@/common/utils/validator';

export interface OrganizationInfo {
    name: string;
    zipCode: string;
    country: string;
    address1: string;
    address2: string;
    phoneNumber: string;
    customerId: string;
}
export const New = (): OrganizationInfo => ({
    name: '',
    zipCode: '',
    country: '',
    address1: '',
    address2: '',
    phoneNumber: '',
    customerId: '',
});

export const validation = (): validator.Constraints => {
    return {};
    // #1655 zipCodeとphoneNumberを数値から文字列に変更
    // zipCode: {
    //     format: {
    //         pattern: '^[0-9]*$',
    //         message: locale.t(locale.keys.validation.numeric),
    //     },
    // },
    // phoneNumber: {
    //     format: {
    //         pattern: '^[0-9]*$',
    //         message: locale.t(locale.keys.validation.numeric),
    //     },
    // },
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        name: null,
        zipCode: null,
        country: null,
        address1: null,
        address2: null,
        phoneNumber: null,
        customerId: null,
    };
};
