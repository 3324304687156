import React, { FC, useState, useEffect } from 'react';
import { Theme, Modal, Typography, WithStyles, withStyles, Grid, TextField, Button, createStyles, FormControl, InputLabel, Select, FilledInput, MenuItem } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { createOrder } from '@/common/api/x-storages/order/management';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { validationOutputFormat } from './useValidation';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import { Variants } from '@/common/components/messages/CommonMessage';
import ModalHeader from '@/common/components/headers/ModalHeader';
import { StyledSendAuthRequestMailPreview } from '@/admin/components/common/SendMailPreview';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            gap: '16px',
        },
        bodyRoot: {
            gap: '8px',
        },
        textMailSubject: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        textMailSubjectInput: {
            fontWeight: 'bold',
        },
        textMailBody: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        textMailBodyInput: {
            fontSize: '15px',
        },
        formControl: {
            maxWidth: '85%',
            minWidth: '85%',
            margin: '5px',
        },
        selectInput: {
            padding: '28px 12px 9px 12px',
        },
        prevButton: {
            padding: 0,
            margin: 0,
        },
        tipsText: {
            display: 'flex',
            alignItems: 'center',
        },
        modalWindow: {
            overflow: 'hidden',
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '60%',
            padding: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
            borderRadius: 10,
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                height: '80%',
                width: '80%',
            },
        },
        previewRoot: {
            // 下記の2つのプロパティは上下幅が出たときにスクロールできるようにする設定
            // TODO: Headerとかぶらないように52pxのpaddingを取るのだが、次回修正時に全ての画面で一元管理かつ可変にしておきたい。
            maxHeight: 'calc(100% - 52px)',
            height: 'calc(100% - 52px)',
            overflow: 'auto',
            paddingTop: 24,
            marginInline: '10%',
            [theme.breakpoints.down('sm')]: {
                paddingTop: 16,
                marginInline: '4%',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    formValue: schema.V1XStoragesOrderCreateRequest;
    setFormValue: React.Dispatch<React.SetStateAction<schema.V1XStoragesOrderCreateRequest>>;
    validateMessage: validationOutputFormat;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

// Ccはカンマ区切りで入力
const validateCc = (text: string): string[] => {
    // リクエスト用にArrayに変換
    if (text.includes(',')) {
        const mailList = text.split(',');
        return mailList;
    }
    return [text];
};

const StepThree: FC<Props> = (props) => {
    const [ccText, setCcText] = useState(props.formValue.cc.join(','));
    const [isOpen, setOpen] = useState(false);

    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI(UI.Loaded);

    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    const handleCcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCcText(e.target.value);
        const mailList = validateCc(e.target.value);
        props.setFormValue((prevState) => {
            return {
                ...prevState,
                cc: mailList,
            };
        });
    };

    const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        props.setFormValue((prevState) => {
            return {
                ...prevState,
                title: text,
            };
        });
    };

    const handleChangeBody = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        props.setFormValue((prevState) => {
            return {
                ...prevState,
                body: text,
            };
        });
    };

    const handleChangeLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const limit = parseInt(e.target.value, 10);
        props.setFormValue((prevState) => {
            return {
                ...prevState,
                limit: limit,
            };
        });
    };

    const switchModal = () => {
        setOpen(!isOpen);
    };

    const isDisableNext = () => {
        if (props.validateMessage.body || props.validateMessage.cc || props.validateMessage.title) {
            return true;
        } else {
            return false;
        }
    };

    const onClickCreateOrder = async () => {
        try {
            updateUIStatus(UI.Loading);
            const res = await createOrder(
                {
                    ...props.formValue,
                    // リクエスト用のccリストは両端の空白を暗黙的にトリミングする
                    cc: props.formValue.cc.map((email) => email.trim()),
                },
                appContainer.values.authorizationCode,
            );

            if (res) {
                updateUIStatus(UI.Loaded);
                props.setActiveStep(3);
            } else {
                throw Error('Response is not found.');
            }
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.sent),
                variant: Variants.success,
            });
        } catch (e) {
            updateUIStatus(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    return (
        <>
            <Grid container direction="column" id="stepthree-root" className={props.classes.root}>
                <TextField
                    id="stepthree-cc"
                    variant="filled"
                    value={ccText}
                    label={locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.label.cc)}
                    onChange={handleCcChange}
                    error={props.validateMessage.cc !== ''}
                    helperText={props.validateMessage.cc}
                    inputProps={{
                        'data-testid': 'cc-input',
                    }}
                />
                <Grid id="stepthree-body" container direction="column" className={props.classes.bodyRoot}>
                    <Grid item>
                        <Typography align="left" color="textSecondary">
                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.subHeader.body)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            className={props.classes.textMailSubject}
                            value={props.formValue.title}
                            onChange={handleChangeTitle}
                            variant="outlined"
                            error={props.validateMessage.title !== ''}
                            helperText={props.validateMessage.title}
                            inputProps={{ className: props.classes.textMailSubjectInput, style: { height: '100%' }, 'data-testid': 'title-input' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            className={props.classes.textMailBody}
                            value={props.formValue.body}
                            onChange={handleChangeBody}
                            multiline
                            rows={7}
                            rowsMax={15}
                            variant="outlined"
                            error={props.validateMessage.body !== ''}
                            helperText={props.validateMessage.body}
                            inputProps={{ className: props.classes.textMailBodyInput, style: { height: '100%' }, 'data-testid': 'body-input' }}
                        />
                    </Grid>
                    <Grid item container direction="row" justify="space-between">
                        <Typography className={props.classes.tipsText}>{locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.tips.mailBody)}</Typography>
                        <Button className={props.classes.prevButton} variant="text" style={{ color: 'blue' }} onClick={switchModal} data-testid="preview-button">
                            {locale.t(locale.keys.memberAuthManagement.orderCreate.button.preview)}
                        </Button>
                    </Grid>
                </Grid>
                <Grid id="stepthree-expire">
                    <FormControl className={props.classes.formControl} data-testid="expired-select-root">
                        <InputLabel htmlFor="filled-period" variant="filled">
                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.placeholder.expire)}
                        </InputLabel>
                        <Select
                            id="period"
                            value={props.formValue.limit}
                            onChange={handleChangeLimit}
                            input={<FilledInput name="period" id="filled-period" classes={{ input: props.classes.selectInput }} />}
                            inputProps={{
                                style: { height: '1.46em', lineHeight: 'normal' },
                            }}
                        >
                            <MenuItem value={7}>{locale.plural('invitationNewWorkspaceMail.invitationUnit.day', 7, { n: 7 })}</MenuItem>
                            <MenuItem value={14}>{locale.plural('invitationNewWorkspaceMail.invitationUnit.day', 14, { n: 14 })}</MenuItem>
                            <MenuItem value={30}>{locale.plural('invitationNewWorkspaceMail.invitationUnit.day', 30, { n: 30 })}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid id="stepone-footer">
                    <Button onClick={() => props.setActiveStep(1)}>{locale.t(locale.keys.memberAuthManagement.orderCreate.button.back)}</Button>
                    <Button variant="contained" color="secondary" style={{ color: '#fff' }} onClick={onClickCreateOrder} disabled={isDisableNext()} data-testid="formsend">
                        {locale.t(locale.keys.memberAuthManagement.orderCreate.button.send)}
                    </Button>
                </Grid>
                <Grid />
            </Grid>
            <Modal id={'previewModal'} open={isOpen} onClose={switchModal}>
                <Grid className={props.classes.modalWindow}>
                    <ModalHeader title={locale.t(locale.keys.memberAuthManagement.orderConfirmation.index)} onClose={switchModal} isLoading={appContainer.loadingState} />
                    <Grid id={'send-mail-root'} className={props.classes.previewRoot}>
                        <StyledSendAuthRequestMailPreview
                            mailTo={
                                // メールアドレスのプレビュー。メールアドレスは昇順に表示する。
                                props.formValue.info
                                    ? props.formValue.info
                                          .map((i) => (i.externalEmail ? i.externalEmail : i.toUser ? i.toUser.contactEmail || i.toUser.invitationEmail : ''))
                                          .filter((email) => email)
                                          .sort()
                                          .join(', ')
                                    : ''
                            }
                            mailCc={props.formValue.cc.join(',')}
                            title={props.formValue.title}
                            body={
                                props.formValue.allowedDomain
                                    ? // ドメイン許可が設定されている場合のbodyのプレビュー
                                      props.formValue.body +
                                      '\n\n' +
                                      locale.t(locale.keys.memberAuthManagement.orderConfirmation.body.allowedDomain, { domain: props.formValue.allowedDomain }) +
                                      '\n\n' +
                                      locale.t(locale.keys.memberAuthManagement.orderConfirmation.body.footer, { expired: props.formValue.limit })
                                    : // ドメイン許可が設定されていない場合のbodyのプレビュー
                                      props.formValue.body + '\n\n' + locale.t(locale.keys.memberAuthManagement.orderConfirmation.body.footer, { expired: props.formValue.limit })
                            }
                            expired={props.formValue.limit}
                            onClose={switchModal}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};

export default withStyles(styles)(StepThree);
