import * as React from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Typography } from '@material-ui/core';
import useTitle from '../hooks/useTitle';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { LogoHeaderContainer } from '../headers/LogoHeaderContainer';
import LogoHeader from '../headers/LogoHeader';
import { default as commonConsts } from '@/common/constants';
import locale from '@/common/utils/locale';
import '@/common/css/app.css';
import { UserAppContainer } from '@/user/components/UserAppContainer';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            paddingTop: commonConsts.ui.headerHeight * 2,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        divContent: {
            width: '22vw',
            paddingTop: theme.spacing.unit * 8,
            fontWeight: 500,
            fontSize: '14px',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle?: () => void;
    theme: Theme;
    isAdminApp: boolean;
}

export const Component: React.SFC<Props> = (props) => {
    useTitle(locale.t(locale.keys.forbidden.title));
    const appContainer = props.isAdminApp ? AdminAppContainer.useContainer() : UserAppContainer.useContainer();
    if (typeof props.onTitle === 'function') {
        props.onTitle();
    }
    return (
        <div className="Error">
            <LogoHeaderContainer.Provider>
                <LogoHeader loadingState={appContainer.loadingState} />
                <div className={props.classes.root}>
                    <main className={props.classes.content}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Typography align="center" variant="h1" gutterBottom>
                                {locale.t(locale.keys.forbidden.title)}
                            </Typography>
                            <Typography align="left" variant="h4" color="textSecondary" className={props.classes.divContent}>
                                {locale.t(locale.keys.forbidden.sub1)}
                            </Typography>
                            <Typography align="left" variant="h4" color="textSecondary" className={props.classes.divContent}>
                                {locale.t(locale.keys.forbidden.sub2)}
                            </Typography>
                        </Grid>
                    </main>
                </div>
            </LogoHeaderContainer.Provider>
        </div>
    );
};
export default withStyles(styles, { withTheme: true })(Component);
