// @ts-nocheck
import { default as React, useEffect } from 'react';
import { Theme, TextField, Typography, createStyles, withStyles, WithStyles, Grid, Checkbox, Button } from '@material-ui/core';
import { WorkspaceSignupContainer } from './WorkspaceSignupContainer';
import { VerifyContainer } from '../VerifyContainer';

import locale from '@/common/utils/locale';
import ImageFileSelector from '@/common/components/ImageFileSelector';
import LanguageSelect from '@/common/components/LanguageSelect';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import TermsMessage from '@/common/components/auth/common/TermsMessage';
import * as model from '@/common/api/auth/workspace-signup/WorkspaceSignup';

const styles = (theme: Theme) =>
    createStyles({
        mt20: {
            marginTop: 20,
        },
        mb20: {
            marginBottom: 20,
        },
        formControl: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
                minWidth: 240,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const container = WorkspaceSignupContainer.useContainer();
    const vc = VerifyContainer.useContainer();
    const { classes } = props;
    const lhc = LogoHeaderContainer.useContainer();

    useEffect(() => {
        lhc.setBackFunc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 次へ
    const handleNext = () => {
        container.setScreenState(model.WorkspaceSignupState.OrganizationSetting);
    };
    return (
        <>
            <Grid container spacing={32}>
                <Grid item xs={12} className={`${classes.mb20} ${classes.mt20}`}>
                    <Typography align="center">{locale.t(locale.keys.newWorkspaceSetting.workspaceSetting)}</Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Typography align="left" color="textSecondary">
                        {locale.t(locale.keys.newWorkspaceSetting.logo)}
                    </Typography>

                    <ImageFileSelector
                        seed={vc.values.workspace ? vc.values.workspace.id : null}
                        uploadWidth={512}
                        uploadHeight={512}
                        mobileWidth={160}
                        mobileHeight={160}
                        pcWidth={160}
                        pcHeight={160}
                        defaultUrl={container.values.logoUrl}
                        logoDataUri={container.logoDataUri}
                        isAvatar={true}
                        onLoaded={(datauri) => {
                            container.handleChangeLogoDataUri(datauri);
                        }}
                        dependAppContainer={AdminAppContainer.useContainer}
                        editable={true}
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField
                        className={classes.mb20}
                        variant="filled"
                        fullWidth
                        label={locale.t(locale.keys.newWorkspaceSetting.workspaceId)}
                        value={container.values.workspaceId}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangeWorkspaceID(event.target.value)}
                        error={container.handleValidateWorkspaceID() !== ''}
                        helperText={container.handleValidateWorkspaceID()}
                    />
                    <TextField
                        className={classes.mb20}
                        variant="filled"
                        fullWidth
                        label={locale.t(locale.keys.newWorkspaceSetting.workspaceDisplayName)}
                        value={container.values.workspaceName}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangeWorkspaceName(event.target.value)}
                        error={container.handleValidateWorkspaceName() !== ''}
                        helperText={container.handleValidateWorkspaceName()}
                    />
                    <LanguageSelect
                        value={container.values.workspaceLang}
                        label={locale.t(locale.keys.newWorkspaceSetting.workspaceLanguage)}
                        handleChange={(event) => container.handleChangeWorkspaceLang(event.currentTarget.value)}
                        handleValidate={() => container.handleValidateWorkspaceLang()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.mb20}>
                        <Checkbox checked={container.values.terms} onChange={(event: React.ChangeEvent<HTMLInputElement>) => container.handleChangeTerms(event.currentTarget.checked)} />
                        <TermsMessage />
                    </div>
                    <Button className={classes.button} fullWidth size="large" variant="contained" disabled={container.profileSettingNextButtonDisabled()} onClick={() => handleNext()}>
                        {locale.t(locale.keys.action.next)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
export default withStyles(styles)(Component);
