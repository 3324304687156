import * as Sentry from '@sentry/browser';

import environment from '@/common/constants/environment';

/**
 * Given any types of array, return string concatenated by comma.
 */
const stringify = (...given: any[]): string => {
    const got = given.map((chunk: any) => {
        if (chunk instanceof Object) {
            return JSON.stringify(chunk);
        }

        if (typeof chunk === 'function') {
            return null;
        }

        return chunk;
    });

    return got.join(', ');
};

/**
 * console.debug wrapper.
 */
export const debug = (...given: any[]): void => {
    if (environment.env !== environment.availableEnvs.Develop || isTesting()) {
        return;
    }

    console.log(...given);
};

/**
 * console.error wrapper.
 * This method also reports to Sentry.
 */
export const error = (...given: any[]): void => {
    if (isTesting()) {
        return;
    }

    if (environment.env === environment.availableEnvs.Develop) {
        console.error(...given);
        return;
    }

    // Send stringified representation to Sentry.
    // Note there are admin and user projects in Sentry, and the context is declared here:
    // src/(admin|user)/index.tsx
    const out = stringify(given);
    Sentry.captureException(new Error(stringify(out)));
};

/**
 * Returns true when in Jest context.
 * Use this to mute annoying STDOUT.
 */
const isTesting = (): boolean => {
    if (process && process.env && process.env.JEST_WORKER_ID) {
        return true;
    }

    return false;
};

export default {
    debug,
    error,
    // Exported only for testing.
    isTesting,
};
