export interface Device {
    id: string;
    deviceName: string;
    mfpNumber: string;
    noMembers: number;
    mfpName: string;
    status?: string;
}

export const dummyDeviceList: Device[] = [
    {
        id: '1',
        deviceName: '南麻布1F-MFP',
        mfpNumber: 'MACHINENO',
        noMembers: 1,
        mfpName: 'xyz',
        status: 'active',
    },
];

export default dummyDeviceList;
