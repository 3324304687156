import { Theme, createStyles } from '@material-ui/core';
import ui from '@/common/constants/ui';
import constants from '@/common/constants';

interface Override {
    headerBackgroundColor: string;
}

export const styles = (theme: Theme, given: Override) =>
    createStyles({
        header: {
            backgroundColor: given.headerBackgroundColor,
            height: ui.headerHeight,
            position: 'fixed',
            // Material UI's default navbar height is 48px, however app requires 64px.
            // This line alignes inner elements to vertical center.
            justifyContent: 'center',
            maxWidth: constants.ui.maxWidthScreen,
        },
        serviceName: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        workspace: {
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                margin: '0',
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: '66px',
            },
        },
        user: {
            cursor: 'pointer',
            position: 'absolute',
            height: ui.headerHeight,
            right: theme.spacing.unit * 6,
            [theme.breakpoints.up('md')]: {
                right: theme.spacing.unit * 2,
            },
            alignItems: 'center',
            display: 'flex',
            margin: '0',
        },
        userName: {
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        menuButton: {
            position: 'absolute',
            right: 0,
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        avatar: {
            marginRight: theme.spacing.unit,
        },
        logo: {
            width: '190px',
        },
    });

export default styles;
