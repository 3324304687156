import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Typography, Button, List, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as schema from '@/bundles/schema/typescript/schema';
import ModalHeader from '@/common/components/headers/ModalHeader';
import { MemberContainer } from '../MemberContainer';
import CovasAvatar from '@/common/components/CovasAvatar';
import { MultiLineText } from '@/common/components/messages/MultiLineText';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: `0px ${theme.spacing.unit * 3}px`,
            height: '100%',
            gap: '12px',
            paddingTop: 24,
        },
        listRoot: {
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        okBtn: {
            color: '#ff7300',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const DeleteWorkspaceUser: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const memberContainer = MemberContainer.useContainer();

    const selectedMemberList = () => {
        const selectedGroup = memberContainer.member.memberListByGroup.find((groupList) => groupList.id === memberContainer.group.selectedGroup.groupId);
        if (selectedGroup === undefined || selectedGroup.members === undefined) return [] as schema.V1ObjectsWorkspaceuserMedium[];
        return selectedGroup.members.filter((member) => {
            if (memberContainer.member.form.selectedMemberIdList.includes(member.id)) {
                // 自分自身は削除できないようにリストから除外
                if (member.id !== appContainer.values.signinWorkspaceUserObject.id) return member;
            }
            return false;
        });
    };

    return (
        <>
            <ModalHeader
                title={locale.t(locale.keys.memberManagement.modal.deleteWorkspaceMember.title)}
                onClose={() => {
                    memberContainer.closeModal();
                }}
                isLoading={appContainer.loadingState}
            />
            <Grid container direction="column" style={{ height: 'calc(100% - 48px)', maxHeight: '80vh', justifyContent: 'space-between', flexWrap: 'nowrap' }} className={classes.root}>
                <Grid item container direction="column" style={{ gap: '8px', overflow: 'auto', flexWrap: 'nowrap', flexGrow: 1, height: 300 }}>
                    <Grid item container direction="column" style={{ gap: '8px' }}>
                        <MultiLineText value={locale.t(locale.keys.memberManagement.modal.deleteWorkspaceMember.description)} />
                    </Grid>

                    <Grid item container direction="column" style={{ gap: '8px' }}>
                        <Typography style={{ width: '100%' }}>{locale.t(locale.keys.memberManagement.modal.deleteWorkspaceMember.deleteMember.title)}</Typography>
                        <Grid item style={{ height: 'auto', maxHeight: 550, overflow: 'auto', width: '100%' }}>
                            <List className={classes.listRoot}>
                                {selectedMemberList()
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((val) => {
                                        return (
                                            <ListItem key={val.id}>
                                                <ListItemAvatar>
                                                    <CovasAvatar size={40} seed={val.invitationEmail} src={val.avatarUrl} onClick={() => {}} color="disabled" />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={val.name}
                                                    primaryTypographyProps={{
                                                        style: {
                                                            fontSize: 12,
                                                            fontWeight: 'bold',
                                                            color: '#333',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'pre' /**空白は詰めない */,
                                                        },
                                                    }}
                                                    secondary={val.invitationEmail}
                                                    secondaryTypographyProps={{ style: { fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }}
                                                />
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container direction="row" justify="flex-end" id="emailmodal-footer" style={{ width: '100%' }}>
                    <Button onClick={() => memberContainer.closeModal()} size="small" color="primary">
                        {locale.t(locale.keys.action.cancel)}
                    </Button>
                    <Button
                        onClick={() => {
                            memberContainer.closeModal();
                            memberContainer.deleteWorkspaceusers(selectedMemberList().map((val) => val.id));
                        }}
                        variant="outlined"
                        className={classes.okBtn}
                        disabled={selectedMemberList().length === 0}
                        style={{ border: 'none' }}
                    >
                        {locale.t(locale.keys.action.ok)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(DeleteWorkspaceUser);
