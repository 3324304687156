import React, { FC, useState, useEffect } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { WithStyles, withStyles, Grid, Modal, Paper, IconButton, InputBase, Link } from '@material-ui/core';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as xStoragesModel from '@/common/api/x-storages/order/management';
import * as schema from '@/bundles/schema/typescript/schema';
import * as errorHandler from '@/common/utils/errorHandler';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { Search } from '@material-ui/icons';
import moment from 'moment';
import { DateFormat } from '@/common/constants/dateFormat';
import Table, { Row } from './Table';
import EmailModal from './EmailModal';
import { detailStyles } from '@/admin/components/auth-management/css/detail';
import { useValidation } from '@/admin/components/auth-management/create-order-modal/useValidation';
import * as webappUtil from '@/common/utils/webappUtil';
import routes from '@/admin/constants/routes';
import { useParams } from 'react-router';

type Master = {
    requestedDate: string;
    clientName: string;
    cloud: string;
    allowedDomain: string;
    storageType: number;
    storageUserType: string;
};

interface Props extends WithStyles<typeof detailStyles> {}

const AuthManagementDetailPage: FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.memberAuthManagementDetail.index));

    const appContainer = AdminAppContainer.useContainer();
    const { orderId } = useParams<{ orderId: string }>();
    const [isOpen, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState<Row[]>([]);
    const [rowsSearched, setRowsSearched] = useState<Row[]>([]);
    const [isBoolEmail, setIsBoolEmail] = useState(true);
    const [master, setMaster] = useState<Master>({
        requestedDate: '',
        clientName: '',
        cloud: '',
        allowedDomain: '',
        storageType: 0,
        storageUserType: '',
    });
    const [formValue, setFormValue] = useState<schema.V1XStoragesOrderUpdateRequest>({
        service: schema.V1ObjectsServiceEnum.Nonecloud,
        allowedDomain: '',
        body: locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.placeholder.mailBody),
        cc: [''],
        limit: 30,
        title: locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.placeholder.mailTitle),
        ordertype: schema.V1ObjectsRegisteredUserEnum.EachUser,
        info: [],
        share: 0,
        orderHistoryId: '',
    });
    const validateMessage = useValidation(formValue);

    // 表示用に取得したデータを加工
    const convertRows = (historyData: schema.V1ObjectsXStoragesOrderHistory[], master: schema.V1ObjectsXStoragesOrderMaster): Row[] => {
        const rows: Row[] = [];
        if(master.share === 0) {
            // クラウドストレージ・1ユーザーに全て登録する場合(登録先ユーザーが定義されている場合)
            // メール、名前が登録先ユーザーになる
            if(master.registeredUser) {
                const regWsUser = master.registeredUser;
                historyData.forEach((val) => {
                    rows.push({
                        historyId: val.id,
                        createDate: moment(val.createdAt).format(DateFormat.fullDateTimeWithDot),
                        email: regWsUser.invitationEmail,
                        name: regWsUser.name,
                        authStatus: val.status
                            ? locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.authed)
                            : locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.still),
                        memberId: val.user ? val.user.id || '' : '',
                        memberRole: val.user ? val.user.role || 0 : 0,
                        user: val.user as schema.V1ObjectsWorkspaceuserLarge,
                        master:{
                            ...master,
                            registeredUser: regWsUser,
                            share: master.share,
                        },
                        externalEmail: val.externalEmail || '',
                    });
                    setFormValue((prevState) => {
                        return {
                            ...prevState,
                            share: master.share,
                            ordertype: schema.V1ObjectsRegisteredUserEnum.OneUser,
                            orderHistoryId: val.id,
                            info: [
                                {
                                    registeredUser: regWsUser,
                                    toUser: val.user,
                                    externalEmail: val.externalEmail || '',
                                }
                            ],
                        };
                    });
                });
                return rows;
            } else {
                // クラウドストレージ・各ユーザーに登録する場合(登録先ユーザーが定義されていない場合)
                historyData.forEach((val) => {
                    rows.push({
                        historyId: val.id,
                        createDate: moment(val.createdAt).format(DateFormat.fullDateTimeWithDot),
                        email: val.user ? val.user.invitationEmail : '',
                        name: val.user ? val.user.name || '' : '',
                        authStatus: val.status
                            ? locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.authed)
                            : locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.still),
                        memberId: val.user ? val.user.id || '' : '',
                        memberRole: val.user ? val.user.role || 0 : 0,
                        user: val.user as schema.V1ObjectsWorkspaceuserLarge,
                        master:{
                            ...master,
                            share: master.share,
                        },
                        externalEmail: val.externalEmail || '',
                    });
                    setFormValue((prevState) => {
                        return {
                            ...prevState,
                            share: master.share,
                            ordertype: schema.V1ObjectsRegisteredUserEnum.EachUser,
                            orderHistoryId: val.id,
                            info: [
                                {
                                    toUser: val.user,
                                }
                            ],
                        };
                    });
                });
                return rows;
            }
        } else {
            // クラウドストレージ・共有クラウドストレージの場合
            // メール、名前が登録先ユーザーになる
            historyData.forEach((val) => {
                rows.push({
                    historyId: val.id,
                    createDate: moment(val.createdAt).format(DateFormat.fullDateTimeWithDot),
                    email: val.user ? val.user.invitationEmail : '',
                    name: val.user ? val.user.name || '' : '',
                    authStatus: val.status
                        ? locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.authed)
                        : locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.still),
                    memberId: val.user ? val.user.id || '' : '',
                    memberRole: val.user ? val.user.role || 0 : 0,
                    user: val.user as schema.V1ObjectsWorkspaceuserLarge,
                    master:{
                        ...master,
                        share: master.share,
                    },
                    externalEmail: val.externalEmail || '',
                });
                setFormValue((prevState) => {
                    return {
                        ...prevState,
                        share: master.share,
                        orderHistoryId: val.id,
                        info: [
                            {
                                toUser: val.user,
                                externalEmail: val.externalEmail || '',
                            }
                        ],
                    };
                });
            });
            return rows;
        }
    };

    //依頼者データの加工
    const convertMaster = (OrderDetailIndexResponse: schema.V1XStoragesOrderDetailIndexResponse): Master => {
        const masterData = OrderDetailIndexResponse.master;
        let requestedDate = '';
        let clientName = '';
        let cloud = '';
        let allowedDomain = '';
        let storageType = 0;
        let storageUserType = '';
        if (masterData) {
            requestedDate = masterData.createdAt;
            /**
             * COVAS-2703 【1_11_0】：認可依頼画面において、物理削除されたユーザーが参照された場合画面がクラッシュする
             * 上記課題において、val.clientがnullになる場合があるため、val.clientが存在する場合のみval.client.nameを取得するように修正
             */
            clientName = masterData.client ? (masterData.client.name ? masterData.client.name : '') : '';
            cloud = masterData.service;
            allowedDomain = masterData.allowedDomain;
            storageType = masterData.share ? masterData.share : 0;
            storageUserType = masterData.registeredUser ? schema.V1ObjectsRegisteredUserEnum.OneUser : schema.V1ObjectsRegisteredUserEnum.EachUser;
        }
        const requestData: Master = {
            requestedDate: moment(requestedDate).format(DateFormat.fullDateWithSlash),
            clientName: clientName,
            cloud: cloud,
            allowedDomain: allowedDomain,
            storageType: storageType,
            storageUserType: storageUserType,
        };
        return requestData;
    };

    //検索項目が変化した時に動く関数
    const handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    // テキストフォームの内容で絞り込むDecorator
    // 登録先Eメールアドレス、名前、認可状況、外部Eメールアドレス、ユーザーのEメールアドレス、連絡先Eメールアドレスで絞り込み
    const getSearchTextRows = (rows: Row[]): Row[] => {
        return rows.filter((val) => {
            return val.email.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                   val.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                   val.authStatus.indexOf(searchText) !== -1 ||
                   (val.externalEmail && val.externalEmail.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) ||
                   (val.user && val.user.contactEmail && val.user.contactEmail.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) ||
                   (val.user && val.user.invitationEmail.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
        });
    };

    // 検索条件にマッチしたRowを取得
    const getRows = (rows: Row[]) => {
        // Eメールアドレス || 名前 || 認可状況
        return getSearchTextRows(rows);
    };
    const switchModal = () => {
        setOpen(!isOpen);
    };

    //ローディング時のui関連
    const ui = useUI(UI.Loaded);
    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };

    //検索されたメンバー認可Eメールを送信するかどうかの判定
    //「検索件数が0件」または「検索結果が1件以上」かつ「表示されているメンバーが全員認可済み」の時にリンクが非表示
    const isBoolSendGroupEmail = (rows: Row[]): boolean => {
        if (rows.length === 0) return false;
        let count: number = 0;
        for (let row of rows) {
            if (row.authStatus === locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.authed)) {
                count++;
            }
        }
        if (count === rows.length) {
            return false;
        }
        return true;
    };

    //未認可のユーザのみを絞り込みしてformValueに代入
    const nonAuthStatusFilter = (rows: Row[]) => {
        const nonAuthRows = rows.filter((row) => row.authStatus === locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.still));
        const nonAuthInfo: schema.V1ObjectsXStoragesOrderInfo[] = [];
        nonAuthRows.map((row) => {
            nonAuthInfo.push({
                registeredUser: row.master.registeredUser,
                toUser: row.user,
                externalEmail: row.externalEmail || '',
            });
        });
        setFormValue((prevState) => {
            return {
                ...prevState,
                info: [...nonAuthInfo],
            };
        });
    }

    const createClientInformation = (requestedDate: string, clientName: string, cloud: string, allowedDomain: string, storageTypeParam: number, storageUserTypeParam: string): string => {
        let infoText =
            locale.t(locale.keys.memberAuthManagementDetail.requestInformation.requestedDate) +
            '：' +
            requestedDate +
            '　' +
            locale.t(locale.keys.memberAuthManagementDetail.requestInformation.clientName) +
            '：' +
            clientName +
            '　';
        if (cloud) {
            infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.cloud) + '：' + webappUtil.getServiceText(cloud) + '　';
            infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.allowedDomain) + '：' + allowedDomain + '　';
            if(storageTypeParam === 0) {
                infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredStorage.index) + '：' + locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredStorage.cloudStorage) + '　';
                if(storageUserTypeParam === schema.V1ObjectsRegisteredUserEnum.EachUser){
                    infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredUser.index) + '：' + locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredUser.eachUser);
                } else {
                    infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredUser.index) + '：' + locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredUser.oneUser);
                }
            } else {
                infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredStorage.index) + '：' + locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredStorage.sharedCloudStorage) + '　';
            }
        } else {
            infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.cloud) + '：' + '　';
            infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.allowedDomain) + '：' + allowedDomain + '　';
            infoText += locale.t(locale.keys.memberAuthManagementDetail.requestInformation.registeredStorage.index) + '：';
        }
        return infoText;
    };

    useEffect(() => {
        (async () => {
            try {
                if (appContainer.values.signinWorkspaceObject.id) {
                    updateUIStatus(UI.Loading);
                    const data = await xStoragesModel.indexDetailOrderList(appContainer.values.authorizationCode, orderId);
                    if (data) {
                        setRows(convertRows(data.history, data.master));
                        setRowsSearched(convertRows(data.history, data.master));
                        setMaster(convertMaster(data));
                    }
                } else {
                    throw Error('Workspace ObjectId is not found. Caused by React State.');
                }
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            } finally {
                updateUIStatus(UI.Loaded);
            }
        })();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //検索ワード変わったタイミングでサーチしたrowを代入
    useEffect(() => {
        setRowsSearched(getRows(rows));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    //サーチしたrowが変化した場合に発火
    //検索されたメンバー認可Eメールを送信するかどうかの判定を行う+一斉送信するメールの除外判定を行う
    useEffect(() => {
        setIsBoolEmail(isBoolSendGroupEmail(rowsSearched));
        nonAuthStatusFilter(rowsSearched);
    }, [rowsSearched]);

    //uiのステータスが変わったときにhookする関数
    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    return (
        <>
            <SubHeader title={locale.t(locale.keys.memberAuthManagementDetail.index)} isBack={routes.authManagement.index} />
            <div className={props.classes.pageRoot}>
                <div className={props.classes.clientInformation}>{createClientInformation(master.requestedDate, master.clientName, master.cloud, master.allowedDomain, master.storageType, master.storageUserType)}</div>
                <Grid container className={props.classes.content}>
                    <Grid item xs={12}>
                        <Paper className={props.classes.searchRoot}>
                            <IconButton className={props.classes.searchIconButton} aria-label="Search">
                                <Search />
                            </IconButton>
                            <InputBase className={props.classes.searchInput} onChange={handleChangeSearchText} inputProps={{ style: { height: '100%' } }} />
                        </Paper>
                    </Grid>
                </Grid>
                {isBoolEmail ? (
                    <div style={{ textAlign: 'end' }}>
                        <Link onClick={switchModal} className={props.classes.sendGroup}>
                            {locale.t(locale.keys.memberAuthManagementDetail.action.sendGroupEmail)}
                        </Link>
                    </div>
                ) : (
                    ''
                )}
                <Modal open={isOpen} onClose={switchModal}>
                    <div className={props.classes.modalWindow}>
                        <EmailModal formValue={formValue} setFormValue={setFormValue} validateMessage={validateMessage} onClose={switchModal} />
                    </div>
                </Modal>
            </div>

            <div className={props.classes.pageRoot}>
                <Grid item xs={12}>
                    <Table responseData={rowsSearched} storageType={master.storageType} storageUserType={master.storageUserType}/>
                </Grid>
            </div>
        </>
    );
};

export default withStyles(detailStyles)(AuthManagementDetailPage);
