import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

const isSuccess = (arg: schema.V1UserCreateResponse | null): arg is schema.V1UserCreateResponse => {
    return arg !== null && (arg as schema.V1UserCreateResponse).id !== null;
};

export const getWorkspaceObject = async (displayId: string, auth: string): Promise<schema.V1ObjectsWorkspace> => {
    const response = await axiosFactory.get<schema.V1WorkspaceShowResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${displayId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data.workspace;
};

export const memberSignin = async (workspaceId: string, email: string, pass: string): Promise<schema.V1UserCreateResponse | null> => {
    const response = await axiosFactory.post<schema.V1UserCreateResponse>(`https://${consts.environment.api.hostname}/v1/user/signin`, { workspace: workspaceId, email: `${email}`, password: pass });
    const result = response.data;

    if (isSuccess(result)) {
        return result;
    }

    return null;
};

export const myPasswordReset = async (req: schema.V1WorkspaceuserPasswordResetCreateRequest): Promise<schema.V1WorkspaceuserPasswordResetCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceuserPasswordResetCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaceusers/passwordreset`, req);
    return response.data;
};

export interface Form {
    email: string;
    password: string;
    validateInit: {
        email: boolean;
        password: boolean;
    };
    errors: validator.ValidationOutput;
}

export const New = (): Form => ({
    email: '',
    password: '',
    validateInit: {
        email: false,
        password: false,
    },
    // Initialize error holder.
    errors: NewValidation(),
});

export const validations = (): validator.Constraints => {
    return {
        email: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            email: {
                message: locale.t(locale.keys.validation.email),
            },
        },
        password: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
    };
};
export const passwordResetValidations = (): validator.Constraints => {
    return {
        email: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.email),
            },
            email: {
                message: locale.t(locale.keys.validation.email),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        email: null,
        password: null,
    };
};
