import { createStyles } from '@material-ui/core';

/**
 * @description Tableコンポーネントで使用する共通コンポーネント
 */
export const styles = () =>
    createStyles({
        table: {
            minWidth: 700,
            padding: 0,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        tableCell: {
            paddingInline: '4px',
            paddingTop: 0,
            paddingBottom: 0,
        },
        oddRow: {
            background: '#f2f4f5',
        },
        evenRow: {
            background: 'inherit',
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
    });
