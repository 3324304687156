import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import logger from '@/common/utils/logger';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import locale from '@/common/utils/locale';
import * as InvitationMember from '@/common/api/workspaceuser/invite/InvitationMember';
import * as validator from '@/common/utils/validator';
import * as errorHandler from '@/common/utils/errorHandler';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as schema from '@/bundles/schema/typescript/schema';
import * as invitationModel from '@/common/api/workspaceuser/invite/InvitationMember';
import dayjs from 'dayjs';
import { useMember } from '@/common/models/member/useMember';

const useInvitationContainer = () => {
    // Invitaiton.tsx ===========================================
    const appContainer = AdminAppContainer.useContainer();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [form, setForm] = useState(InvitationMember.New());
    const [invitationRow, setInvitationRow] = useState<invitationModel.Row[]>([]);
    const ui = useUI();
    const member = useMember();
    const [errors, setErrors] = useState({
        emails: '',
    });

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        // v1.6.0からインターナルプラン、フリープランで新規メンバー招待を表示
        if (
            appContainer.values.signinWorkspaceObject == null ||
            (appContainer.values.signinWorkspaceObject.billingPlan !== schema.BillingPlan.Free && appContainer.values.signinWorkspaceObject.billingPlan !== schema.BillingPlan.Internal)
        ) {
            ui.update(UI.Error);
            return;
        }
        (async () => {
            try {
                // 重複検証用のユーザー一覧を取得
                const members = await member.indexWorkspaceuser(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                member.setAllMemberWithNotVerified(members);

                const initData = await invitationModel.createData(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                setInvitationRow(initData);

                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // コンポーネントがマウントされたときにセッションストレージからページ番号を読み込む
        const storedPage = parseInt(sessionStorage.getItem('InvitationMemberCurrentPage') || '0', 10);
        setPage(storedPage);

        const storedRowsPerPage = parseInt(sessionStorage.getItem('InvitationMemberRowsPerPage') || '10', 10);
        setRowsPerPage(storedRowsPerPage);
    }, []);

    useEffect(() => {
        const lastViewedMemberIndex = parseInt(sessionStorage.getItem('LastViewedMemberIndex') || '0', 10);
        const storedInvitationRowsPerPage = parseInt(sessionStorage.getItem('InvitationMemberRowsPerPage') || '10', 10);
        if (storedInvitationRowsPerPage !== 0) {
            setRowsPerPage(storedInvitationRowsPerPage);
        }
        if (lastViewedMemberIndex !== 0) {
            const invitationPage = Math.floor(lastViewedMemberIndex / storedInvitationRowsPerPage);
            setPage(invitationPage);
            sessionStorage.setItem('InvitationMemberCurrentPage', invitationPage.toString());
            sessionStorage.setItem('LastViewedMemberIndex', '0');
        }
    }, []);

    // 招待後に一覧を更新
    const onInvite = (emailStrings: string[] | null) => {
        logger.debug('onInvite');
        ui.update(UI.Loading);
        if (emailStrings != null) {
            for (const emailString of emailStrings) {
                invitationRow.push({
                    id: invitationRow.length + 1,
                    userId: '',
                    email: emailString,
                    invitationUser: '',
                    invitationDate: '',
                    limit: '',
                    limitDays: 0,
                    invited: false,
                });
            }
            setInvitationRow(invitationRow);
        }
        (async () => {
            try {
                const initData = await invitationModel.createData(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                setInvitationRow(initData);
                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    const getRows = (rows: InvitationMember.Row[]) => {
        return rows;
    };

    /**
     * メールアドレスの検証
     * 空文字，数字，パターンマッチの検証を行う
     */
    const validate = () => {
        const msg = validator.Validate<InvitationMember.Form>(form, InvitationMember.validations(), InvitationMember.NewValidation);
        const emailMsg = form.emails
            .map((value) => {
                const attr = { email: value };
                return validator.Validate<{ email: string }>(attr, InvitationMember.emailValidations(), InvitationMember.NewEmailValidation);
            })
            .find((msg) => {
                return msg && msg.email;
            });
        const emails = msg && msg.emails ? msg.emails.toString() : '';
        const emailsFormat = emailMsg && emailMsg.email ? emailMsg.email.toString() : '';

        // 不正なメールアドレスが入力された場合は、不正なメールアドレスが入力されている旨の表示を出力
        if (emailsFormat) {
            setErrors({
                emails: !form.validateInit.emails ? '' : `${emails}${emailsFormat}`,
            });
            return;
        }

        let duplicateEmail = null as null | string;
        if (!member.allmemberWithNotVerified) {
            return;
        }
        member.allmemberWithNotVerified.users.forEach((value) => {
            form.emails.forEach((email) => {
                if (value.invitationEmail === email) {
                    duplicateEmail = email;
                }
            });
        });
        // 重複したメールアドレスが入力された場合は、重複したメールアドレスが入力されている旨の表示を出力
        if (duplicateEmail) {
            setErrors({
                emails: locale.t(locale.keys.memberInvitation.memberEmailAddress.helperText.duplicateError, { email: duplicateEmail }),
            });
            return;
        }

        // エラーがない場合はエラーメッセージをクリア
        setErrors({
            emails: '',
        });
    };

    /**
     * 入力データを「,」で分解しメールアドレスか検証する
     * 入力(Enterを押した時)確定するたびに呼び出される
     * @param chip 入力データ
     */
    const handleAdd = (chip: string) => {
        form.validateInit.emails = true;
        // 確定している入力と入力データを合わせて比較
        const inputArray = chip.split(',').filter((elem, index, self) => self.indexOf(elem) === index && elem.length !== 0 && !DuplicateCheck(self, elem, index));
        form.emails = form.emails.concat(inputArray);
        setForm(form);
        validate();
    };

    /**
     * 入力中のメールアドレスの重複チェック
     * @param inputEmails 新しく入力された全てのメールアドレス
     * @param inputOne 新しく入力されたメールアドレス
     * @param index 元の入力データ配列のインデックス(小文字変換後の配列のインデックスと比較)
     * @return 重複しているとき true それ以外 false
     */
    const DuplicateCheck = (inputEmails: string[], inputOne: string, index: number) => {
        let duplicate = false;
        // 既に入力が確定しているメールアドレスと比較
        form.emails.forEach((value) => {
            if (inputOne.toLowerCase() === value.toLowerCase()) {
                duplicate = true;
            }
        });
        // 入力データと比較
        const lowerCaseEmails: string[] = [];
        for (const data of inputEmails) {
            // 全部小文字に変換
            lowerCaseEmails.push(data.toLowerCase());
        }
        if (lowerCaseEmails.indexOf(inputOne.toLowerCase()) !== index) {
            duplicate = true;
        }
        return duplicate;
    };

    /**
     * 入力データの削除
     * @param data 入力データ
     * @param index 削除するデータのインデックス
     */
    const handleDelete = (data: string, index: number) => {
        logger.debug('handleDelete');
        form.validateInit.emails = true;
        const filteredEmails = form.emails.filter((it) => data !== it);
        form.emails = filteredEmails;
        setForm(form);
        validate();
    };
    const handleState = (data: string) => {
        form.limit = parseInt(data, 10);
        setForm(form);
        validate();
    };

    const errorChipColor = (key: number) => {
        const attr = { email: form.emails[key] };

        // メール形式チェック
        const msg = validator.Validate<{ email: string }>(attr, InvitationMember.emailValidations(), InvitationMember.NewEmailValidation);
        const isMailFormatError = msg && msg.email ? true : false;

        // メール重複チェック
        let duplicateEmail = null as null | string;
        if (!member.allmemberWithNotVerified) {
            return;
        }
        member.allmemberWithNotVerified.users.forEach((value) => {
            if (value.invitationEmail === attr.email) {
                duplicateEmail = attr.email;
            }
        });
        const isDuplicateError = duplicateEmail ? true : false;

        return isMailFormatError || isDuplicateError;
    };

    /**
     * 招待メールの送信処理
     * apiに入力されたメールアドレス、ワークスペースID、サインアップ期限を送る
     * @param onInvite
     */
    const handleInvite = (onInvite: (emailStrings: string[] | null) => void) => {
        logger.debug('handleInvite');
        (async () => {
            try {
                const result = await InvitationMember.inviteNewMember(
                    {
                        emails: form.emails,
                        invitedUser: '',
                        workspace: appContainer.values.signinWorkspaceObject.id!,
                        signupLimitDays: form.limit,
                    },
                    appContainer.values.signinWorkspaceObject.displayId,
                    appContainer.values.authorizationCode,
                );
                logger.debug(result);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.invited),
                    variant: Variants.success,
                });
                // 入力フォームのクリア
                form.emails.forEach((value) => handleDelete(value, 0));
                form.validateInit.emails = false;
                setErrors({ emails: '' });
                // 招待メンバーのテーブル更新
                onInvite(form.emails);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };
    const handleReSend = (row: InvitationMember.Row, reloadCallback: () => void) => {
        (async () => {
            try {
                let resendLimitDays = form.limit;
                // form.limit === 0 は「無期限」選択
                if (form.limit === 0) resendLimitDays = dayjs('9999-12-31 23:59:59').diff(dayjs(), 'days');
                await InvitationMember.resendInviteNewMember(
                    { displayId: appContainer.values.signinWorkspaceObject.displayId, signupLimitDays: resendLimitDays, email: row.email },
                    appContainer.values.authorizationCode,
                );
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.invited),
                    variant: Variants.success,
                });
                // 招待メンバー一覧を再取得
                reloadCallback();
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
        sessionStorage.setItem('InvitationMemberCurrentPage', newPage.toString());
    };

    // イベントハンドラ内でバブリングを防止
    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        if (parseInt(sessionStorage.getItem('InvitationMemberCurrentPage') || '0', 10) != 0 && event == null) {
            return;
        } else {
            handleChangePage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        sessionStorage.setItem('InvitationMemberRowsPerPage', newRowsPerPage.toString());
        setPage(0);
        sessionStorage.setItem('InvitationMemberCurrentPage', '0');
    };
    const getEmptyRowCount = (invitationRow: InvitationMember.Row[]) => {
        if (!invitationRow) {
            return 5;
        }
        return rowsPerPage - Math.min(rowsPerPage, invitationRow.length - page * rowsPerPage);
    };

    return {
        errors,
        form,
        errorChipColor,
        handleAdd,
        handleInvite,
        handleDelete,
        handleState,
        getRows,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        handleReSend,
        getEmptyRowCount,
        handlePageChange,
        handleChangeRowsPerPage,
        onInvite,
        ui,
        invitationRow,
    };
};

export const InvitationMemberContainer = createContainer(useInvitationContainer);
