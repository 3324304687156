import locale from '@/common/utils/locale';
import { default as consts } from '@/common/constants';
import * as schema from '@/bundles/schema/typescript/schema';
import { AvailableMethod } from '@/common/constants/authorization';
import { V1ObjectsMailResourceType } from '@/bundles/schema/typescript/schema';
import axios from 'axios';
import { useState } from 'react';

// メールステータス取得
export const useInviteMailState = (type: V1ObjectsMailResourceType, id: string, auth: string, wsUserId?: string | undefined, email?: string, abortController?: AbortController) => {
    const [status, setStatus] = useState<string>();
    const client = axios.create({
        baseURL: `https://${consts.environment.api.hostname}`,
        headers: { authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    const options = abortController ? { signal: abortController.signal } : undefined;

    const getInviteMailStatus = () => {
            client
                .get<schema.V1MailShowResponse>(`/v1/mail-event/${id}?resourceType=${type}&email=${email}&workspaceUserId=${wsUserId}`, options)
                .then((response) => {
                    switch (response.data.status) {
                        case schema.V1ObjectsMailEvent.Processed:
                            setStatus(locale.t(locale.keys.invitationNewWorkspace.tab3.mailStatus.processed));
                            break;
                        case schema.V1ObjectsMailEvent.Delivered:
                            setStatus(locale.t(locale.keys.invitationNewWorkspace.tab3.mailStatus.deliverd));
                            break;
                        case schema.V1ObjectsMailEvent.Open:
                        case schema.V1ObjectsMailEvent.Click:
                            setStatus(locale.t(locale.keys.invitationNewWorkspace.tab3.mailStatus.open));
                            break;
                        case schema.V1ObjectsMailEvent.Bounce:
                        case schema.V1ObjectsMailEvent.Dropped:
                        case schema.V1ObjectsMailEvent.Spamreport:
                        case schema.V1ObjectsMailEvent.Deferred:
                            setStatus(locale.t(locale.keys.invitationNewWorkspace.tab3.mailStatus.dropped));
                            break;
                        case schema.V1ObjectsMailEvent.Unsent:
                            setStatus(locale.t(locale.keys.invitationNewWorkspace.tab3.mailStatus.unsent));
                            break;
                        default:
                            setStatus(locale.t(locale.keys.invitationNewWorkspace.tab3.mailStatus.failed));
                            break;
                    }
                })
                .catch((error) => {
                    setStatus(locale.t(locale.keys.invitationNewWorkspace.tab3.mailStatus.failed));
                });
    };
    return { status, getInviteMailStatus };
};
