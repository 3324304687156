import { default as React } from 'react';
import { withStyles, createStyles, Theme, Tabs, Tab } from '@material-ui/core';

export const StyledTabs = withStyles(() =>
    createStyles({
        root: {
            margin: '16px 0',
            minHeight: 32,
            display: 'inline-block',
            borderBottom: '2px solid #ff7300',
        },
        indicator: {
            display: 'none',
        },
    }),
)(Tabs);

interface StyledTabProps {
    label: string;
    value: any;
    disabled?: boolean;
}

export const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 32,
            minWidth: 107,
            padding: 0,
            margin: '0 5px',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '&:hover': {
                opacity: 1,
            },
            '&$selected': {
                color: 'white',
                backgroundColor: theme.palette.secondary.main,
            },
            '&$selected :active': {
                color: 'white',
            },
            '&:active': {
                color: theme.palette.secondary.main,
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab {...props} />);
