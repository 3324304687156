import { default as React, useState } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import { useParams } from 'react-router-dom';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { DetailDeviceContainer } from './DetailDeviceContainer';
import EditForm from './EditForm';
import TableRoot from './TableRoot';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import ErrorBoundary from '@/common/utils/ErrorBoundary';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            position: 'relative',
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    useTitle(locale.t(locale.keys.manageShareDevice.edit));
    const [previousRoute, setPreviousRoute] = useState('');
    const [previousTitle, setPreviousTitle] = useState('');
    // deviceIdをパスパラメータから取得してContextに渡す
    const { id } = useParams<{ id: string }>();

    const setBackRoute = (path: string) => {
        if (path) {
            setPreviousRoute(path);
        }
    };

    const setBackTitle = (title: string) => {
        if (title) {
            setPreviousTitle(title);
        }
    };

    return (
        <>
            <SubHeader title={locale.t(locale.keys.manageShareDevice.edit)} isBack={previousRoute} backTitle={previousTitle} />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    <DetailDeviceContainer.Provider initialState={id}>
                        <ErrorBoundary container={appContainer}>
                            <EditForm />
                            <TableRoot setPreviousRoute={setBackRoute} setPreviousTitle={setBackTitle} />
                        </ErrorBoundary>
                    </DetailDeviceContainer.Provider>
                </div>
            </div>
        </>
    );
};

export default withStyles(styles)(Component);
