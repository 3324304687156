// https://ja.react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
import React from 'react';
import { StateContainer } from '@/common/components/AppContainerBase';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as locale from '@/common/utils/locale/locale';
import { State as UIState } from '@/common/components/hooks/useUI';

interface Props {
    children: React.ReactNode;
    container?: StateContainer;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // You can also log the error to an error reporting service
        if (this.props.container) {
            this.props.container.updateLoadingState(UIState.Loaded);
            this.props.container.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.error.unknown),
                variant: Variants.error,
            });
            return;
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <></>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
