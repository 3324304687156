import * as schema from '@/bundles/schema/typescript/schema';
import * as locale from '@/common/utils/locale/locale';
import { serviceName } from '@/user/constants/cloud-settings';
import logger from './logger';
import * as iconv from 'iconv-lite';
import stringWidth from 'string-width';
import * as papaparse from 'papaparse';
import { isNullOrEmptyArray } from '@/common/utils/array-helper/arrayHelper';

export const getEnumKey = (value: string, object: object) => {
    const data = JSON.parse(JSON.stringify(object));
    for (const key in data) {
        if (data[key] === value) {
            return key;
        }
    }
    return '';
};

export const getServiceText = (service?: string) => {
    if (service === schema.V1ObjectsServiceEnum.Googledrive) {
        return locale.t(locale.keys.cloudConnection.service.google);
    }
    if (service === schema.V1ObjectsServiceEnum.Googleteamdrive) {
        return locale.t(locale.keys.cloudConnection.service.googleTeam);
    }
    if (service === schema.V1ObjectsServiceEnum.Onedrive) {
        return locale.t(locale.keys.cloudConnection.service.oneDrive);
    }
    if (service === schema.V1ObjectsServiceEnum.Sharepointonline) {
        return locale.t(locale.keys.cloudConnection.service.sharePointOnline);
    }
    if (service === schema.V1ObjectsServiceEnum.Nonecloud) {
        return locale.t(locale.keys.cloudConnection.service.noneCloud);
    }
    if (service === schema.V1ObjectsServiceEnum.Docard) {
        return locale.t(locale.keys.cloudConnection.service.docard);
    }
    if (service === schema.V1ObjectsServiceEnum.Docab) {
        return locale.t(locale.keys.cloudConnection.service.docab);
    }
    if (service === schema.V1ObjectsServiceEnum.Email) {
        return locale.t(locale.keys.cloudConnection.service.email);
    }
    const text = getEnumKey(service || schema.V1ObjectsServiceEnum.Nonecloud, schema.V1ObjectsServiceEnum);
    return text;
};

export const getEnumDataByServiceName = (value: string, object: Object) => {
    const data = JSON.parse(JSON.stringify(object));
    for (const key in data) {
        if (data[key] === value) {
            const returnData = JSON.parse(JSON.stringify(serviceName));
            return returnData[key] || key;
        }
    }
    return '';
};

export const getEnumData = (value: string, object: object) => {
    const data = JSON.parse(JSON.stringify(object));
    for (const key in data) {
        if (data[key] === value) {
            return data[key];
        }
        if (key === value) {
            return data[key];
        }
    }
    return '';
};

// This is code of ・ and ￥
const CT_MACHINE_DEF_CHARLIST = ['\u2022', '\u00A5'];

const hasMDC = (s: string) => {
    if (CT_MACHINE_DEF_CHARLIST.includes(s)) {
        return true;
    }
    return false;
};

export const isNotGarble = (data: string) => {
    try {
        if (!data) {
            return true;
        }
        let tempStr = '';
        for (let i = 0; i < data.length; i++) {
            const char = data[i];
            if (hasMDC(char)) {
                tempStr += char;
                continue;
            }
            // encode then decode to make sure character is not garble
            tempStr += iconv.decode(iconv.encode(char, 'MS932'), 'MS932');
        }
        return data === tempStr;
    } catch (err) {
        logger.error(err);
        return false;
    }
};

export const removeEmojiChar = (data: string) => {
    let newInput = '';
    if (!data) {
        return '';
    }
    for (let i = 0; i < data.length; i++) {
        const char = data[i];
        if (!isNotGarble(char)) {
            continue;
        }
        newInput += char;
    }
    return newInput;
};

export const getStringByteCount = (input: string) => {
    return stringWidth(input);
};

/**
 * 指定されたバイト数の制限内で文字列を表示する。
 * 制限バイト数内に収まる部分文字列に省略記号...を追加して返す。
 * @param input
 * @param limit
 * @returns 制限バイト数に近い文字数の文字列
 */
export const showStringWithLimitByte = (input: string, limit: number) => {
    if (getStringByteCount(input) <= limit) {
        return input;
    }
    let displayString = input.slice(0, limit);
    while (getStringByteCount(displayString) > limit) {
        displayString = displayString.slice(0, displayString.length - 1);
    }
    return `${displayString}...`;
};

/**
 * 指定文字数以上の文字列を中略する
 * @param input 入力
 * @param limit 文字数制限
 * @return 制限文字数に近い文字数の文字列
 */
export const showEllipsisString = (input: string, limit: number) => {
    // 入力の確認
    if (!input) {
        return '';
    }
    // 制限文字数以下ならそのまま返す
    if (input.length <= limit) {
        return input;
    }
    const CharacterLength = Math.floor(limit / 2) - 1;
    const firstString = input.substring(0, CharacterLength);
    const lastString = input.substring(input.length - CharacterLength);
    return `${firstString}...${lastString}`;
};
// 改行で分割したstring配列を返す
export const getSplitNewLineString = (input: string): string[] => {
    return input.split(/\r\n|\n/);
};

// ""で囲まれている場合""を削除
export const removeDoubleQuotation = (input: string): string => {
    if (!input) {
        return '';
    }
    // 2文字以上、先頭・末尾に"があるか
    if (input.length >= 2 && input.charAt(0) === '"' && input.charAt(input.length - 1) === '"') {
        return input.substring(1, input.length - 1);
    }
    // ""で囲まれていない場合そのまま返す
    return input;
};

// CSVファイルの解析
export const csvPaser = (input: string, encode: string): string[] => {
    if (input === '') {
        return [''];
    }
    // https://github.com/mholt/PapaParse
    const options: papaparse.ParseConfig = {
        delimiter: ',', // カンマを指定
        encoding: encode, // エンコード文字 FileReader APIでサポートされている値
    };
    const spliteComma = papaparse.parse<string[]>(input, options);
    // パースエラー
    if (!isNullOrEmptyArray(spliteComma.errors) || !spliteComma.data[0]) {
        /**
         * 現状はダブルクォートがエスケープされていないまたは正しく閉じられていない場合のみエラーが発生する
         * 区切り文字：明示的に指定しているため、区切り文字起因のエラーはない
         * フィールド（行ごとに列数が異なる異常）：一括登録からは1行ずつ読み込むため、フィールド起因のエラーはない
         * https://www.papaparse.com/docs#errors
         */
        throw new Error('CSV parse error');
    }
    return spliteComma.data[0];
};

// 指定文字で切り分け
export const splitString = (input: string, symbol: string): string[] => {
    let result = input.split(symbol);
    result = result.map((value) => {
        return removeSpace(value);
    });
    return result;
};

// スペース削除
export const removeSpace = (input: string): string => {
    input = input.split(' ').join('');
    return input;
};

/**
 * スリープ処理
 * @param ms ミリ秒単位
 * @returns
 */
export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * バックオフ時間(s)を計算してスリープする.
 * 0回目のリトライはスリープしない.
 * 60秒を超えるスリープの場合は60秒未満にする.
 * 2, 4, 8, 16, 32, 64
 *
 * 参考アルゴリズム.
 * https://developers.google.com/drive/api/guides/limits?hl=ja#exponential
 *
 * 使用例:
 * ```
 * for (let n = 0; n <= 7; n++) {
 *  const sleepMs = await backoffSleep(n);
 *  logger.info(`Retry: ${n}, Sleep: ${sleepMs}ms`);
 *  ...処理...
 * }
 * ```
 * @param retries リトライ回数
 */
export const backoffSleep = async (retries: number): Promise<number> => {
    if (retries <= 0) return 0;
    let s = backoff(retries);
    // s * randomMsが最大で60秒未満になるようにする
    if (s > 60) {
        s = 60;
    }
    // リトライ間隔を分散させるためにランダムなmsを追加する
    const randomMs = Math.floor(Math.random() * 1000);
    const sleepMs = s * randomMs;

    // s * randomMs秒スリープする
    await sleep(sleepMs);
    return sleepMs;
};

/**
 * バックオフ時間(s)を計算する.
 * 10回目で1024秒(約17分)になる.
 * @param retries リトライ回数
 * @param pages ページ数
 * @returns バックオフ時間
 */
const backoff = (retries: number): number => {
    let delay = 1;
    if (!retries) {
        return delay;
    }
    delay = Math.pow(2, retries);
    return delay;
};

/**
 * 全角数字を半角に変換する。
 * CSVから受け取ったパラメータのフォーマット修正を行う。
 * @param str 対象文字列
 * @returns
 */
export const zenkaku2Hankaku = (str: string) => {
    return str.replace(/[０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
};
