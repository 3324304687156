import { default as React, useEffect } from 'react';
import { default as dashboardImg } from '@/common/img/dashboard-img/index';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import useTitle from '@/common/components/hooks/useTitle';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import Error from '@/common/components/state/Error';
import Loading from '@/common/components/state/Loading';
import Saving from '@/common/components/state/Saving';
import SubHeader from '@/admin/components/common/subheader/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI();

    useTitle(locale.t(locale.keys.pageTitle.dashboard.adminIndex));

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SubHeader title={locale.t(locale.keys.pageTitle.dashboard.adminIndex)} />
            <div className={props.classes.mainDiv}>
                {ui.current === UI.Loading && (
                    <div data-testid={UI.Loading}>
                        <Loading />
                    </div>
                )}
                {ui.current === UI.Loaded && (
                    <div data-testid={UI.Loaded}>
                        <img src={dashboardImg.dashboardImg.siosDashboard2x} alt="img" />
                    </div>
                )}
                {ui.current === UI.Saving && (
                    <div data-testid={UI.Saving}>
                        <Saving />
                    </div>
                )}

                {ui.current === UI.Error && (
                    <div data-testid={UI.Error}>
                        <Error />
                    </div>
                )}
            </div>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
