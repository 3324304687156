import querystring from 'querystring';
import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const indexOrderList = async (auth: string, offset?: string, limit?: string, createDate?: string, ownerName?: string, service?: string, allowedDomain?: string, timeZone?: string) => {
    const query = querystring.stringify({
        offset,
        limit,
        createDate,
        ownerName,
        service,
        allowedDomain,
        timeZone,
    });
    const response = await axiosFactory.get<schema.V1XStoragesOrderIndexResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/order?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const createOrder = async (order: schema.V1XStoragesOrderCreateRequest, auth: string) => {
    const response = await axiosFactory.post<string>(`https://${consts.environment.api.hostname}/v1/x-storages/order`, order, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const resendEmail = async (order: schema.V1XStoragesOrderCreateRequest, auth: string, orderId: string) => {
    const response = await axiosFactory.patch<string>(`https://${consts.environment.api.hostname}/v1/x-storages/order/${orderId}`, order, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const indexDetailOrderList = async (auth: string, orderId: string, limit?: string, offset?: string, email?: string, userName?: string, status?: boolean) => {
    const query = querystring.stringify({
        offset,
        limit,
        email,
        userName,
        status,
    });
    const response = await axiosFactory.get<schema.V1XStoragesOrderDetailIndexResponse>(`https://${consts.environment.api.hostname}/v1/x-storages/order/${orderId}?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};
