import { default as React } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { WorkspaceContainer } from '@/admin/components/workspace/WorkspaceContainer';
import Workspace from './Workspace';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });
interface Props extends WithStyles<typeof styles> {}
export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.workspaceSetting.index));

    return (
        <>
            <WorkspaceContainer.Provider>
                <Workspace />
            </WorkspaceContainer.Provider>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
