import React from 'react';
import { getSplitNewLineString } from '@/common/utils/webappUtil';
import { PropTypes, Typography } from '@material-ui/core';

interface Props {
    value: string;
    align?: PropTypes.Alignment | undefined;
    inline?: boolean | undefined;
    variant?: any;
    color?: PropTypes.Color | 'textPrimary' | 'textSecondary' | 'error' | undefined;
}
/**
 * 複数行テキスト(改行(\n)区切り)
 * @param str 改行ありの長めの文字列
 * @returns JSX.Element
 */
export const MultiLineText: React.FC<Props> = (props: Props) => {
    const splite = getSplitNewLineString(props.value);
    const components: JSX.Element[] = [];
    for (let textindex in splite) {
        components.push(
            <Typography key={textindex} align={props.align} variant={props.variant} color={props.color}>
                {splite[textindex]}
            </Typography>,
        );
    }
    return <>{components}</>;
};
