import routes from '@/admin/constants/routes';
import * as services from '@/common/api/workspace/cloud-setting/services';
import * as schema from '@/bundles/schema/typescript/schema';
import useTitle from '@/common/components/hooks/useTitle';
import { default as UI } from '@/common/constants/ui';
import { Variants } from '@/common/components/messages/CommonMessage';
import locale from '@/common/utils/locale';
import { Button, createStyles, Grid, Switch, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { CloudUpload, Delete, FileCopy } from '@material-ui/icons';
import { default as React, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Prompt } from 'react-router';
import { default as consts } from '@/common/constants';
import { default as cloudImages } from '@/common/img/cloud-setting/index';
import { AdminAppContainer } from '../AdminAppContainer';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';
import checkCloudSetting from './checkCloudSetting';
import * as errorHandler from '@/common/utils/errorHandler';
import * as webappUtil from '@/common/utils/webappUtil';
import usePreventWindowUnload from '@/common/components/hooks/usePreventWindowUnload';
import SubHeader from '@/admin/components/common/subheader/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: '35px',
            marginBottom: '24px',
        },
        serviceNameContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        switchContainer1: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #ccc;1px solid #ccc',
            borderTop: '1px solid #ccc;1px solid #ccc',
        },
        switchContainer2: {
            borderTop: '0px',
        },
        switchContainer3: {
            borderTop: '0px',
            flexDirection: 'column',
        },
        authorationBottom: {
            paddingBottom: '16px',
            borderBottom: '1px solid #ccc;1px solid #ccc',
        },
        serviceName: {
            paddingLeft: '12px',
        },
        serviceIcon: {
            width: '32.5px',
            height: '28px',
        },
        switchItem: {
            marginLeft: 'auto',
        },
        upDownLabel: {
            display: 'flex',
            paddingBottom: '24px',
            fontWeight: 'bold',
            height: '45px',
        },
        commonOauthSwitch: {
            display: 'flex',
            alignItems: 'center',
        },
        jsonSettingLabel: {
            textAlign: 'left',
            paddingBottom: '20px',
            paddingTop: '8px',
        },
        jsonFileNameContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '50%',
        },
        jsonFileName: {
            paddingRight: '8px',
        },
        selectButton: {
            textTransform: 'none',
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            marginLeft: '0px',
        },
        selectButtonContainer: {
            width: '100%',
            display: 'flex',
        },
        buttonLabel: {
            color: theme.palette.secondary.main,
            paddingLeft: '4px',
        },
        buttonLabelDisabled: {
            color: theme.palette.grey[400],
            paddingLeft: '4px',
        },
        buttonSave: {
            textTransform: 'none',
            display: 'flex',
            borderRadius: '24px',
            width: theme.spacing.unit * 30,
        },
        covasInput: {
            border: 'none',
            fontSize: '16px',
            width: '60%',
        },
        btnOnclick: {
            marginTop: theme.spacing.unit * 3,
            border: 'solid 1px #ff7300',
        },
        btnOnclickDisable: {
            marginTop: theme.spacing.unit * 3,
            border: 'solid 1px #dbdbdb',
        },
        gridSave: {
            display: 'flex',
            justifyContent: 'center',
        },
        divConfirm: {
            maxWidth: theme.spacing.unit * 31.25,
        },
        notes: {
            paddingTop: theme.spacing.unit * 3,
            textAlign: 'left',
            '&& p': {
                fontWeight: 'bold',
                color: '#333333',
            },
        },
    });

type TParams = { id: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    skipEffect?: boolean;
}

export const Component: React.SFC<Props> = (props) => {
    const { classes } = props;
    const serviceId = props.match.params.id;
    const [ui, setUI] = useState(UI.state.Loading);
    const appContainer = AdminAppContainer.useContainer();
    const [cloudDetail, setCloudDetail] = useState<schema.V1WorkspaceCloudSettingShowResponse>({
        serviceId: 'adipisicing proident',
        serviceName: schema.EndpointType.Docab,
        isUploadable: true,
        isDownloadable: true,
        is2L: false,
        is3L: false,
        apiKey: 'Duis dolor in Excepteur',
    });
    const [jsonFiles, setJsonFiles] = useState([
        {
            name: '',
            content: '',
        },
    ]);
    const [isClicked, setIsClicked] = useState(false);
    const [disableSelect, setDisableSelect] = React.useState(false);
    const [deleteFlag, setDeleteFlag] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const list = [];
    let cloudIcon;
    usePreventWindowUnload(isEdit);
    useTitle(locale.t(locale.keys.adminCloudDetail.title));

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dataJsonFiles: { name: string; content: string }[] = [];
        const reader = new FileReader();
        let isExist = false;

        for (let i = 0; i < jsonFiles.length; i += 1) {
            if (e && e.target && e.target.files && 0 < e.target.files.length && e.target.files[0].name === jsonFiles[i].name) {
                isExist = true;
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.adminCloudDetail.warning),
                    variant: Variants.warning,
                });
            }
        }

        if (!isExist && e && e.target && e.target.files && 0 < e.target.files.length) {
            const element = e.target.files[0];
            reader.readAsBinaryString(element);
            reader.onload = () => {
                const data = String(reader.result);
                const strBase64 = window.btoa(data);
                dataJsonFiles.push({
                    name: element.name,
                    content: strBase64,
                });
                setJsonFiles(dataJsonFiles);
            };
        }
        // setDisableSelect(true);
        setIsEdit(true);
    };

    const onInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setIsClicked(true);
        setIsEdit(true);
        if (!cloudDetail.is2L || disableSelect || isClicked) {
            e.preventDefault();
        }
        const element = e.target as HTMLInputElement;
        element.value = '';
        setTimeout(() => {
            setIsClicked(false);
        }, 300);
    };

    const deleteJsonFile = (index: number) => {
        const dataList = [...jsonFiles];
        dataList.splice(index, 1);
        setJsonFiles(dataList);
        setDisableSelect(false);
        setDeleteFlag(true);
        setIsEdit(true);
    };

    const handleSwitch = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCloudDetail({ ...cloudDetail, [name]: event.target.checked });
        setIsEdit(true);
    };

    const isDisableSave = () => {
        if (jsonFiles.length < 1 && cloudDetail.is2L && cloudDetail.serviceName === schema.EndpointType.Box) {
            return true;
        }
        return false;
    };

    const isDisableSelect = () => {
        if (!cloudDetail.is2L || disableSelect) {
            return true;
        }
        return false;
    };

    const saveChange = () => {
        setIsEdit(false);
        if (jsonFiles.length !== 0 && jsonFiles[0].name === '') {
            jsonFiles.shift();
        }
        if (!cloudDetail) {
            return;
        }
        const objectRequest: schema.V1WorkspaceCloudSettingUpdateRequest = {
            jsonFiles,
            serviceId: cloudDetail.serviceId,
            is2L: cloudDetail.is2L,
            is3L: cloudDetail.is3L,
            isDownloadable: cloudDetail.isDownloadable,
            isUploadable: cloudDetail.isUploadable,
            serviceName: cloudDetail.serviceName,
            jsonFileDeleted: deleteFlag,
        };

        if (cloudDetail.serviceName !== schema.EndpointType.Box) {
            objectRequest.jsonFiles = [];
        }
        if (cloudDetail.serviceName === schema.EndpointType.Box && jsonFiles[0] && jsonFiles[0].content === '') {
            objectRequest.jsonFiles = [];
        }
        if (!cloudDetail.is2L && jsonFiles) {
            objectRequest.jsonFiles = [];
        }

        update(objectRequest);
        setDeleteFlag(false);
        // routerContext.history.push(routes.cloudSetting.index);
    };

    const update = (objectRequest: schema.V1WorkspaceCloudSettingUpdateResponse) => {
        void (async () => {
            try {
                await services.update(appContainer.values.signinWorkspaceObject.id || '', serviceId, objectRequest, appContainer.values.authorizationCode);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.updated),
                    variant: Variants.success,
                });
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    if (jsonFiles[0]) {
        list.push(
            <div className={classes.jsonFileNameContainer} key={0}>
                <Typography className={classes.jsonFileName}>{jsonFiles[0].name}</Typography>
                <Delete style={{ fontSize: '20px', marginLeft: 'auto' }} onClick={() => deleteJsonFile(0)} />
            </div>,
        );
    }

    const copyOnClick = () => {
        const copyText: HTMLInputElement = document.getElementById(`SIOSCovasSDCId`) as HTMLInputElement;
        if (copyText) {
            copyText.select();
            document.execCommand('copy');
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.copied),
                variant: Variants.success,
            });
        }
    };

    if (checkCloudSetting.isOAuthAvailable(cloudDetail) && checkCloudSetting.isBothUploadDownloadAvailable(cloudDetail)) {
        cloudIcon = <img src={cloudImages.cloudImages.full} alt="img" className={classes.serviceIcon} />;
    }
    if (checkCloudSetting.isOAuthAvailable(cloudDetail) && checkCloudSetting.isOnlyUploadAvailable(cloudDetail)) {
        cloudIcon = <img src={cloudImages.cloudImages.upload} alt="img" className={classes.serviceIcon} />;
    }
    if (checkCloudSetting.isOAuthAvailable(cloudDetail) && checkCloudSetting.isOnlyDownloadAvailable(cloudDetail)) {
        cloudIcon = <img src={cloudImages.cloudImages.download} alt="img" className={classes.serviceIcon} />;
    }
    if (checkCloudSetting.isOAuthAvailable(cloudDetail) && checkCloudSetting.isUploadDownloadProhibited(cloudDetail)) {
        cloudIcon = <img src={cloudImages.cloudImages.none} alt="img" className={classes.serviceIcon} />;
    }
    if (checkCloudSetting.isOAuthProhibited(cloudDetail)) {
        cloudIcon = <img src={cloudImages.cloudImages.none} alt="img" className={classes.serviceIcon} />;
    }

    useEffect(() => {
        appContainer.updateLoadingState(ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        if (props.skipEffect) {
            setUI(UI.state.Loaded);
            return;
        }
        // if (jsonFiles.length > 0 && jsonFiles[0].name !== '') {
        //     setDisableSelect(true);
        // }
        void (async () => {
            try {
                setUI(UI.state.Loading);
                const res = await services.getServiceDetail(appContainer.values.signinWorkspaceObject.id || '', serviceId, appContainer.values.authorizationCode);
                setUI(UI.state.Loaded);
                if (!res) {
                    throw new Error('fail to load');
                }
                setCloudDetail(res);
                const dataJsonFilesName: { name: string; content: string }[] = [];
                if (res.jsonFileNames && res.jsonFileNames.length > 0) {
                    res.jsonFileNames.forEach((element) => {
                        dataJsonFilesName.push({
                            name: element,
                            content: '',
                        });
                    });
                }
                setJsonFiles(dataJsonFilesName);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SubHeader title={locale.t(locale.keys.adminCloudDetail.title)} isBack={routes.cloudSetting.index} backTitle={locale.t(locale.keys.adminCloudDetail.title)} />
            {isLoading(ui) && <div data-testid={UI.state.Loading} />}
            {isLoaded(ui) && cloudDetail && (
                <div data-testid={UI.state.Loaded} className={props.classes.mainDiv}>
                    <Grid container spacing={32} className={classes.root}>
                        <Grid item sm={4}>
                            <div className={classes.serviceNameContainer}>
                                {cloudIcon}
                                <Typography variant="h3" className={classes.serviceName}>
                                    {webappUtil.getServiceText(cloudDetail.serviceName)}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sm={4}>
                            <div>
                                <Typography variant="h4" className={classes.upDownLabel}>
                                    {locale.t(locale.keys.adminCloudDetail.subTitle)}
                                </Typography>
                                <div className={classes.switchContainer1}>
                                    <Typography variant="h4">{locale.t(locale.keys.adminCloudDetail.uploadable)}</Typography>
                                    <Switch onChange={handleSwitch('isUploadable')} className={classes.switchItem} checked={cloudDetail.isUploadable} />
                                </div>
                                <div className={`${classes.switchContainer1} ${classes.switchContainer2}`}>
                                    <Typography variant="h4">{locale.t(locale.keys.adminCloudDetail.downloadable)}</Typography>
                                    <Switch
                                        disabled={cloudDetail.serviceName === schema.EndpointType.Docard || cloudDetail.serviceName === schema.EndpointType.Email}
                                        onChange={handleSwitch('isDownloadable')}
                                        className={classes.switchItem}
                                        checked={cloudDetail.isDownloadable}
                                    />
                                </div>
                                <div className={classes.notes}>
                                    <Typography>{locale.t(locale.keys.adminCloudDetail.note)}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={4}>
                            <div>
                                <Typography className={classes.upDownLabel}>{locale.t(locale.keys.adminCloudDetail.oauthLabel)}</Typography>
                                <div className={classes.switchContainer1}>
                                    <Typography>{locale.t(locale.keys.adminCloudDetail.label3L)}</Typography>
                                    <Switch onChange={handleSwitch('is3L')} className={classes.switchItem} checked={cloudDetail.is3L} />
                                </div>
                                {/* // TODO: Show 2L again in the future */}
                                {/* // {cloudDetail.serviceName !== schema.EndpointType.Dropbox && ( */}
                                {false && cloudDetail.serviceName !== schema.EndpointType.Dropbox && (
                                    <div className={classes.authorationBottom}>
                                        <div className={classes.commonOauthSwitch}>
                                            <Typography>{locale.t(locale.keys.adminCloudDetail.label2L)}</Typography>
                                            <Switch onChange={handleSwitch('is2L')} className={classes.switchItem} checked={cloudDetail.is2L} />
                                        </div>
                                        {cloudDetail.serviceName === schema.EndpointType.Googledrive || cloudDetail.serviceName === schema.EndpointType.Googleteamdrive ? (
                                            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                                <Typography className={classes.jsonSettingLabel}>{locale.t(locale.keys.adminCloudDetail.siosCovasAccId)}</Typography>
                                                <input
                                                    className={classes.covasInput}
                                                    id="SIOSCovasSDCId"
                                                    contentEditable={false}
                                                    onChange={() => {}}
                                                    value={
                                                        cloudDetail.serviceName === schema.EndpointType.Googledrive
                                                            ? consts.environment.google.serviceId
                                                            : consts.environment.google.serviceGoogleTeamDriveId
                                                    }
                                                />
                                                <Button className={cloudDetail.is2L ? classes.btnOnclick : classes.btnOnclickDisable} onClick={copyOnClick} disabled={!cloudDetail.is2L}>
                                                    <FileCopy />
                                                    {locale.t(locale.keys.adminCloudDetail.btnCopy)}
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Typography className={classes.jsonSettingLabel}>{locale.t(locale.keys.adminCloudDetail.jsonSetting)}</Typography>
                                                <div style={{ marginBottom: '16px' }}>{list}</div>
                                                <div className={classes.selectButtonContainer}>
                                                    <input
                                                        accept=".json"
                                                        style={{ display: 'none' }}
                                                        id="contained-button-file"
                                                        type="file"
                                                        onChange={(e) => onSelectFile(e)}
                                                        onClick={(e) => onInputClick(e)}
                                                    />
                                                    <label htmlFor="contained-button-file">
                                                        <Button variant="outlined" className={classes.selectButton} component="span" disabled={isDisableSelect()}>
                                                            <CloudUpload />
                                                            <Typography className={!cloudDetail.is2L || disableSelect ? classes.buttonLabelDisabled : classes.buttonLabel}>
                                                                {locale.t(locale.keys.adminCloudDetail.btnSelect)}
                                                            </Typography>
                                                        </Button>
                                                    </label>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={props.classes.gridSave}>
                        <Button variant="contained" className={classes.buttonSave} disabled={isDisableSave()} onClick={saveChange}>
                            {locale.t(locale.keys.adminCloudDetail.btnSave)}
                        </Button>
                    </Grid>
                    <Prompt when={isEdit} message={locale.t(locale.keys.promptMessage)} />
                </div>
            )}

            {isError(ui) && <div data-testid={UI.state.Error} />}
        </>
    );
};

export default withStyles(styles)(withRouter(Component));
