/**
 * Route definitions.
 * Heavily inspired by Rails routing.
 * https://guides.rubyonrails.org/routing.html#crud-verbs-and-actions
 */

const routes = {
    auth: {
        // /auth以下は全て認証なしでアクセスできる画面
        index: '/auth',
        login: {
            index: '/auth/login/',
            // /auth/loginと役割がかぶっている。本来はワークスペース選択後のパスワード認証画面用であるが、実際は/auth/loginをそのまま利用している状態である（/auth/login/:idでもパスワード認証ができることはできる。）。
            selectedWorkspace: '/auth/login/:id',
        },
        recover: {
            index: '/auth/recover',
        },
        logout: {
            index: '/auth/logout',
        },
        verify: {
            index: '/auth/verify',
        },
        signup: {
            index: '/auth/signup',
        },
        workspaceSignup: {
            index: '/auth/workspace-signup',
        },
    },
    changePassword: {
        index: '/change/password',
    },
    dashboard: {
        index: '/',
        spool: '/spool',
    },
    sharedCloudConnection: {
        index: '/shared-cloud-connection',
        docardAuth: '/docard-auth',
        auth: '/docab-auth',
    },
    cloudConnection: {
        index: '/cloud-connection',
        docardAuth: '/docard-auth',
        auth: '/docab-auth',
        authRequest: '/cloud-connection/auth',
    },
    userProfile: {
        index: '/user-profile',
    },
    mfp: {
        index: '/mfp',
    },
    sharedEndpoints: {
        index: '/shared-endpoints',
        new: '/shared-endpoints/new',
        show: '/shared-endpoints/:id',
        edit: '/shared-endpoints/:id/edit',
        cloud: '/shared-endpoints/:id/share/:num/cloud',
        folders: {
            index: '/shared-endpoints/:id/folders',
        },
        filename: {
            index: '/shared-endpoints/:id/filename',
        },
        scan: {
            index: '/shared-endpoints/:id/mfp-setting',
            edit: '/shared-endpoints/:id/mfp-setting/:mfpSettingId',
        },
    },
    endpoints: {
        index: '/endpoints',
        new: '/endpoints/new',
        show: '/endpoints/:id',
        edit: '/endpoints/:id/edit',
        cloud: '/endpoints/:id/share/:num/cloud',
        folders: {
            index: '/endpoints/:id/folders',
        },
        filename: {
            index: '/endpoints/:id/filename',
        },
        scan: {
            index: '/endpoints/:id/mfp-setting',
            edit: '/endpoints/:id/mfp-setting/:mfpSettingId',
        },
    },
    parts: {
        index: '/parts',
    },
    forbidden: {
        index: '/forbidden',
    },
    unsubscribe: {
        index: '/unsubscribe',
    },
};

export default routes;
