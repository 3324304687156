import { default as React } from 'react';
import locale from '@/common/utils/locale';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import * as Invitation from '@/common/api/sub-workspace/inivite/Invitation';
import * as plan from '@/admin/constants/plan';
import { InvitationContainer } from '@/admin/components/sub-workspace/invite/InvitationContainer';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
            textAlign: 'left',
        },
        text: {
            textAlign: 'center',
        },
        inviteGrid: {
            textAlign: 'center',
        },
        inviteButton: {
            color: 'white',
            minWidth: 240,
        },
        label: {
            textAlgin: 'left',
            color: 'gray',
        },
        root: {
            display: 'flex',
            border: '2px solid #bbbbbb',
            justifyContent: 'left',
            flexWrap: 'wrap',

            minHeight: 90,
            margin: 10,
            padding: 10,
        },
        chip: {
            textAlign: 'left',
            marginTop: '15px',
        },
        formControl: {},
        select: {
            minWidth: '278px',
        },
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
            marginTop: '-5px',
            minWidth: '278px',
        },
        selectInput: {
            padding: '28px 12px 9px 12px',
        },
    });
interface Props extends WithStyles<typeof styles> {
    onInvite: (row: Invitation.OnInviteRow) => void;
}
export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = InvitationContainer.useContainer();

    return (
        <div>
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={8}>
                        <Grid item xs={11} sm={11} lg={4} className={classes.inviteGrid}>
                            <TextField
                                className={classes.textField}
                                label={locale.t(locale.keys.invitationNewWorkspace.tab3.emailAddress)}
                                placeholder=""
                                margin="normal"
                                variant="filled"
                                value={container.form.email}
                                inputProps={{ style: { height: '100%' } }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangeEmail(event.target.value)}
                                error={container.validate().email !== ''}
                                helperText={container.validate().email}
                            />
                        </Grid>
                        <Grid item xs={11} sm={11} lg={4} className={classes.inviteGrid}>
                            <TextField
                                className={classes.textField}
                                label={locale.t(locale.keys.invitationNewWorkspace.tab3.workspaceId.index)}
                                placeholder=""
                                margin="normal"
                                variant="filled"
                                value={container.form.displayId}
                                inputProps={{ style: { height: '100%' } }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangeInvitationDisplayId(event.target.value)}
                                error={container.validate().displayId !== ''}
                                helperText={container.validate().displayId}
                            />
                        </Grid>
                        <Grid item xs={11} sm={11} lg={4} className={classes.inviteGrid}>
                            <FormControl className={classes.textField}>
                                <InputLabel htmlFor="filled-period" variant="filled">
                                    {locale.t(locale.keys.common.plan.index)}
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    id="period"
                                    value={container.form.planBrand}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => container.handleChangePlanBrand(event.target.value)}
                                    input={<FilledInput name="period" id="filled-period" classes={{ input: classes.selectInput }} />}
                                    inputProps={{
                                        style: { height: '1.46em', lineHeight: 'normal' },
                                    }}
                                >
                                    {// systemのworkspaceの場合、brandとplanを選択
                                    // system以外のworkspaceの場合planのみ選択(brand固定)
                                    container.isCompanyBrand()
                                        ? plan.computePlanItem().map((e) => <MenuItem value={`${e.plan}:${container.getCurrentBrand()}`}>{e.message}</MenuItem>)
                                        : plan.computeBrandPlanItem().map((e) => <MenuItem value={`${e.plan}:${e.brand}`}>{e.message}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <br />
                        <Grid item xs={11} sm={12} className={classes.inviteGrid}>
                            <Fab
                                disabled={
                                    !container.form.validateInit.email ||
                                    container.validate().email !== '' ||
                                    !container.form.validateInit.displayId ||
                                    container.validate().displayId !== '' ||
                                    !container.form.validateInit.planBrand ||
                                    container.form.lock
                                }
                                variant="extended"
                                onClick={() => container.handleInvite(props.onInvite)}
                                className={classes.inviteButton}
                                size="large"
                                color="secondary"
                            >
                                {locale.t(locale.keys.invitationNewWorkspace.tab3.invitation.index)}
                            </Fab>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default withStyles(styles)(Component);
