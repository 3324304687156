import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';
import querystring from 'querystring';
import { backoffSleep } from '@/common/utils/webappUtil';
import { AxiosError, AxiosResponse, AxiosHeaders } from 'axios';
import * as errorLocale from '@/common/utils/locale/error-locale';
/**
 * アナリティクス結果のダウンロードリクエスト
 */
export const getDownload = async (workspaceId: string, auth: string, downloadRequestId: string) => {
    const query = querystring.stringify({
        downloadRequestId,
    });

    let retryCount = 0;
    let response: AxiosResponse<schema.V1WorkspaceAnalyticsDownloadCreateResponse | Blob, any>;

    // ステータスコード200の場合にダウンロード用のCSVファイルが返却される。
    // ステータスコード202の場合は、ダウンロード用のCSV作成待ち状態なので、200が返却されるまでポーリング。
    do {
        response = await axiosFactory.get<schema.V1WorkspaceAnalyticsDownloadCreateResponse | Blob>(
            `https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/analytics/download?${query}`,
            {
                headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
                responseType: 'blob',
            },
        );
        if (response.status !== 200) {
            await backoffSleep(retryCount);
        }
        retryCount++;
    } while (response.status !== 200 && retryCount < 10);

    if (response.status === 200) {
        return response.data as Blob;
    }

    // リトライ処理の中でCSVを得られなかったら、タイムアウトエラーを作成する。
    const error: AxiosResponse<schema.V1ErrorsErrorResponse> = {
        data: {
            error: {
                code: errorLocale.keys.E99001,
                message: errorLocale.translate(errorLocale.keys.E99001).message,
                requestId: '',
                resource: '',
            },
            error_summary: '',
        },
        status: 504,
        statusText: '',
        headers: {},
        config: {
            headers: new AxiosHeaders(),
        },
    };

    throw new AxiosError<schema.V1ErrorsErrorResponse>(undefined, undefined, undefined, undefined, error);
};

/**
 * アナリティクスの作成リクエスト
 */
export const postDownload = async (workspaceId: string, auth: string, req: schema.V1WorkspaceAnalyticsDownloadCreateRequest) => {
    const response = await axiosFactory.post<schema.V1WorkspaceAnalyticsDownloadCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/analytics/download`, req, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const getNotificationConfig = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceAnalyticsNotificationConfigShowResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceAnalyticsNotificationConfigShowResponse>(
        `https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/analytics/notification/config`,
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return response.data;
};

export const patchNotificationConfig = async (
    workspaceId: string,
    auth: string,
    req: schema.V1WorkspaceAnalyticsNotificationConfigUpdateRequest,
): Promise<schema.V1WorkspaceAnalyticsNotificationConfigShowResponse> => {
    const response = await axiosFactory.patch<schema.V1WorkspaceAnalyticsNotificationConfigShowResponse>(
        `https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/analytics/notification/config`,
        req,
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return response.data;
};
