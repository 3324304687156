import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import CloudSetting from './CloudSetting';
import { CloudConnectionContainer } from './CloudSettingContainer';
import locale from '@/common/utils/locale';
import SubHeader from '@/admin/components/common/subheader/SubHeader';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.adminCloudDasboard.title));

    return (
        <>
            <SubHeader title={locale.t(locale.keys.adminCloudDasboard.title)} />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    <CloudConnectionContainer.Provider>
                        <CloudSetting />
                    </CloudConnectionContainer.Provider>
                </div>
            </div>
        </>
    );
};

export default withStyles(styles)(Component);
