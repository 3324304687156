import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import * as AppContainerBase from '@/common/components/AppContainerBase';
import { Variants, IMessage } from '@/common/components/messages/CommonMessage';
import { State, isLoading, isSaving, isLoaded, isError } from '@/common/components/hooks/useUI';
import routes from '@/user/constants/routes';
import { useHistory } from 'react-router-dom';
import * as cookieInterfaces from '@/common/utils/authManagement/cookieInterfaces';
import * as schema from '@/bundles/schema/typescript/schema';
import * as localStorageConstants from '@/common/utils/authManagement/localStorageConstants';

const prefix = localStorageConstants.prefix.userApp;
export const initStateWithCache = () => AppContainerBase.initStateWithCache(prefix);
export const removeCacheAll = () => AppContainerBase.removeCacheAll(prefix);
const useUserAppContainer = (): AppContainerBase.StateContainer => {
    const [values, setValuesInternal] = useState<AppContainerBase.State>(initStateWithCache());
    const history = useHistory();
    // public =======================
    const defaultMessData: IMessage = {
        isOpen: false,
        message: '',
        variant: Variants.info,
        resultCode: undefined,
        autoHideDuration: undefined,
    };
    const [message, updateMessage] = useState(defaultMessData);
    const setValues = (input: AppContainerBase.State) => {
        AppContainerBase.setValues(prefix, input);
        setValuesInternal(input);
    };
    const removeCacheAll = () => AppContainerBase.removeCacheAll(prefix);
    const removeCacheByKey = (key: string) => AppContainerBase.removeCacheByKey(prefix, key);
    const stateNew = () => AppContainerBase.stateNew();
    const switchStateWithCache = (displayId: string) => AppContainerBase.switchStateWithCache(displayId, prefix);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [onBackError, setOnBackError] = useState(false);

    const updateLoadingState = (ui: State) => {
        if (isLoading(ui) || isSaving(ui)) {
            setLoadingState(true);
        }
        if (isLoaded(ui) || isError(ui)) {
            setLoadingState(false);
        }
    };

    const getPrefix = () => {
        return prefix;
    };

    // Pagination
    const useInfiniteScroll = (isItemFull: boolean, callback: () => void): AppContainerBase.InfiniteScroll => {
        const [isFetching, setIsFetching] = useState(false);
        useEffect(() => {
            const contentRight = document.getElementById('contentRight');
            if (contentRight) {
                contentRight.addEventListener('scroll', handleScroll);
                return () => {
                    setIsFetching(false);
                    contentRight.removeEventListener('scroll', handleScroll);
                };
            }
            window.addEventListener('scroll', handleScroll);
            return () => {
                setIsFetching(false);
                window.removeEventListener('scroll', handleScroll);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (!isFetching || isItemFull) return;
            callback();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isFetching]);

        const handleScroll = () => {
            const contentRight = document.getElementById('contentRight') as HTMLDivElement;
            const manDiv = document.getElementById('mainDiv') as HTMLDivElement;
            const manDivHeght = !manDiv ? 0 : manDiv.offsetHeight;
            const scrollTopContentRight = !contentRight ? 0 : contentRight.scrollTop;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
            //  For product screens, the innerHeight and scrollTop values are float
            //  So use Math.ceil to ensure the below results are true in both browsers and smartphones
            if (document.documentElement.offsetHeight > Math.ceil(window.innerHeight + scrollTop + 50) || manDivHeght > Math.ceil(window.innerHeight + scrollTopContentRight) || isFetching) return;
            setIsFetching(true);
        };

        return {
            isFetching,
            setIsFetching,
        };
    };

    const signoutRedirect = () => {
        history.push(routes.auth.login.index);
    };

    const onSignin = (container: AppContainerBase.StateContainer, sub: string, workspace: schema.V1ObjectsWorkspace, user: schema.V1ObjectsWorkspaceuserLarge) => {
        AppContainerBase.onSignin(container, sub, workspace, user);
    };

    const onSignout = (container: AppContainerBase.StateContainer, state: AppContainerBase.State) => {
        AppContainerBase.onSignout(container, state);
    };

    const switchWorkspace = (targetWorkspaceId: string, container: AppContainerBase.StateContainer, state: AppContainerBase.State): AppContainerBase.State => {
        return AppContainerBase.switchWorkspace(targetWorkspaceId, container, state);
    };

    const selectAlready = async (displayId: string, container: AppContainerBase.StateContainer) => {
        return AppContainerBase.selectAlready(displayId, container);
    };

    const skipSignin = async (displayId: string, authCookie: cookieInterfaces.ShareAuthInfo, container: AppContainerBase.StateContainer) => {
        return AppContainerBase.skipSignin(displayId, authCookie, container);
    };

    return {
        message,
        updateMessage,
        values,
        setValues,
        removeCacheAll,
        removeCacheByKey,
        stateNew,
        switchStateWithCache,
        getPrefix,
        loadingState,
        setLoadingState,
        updateLoadingState,
        onBackError,
        setOnBackError,
        initStateWithCache,
        useInfiniteScroll,
        signoutRedirect,
        onSignin,
        onSignout,
        switchWorkspace,
        selectAlready,
        skipSignin,
    };
};
export const UserAppContainer = createContainer(useUserAppContainer);
