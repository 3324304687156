import { default as React, useEffect, useState } from 'react';
import { WithStyles, Theme, createStyles, withStyles, Avatar, Button, Card, CardContent, Fab, Grid, Typography } from '@material-ui/core';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { showEllipsisString } from '@/common/utils/webappUtil';
import { CollectiveRegisterContainer } from './CollectiveRegisterContainer';
import { csvFormat } from '@/common/constants/csvFormat';
import { default as Dropzone } from 'react-dropzone';
import { MultiLineText } from '@/common/components/messages/MultiLineText';
import DialogBase, { DialogBaseProps } from '@/common/components/Confirm/DialogBase';
import environment from '@/common/constants/environment';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
            textAlign: 'left',
            marginTop: '8px',
            marginBottom: '16px',
        },
        inviteButton: {
            color: 'white',
            minWidth: 240,
        },
        text: {
            marginTop: 50,
        },
        avatarIcon: {
            borderRadius: 0,
            width: 20,
            height: 20,
        },
        dropzone: {
            backgroundColor: '#eeeeee',
            border: '2px dashed #bdbdbd',
            borderRadius: 6,
            padding: 16,
            marginBlock: '16px',
            maxWidth: '100%',
            minWidth: '100%',
            cursor: 'pointer',
        },
        dropButton: {
            backgroundColor: 'white',
        },
        dropzoneInnerText: {
            paddingTop: '5px',
            textAlign: 'center',
            margin: '0 auto',
            fontSize: '15px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#666666',
        },
        dropzoneInnerDetailText: {
            paddingTop: '10px',
            textAlign: 'center',
            margin: '0 auto',
            fontSize: '10px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#666666',
        },
        linkStyleBtn: {
            fontFamily: ['Noto Sans JP', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'Meiryo', 'sans-serif'].join(','),
            fontSize: '15px',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            color: '#0000EE',
            padding: '0px',
            '&:active': {
                color: '#ff0000',
            },
        },
        prevButton: {
            padding: 0,
            margin: 0,
            minWidth: 0,
            lineHeight: '21px',
            fontSize: '0.875rem',
            fontWeight: 400,
        },
    });

const initialDialogObject: DialogBaseProps = {
    callBack: () => {},
    onClose: () => {},
    isOpen: false,
    title: '',
    renderChildren: () => <></>,
    type: 'alert',
};

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const { csvFileName, buttonDisable, ui, onSelectedCSV, onImportCsv, clickExportCsv } = CollectiveRegisterContainer.useContainer();
    const [displayFileName, setDisplayFileName] = useState<string>(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));
    const [dialogObject, setDialogObject] = useState<DialogBaseProps>(initialDialogObject);

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        setDisplayFileName(showEllipsisString(csvFileName, csvFormat.CSVFILENAME_MAX));
    }, [csvFileName]);

    const onDrop = (accepts: File[]) => {
        // multiple={false}プロパティを指定しているので、必ず要素数は1になる。
        onSelectedCSV(accepts);
    };

    return (
        <div>
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    <Typography align="left" variant="h6">
                        {locale.t(locale.keys.registrationEndpoint.desc1)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left" variant="h6">
                        {locale.t(locale.keys.registrationEndpoint.desc2)}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.text}>
                    <Typography align="left" variant="h5">
                        {locale.t(locale.keys.registrationEndpoint.upload)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Dropzone multiple={false} onDrop={(accepts) => onDrop(accepts)} accept={'.csv'}>
                        {({ getRootProps, getInputProps }) => (
                            <div className={classes.dropzone} {...getRootProps()}>
                                <Grid container spacing={8} direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerText}>{locale.t(locale.keys.registrationEndpoint.csvtitle)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerDetailText}>{locale.t(locale.keys.action.or)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <input {...getInputProps()} />
                                        <Button variant="outlined" type="button" component="label" className={classes.dropButton}>
                                            {locale.t(locale.keys.registrationEndpoint.select)}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerText}>{displayFileName}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </Grid>

                <Grid item container xs={12} justify={'flex-start'} spacing={24}>
                    <Grid item>
                        <Fab
                            disabled={buttonDisable}
                            variant="extended"
                            onClick={() => {
                                setDialogObject({
                                    callBack: () => {
                                        setDialogObject(initialDialogObject);
                                        onImportCsv(schema.ImportMode.Recreate);
                                    },
                                    onClose: () => setDialogObject(initialDialogObject),
                                    isOpen: true,
                                    title: locale.t(locale.keys.registrationEndpoint.message.recreateNotification.title),
                                    renderChildren: () => {
                                        return (
                                            <>
                                                <Typography>{locale.t(locale.keys.registrationEndpoint.message.recreateNotification.desc1)}</Typography>
                                                <ul style={{ margin: 0, padding: 0 }}>
                                                    <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                        {locale.t(locale.keys.registrationEndpoint.message.recreateNotification.desc2)}
                                                    </li>
                                                    <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                        {locale.t(locale.keys.registrationEndpoint.message.recreateNotification.desc3)}
                                                    </li>
                                                </ul>
                                            </>
                                        );
                                    },
                                    type: 'confirm',
                                });
                            }}
                            className={classes.inviteButton}
                            size="large"
                            color="secondary"
                        >
                            <Grid container justify="center" style={{ alignItems: 'flex-end' }}>
                                {locale.t(locale.keys.registrationEndpoint.register.text)}
                                <Typography style={{ color: 'white', fontSize: '1.16rem', fontWeight: 600 }}>{locale.t(locale.keys.registrationEndpoint.register.emphasis)}</Typography>
                            </Grid>
                        </Fab>
                    </Grid>

                    <Grid item>
                        <Fab
                            disabled={buttonDisable}
                            variant="extended"
                            onClick={() => {
                                setDialogObject({
                                    callBack: () => {
                                        setDialogObject(initialDialogObject);
                                        onImportCsv(schema.ImportMode.Append);
                                    },
                                    onClose: () => setDialogObject(initialDialogObject),
                                    isOpen: true,
                                    title: locale.t(locale.keys.registrationEndpoint.message.recreateNotification.title),
                                    renderChildren: () => {
                                        return (
                                            <>
                                                <Typography>{locale.t(locale.keys.registrationEndpoint.message.createNotification.desc1)}</Typography>
                                                <ul style={{ margin: 0, padding: 0 }}>
                                                    <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                        {locale.t(locale.keys.registrationEndpoint.message.createNotification.desc2)}
                                                    </li>
                                                    <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                        {locale.t(locale.keys.registrationEndpoint.message.createNotification.desc3)}
                                                    </li>
                                                </ul>
                                            </>
                                        );
                                    },
                                    type: 'confirm',
                                });
                            }}
                            className={classes.inviteButton}
                            size="large"
                            color="secondary"
                        >
                            <Grid container justify="center" style={{ alignItems: 'flex-end' }}>
                                {locale.t(locale.keys.registrationEndpoint.create.text)}
                                <Typography style={{ color: 'white', fontSize: '1.16rem', fontWeight: 600 }}>{locale.t(locale.keys.registrationEndpoint.create.emphasis)}</Typography>
                            </Grid>
                        </Fab>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.text}>
                    <Typography align="left" variant="h6">
                        {locale.t(locale.keys.registrationEndpoint.process)}
                    </Typography>
                </Grid>
                <Grid container spacing={0} direction="row" alignItems="center" justify="flex-start">
                    <Avatar className={classes.avatarIcon} src="/assets/images/logo/csvfileicon.svg" />
                    {appContainer.values.signinWorkspaceUserObject.language === schema.Language.Ja ? (
                        // 日本語なら日本語のテンプレートのダウンロードリンク
                        appContainer.values.signinWorkspaceObject.brand === schema.Brand.Ricoh ? (
                            // リコーテナントならリコーテナント用のテンプレートのダウンロードリンク
                            // ファイル名はどちらもImportEndpoint_template_ja.csvにする
                            <a href="/csv/template/ImportEndpoint_template_ja_ricoh.csv" download="ImportEndpoint_template_ja.csv">
                                {locale.t(locale.keys.registrationEndpoint.template)}
                            </a>
                        ) : (
                            // それ以外なら大塚商会用のテンプレートのダウンロードリンク
                            <a href="/csv/template/ImportEndpoint_template_ja_otsuka.csv" download="ImportEndpoint_template_ja.csv">
                                {locale.t(locale.keys.registrationEndpoint.template)}
                            </a>
                        )
                    ) : // 日本語以外なら英語テンプレートのダウンロードリンク
                    appContainer.values.signinWorkspaceObject.brand === schema.Brand.Ricoh ? (
                        // リコーテナントならリコーテナント用のテンプレートのダウンロードリンク
                        // ファイル名はどちらもImportEndpoint_template_en.csvにする
                        <a href="/csv/template/ImportEndpoint_template_en_ricoh.csv" download="ImportEndpoint_template_en.csv">
                            {locale.t(locale.keys.registrationEndpoint.template)}
                        </a>
                    ) : (
                        // それ以外なら大塚商会用のテンプレートのダウンロードリンク
                        <a href="/csv/template/ImportEndpoint_template_en_otsuka.csv" download="ImportEndpoint_template_en.csv">
                            {locale.t(locale.keys.registrationEndpoint.template)}
                        </a>
                    )}
                </Grid>
                <Grid container spacing={0} direction="row" alignItems="center" justify="flex-start">
                    <Avatar className={classes.avatarIcon} src="/assets/images/logo/csvfileicon.svg" />
                    {/* エクスポートのリンク */}
                    <button className={classes.linkStyleBtn} onClick={() => clickExportCsv()}>
                        {locale.t(locale.keys.registrationEndpoint.export)}
                    </button>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left">{locale.t(locale.keys.registrationEndpoint.please)}</Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Typography align="left">{locale.t(locale.keys.registrationEndpoint.createAndRecreateDescription.body)}</Typography>
                    <Button
                        className={props.classes.prevButton}
                        variant="text"
                        style={{ color: 'blue' }}
                        onClick={() => {
                            setDialogObject({
                                callBack: () => {
                                    setDialogObject(initialDialogObject);
                                },
                                onClose: () => setDialogObject(initialDialogObject),
                                isOpen: true,
                                title: locale.t(locale.keys.registrationEndpoint.message.aboutRecreateAndCreate.title),
                                renderChildren: () => {
                                    return (
                                        <>
                                            <Typography>{locale.t(locale.keys.registrationEndpoint.message.aboutRecreateAndCreate.desc1)}</Typography>
                                            <ul style={{ margin: 0, padding: 0 }}>
                                                <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                    {locale.t(locale.keys.registrationEndpoint.message.aboutRecreateAndCreate.desc2)}
                                                </li>
                                                <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                    {locale.t(locale.keys.registrationEndpoint.message.aboutRecreateAndCreate.desc3)}
                                                </li>
                                                <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                    {locale.t(locale.keys.registrationEndpoint.message.aboutRecreateAndCreate.desc4)}
                                                </li>
                                                <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                    {locale.t(locale.keys.registrationEndpoint.message.aboutRecreateAndCreate.desc5)}
                                                </li>
                                            </ul>
                                            <br />
                                            <Typography>{locale.t(locale.keys.registrationEndpoint.message.aboutRecreateAndCreate.desc6)}</Typography>
                                            <ul style={{ margin: 0, padding: 0 }}>
                                                <li style={{ listStyle: 'none', textIndent: '-1.25rem', paddingLeft: '1.25rem' }}>
                                                    {locale.t(locale.keys.registrationEndpoint.message.aboutRecreateAndCreate.desc7)}
                                                </li>
                                            </ul>
                                        </>
                                    );
                                },
                                type: 'notice',
                            });
                        }}
                        data-testid="preview-button"
                    >
                        {locale.t(locale.keys.registrationEndpoint.createAndRecreateDescription.link)}
                    </Button>
                    <Typography align="left">{locale.t(locale.keys.registrationEndpoint.createAndRecreateDescription.end)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">{locale.t(locale.keys.registrationEndpoint.title)}</Typography>
                            <Typography>
                                {locale.t(locale.keys.registrationEndpoint.description1)}
                                <a target="admin_manual" href={environment.webapp.admin.descriptionUrl}>
                                    {locale.t(locale.keys.registrationEndpoint.descriptionLink)}
                                </a>
                                {locale.t(locale.keys.registrationEndpoint.description2)}
                            </Typography>
                            <MultiLineText value={locale.t(locale.keys.registrationEndpoint.description)} align="left" variant="body1" color="default" />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <DialogBase
                callBack={dialogObject.callBack}
                onClose={dialogObject.onClose}
                isOpen={dialogObject.isOpen}
                title={dialogObject.title}
                type={dialogObject.type}
                renderChildren={dialogObject.renderChildren}
            />
        </div>
    );
};

export default withStyles(styles)(Component);
