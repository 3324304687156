import * as schema from '@/bundles/schema/typescript/schema';

export enum CLIENT_APP {
    FAX = 'fax', // Easyファクス または スマートecoファクス
    SCAN = 'scan', // QuickスキャンまたはSpeedoc
}

/**
 * @description 利用テナントに応じて、クライアントアプリの種別を返却する
 */
export const convertAbstractProperty = (property: schema.V1ObjectsClientApp, tenant: schema.Brand): CLIENT_APP => {
    if (tenant === schema.Brand.Otsuka) {
        switch (property) {
            case schema.V1ObjectsClientApp.Easyfax:
                return CLIENT_APP.FAX;
            case schema.V1ObjectsClientApp.Quickscan:
                return CLIENT_APP.SCAN;
            default:
                throw new Error(`Invalid property: ${property}`);
        }
    } else {
        switch (property) {
            case schema.V1ObjectsClientApp.Smartecofax:
                return CLIENT_APP.FAX;
            case schema.V1ObjectsClientApp.Speedoc:
                return CLIENT_APP.SCAN;
            default:
                throw new Error(`Invalid property: ${property}`);
        }
    }
};

/**
 * @description 利用テナントに応じて、クライアントアプリの値を返却する
 */
export const convertClientApp = (property: CLIENT_APP, tenant: schema.Brand): schema.V1ObjectsClientApp => {
    if (tenant === schema.Brand.Otsuka) {
        switch (property) {
            case CLIENT_APP.FAX:
                return schema.V1ObjectsClientApp.Easyfax;
            case CLIENT_APP.SCAN:
                return schema.V1ObjectsClientApp.Quickscan;
            default:
                throw new Error(`Invalid property: ${property}`);
        }
    } else {
        switch (property) {
            case CLIENT_APP.FAX:
                return schema.V1ObjectsClientApp.Smartecofax;
            case CLIENT_APP.SCAN:
                return schema.V1ObjectsClientApp.Speedoc;
            default:
                throw new Error(`Invalid property: ${property}`);
        }
    }
};
