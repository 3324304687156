import { default as React } from 'react';
import { WithStyles, Theme, createStyles, withStyles } from '@material-ui/core';
import CollectiveRegister from './CollectiveRegister';
import { RouteComponentProps } from 'react-router-dom';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import routes from '@/admin/constants/routes';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { withRouter } from 'react-router';
import { CollectiveRegisterContainer } from './CollectiveRegisterContainer';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{ id: string }> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.pageTitle.mfp.collective));
    return (
        <>
            <SubHeader title={locale.t(locale.keys.pageTitle.mfp.collective)} isBack={routes.device.new} />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    <CollectiveRegisterContainer.Provider>
                        <CollectiveRegister />
                    </CollectiveRegisterContainer.Provider>
                </div>
            </div>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
