import * as schema from '@/bundles/schema/typescript/schema';
import { IMessage } from '@/common/components/messages/CommonMessage';
import { State as UIState } from '@/common/components/hooks/useUI';
import { UI } from '@/common/components/ImageFileSelector';
import { CovasDropAndCropProps } from '@/common/components/file/CovasDropAndCrop';
import * as cookies from '@/common/utils/browser/cookies';
import * as store from '@/common/utils/browser/store';
import { isAgent } from '@/common/utils/authManagement/agentSignIn';
import * as authUtil from '@/common/utils/authManagement/authUtil';
import * as locale from '@/common/utils/locale/locale';
import * as workspaceModel from '@/common/api/workspace/workspace';
import * as commonWorkspaceUserModel from '@/common/api/workspaceuser/workspaceuser';
import * as cookieInterfaces from '@/common/utils/authManagement/cookieInterfaces';
import { keys as cookieKeys, getHistoryCookieKey, options as cookieOptions } from '@/common/utils/authManagement/cookieConstants';
import { keys as LocalStorageKeys, getHistoryLocalStorageKey } from '@/common/utils/authManagement/localStorageConstants';

export interface StateContainer {
    updateMessage: (message: IMessage) => void;
    message: IMessage;
    setValues: (state: State) => void;
    removeCacheAll: (prefix: string) => void;
    removeCacheByKey: (prefix: string, key: string) => void;
    stateNew: () => State;
    values: State;
    switchStateWithCache: (displayId: string, prefix: string) => State | null;
    getPrefix: () => string;
    // ロード中かどうかのboolean
    loadingState: boolean;
    setLoadingState: (state: boolean) => void;
    // ロード中のステートを更新
    updateLoadingState: (ui: UIState) => void;
    onBackError: boolean;
    setOnBackError: (state: boolean) => void;
    initStateWithCache: (prefix: string) => State;
    useInfiniteScroll: (isItemFull: boolean, callback: () => void) => InfiniteScroll;
    signoutRedirect: () => void;
    onSignin: (container: StateContainer, sub: string, workspace: schema.V1ObjectsWorkspace, user: schema.V1ObjectsWorkspaceuserLarge) => void;
    onSignout: (container: StateContainer, state: State) => void;
    switchWorkspace: (targetWorkspaceId: string, container: StateContainer, state: State) => State;
    selectAlready: (displayId: string, container: StateContainer) => Promise<void>;
    skipSignin: (displayId: string, authCookie: cookieInterfaces.ShareAuthInfo, container: StateContainer) => Promise<State>;
}

export interface InfiniteScroll {
    isFetching: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface State {
    signinWorkspaceObject: schema.V1ObjectsWorkspace;
    signinWorkspaceUserObject: schema.V1ObjectsWorkspaceuserLarge;
    // user object id
    authorizationCode: string;
    cropAndDropProps: CovasDropAndCropProps;
}
// internal =======================
export const switchStateWithCache = (displayId: string, prefix: string): State | null => {
    const key = getHistoryLocalStorageKey(prefix, displayId);
    const authKey = getHistoryCookieKey(displayId);
    const maybeState = store.getValue<State>(key);
    const auth = cookies.get<string>(authKey);
    if (maybeState) {
        if (isState(maybeState)) {
            const state = maybeState as State;
            // 上書き
            setValues(prefix, state);
            return {
                ...state,
                authorizationCode: auth != null ? auth : '',
            };
        }
    }
    return null;
};
export const initStateWithCache = (prefix: string) => {
    const initialValues: State = stateNew();
    Object.keys(initialValues).forEach((key) => {
        const k = key as (keyof State);
        switch (key) {
            case cookieKeys.authorizationCode:
                // patch
                localStorage.removeItem(key);
                const stringValue = cookies.get<string>(key.toString());
                if (stringValue && isString(stringValue)) {
                    (initialValues[k] as string) = stringValue!;
                }
                break;
            case LocalStorageKeys.signinWorkspaceObject:
                const workspaceObj = store.getValue<schema.V1ObjectsWorkspace>(prefix + key.toString());
                if (workspaceObj && isV1ObjectsWorkspace(k, workspaceObj)) {
                    (initialValues[k] as schema.V1ObjectsWorkspace) = workspaceObj!;
                }
                break;
            case LocalStorageKeys.signinWorkspaceUserObject:
                const userObj = store.getValue<schema.V1ObjectsWorkspaceuserLarge>(prefix + key.toString());
                if (userObj && isV1ObjectsWorkspaceuser(k, userObj)) {
                    (initialValues[k] as schema.V1ObjectsWorkspaceuserLarge) = userObj!;
                }
                break;
            case LocalStorageKeys.cropAndDropProps:
                const crop = store.getValue<CovasDropAndCropProps>(prefix + key.toString());
                if (crop && isCropAndDropProps(k, crop)) {
                    (initialValues[k] as CovasDropAndCropProps) = crop!;
                }
                break;
            default:
                const value = store.getValue<any>(prefix + key.toString());
                if (value) {
                    (initialValues[k] as string) = stringValue!;
                }
                break;
        }
    });
    return initialValues;
};

/**
 * @description グローバルステートの初期化
 */
export const stateNew = (): State => {
    return {
        authorizationCode: '',
        signinWorkspaceObject: {
            active: false,
            displayId: '',
            displayName: '',
        },
        signinWorkspaceUserObject: {
            active: false,
            avatarUrl: '',
            id: '',
            invitationDate: '',
            invitationEmail: '',
            invitationEndDate: '',
            invitationVerified: false,
            invitedUser: '',
            invited: false,
            language: schema.Language.En,
            name: '',
            phoneticName: '',
            deviceLoginUser: '',
            role: 0,
            user: '',
            workspace: '',
            pin: '',
            contactEmail: '',
        },
        cropAndDropProps: {
            ui: UI.Selector,
            visibility: 'hidden',
            classes: { button: '', dropzone: '', content: '', dropzoneText: '' },
            onDropAction: (accepts: File[]) => {},
            onDropCancelAction: () => {},
            onLoadAction: (ref: React.MutableRefObject<Cropper | null>) => {
                alert('default');
            },
            onCropCancelAction: () => {},
        },
    };
};

export const removeCacheAll = (prefix: string) => {
    const v: State = stateNew();
    Object.keys(v).forEach((key) => {
        cookies.remove(prefix + key.toString());
    });
};
export const removeCacheByKey = (prefix: string, key: string) => {
    cookies.remove(prefix + key);
};

/**
 * @description サインイン後の認証情報、ユーザー情報、その他アプリケーションで使用する情報をCookieまたはLocalStorageに保存する
 */
export const setValues = (prefix: string, input: State) => {
    // サインイン中のワークスペースのアプリケーション情報をstore(localStorageにsave)
    Object.keys(input).forEach((key) => {
        const k = key as (keyof State);
        const value = input[k];
        if (isV1ObjectsWorkspace(k, value)) {
            store.setValue<schema.V1ObjectsWorkspace>(prefix + key.toString(), value);
        } else if (isV1ObjectsWorkspaceuser(k, value)) {
            store.setValue<schema.V1ObjectsWorkspaceuserLarge>(prefix + key.toString(), value);
        } else if (isCropAndDropProps(k, value)) {
            store.setValue<CovasDropAndCropProps>(prefix + key.toString(), value);
        } else if (cookieKeys.authorizationCode === key.toString()) {
            cookies.set<string>(key.toString(), value, cookieOptions.authorizationCode);
        } else if (isString(input[k])) {
            cookies.set<string>(key.toString(), value);
        }
    });

    if (input.signinWorkspaceObject.displayId === '') {
        // skip
        return;
    }

    // 代理ログインの場合、既存のワークスペースログインに使用するための-Historyは更新しない
    if (isAgent()) {
        // skip
        return;
    }

    // サインイン済みのワークスペースごとにhistoryをsave
    // 同じkey名だが、Cookieに保存されるのはトークン、LocalStrageに保存されるのはワークスペース情報
    const workspaceKey = getHistoryLocalStorageKey(prefix, input.signinWorkspaceObject.displayId);
    const authKey = getHistoryCookieKey(input.signinWorkspaceObject.displayId);
    // displayIdごとに情報を保持
    // authcodeはmask
    store.setValue<State>(workspaceKey, {
        ...input,
        authorizationCode: '',
    });
    // authocode のみcookieへ
    cookies.set<string>(authKey, input.authorizationCode, cookieOptions.authorizationCode);
};

export const onSignin = (container: StateContainer, sub: string, workspace: schema.V1ObjectsWorkspace, user: schema.V1ObjectsWorkspaceuserLarge) => {
    const state = container.stateNew();
    state.signinWorkspaceObject = workspace;
    state.signinWorkspaceUserObject = user;
    state.authorizationCode = sub;
    container.setValues(state);
    authUtil.setCookieSigninWorkspace({
        displayName: state.signinWorkspaceObject.displayName,
        displayId: state.signinWorkspaceObject.displayId,
        // TODO saml
        isSaml: false,
        samlUrl: '',
    });

    // 言語設定
    locale.set(user.language, workspace.language || '');
};

export const onSignout = (container: StateContainer, state: State) => {
    if (state.signinWorkspaceObject) {
        authUtil.removeCookieSigninWorkspace(state.signinWorkspaceObject.displayId);
    }

    // ステートの初期化
    /**
     * container.setValuesで空の値を持つCookieが作られてしまう構造になってしまっているため、
     * キャッシュ（cookieとlocalstorage）を削除する前にステートを初期化する。
     */
    container.setValues(container.stateNew());

    // キャッシュの削除
    const parts = document.location.hostname.split('.');
    parts.shift();
    const upperleveldomain = parts.join('.');
    cookies.remove(cookieKeys.authorizationCode, {
        domain: upperleveldomain,
    });
    cookies.remove(getHistoryCookieKey(container.values.signinWorkspaceObject.displayId), {
        domain: upperleveldomain,
    });
    store.remove(getHistoryLocalStorageKey(container.getPrefix(), container.values.signinWorkspaceObject.displayId));

    container.signoutRedirect();
};

/**
 * @description alreadySigninWorkpsaceのcookieに保存されているWSリストの中で、指定されたdisplayIdのWSに切り替える
 */
export const switchWorkspace = (targetWorkspaceId: string, container: StateContainer, state: State): State => {
    container.removeCacheAll(container.getPrefix());
    container.setValues(container.stateNew());
    const returnState = container.switchStateWithCache(targetWorkspaceId, container.getPrefix());
    if (returnState) {
        return returnState;
    }
    return container.stateNew();
};

/**
 * ログイン中のワークスペースに切り替える
 * @param displayId ワークスペースのdisplayId
 * @param container
 */
export const selectAlready = async (displayId: string, container: StateContainer) => {
    const currentDisplayId = container.values.signinWorkspaceObject.displayId;

    try {
        const state = switchWorkspace(displayId, container, container.values);
        if (state.signinWorkspaceObject.displayId === '') {
            // 連続でクリックされた場合
            return;
        }
        // 最新の情報を取り直す
        const workspaceRes = await workspaceModel.showWorkspace(state.signinWorkspaceObject.displayId, state.authorizationCode);
        state.signinWorkspaceObject = workspaceRes.workspace;

        const userRes = await commonWorkspaceUserModel.showWorkspaceUser(state.signinWorkspaceObject.id!, state.signinWorkspaceUserObject.id, state.authorizationCode);
        state.signinWorkspaceUserObject = userRes.user;

        container.setValues(state);
        authUtil.setCookieSigninWorkspace({
            displayName: state.signinWorkspaceObject.displayName,
            displayId: state.signinWorkspaceObject.displayId,
            // TODO saml
            isSaml: false,
            samlUrl: '',
        });

        // 言語設定
        locale.set(userRes.user.language, workspaceRes.workspace.language || '');
    } catch (error) {
        authUtil.removeCookieSigninWorkspace(displayId);
        const currentState = switchWorkspace(currentDisplayId, container, container.values);
        if (currentState) {
            container.setValues(currentState);
        }
        throw error;
    }
};

export const skipSignin = async (displayId: string, authCookie: cookieInterfaces.ShareAuthInfo, container: StateContainer) => {
    try {
        const state = switchWorkspace(displayId, container, container.values);
        state.authorizationCode = authCookie.token;
        // 最新の情報を取り直す
        const workspaceRes = await workspaceModel.showWorkspace(displayId, authCookie.token);
        state.signinWorkspaceObject = workspaceRes.workspace;
        const userRes = await commonWorkspaceUserModel.showWorkspaceUser(workspaceRes.workspace.id!, authCookie.workspaceUserId, authCookie.token);
        state.signinWorkspaceUserObject = userRes.user;

        authUtil.setCookieSigninWorkspace({
            displayName: state.signinWorkspaceObject.displayName,
            displayId: state.signinWorkspaceObject.displayId,
            // TODO saml
            isSaml: false,
            samlUrl: '',
        });

        // 言語設定
        locale.set(userRes.user.language, workspaceRes.workspace.language || '');
        return {
            ...state,
            authorizationCode: authCookie.token,
            signinWorkspaceObject: workspaceRes.workspace,
            signinWorkspaceUserObject: userRes.user,
        };
    } catch (error) {
        authUtil.removeCookieSigninWorkspace(displayId);
        const currentState = switchWorkspace(displayId, container, container.values);
        if (currentState) {
            container.setValues(currentState);
        }
        throw error;
    }
};

const isV1ObjectsWorkspace = (key: string, item: any): item is schema.V1ObjectsWorkspace => {
    return key === LocalStorageKeys.signinWorkspaceObject && item.displayId !== undefined && item.displayName !== undefined;
};
const isV1ObjectsWorkspaceuser = (key: string, item: any): item is schema.V1ObjectsWorkspaceuserLarge => {
    return key === LocalStorageKeys.signinWorkspaceUserObject && item.active !== undefined && item.invitationEmail !== undefined && item.invitedUser !== undefined;
};
const isCropAndDropProps = (key: string, item: any): item is CovasDropAndCropProps => {
    return key === LocalStorageKeys.cropAndDropProps && item.onDropAction !== undefined;
};
const isString = (item: any): item is string => {
    return 'string' === typeof item;
};
const isState = (item: any): item is State => {
    return item.signinWorkspaceObject !== undefined && item.signinWorkspaceUserObject !== undefined && item.authorizationCode !== undefined;
};
