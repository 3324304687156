import { default as React, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Theme, createStyles, withStyles, WithStyles, Fab, Grid, Modal } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import locale from '@/common/utils/locale';
import routes from '@/admin/constants/routes';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { State as UI } from '@/common/components/hooks/useUI';
import Error from '@/common/components/state/Error';

import { MemberContainer } from './MemberContainer';
import MembersTable from './MembersTable';
import * as schema from '@/bundles/schema/typescript/schema';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import GroupList from './GroupList';
import theme from '@/common/css/theme';
import AddGroup from './Modal/AddGroup';
import AddGroupMember from './Modal/AddGroupMember';
import DeleteGroupMember from './Modal/DeleteGroupMember';
import SettingGroup from './Modal/SettingGroup';
import DeleteGroup from './Modal/DeleteGroup';
import DeleteWorkspaceUser from './Modal/DeleteWorkspaceUser';
import ErrorBoundary from '@/common/utils/ErrorBoundary';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        icon: {
            margin: theme.spacing.unit * 2,
        },
        searchText: {
            width: '95%',
            marginTop: theme.spacing.unit * 2,
            overflowX: 'auto',
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 5,
            overflowX: 'auto',
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        input: {
            marginLeft: 1,
            minWidth: '90%',
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        movetoInviteFab: {
            color: 'white',
            top: '-23px',
            '&& span': {
                fontSize: '1rem',
            },
            height: '44px',
        },
        modalWindow: {
            overflow: 'auto',
            backgroundColor: '#fff',
            flexGrow: 1,
            padding: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
            borderRadius: 10,
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                height: '80%',
                width: '80%',
            },
        },
    });
interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const container = MemberContainer.useContainer();
    const { classes } = props;
    const history = useHistory();
    const location = useLocation();
    const currentPath = encodeURIComponent(location.pathname);

    useEffect(() => {
        appContainer.updateLoadingState(container.ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.ui]);

    const FabComponent = () => {
        // v1.6.0からインターナルプラン、フリープランで新規メンバー招待を表示
        if (
            appContainer.values.signinWorkspaceObject != null &&
            (appContainer.values.signinWorkspaceObject.billingPlan === schema.BillingPlan.Internal || appContainer.values.signinWorkspaceObject.billingPlan === schema.BillingPlan.Free)
        ) {
            return (
                <Grid container direction="row" style={{ width: 420, gap: 8 }}>
                    <Grid item>
                        <Fab
                            variant="extended"
                            onClick={() => history.push(`${routes.user.collective.index}?from=${currentPath}`)}
                            className={classes.movetoInviteFab}
                            style={{ width: 225 }}
                            size="large"
                            color="secondary"
                        >
                            {locale.t(locale.keys.memberManagement.link.collective)}
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab variant="extended" onClick={() => history.push(routes.user.invite.index)} className={classes.movetoInviteFab} style={{ width: 184 }} size="large" color="secondary">
                            <Add />
                            {locale.t(locale.keys.memberManagement.link.invite)}
                        </Fab>
                    </Grid>
                </Grid>
            );
        }
        return undefined;
    };

    return (
        <>
            <SubHeader title={locale.t(locale.keys.memberManagement.index)} pageDescription={locale.t(locale.keys.pageTitle.adminHeaderDescription.memberManagement)} Fab={FabComponent()} />
            <ErrorBoundary container={appContainer}>
                <div className={props.classes.mainDiv}>
                    {/* Loading, Saving中でも画面は出したままにしておく */}
                    {container.ui.current !== UI.Error && (
                        <>
                            <Grid container>
                                <Grid item container justify="space-between" style={{ width: '100%', flexDirection: 'row', height: 736, marginTop: theme.spacing.unit * 3 }}>
                                    <Grid item style={{ width: '30%', height: '100%' }}>
                                        <GroupList />
                                    </Grid>
                                    <Grid item style={{ width: '68%', height: '100%' }}>
                                        <MembersTable />
                                    </Grid>
                                </Grid>
                                <Modal
                                    open={container.isModalOpen()}
                                    onClose={() => {
                                        container.closeModal();
                                    }}
                                >
                                    <Grid className={props.classes.modalWindow}>
                                        {container.modalTarget.addGroupModal && <AddGroup />}
                                        {container.modalTarget.addGroupMemberModal && <AddGroupMember />}
                                        {container.modalTarget.deleteGroupMemberModal && <DeleteGroupMember />}
                                        {/*SettingGroupとDeleteGroupがpropsを持つのは、各グループのメニューから表示時にどのメニューから開いたグループかをパラメータとして渡す必要があるため。 */}
                                        {container.modalTarget.settingGroupModal.open && (
                                            <SettingGroup
                                                groupId={container.modalTarget.settingGroupModal.groupId}
                                                groupName={container.modalTarget.settingGroupModal.groupName}
                                                avatarUrl={container.modalTarget.settingGroupModal.avatarUrl}
                                            />
                                        )}
                                        {container.modalTarget.deleteGroupModal.open && (
                                            <DeleteGroup
                                                groupId={container.modalTarget.deleteGroupModal.groupId}
                                                groupName={container.modalTarget.deleteGroupModal.groupName}
                                                memberCount={container.modalTarget.deleteGroupModal.memberCount}
                                                avatarUrl={container.modalTarget.deleteGroupModal.avatarUrl}
                                            />
                                        )}
                                        {container.modalTarget.deleteWorkspaceUserModal && <DeleteWorkspaceUser />}
                                    </Grid>
                                </Modal>
                            </Grid>
                        </>
                    )}
                    {container.ui.current === UI.Error && (
                        <div data-testid={UI.Error}>
                            <Error />
                        </div>
                    )}
                </div>
            </ErrorBoundary>
        </>
    );
};

export default withStyles(styles)(Component);
