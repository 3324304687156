import { createContainer } from 'unstated-next';
import * as schema from '@/bundles/schema/typescript/schema';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';
import { default as consts } from '@/common/constants';
import { useState } from 'react';
import locale from '@/common/utils/locale';
import ui from '@/common/constants/ui';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { AdminAppContainer } from '../AdminAppContainer';

const useNoticeContainer = () => {
    const appContainer = AdminAppContainer.useContainer();
    // 送信情報
    const [sendInfo, setSendInfo] = useState<schema.V1NoticeCreateRequest>({
        destination: schema.V1ObjectsNoticeDestination.System,
        mailtitle: locale.t(locale.keys.pageTitle.announcement.mailtitlePlaceholder),
        mailtext: '',
    });
    // ページング情報
    const [paging, setPaging] = useState<{ page: number; limit: number }>({ page: 0, limit: 5 });
    // 一覧表示か詳細表示か管理するステート
    const [isDetail, setIsDetail] = useState<{ detail: boolean; id: string }>({ detail: false, id: '' });
    // アナウンス一覧データ
    const [noticeList, setNoticeList] = useState<schema.V1NoticeIndexResponse | undefined>(undefined);
    // 送信フラグ
    const [isSend, setIsSend] = useState<boolean>(true);

    /**
     * apiに送信リクエストを投げる
     */
    const postSendAnouncementMail = async (auth: string, mailrequest: schema.V1NoticeCreateRequest): Promise<schema.V1NoticeCreateResponse> => {
        appContainer.updateLoadingState(UI.Loading);
        // 再送信の時のみidを確認
        if (mailrequest.destination === schema.V1ObjectsNoticeDestination.Resend && !mailrequest.noticeId) {
            throw new Error('noticeId is required');
        }
        const response = await axiosFactory.post<schema.V1NoticeCreateResponse>(`https://${consts.environment.api.hostname}/v1/notice`, mailrequest, {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        });

        // 入力値を初期化
        setSendInfo({ destination: schema.V1ObjectsNoticeDestination.System, mailtitle: locale.t(locale.keys.pageTitle.announcement.mailtitlePlaceholder), mailtext: '' });
        appContainer.updateLoadingState(UI.Loaded);
        setIsSend(true);

        return response.data;
    };

    /**
     * 送信したアナウンスの一覧を取得
     * @param auth
     * @param page
     * @param limit
     */
    const getNoticeList = async (auth: string, page: number, limit: number): Promise<schema.V1NoticeIndexResponse> => {
        const response = await axiosFactory.get<schema.V1NoticeIndexResponse>(`https://${consts.environment.api.hostname}/v1/notice?offset=${page}&limit=${limit}`, {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        });
        return response.data;
    };

    /**
     * メールの転送状況を取得
     */
    const getNoticeDetail = async (auth: string, id: string): Promise<schema.V1MailShowResponse> => {
        const response = await axiosFactory.get<schema.V1MailShowResponse>(`https://${consts.environment.api.hostname}/v1/mail-event/${id}`, {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        });

        return response.data;
    };

    return {
        sendInfo,
        isDetail,
        noticeList,
        paging,
        isSend,

        setSendInfo,
        setIsDetail,
        setNoticeList,
        postSendAnouncementMail,
        getNoticeList,
        setPaging,
        setIsSend,
    };
};
export const NoticeContainer = createContainer(useNoticeContainer);
