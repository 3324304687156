import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import * as management from '@/common/api/entry/detail/management';
import * as schema from '@/bundles/schema/typescript/schema';
import * as errorHandler from '@/common/utils/errorHandler';
import locale from '@/common/utils/locale';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import {
    validateInfo,
    initValidation,
    EntryDetailValidate,
    validateProducts,
    EntryDetailProductsValidate,
    initProductsValidation,
    validateCloud,
    EntryDetailCloudValidate,
    initCloudValidation,
} from './entryDetailVaridator';

export enum SelectedTab {
    tab1 = 'tab1',
    tab2 = 'tab2',
    tab3 = 'tab3',
}

const useEntryDetailContainer = (initialState: string = '') => {
    const appContainer = AdminAppContainer.useContainer();

    // 初期化
    useEffect(() => {
        createInitialState(initialState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const ui = useUI();

    const [entryDetail, setEntryDetail] = useState<schema.V1ObjectsEntryIndex>(management.initialEntryDetail);
    const [error, setError] = useState<EntryDetailValidate>(initValidation);
    const [errorProducts, setErrorProducts] = useState<EntryDetailProductsValidate>(initProductsValidation);
    const [errorCloud, setErrorCloud] = useState<EntryDetailCloudValidate>(initCloudValidation);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    const openMessage = (message: string, variant: Variants) => {
        const isOpen = true;
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen,
            message,
            variant,
        });
    };

    const createInitialState = async (entryNumber: string) => {
        try {
            ui.update(UI.Loading);
            appContainer.updateLoadingState(UI.Loading);

            const initialState = await management.findEntryDetail(entryNumber, appContainer.values.authorizationCode);
            handleChangeEntryDetail(initialState);

            ui.update(UI.Loaded);
            appContainer.updateLoadingState(UI.Loaded);
        } catch (e) {
            ui.update(UI.Error);
            errorHandler.handleApiError(appContainer, e);
        }
    };
    // タブのステート
    const [tab, setTab] = useState<SelectedTab>(SelectedTab.tab1);

    const handleTabChange = (e: React.ChangeEvent<{}>, value: SelectedTab) => {
        setTab(value);
    };

    const handleChangeEntryDetail = (entryDetail: schema.V1ObjectsEntryIndex) => {
        const validateResult = validateInfo(entryDetail);
        const validateProductsResult = validateProducts(entryDetail.products);
        const validateCloudResult = validateCloud(entryDetail.cloud);
        setSaveButtonDisabled(validateResult.saveButtonDisabled || validateProductsResult.saveButtonDisabled || validateCloudResult.saveButtonDisabled);
        setError({ ...validateResult.msg });
        setErrorProducts({ ...validateProductsResult.msg });
        setErrorCloud({ ...validateCloudResult.msg });
        setEntryDetail({ ...entryDetail });
    };

    const onClickWorkspaceCreate = async () => {
        ui.update(UI.Loading);
        appContainer.updateLoadingState(UI.Loading);
        try {
            await management.createWorkspaceFromEntry(entryDetail.id, appContainer.values.authorizationCode);
            const refreshState = await management.findEntryDetail(entryDetail.id, appContainer.values.authorizationCode);
            setEntryDetail({ ...refreshState });
            openMessage(locale.t(locale.keys.applicationList.action.created), Variants.success);
        } catch (e) {
            ui.update(UI.Error);
            errorHandler.handleApiError(appContainer, e);
        }
        ui.update(UI.Loaded);
        appContainer.updateLoadingState(UI.Loaded);
    };

    // 現在の変更状態を保存
    const editSave = async () => {
        ui.update(UI.Loading);
        appContainer.updateLoadingState(UI.Loading);
        try {
            const res = await management.updateEntryDetail(
                {
                    id: entryDetail.id,
                    category: entryDetail.category,
                    products: entryDetail.products,
                    cloud: entryDetail.cloud,
                    clientCompany: entryDetail.clientCompany,
                    clientDepartment: entryDetail.clientDepartment,
                    clientFirstName: entryDetail.clientFirstName,
                    clientLastName: entryDetail.clientLastName,
                    clientMail: entryDetail.clientMail,
                    clientPhone: entryDetail.clientPhone,
                    otsSalesDepartment: entryDetail.otsSalesDepartment,
                    otsSalesFirstName: entryDetail.otsSalesFirstName,
                    otsSalesLastName: entryDetail.otsSalesLastName,
                    otsSalesMail: entryDetail.otsSalesMail,
                    otsSalesPhone: entryDetail.otsSalesPhone,
                    otsEngineerFirstName: entryDetail.otsEngineerFirstName,
                    otsEngineerLastName: entryDetail.otsEngineerLastName,
                    otsEngineerMail: entryDetail.otsEngineerMail,
                    entryNumber: entryDetail.entryNumber,
                    entryState: entryDetail.entryState || schema.V1ObjectsEntryState.Entry,
                },
                appContainer.values.authorizationCode,
            );
            setEntryDetail(res);
            openMessage(locale.t(locale.keys.applicationList.action.saved), Variants.success);
        } catch (e) {
            ui.update(UI.Error);
            errorHandler.handleApiError(appContainer, e);
        }
        ui.update(UI.Loaded);
        appContainer.updateLoadingState(UI.Loaded);
    };

    // 申込をキャンセル
    const onClickCancelSave = async () => {
        ui.update(UI.Loading);
        appContainer.updateLoadingState(UI.Loading);
        try {
            const res = await management.cancelEntry({ entryId: entryDetail.id, entryNumber: entryDetail.entryNumber }, appContainer.values.authorizationCode);
            setEntryDetail(res);
            openMessage(locale.t(locale.keys.applicationList.action.canceled), Variants.success);
        } catch (e) {
            ui.update(UI.Error);
            errorHandler.handleApiError(appContainer, e);
        }
        ui.update(UI.Loaded);
        appContainer.updateLoadingState(UI.Loaded);
    };

    // 申込解約取消
    const onClieckWithdrawCancel = async () => {
        ui.update(UI.Loading);
        appContainer.updateLoadingState(UI.Loading);
        try {
            const res = await management.withdrawCancellation({ entryId: entryDetail.id, entryNumber: entryDetail.entryNumber }, appContainer.values.authorizationCode);
            setEntryDetail(res);
            openMessage(locale.t(locale.keys.applicationList.action.reverted), Variants.success);
        } catch (e) {
            ui.update(UI.Error);
            errorHandler.handleApiError(appContainer, e);
        }
        ui.update(UI.Loaded);
        appContainer.updateLoadingState(UI.Loaded);
    };

    // 申込を削除
    const onClickDeleteSave = async () => {
        ui.update(UI.Loading);
        appContainer.updateLoadingState(UI.Loading);
        try {
            const res = await management.deleteEntry(entryDetail.id, appContainer.values.authorizationCode);
            setEntryDetail(res);
            openMessage(locale.t(locale.keys.applicationList.action.deleted), Variants.success);
        } catch (e) {
            ui.update(UI.Error);
            errorHandler.handleApiError(appContainer, e);
        }
        ui.update(UI.Loaded);
        appContainer.updateLoadingState(UI.Loaded);
    };

    return {
        tab,
        setTab,
        ui,
        handleTabChange,
        entryDetail,
        error,
        errorProducts,
        errorCloud,
        setEntryDetail,
        handleChangeEntryDetail,
        createInitialState,
        onClickWorkspaceCreate,
        editSave,
        onClickCancelSave,
        onClieckWithdrawCancel,
        onClickDeleteSave,
        saveButtonDisabled,
        setSaveButtonDisabled,
    };
};

export const EntryDetailContainer = createContainer(useEntryDetailContainer);
