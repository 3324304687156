import { default as React } from 'react';
import { Button, Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import * as Invitation from '@/common/api/sub-workspace/inivite/Invitation';
import Email from '@material-ui/icons/Email';
import Error from '@material-ui/icons/Error';
import locale from '@/common/utils/locale';

enum ButtonState {
    Send = 'action.invitationSend',
    Resend = 'action.invitationReSend',
    SendFailure = 'action.invitationSendFailure',
}

const rowToState = (row: Invitation.Row) => {
    if (row.failure) {
        return ButtonState.SendFailure;
    }
    if (row.invitationDate === '') {
        return ButtonState.Send;
    }
    return ButtonState.Resend;
};
const styles = (theme: Theme) => createStyles({});
interface Props extends WithStyles<typeof styles> {
    row: Invitation.Row;
    resendAction: (row: Invitation.Row) => void;
    sendAction: (row: Invitation.Row) => void;
    disabled?: boolean;
}
export const Component: React.FC<Props> = (props) => {
    const { row } = props;
    const SendFailureButton = (
        <Button
            variant="outlined"
            disabled={props.disabled}
            style={{
                whiteSpace: 'nowrap',
                marginTop: '5px',
                marginLeft: '20px',
                marginBottom: '5px',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                color: 'white',
                backgroundColor: props.disabled ? 'gray' : '#ff3d00',
                verticalAlign: 'middle',
                height: '28px',
                border: props.disabled ? '1px solid gray' : '1px solid orange',
            }}
            onClick={() => props.sendAction(row)}
        >
            <Error style={{ transform: `scale(1.0)` }} />
            <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.action.invitationSendFailure)}</span>
        </Button>
    );
    const SendButton = (
        <Button
            disabled={props.disabled}
            variant="outlined"
            style={{
                whiteSpace: 'nowrap',
                marginTop: '5px',
                marginLeft: '20px',
                marginBottom: '5px',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                color: 'white',
                backgroundColor: props.disabled ? 'gray' : '#ff3d00',
                verticalAlign: 'middle',
                height: '28px',
                border: props.disabled ? '1px solid gray' : '1px solid orange',
            }}
            onClick={() => props.sendAction(row)}
        >
            <Email style={{ transform: `scale(1.0)` }} />
            <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.action.invitationSend)}</span>
        </Button>
    );
    const ReSendButton = (
        <Button
            variant="outlined"
            disabled={props.disabled}
            style={{
                whiteSpace: 'nowrap',
                marginTop: '5px',
                marginLeft: '20px',
                marginBottom: '5px',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: 'bold',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                color: props.disabled ? 'white' : '#ff7300',
                backgroundColor: props.disabled ? 'gray' : 'white',
                verticalAlign: 'middle',
                height: '28px',
                border: props.disabled ? '1px solid gray' : '1px solid orange',
            }}
            onClick={() => props.resendAction(row)}
        >
            <Email style={{ transform: `scale(1.0)` }} />
            <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.action.invitationReSend)}</span>
        </Button>
    );
    if (rowToState(row) === ButtonState.Send) {
        return <>{SendButton}</>;
    }
    if (rowToState(row) === ButtonState.Resend) {
        return <>{ReSendButton}</>;
    }
    if (rowToState(row) === ButtonState.SendFailure) {
        return <>{SendFailureButton}</>;
    }
    return <div>{SendButton}</div>;
};

export default withStyles(styles)(Component);
