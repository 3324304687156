import { getMemberListFromGroupList, useGroup } from '@/common/models/groups/useGroup';
import * as schema from '@/bundles/schema/typescript/schema';
import { styles as baseTabStyles } from '@/common/components/Tab/style';
import { styles as baseTableStyles } from '@/common/components/Table/style';
import * as locale from '@/common/utils/locale/locale';
import { Grid, InputBase, Paper, Tab, Tabs, Theme, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import GroupTable from './GroupTable';
import MemberTable from './MemberTable';
import shareFormat from '@/common/constants/shareFormat';
import InputEmail from './InputEmail';

const styles = (theme: Theme) =>
    createStyles({
        ...baseTableStyles(),
        ...baseTabStyles(theme),
        root: {
            width: '100%',
            gap: '16px',
        },
        searchRoot: {
            width: '100%',
            display: 'flex',
            overflowX: 'auto',
        },
        searchIconButton: {
            marginBlock: 'auto',
            marginInline: '10px',
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        table: {
            width: '100%',
            padding: 0,
        },

        checkCell: {
            width: 40,
            maxWidth: 40,
            margin: 4,
            wordBreak: 'break-all',
        },
        avatarCell: {
            width: 40,
            maxWidth: 40,
            margin: 4,
            wordBreak: 'break-all',
        },
        mailCell: {
            width: 'auto',
            margin: 4,
            wordBreak: 'break-all',
        },
        roleCell: {
            width: '12%',
            margin: 4,
            wordBreak: 'break-all',
        },
        authCell: {
            width: '12%',
            margin: 4,
            wordBreak: 'break-all',
        },
        statusCell: {
            width: '20%',
            margin: 4,
            wordBreak: 'break-all',
        },

        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
    });

interface PresenterProps extends WithStyles<typeof styles> {
    responseData: schema.V1ObjectsXStoragesStatus[];
    setResponseData: React.Dispatch<React.SetStateAction<schema.V1ObjectsXStoragesStatus[]>>;
    groupRows: schema.V1WorkspaceGroupsIndexResponse;

    formValue: schema.V1XStoragesOrderCreateRequest;
    setFormValue: React.Dispatch<React.SetStateAction<schema.V1XStoragesOrderCreateRequest>>;
    selectedUsers: string[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    selectedGroups: string[];
    setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>;
    selectedOwnerUser: string;
    setSelectedOwnerUser: React.Dispatch<React.SetStateAction<string>>;
    // true: メールの送信先を選択する,false: クラウドストレージの登録先を選択する
    senderSelect: boolean;
    externalMails: { error: string; input: string[] };
    setExternalMails: React.Dispatch<React.SetStateAction<{ error: string; input: string[] }>>;
}

// Humble Object
// APIをモックするのが大変なので、画面側で重要な部分だけをPresenterとして切り出し
const Presenter: FC<PresenterProps> = (props) => {
    const [search, setSearch] = useState({
        searchText: '',
    });
    const [tabIndex, setTabIndex] = React.useState(0);

    // ユーザーとグループタブの切り替え
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    // formValue.infoの生成(選択項目が変更されるたびに整合性を合わせるため実行)
    useEffect(() => {
        const selectedUserListFromSelectedGroup = getMemberListFromGroupList(props.selectedGroups, props.groupRows.groups);
        // 選択済みグループから取得したユーザーIDをもとにschema.V1ObjectsWorkspaceuserLarge[]に変換する
        const selectedUserListFromGroup = [] as schema.V1ObjectsWorkspaceuserLarge[];
        props.responseData.forEach((user) => {
            if (selectedUserListFromSelectedGroup.some((selectedUser) => selectedUser === user.user.id)) {
                selectedUserListFromGroup.push(user.user);
            }
        });

        const selectedUserListFromUser = [] as schema.V1ObjectsWorkspaceuserLarge[];
        props.responseData.forEach((user) => {
            if (props.selectedUsers.some((selectedUser) => selectedUser === user.user.id)) {
                selectedUserListFromUser.push(user.user);
            }
        });

        const tmpSelectedOwnerUser = props.responseData.find((user) => user.user.id === props.selectedOwnerUser);
        const selectedOwnerUser = tmpSelectedOwnerUser ? tmpSelectedOwnerUser.user : undefined;
        // 選択ユーザーと選択グループに所属するメンバーで重複を除いてステートに追加。
        const updatedUserListForm = selectedUserListFromGroup.concat(selectedUserListFromUser);
        const uniqueArray = removeDuplicate(updatedUserListForm, props.formValue.ordertype, props.formValue.share);

        // ユーザーグループ選択の重複を除いたユーザーをステートに追加
        const newInfo: schema.V1ObjectsXStoragesOrderInfo[] = uniqueArray.map((user) => {
            return {
                // 登録外メールアドレス
                externalEmail: undefined,
                // 登録先を選択したときのワークスペースユーザー。
                registeredUser: selectedOwnerUser,
                // 送信先ワークスペースユーザー
                toUser: user,
            };
        });
        if (props.formValue.ordertype === schema.V1ObjectsRegisteredUserEnum.OneUser || props.formValue.share === shareFormat.share) {
            // user.invitationEmail or user.contactEmailに含まれない外部メールアドレスの追加
            const newExternalMails = props.externalMails.input.filter((mail) => {
                if (newInfo.length === 0) {
                    return true;
                }
                for (const user of newInfo) {
                    if (user.toUser && (user.toUser.invitationEmail === mail || user.toUser.contactEmail === mail)) {
                        return false;
                    }
                }
                return true;
            });

            props.externalMails.input = newExternalMails;
            newExternalMails.forEach((mail) => {
                newInfo.push({
                    externalEmail: mail,
                    registeredUser: selectedOwnerUser,
                    toUser: undefined,
                });
            });
        }

        props.setFormValue({
            ...props.formValue,
            info: newInfo,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedUsers, props.selectedGroups, props.selectedOwnerUser, props.externalMails.input]);

    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch({
            ...search,
            searchText: event.target.value,
        });
    };

    return (
        <div>
            <Paper className={props.classes.searchRoot}>
                <Grid aria-label="Search" className={props.classes.searchIconButton}>
                    <Search />
                </Grid>
                <InputBase className={props.classes.searchInput} value={search.searchText} onChange={handleSearchText} inputProps={{ 'data-testid': 'search-input' }} />
            </Paper>
            {/* selected.lengthが0のときなぜか文字が表示されないので、stringに変換して表示させる */}
            <Typography>
                {props.senderSelect
                    ? locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.tips.sendSum, {
                          n: props.selectedUsers.length + props.selectedGroups.length === 0 ? '0' : props.selectedUsers.length + props.selectedGroups.length,
                          i: !props.formValue.info || props.formValue.info.length === 0 ? '0' : props.formValue.info.length,
                      })
                    : null}
            </Typography>
            <Paper className={props.classes.root}>
                <div className={props.classes.tabs} style={{ marginInlineStart: '4px' }}>
                    {/* ユーザーとグループのタブ */}
                    <Tabs value={tabIndex} onChange={handleTabChange} classes={{ root: props.classes.tabsContainer, indicator: props.classes.indicator }}>
                        <Tab label={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.tab.userTable)} className={props.classes.tabTags} classes={{ selected: props.classes.tabSelected }} />
                        {/* 「1ユーザーのクラウドストレージに登録する」の登録先選択の場合、グループを表示しない */}
                        {!(props.formValue.ordertype === schema.V1ObjectsRegisteredUserEnum.OneUser && !props.senderSelect) && (
                            <Tab
                                label={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.tab.groupTable)}
                                className={props.classes.tabTags}
                                classes={{ selected: props.classes.tabSelected }}
                            />
                        )}
                    </Tabs>
                </div>
                {tabIndex === 0 ? (
                    // ユーザー一覧
                    <MemberTable
                        formValue={props.formValue}
                        setFormValue={props.setFormValue}
                        userData={props.responseData}
                        responseData={props.responseData}
                        searchText={search.searchText}
                        selectedUsers={props.selectedUsers}
                        setSelectedUsers={props.setSelectedUsers}
                        selectedOwnerUser={props.selectedOwnerUser}
                        setSelectedOwnerUser={props.setSelectedOwnerUser}
                        senderSelect={props.senderSelect}
                    />
                ) : tabIndex === 1 ? (
                    // グループ一覧
                    <GroupTable
                        formValue={props.formValue}
                        setFormValue={props.setFormValue}
                        groupRows={props.groupRows}
                        searchText={search.searchText}
                        userData={props.responseData}
                        selectedGroups={props.selectedGroups}
                        setSelectedGroups={props.setSelectedGroups}
                    />
                ) : (
                    <></>
                )}
            </Paper>
            {/* クラウドストレージの共有、1ユーザーのクラウドストレージに全て登録する*/}
            {props.senderSelect &&
                ((props.formValue.share === shareFormat.share || (props.formValue.share === shareFormat.personal && props.formValue.ordertype === schema.V1ObjectsRegisteredUserEnum.OneUser)) && (
                    <InputEmail formValue={props.formValue} externalMails={props.externalMails} setExternalMails={props.setExternalMails} />
                ))}
        </div>
    );
};

export const StyledPresenter = withStyles(styles)(Presenter);

// contactEmail,invitationEmailの重複を除いた配列を作成
export const removeDuplicate = (array: schema.V1ObjectsWorkspaceuserLarge[], ordertype: schema.V1ObjectsRegisteredUserEnum | undefined, share: number | undefined) => {
    return array.filter((user, index, self) => {
        if (
            // 1ユーザーのクラウドストレージに全て登録する場合、共有クラウドストレージの場合、ユーザーのメールアドレスの重複を取り除く
            ((ordertype === schema.V1ObjectsRegisteredUserEnum.OneUser && share === shareFormat.personal) || share === shareFormat.share) &&
            !self
                .slice(0, index)
                .some((tempUser) =>
                    tempUser.contactEmail
                        ? user.contactEmail
                            ? tempUser.contactEmail === user.contactEmail
                            : tempUser.contactEmail === user.invitationEmail
                        : tempUser.invitationEmail === user.contactEmail || tempUser.invitationEmail === user.invitationEmail,
                )
        ) {
            return true;
        }
        // 各ユーザーに登録する場合、重複なしのユーザーを返す
        if (ordertype === schema.V1ObjectsRegisteredUserEnum.EachUser && share === shareFormat.personal && !self.slice(0, index).some((tempUser) => tempUser.id === user.id)) {
            return true;
        }
        return false;
    });
};
