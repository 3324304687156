import { useState } from 'react';
import { createContainer } from 'unstated-next';
import logger from '@/common/utils/logger';
import * as management from '@/common/api/sub-workspace/management';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as schema from '@/bundles/schema/typescript/schema';
import useUI from '@/common/components/hooks/useUI';
import * as Invitation from '@/common/api/sub-workspace/inivite/Invitation';
import { Variants } from '@/common/components/messages/CommonMessage';
import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as plan from '@/admin/constants/plan';
import * as errorHandler from '@/common/utils/errorHandler';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';

export enum SelectedTab {
    tab1 = 'tab1',
    tab2 = 'tab2',
    tab3 = 'tab3',
}
const convertPlan = (planBrand: string) => {
    // plan:brandなのでsplit
    switch (planBrand.split(':')[0]) {
        case plan.EPlan.FREE:
            return schema.BillingPlan.Free;
        case plan.EPlan.INTERNAL:
            return schema.BillingPlan.Internal;
        default:
            return schema.BillingPlan.Free;
    }
};

const useInvitationContainer = () => {
    // Invitaiton.tsx ===========================================
    const [form, setForm] = useState(Invitation.New());
    const appContainer = AdminAppContainer.useContainer();
    const invitationFunctions = () => {
        const handleChangeEmail = (value: string) => {
            setForm({
                ...form,
                validateInit: { ...form.validateInit, email: true },
                email: value,
            });
            validate();
        };
        const handleChangeInvitationDisplayId = (value: string) => {
            setForm({
                ...form,
                validateInit: { ...form.validateInit, displayId: true },
                displayId: value,
            });
            validate();
        };
        const handleChangePlanBrand = (value: string) => {
            setForm({
                ...form,
                validateInit: { ...form.validateInit, planBrand: true },
                planBrand: value,
            });
            validate();
        };
        const convertBrand = (planValue: string, workspaceBrand: string | undefined): schema.Brand => {
            // 会社のワークスペースのサブの場合は、サブもbrandを引き継ぐ
            if (appContainer.values.signinWorkspaceObject.brand !== plan.EBrand.SYSTEM) {
                if (!workspaceBrand) {
                    throw new Error('invalid workspaces brand');
                }
                return plan.stringToBrand(workspaceBrand);
            }
            // Systemのワークスペースのサブの場合は、選択されたbrandとする
            // plan:brandなのでsplit
            const rawBrand: string = planValue.split(':')[1];
            return plan.stringToBrand(rawBrand);
        };
        const handleInvite = (onInvite: (row: Invitation.OnInviteRow) => void) => {
            logger.debug('handleInvite');
            setForm({ ...form, lock: true });
            window.setTimeout(() => {
                setForm({ ...form, lock: false });
            }, 500);
            (async () => {
                try {
                    const workspaces = await Invitation.findWorkspaceByDisplayId(appContainer.values.signinWorkspaceObject.displayId, appContainer.values.authorizationCode);
                    logger.debug(workspaces);
                    const result = await Invitation.inviteNewWorkspace(
                        {
                            parentWorkspace: workspaces.workspace.id === undefined ? '' : workspaces.workspace.id,
                            invitedUser: '', // このタイミングでは空なのが正しい
                            billingPlan: convertPlan(form.planBrand),
                            brand: convertBrand(form.planBrand, workspaces.workspace.brand),
                            contactEmail: form.email,
                            displayId: form.displayId,
                        },
                        appContainer.values.authorizationCode,
                    );
                    logger.debug(result);
                    // 404の場合、nullが返ってきている
                    // tsのrest clientは404の場合error throwしない
                    if (!result) {
                        appContainer.updateMessage({
                            autoHideDuration: 3000,
                            isOpen: true,
                            message: locale.t(locale.keys.action.failedToSave),
                            variant: Variants.error,
                        });
                        return;
                    }

                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.action.created),
                        variant: Variants.success,
                    });
                    // tabを招待タブに変更する
                    setTab(SelectedTab.tab3);
                    onInvite({
                        contactEmail: form.email,
                        displayId: form.displayId,
                        invitedUser: '',
                    });
                } catch (e) {
                    errorHandler.handleApiError(appContainer, e);
                }
            })();
        };
        const validate = () => {
            const msg = validator.Validate<Invitation.Form>(form, Invitation.validations(), Invitation.NewValidation);
            const emailMsg = validator.Validate<{ email: string }>(form, Invitation.emailValidations(), Invitation.NewEmailValidation);
            const displayIdMsg = validator.Validate<{ displayId: string }>(form, Invitation.validations(), Invitation.NewEmailValidation);
            const emailsRequred = msg && msg.emails ? msg.emails.toString() : '';
            const emailsFormat = emailMsg && emailMsg.email ? emailMsg.email.toString() : '';
            const displayIdRequred = displayIdMsg && displayIdMsg.displayId ? displayIdMsg.displayId.toString() : '';
            return {
                email: !form.validateInit.email ? '' : `${emailsRequred}${emailsFormat}`,
                planBrand: !form.validateInit.planBrand ? '' : `${emailsRequred}${emailsFormat}`,
                displayId: !form.validateInit.displayId ? '' : `${displayIdRequred}`,
            };
        };

        const isCompanyBrand = () => {
            // brandがないとどうしようもないので、強制signoutさせる
            if (!appContainer.values.signinWorkspaceObject.brand) {
                errorHandler.forceSignout(appContainer);
            }
            return appContainer.values.signinWorkspaceObject.brand !== plan.EBrand.SYSTEM;
        };
        const getCurrentBrand = () => {
            // brandがないとどうしようもないので、強制signoutさせる
            if (!appContainer.values.signinWorkspaceObject.brand) {
                errorHandler.forceSignout(appContainer);
            }
            return appContainer.values.signinWorkspaceObject.brand!;
        };
        return {
            handleChangeEmail,
            handleChangePlanBrand,
            handleInvite,
            handleChangeInvitationDisplayId,
            validate,
            form,
            isCompanyBrand,
            getCurrentBrand,
        };
    };
    // Status.tsx ===========================================
    const [searchText, setSearchText] = useState('');
    const [pageTab3, setPageTab3] = useState(0);
    const [rowsPerPageTab3, setRowsPerPageTab3] = useState(DEFAULT_ROWS_PER_PAGE);
    const [values, setValues] = useState(management.New());
    const ui = useUI();
    const [tab, setTab] = useState<SelectedTab>(SelectedTab.tab3);
    const isTabString = (value: string): value is SelectedTab => {
        return value === 'tab1' || value === 'tab2' || value === 'tab3';
    };
    const [modal, setModal] = useState({
        rowId: 0,
        displayId: '',
        email: '',
        emailCC: [] as string[],
        language: '',
        modalOpen: false,
    });
    const tableFunctions = () => {
        const handleChange = (e: React.ChangeEvent<{}>, value: string) => {
            if (isTabString(value)) {
                setTab(value);
            }
        };
        const handleSend = (row: Invitation.Row) => {
            logger.debug('handleSend', row);
            openSendMailModal(row.id, row.displayId, row.email, row.workspaceLanguage, row.emailCC);
        };
        const openSendMailModal = (rowId: number, displayId: string, email: string, language?: string, emailCC?: string[]) => {
            setModal({
                rowId,
                displayId,
                email,
                emailCC: emailCC ? emailCC : [],
                language: !language ? '' : language,
                modalOpen: true,
            });
        };
        const onCloseSendMailModal = () => {
            setModal({
                rowId: 0,
                displayId: '',
                email: '',
                emailCC: [],
                language: '',
                modalOpen: false,
            });
        };

        const handleChangeSearchText = () => (event: React.ChangeEvent<HTMLInputElement>) => {
            logger.debug('handleChangeSearchText');
            logger.debug(searchText);
            setSearchText(event.target.value);
        };

        function handleChangePageByTab(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number, tab: SelectedTab) {
            setPageTab3(newPage);
        }
        const getPageByTab = (tab: SelectedTab) => {
            return pageTab3;
        };
        function handleChangeRowsPerPageByTab(tab: SelectedTab, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            setRowsPerPageTab3(parseInt(event.target.value, 10));
        }
        const getInvitationRows = (rows: Invitation.Row[]) => {
            return rows
                .filter((r) => {
                    return r.displayId.indexOf(searchText) !== -1 || r.email.indexOf(searchText) !== -1;
                })
                .sort((a, b) => (a.id < b.id ? -1 : 1));
        };
        const getRows = (rows: management.Row[]) => {
            return rows
                .filter((r) => {
                    return r.displayId.indexOf(searchText) !== -1 || r.displayName.indexOf(searchText) !== -1;
                })
                .sort((a, b) => (a.id < b.id ? -1 : 1));
        };
        const getRowsPerPageByTab = (tab: SelectedTab) => {
            return rowsPerPageTab3;
        };
        const handleClick = () => (event: React.MouseEvent<HTMLInputElement>) => {};
        async function handleChangeDisplayId(rowId: number, value: string) {
            try {
                logger.debug('handleChangeDisplayId');
                const changedRows = values.rows;
                changedRows[rowId - 1].displayId = value;
                await management.updateWorkspaceDisplayId(appContainer.values.authorizationCode, changedRows[rowId - 1]);
                setValues({ ...values, rows: changedRows });
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        }
        async function handleChangeDisplayName(rowId: number, value: string) {
            try {
                logger.debug('handleChangeDisplayName');
                const changedRows = values.rows;
                changedRows[rowId - 1].displayName = value;
                await management.updateWorkspaceDisplayId(appContainer.values.authorizationCode, changedRows[rowId - 1]);
                setValues({ ...values, rows: changedRows });
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        }
        async function handleChangeEnableSubWs(rowId: number, value: boolean) {
            try {
                logger.debug('handleChangeEnableSubWs');
                const changedRows = values.rows;
                changedRows[rowId - 1].enableSubWorkspace = value;
                await management.updateEnableSubWorkspace(appContainer.values.authorizationCode, changedRows[rowId - 1]);
                setValues({ ...values, rows: changedRows });
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        }

        return {
            onCloseSendMailModal,
            handleClick,
            handleSend,
            handleChangeRowsPerPageByTab,
            getRowsPerPageByTab,
            handleChangeSearchText,
            handleChangeDisplayName,
            handleChangeDisplayId,
            handleChangeEnableSubWs,
            handleChangePageByTab,
            handleChange,
            ui,
            modal,
            getRows,
            getInvitationRows,
            searchText,
            getPageByTab,
            tab,
        };
    };

    return {
        ...invitationFunctions(),
        ...tableFunctions(),
    };
};

export const InvitationContainer = createContainer(useInvitationContainer);
