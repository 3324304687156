import * as validator from '@/common/utils/validator';
import locale from '@/common/utils/locale';

export interface Form {
    to: string;
    cc: string;
    subject: string;
    body: string;
    signupLimitDays: number;
    validateInit: {
        to: boolean;
        cc: boolean;
        subject: boolean;
        body: boolean;
    };

    errors: validator.ValidationOutput;
}

export const New = (): Form => ({
    to: '',
    cc: '',
    subject: '',
    body: '',
    signupLimitDays: 30,
    validateInit: {
        to: false,
        cc: false,
        subject: false,
        body: false,
    },
    errors: NewValidation(),
});

export const validations = (): validator.Constraints => {
    return {
        to: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            email: {
                message: locale.t(locale.keys.validation.email),
            },
        },
        subject: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        body: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
    };
};

export const ccValidation = (): validator.Constraints => {
    return {
        cc: {
            email: {
                message: locale.t(locale.keys.validation.email),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        to: null,
        cc: null,
        subject: null,
        body: null,
    };
};
