import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import logger from '@/common/utils/logger';
import * as managementMfp from '@/common/api/sub-workspace/mfps/managementMfp';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as schema from '@/bundles/schema/typescript/schema';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';

const useContainer = () => {
    // Invitaiton.tsx ===========================================
    const appContainer = AdminAppContainer.useContainer();
    // Status.tsx ===========================================
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [defaultRows, setDefaultRows] = useState<managementMfp.Row[]>([]);
    const [values, setValues] = useState(managementMfp.New());
    const ui = useUI();

    const createData = async (workspaceId: string, auth: string): Promise<managementMfp.Row[]> => {
        try {
            const wsRes = await managementMfp.findSubWorkspace(workspaceId, auth);
            const verifiedWorkspaces = [] as schema.V1ObjectsWorkspace[];
            wsRes.workspaces.forEach((ws) => {
                if (ws.verified != null && ws.verified) {
                    verifiedWorkspaces.push(ws);
                }
            });
            const wsMfpRes = await managementMfp.findSubWorkspaceMfpCombinations({ subWorkspaceIds: verifiedWorkspaces.map((ws) => ws.id!) }, auth);

            const rows: managementMfp.Row[] = wsMfpRes.combinations.map((combi, index) => {
                // 自ワークスペース、サブワークスペースのみWS詳細ボタンを表示
                let isViewWsDetailButton = false;
                if (combi.workspace.id === appContainer.values.signinWorkspaceObject.id) {
                    isViewWsDetailButton = true;
                } else {
                    verifiedWorkspaces.forEach((ws) => {
                        if (ws.id === combi.workspace.id) {
                            if (ws.parentWorkspace === appContainer.values.signinWorkspaceObject.id) {
                                isViewWsDetailButton = true;
                            }
                        }
                    });
                }

                return {
                    id: index,
                    logoUrl: combi.workspace.logoUrl ? combi.workspace.logoUrl! : '',
                    objId: combi.workspace.id ? combi.workspace.id : '',
                    mfpObjId: combi.mfpId,
                    mfpNumber: combi.mfpNumber,
                    mfpType: combi.mfpName,
                    organizationDetail: combi.workspace.organizationDetail ? combi.workspace.organizationDetail : {},
                    displayId: combi.workspace.displayId,
                    info: isViewWsDetailButton,
                };
            });
            return rows;
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            return [];
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (!values.init) {
                    const initData = await createData(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                    setValues({
                        ...values,
                        init: true,
                        rows: initData,
                    });
                    setDefaultRows(initData);
                    ui.update(UI.Loaded);
                }
            } catch (e) {
                ui.update(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const tableFunctions = () => {
        const isSigninWorkspace = (objId: string) => {
            return objId === appContainer.values.signinWorkspaceObject.id!;
        };
        const handleChangeSearchText = () => (event: React.ChangeEvent<HTMLInputElement>) => {
            logger.debug('handleChangeSearchText');
            logger.debug(searchText);
            setSearchText(event.target.value);
        };

        function handleChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
            setPage(newPage);
        }
        const getPage = () => {
            return page;
        };
        function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            setRowsPerPage(parseInt(event.target.value, 10));
        }
        const getRows = () => {
            return defaultRows
                .filter((r) => {
                    return (
                        (r && r.mfpNumber && r.mfpNumber.indexOf(searchText) !== -1) ||
                        (r && r.mfpType && r.mfpType.indexOf(searchText) !== -1) ||
                        (r && r.displayId && r.displayId.indexOf(searchText) !== -1) ||
                        (r && r.organizationDetail && r.organizationDetail!.name && r.organizationDetail!.name.indexOf(searchText) !== -1)
                    );
                })
                .sort((a, b) => (a.id < b.id ? -1 : 1));
        };
        const getRowsPerPage = () => {
            return rowsPerPage;
        };
        return {
            isSigninWorkspace,
            handleChangeRowsPerPage,
            handleChangeSearchText,
            handleChangePage,
            ui,
            getRows,
            getPage,
            getRowsPerPage,
            searchText,
        };
    };

    return {
        ...tableFunctions(),
    };
};

export const SubWorkspaceMfpsContainer = createContainer(useContainer);
