import * as React from 'react';
import { Button, Typography, createStyles, Theme, WithStyles, withStyles, Grid } from '@material-ui/core';
import locale from '@/common/utils/locale';
import Cancel from '@/common/components/Button/Cancel';
import { getSplitNewLineString } from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        mb20: {
            marginBottom: 20,
        },
        button: {
            fontSize: '0.875rem',
            [theme.breakpoints.up('md')]: {
                width: 'auto',
            },
        },
        buttonCase: {
            textAlign: 'right',
        },
        tips: {
            paddingLeft: 20,
            fontSize: '0.775rem',
        },
    });

interface Props extends WithStyles<typeof styles> {
    onClose: () => void;
    admit?: (propId: string, propName: string) => void;
    value?: string;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    const admitPush = () => {
        if (typeof props.admit === 'function') {
            props.admit('', '');
        }
        props.onClose();
    };
    return (
        <>
            <div className={classes.mb20}>
                <Typography variant="h3" color="inherit">
                    {locale.t(locale.keys.memberAuth.recovery.content, { email: props.value })}
                </Typography>
            </div>
            <div className={classes.mb20}>
                <ul className={classes.tips}>
                    {getSplitNewLineString(locale.t(locale.keys.memberAuth.recovery.tips)).map((v, index) => {
                        return <li key={index}>{v}</li>;
                    })}
                </ul>
            </div>
            <div className={classes.buttonCase}>
                <Cancel cancelFunc={props.onClose} />
                <Button disabled={false} variant="contained" fullWidth onClick={admitPush} className={classes.button}>
                    {locale.t(locale.keys.action.send)}
                </Button>
            </div>
        </>
    );
};

export default withStyles(styles)(Component);
