import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        tabs: {
            backgroundColor: theme.palette.background.paper,
            textAlign: 'left',
            padding: 0,
            marginBlock: '8px',
        },
        tabTags: {
            minHeight: 36,
            color: `${theme.palette.secondary.main}`,
            backgroundColor: 'white',
            textAlign: 'left',
            width: '260px',
            fontWeight: 'bold',
            borderBottom: `3px solid ${theme.palette.secondary.main}`,
        },
        tabsContainer: {
            minHeight: 36,
        },
        tabSelected: {
            color: '#ffffff',
            backgroundColor: `${theme.palette.secondary.main}`,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
        },
        indicator: {
            display: 'none',
        },
    });
