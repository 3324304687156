import { default as React } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography, TextField, Fab, Grid, FormControl } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import locale from '@/common/utils/locale';

import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { MemberDetailContainer } from './MemberDetailContainer';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        button: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        buttonGrid: {
            textAlign: 'left',
        },
        removeButton: {
            color: 'white',
            margin: theme.spacing.unit,
        },
        buttonText: {
            margin: '0 8px',
        },
    });
interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const container = MemberDetailContainer.useContainer();
    const { classes } = props;
    return (
        <Card className={props.classes.card}>
            <CardContent>
                <FormControl fullWidth>
                    <Typography align="left" inline variant="h4">
                        {locale.t(locale.keys.memberDetail.dangerZone.index)}
                    </Typography>
                    <Typography align="left" inline color="textSecondary">
                        {locale.t(locale.keys.memberDetail.dangerZone.warning)}
                    </Typography>
                </FormControl>
                <Grid item xs={12}>
                    <TextField
                        className={classes.text}
                        label={locale.t(locale.keys.memberDetail.dangerZone.emailConfirm)}
                        placeholder=""
                        margin="normal"
                        variant="filled"
                        value={container.removeForm.email}
                        inputProps={{ style: { height: '100%' } }}
                        onChange={container.handleEmailConfirm('email')}
                    />
                </Grid>
                <Grid item xs={12} className={classes.buttonGrid}>
                    <Fab
                        variant="extended"
                        className={classes.removeButton}
                        size="small"
                        disabled={container.removeButtonDisabled()}
                        color="secondary"
                        onClick={container.onRemove(appContainer.values.authorizationCode, props.history)}
                    >
                        <span className={classes.buttonText}>{locale.t(locale.keys.memberDetail.dangerZone.removeAccount)}</span>
                    </Fab>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default withRouter(withStyles(styles)(Component));
