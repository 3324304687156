import { AdminAppContainer, EntrySearch, initialEntrySearch } from '@/admin/components/AdminAppContainer';

// 画面遷移しても検索結果をグローバルステートに保存できるようにしておくためのカスタムフック
export const useGlobalEntrySearch = () => {
    const appContainer = AdminAppContainer.useContainer();
    const searchState = appContainer.searchState
    const setSearchState = appContainer.setSearchState
    const actions = {
        init: () => {
            setSearchState({...initialEntrySearch});
        },
        setGlobalEntrySearchState: (entrySearch: EntrySearch) => {
            setSearchState({...entrySearch});
        }
    }

    return {
        globalEntrySearch: searchState,
        actions
    };
}