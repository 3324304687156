import { CircularProgress } from '@material-ui/core';
import { default as React, useEffect } from 'react';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { V1ObjectsMailResourceType } from '@/bundles/schema/typescript/schema';
import { useInviteMailState } from '@/common/models/mail/status';

interface Props {
    type: V1ObjectsMailResourceType;
    objectId: string;
    wsUserId?: string;
    email?: string;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const abortController = new AbortController();
    const { status, getInviteMailStatus } = useInviteMailState(props.type, props.objectId, appContainer.values.authorizationCode, props.wsUserId, props.email, abortController);

    useEffect(() => {
        getInviteMailStatus();
        return () => {
            // アンマウント時にリクエストキャンセル
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.wsUserId, props.objectId, props.type]);

    return (
        <div key={props.objectId}>
            {status === undefined ? (
                <>
                    {' '}
                    <CircularProgress />
                </>
            ) : (
                <>{status}</>
            )}
        </div>
    );
};

export default Component;
