import * as React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, FormControl } from '@material-ui/core';

import locale from '@/common/utils/locale';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { MemberDetailContainer } from './MemberDetailContainer';
import useRole from '@/common/constants/userRole';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        title: {
            textAlign: 'left',
        },
        button: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        hr: {
            margin: '21px 0',
        },
    });
interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const container = MemberDetailContainer.useContainer();
    const displayId = appContainer.values.signinWorkspaceObject != null ? appContainer.values.signinWorkspaceObject.displayId : '';
    const { classes } = props;
    return (
        <div>
            <Card className={classes.card}>
                <CardContent>
                    {/*
                    <Button variant="outlined" className={classes.button} size="large" color="secondary" onClick={container.onCloud()}>
                        {locale.t(locale.keys.memberDetail.memberControl.clooudSetting)}
                    </Button>
                    <Button variant="outlined" className={classes.button} size="large" color="secondary" onClick={container.onEndpoint()}>
                        {locale.t(locale.keys.memberDetail.memberControl.endpointSetting)}
                    </Button>
                    <Button variant="outlined" className={classes.button} size="large" color="secondary" onClick={container.onDevice()}>
                        {locale.t(locale.keys.memberDetail.memberControl.deviceSetting)}
                    </Button>
                    <hr className={classes.hr} />
                    */}
                    {/* 招待Eメールを送信せずに利用開始 ボタン */}
                    {container.values.user && !container.values.user.invitationVerified && (
                        <>
                            <Button variant="outlined" className={classes.button} size="large" color="secondary" onClick={container.onGetInviteUrl}>
                                {locale.t(locale.keys.subWorkspaceDetail.signinPanel.instantActivate.index)}
                            </Button>
                            <FormControl fullWidth>
                                <Typography align="left" inline color="textSecondary">
                                    {locale.t(locale.keys.subWorkspaceDetail.signinPanel.sendSignupEmailExplanation.index)}
                                </Typography>
                            </FormControl>
                        </>
                    )}
                    {/* パスワードリセット ボタン */}
                    {container.values.user && container.values.user.invitationVerified && (
                        <Button
                            variant="outlined"
                            className={classes.button}
                            size="large"
                            color="secondary"
                            onClick={container.onResetPassword(displayId)}
                            disabled={container.resetPasswordButtonDisabled()}
                        >
                            {locale.t(locale.keys.memberDetail.memberControl.resetPassword)}
                        </Button>
                    )}
                    {/* アカウントの一時停止＆有効化 ボタン*/}
                    {container.values.user && container.values.user.invitationVerified && (
                        <>
                            {container.values.user && container.values.user.active ? (
                                <Button variant="outlined" className={classes.button} size="large" color="secondary" onClick={container.onSuspendAccount(appContainer.values.authorizationCode)}>
                                    {locale.t(locale.keys.memberDetail.memberControl.suspendAccount)}
                                </Button>
                            ) : (
                                <Button variant="outlined" className={classes.button} size="large" color="secondary" onClick={container.onResumeAccount(appContainer.values.authorizationCode)}>
                                    {locale.t(locale.keys.memberDetail.memberControl.resumeAccount)}
                                </Button>
                            )}
                        </>
                    )}
                    {/* 管理者権限でログイン ボタン */}
                    {container.values.user && container.values.user.invitationVerified && container.values.user.role === useRole.member && (
                        <Button variant="outlined" className={classes.button} size="large" color="secondary" onClick={container.onAgentSignIn}>
                            {locale.t(locale.keys.memberDetail.memberControl.agentSignIn)}
                        </Button>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default withStyles(styles)(Component);
