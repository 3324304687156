import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as schema from '@/bundles/schema/typescript/schema';
import * as workspace from '@/common/api/workspace/workspace';

// ワークスペースユーザー取得
export const findSubWorkspace = async (workspaceId: string, auth: string): Promise<schema.V1WorkspaceIndexResponse> => {
    return workspace.findSubWorkspaces(workspaceId, auth);
};
// ワークスペースMfp取得
export const findSubWorkspaceMfpCombinations = async (req: schema.V1WorkspaceMfpsListIndexRequest, auth: string): Promise<schema.V1WorkspaceMfpsListIndexResponse> => {
    return workspace.findSubWorkspaceMfpCombinations(req, auth);
};

export interface Form {
    filter: string;
    rows: Row[];
    init: boolean;
    validateInit: {
        name: boolean;
    };
    errors: validator.ValidationOutput;
}

export const New = (): Form => ({
    filter: '',
    rows: [],
    init: false,
    validateInit: {
        name: false,
    },
    // Initialize error holder.
    errors: NewValidation(),
});

export interface Row {
    id: number;
    objId: string;
    mfpObjId: string;
    logoUrl: string;
    mfpNumber: string;
    mfpType: string;
    organizationDetail: schema.V1ObjectsWorkspacesOrganizationDetail | undefined;
    displayId: string;
    info: boolean; // ワークスペース詳細のリンクを貼るかのフラグ
}

export const validations: validator.Constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: locale.t(locale.keys.validation.required),
        },
    },
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        name: null,
    };
};
