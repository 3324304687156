import { default as React } from 'react';
import { Fab, Card, CardContent, TextField, Theme, Grid, Tabs, Tab, Typography, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ImageFileSelector from '@/common/components/ImageFileSelector';
import LanguageSelect from '@/common/components/LanguageSelect';
import { Variants } from '@/common/components/messages/CommonMessage';
import { State as UI } from '@/common/components/hooks/useUI';
import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import * as errorHandler from '@/common/utils/errorHandler';
import * as uploader from '@/common/utils/uploader';
import * as schema from '@/bundles/schema/typescript/schema';

import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import FormOrganization from '@/admin/components/common/FormOrganization';
import * as workspace from '@/common/api/workspace/workspace';

import { SubWorkspaceDetailContainer } from './SubWorkspaceDetailContainer';

const styles = (theme: Theme) =>
    createStyles({
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        imageFileSelectorCardContent: {
            textAlign: 'center',
            paddingBottom: 0,
            height: '160px',
        },
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        selectLanguage: {
            marginTop: '17px',
        },
        creatableSubWorkspace: {
            marginTop: '10px',
            alignItems: 'center',
            textAlign: 'left',
        },
        typoIcon: {
            color: '#43a047',
            fontSize: 20,
            margin: '5px 5px -5px 5px',
        },
        saveButton: {
            color: 'white',
            margin: theme.spacing.unit,
            minWidth: '240px',
        },

        content: {
            backgroundColor: theme.palette.background.paper,
            textAlign: 'left',
            padding: 0,
        },
        tabTags: {},
        tabGrid: {
            padding: '24px 16px 0 16px',
            backgroundColor: theme.palette.background.paper,
            minHeight: '500px',
            [theme.breakpoints.down('sm')]: {
                minHeight: '700px',
            },
        },
        button: {
            color: 'white',
            margin: 'auto',
            minWidth: '240px',
        },
    });
interface Props extends RouteComponentProps<{ id: string }>, WithStyles<typeof styles> {}

const StyledTabs = withStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '16px 0',
            minHeight: 32,
            display: 'inline-block',
            borderBottom: '2px solid #ff7300',
        },
        indicator: {
            display: 'none',
        },
    }),
)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 32,
            minWidth: 107,
            padding: 0,
            margin: '0 5px',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '&:hover': {
                opacity: 1,
            },
            '&$selected': {
                color: 'white',
                backgroundColor: theme.palette.secondary.main,
            },
            '&$selected :active': {
                color: 'white',
            },
            '&:active': {
                color: theme.palette.secondary.main,
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab {...props} />);

interface StyledTabProps {
    label: string;
}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const swdc = SubWorkspaceDetailContainer.useContainer();
    const { classes } = props;

    const [tabIndex, setTabIndex] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleSave = () => {
        logger.debug('handleSave');
        if (appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace) {
            return;
        }
        swdc.updateUIStatus(UI.Saving);

        (async () => {
            try {
                if (!swdc.values.workspace.id) {
                    return;
                }

                let workspaceLogoUrl = swdc.values.logoUrl;
                if (swdc.logoDataUri) {
                    const uploadRes = await uploader.Upload(`${swdc.values.workspace.id}.png`, schema.BlobType.WorkspaceLogo, appContainer.values.authorizationCode, swdc.logoDataUri);
                    workspaceLogoUrl = uploadRes.publicUrl;
                }
                const req: schema.V1WorkspaceUpdateRequest = {
                    displayId: swdc.values.workspaceId,
                    displayName: swdc.values.workspaceName,
                    enableSubWorkspace: swdc.values.enableSubWorkspace,
                    language: swdc.values.workspaceLang,
                    contactEmail: swdc.values.workspaceContactEmail,
                    logoUrl: workspaceLogoUrl,
                };
                logger.debug('organization', swdc.organization);
                if (swdc.organization) {
                    req.organizationDetail = {};
                    req.organizationDetail.name = swdc.organization.name ? swdc.organization.name : '';
                    req.organizationDetail.zipCode = swdc.organization.zipCode ? swdc.organization.zipCode : '';
                    req.organizationDetail.country = swdc.organization.country ? swdc.organization.country : '';
                    req.organizationDetail.address1 = swdc.organization.address1 ? swdc.organization.address1 : '';
                    req.organizationDetail.address2 = swdc.organization.address2 ? swdc.organization.address2 : '';
                    req.organizationDetail.phoneNumber = swdc.organization.phoneNumber ? swdc.organization.phoneNumber : '';
                    req.organizationDetail.customerId = swdc.organization.customerId ? swdc.organization.customerId : '';
                }
                const result = await workspace.updateWorkspace(swdc.values.workspace.id, appContainer.values.authorizationCode, req);
                logger.debug('updateWorkspace result', result);

                await swdc.loadWorkspace(result.displayId, appContainer.values.authorizationCode);
                props.history.replace(`/sub-workspace/detail/${result.displayId}`);
                swdc.openMessage(locale.t(locale.keys.action.saved), Variants.success);
                swdc.updateUIStatus(UI.Loaded);
            } catch (e) {
                swdc.updateUIStatus(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };
    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <StyledTabs value={tabIndex} onChange={handleChange} className={classes.tabTags}>
                        <StyledTab label={locale.t(locale.keys.workspaceSetting.index)} />
                        <StyledTab label={locale.t(locale.keys.organizationInfo.index)} />
                    </StyledTabs>
                    {tabIndex === 0 && (
                        // プロファイル
                        <Grid container spacing={16} className={classes.tabGrid}>
                            <Grid item xs={12} sm={5} style={{ paddingBottom: 0 }}>
                                <Typography align="left" color="textSecondary">
                                    {locale.t(locale.keys.subWorkspaceDetail.settingPanel.logo.index)}
                                </Typography>
                                <CardContent className={classes.imageFileSelectorCardContent}>
                                    <div>
                                        <ImageFileSelector
                                            seed={swdc.values.workspace.id}
                                            uploadWidth={512}
                                            uploadHeight={512}
                                            mobileWidth={160}
                                            mobileHeight={160}
                                            pcWidth={160}
                                            pcHeight={160}
                                            defaultUrl={swdc.values.logoUrl}
                                            logoDataUri={swdc.logoDataUri}
                                            isAvatar={true}
                                            editable={appContainer.values.signinWorkspaceObject.id === swdc.values.workspace.parentWorkspace}
                                            onLoaded={(datauri) => {
                                                swdc.handleChangeLogoDataUri(datauri);
                                            }}
                                            dependAppContainer={AdminAppContainer.useContainer}
                                        />
                                    </div>
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} sm={7} style={{ paddingBottom: 0 }}>
                                <CardContent style={{ paddingBottom: 0 }}>
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.subWorkspaceDetail.settingPanel.workspaceId.index)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={swdc.values.workspaceId}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => swdc.handleChangeWorkspaceId(event.target.value)}
                                        error={swdc.handleValidateWorkspaceId() !== ''}
                                        helperText={swdc.handleValidateWorkspaceId()}
                                        disabled={appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                                    />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.subWorkspaceDetail.settingPanel.workspaceDisplayName.index)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={swdc.values.workspaceName}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => swdc.handleChangeWorkspaceName(event.target.value)}
                                        error={swdc.handleValidateWorkspaceName() !== ''}
                                        helperText={swdc.handleValidateWorkspaceName()}
                                        disabled={appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                                    />

                                    <div className={classes.selectLanguage}>
                                        <LanguageSelect
                                            value={swdc.values.workspaceLang}
                                            label={locale.t(locale.keys.newWorkspaceSetting.workspaceLanguage)}
                                            handleChange={(event) => swdc.handleChangeWorkspaceLang(event.target.value)}
                                            handleValidate={() => swdc.handleValidateWorkspaceLang()}
                                            disabled={appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                                        />
                                    </div>

                                    {/* {swdc.values.enableSubWorkspace ? (
                                        <Typography className={classes.creatableSubWorkspace}>
                                            <CheckCircle className={classes.typoIcon} />
                                            {locale.t(locale.keys.subWorkspaceDetail.settingPanel.enableSubWorkspace.index)}
                                        </Typography>
                                    ) : (
                                        <br />
                                    )} */}

                                    <br />
                                    <br />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.subWorkspaceDetail.settingPanel.contactEmail.index)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={swdc.values.workspaceContactEmail}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => swdc.handleChangeContactEmail(event.target.value)}
                                        error={swdc.handleValidateContactEmail() !== ''}
                                        helperText={swdc.handleValidateContactEmail()}
                                        disabled={appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                                    />
                                </CardContent>
                            </Grid>
                        </Grid>
                    )}
                    {tabIndex === 1 && (
                        // 組織情報
                        <FormOrganization
                            organization={swdc.organization}
                            setOrganization={swdc.setOrganization}
                            disabled={appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                        />
                    )}
                </CardContent>
                <CardContent>
                    <Grid container spacing={32}>
                        <Grid item xs={12} style={{ width: '100%' }}>
                            <Fab
                                variant="extended"
                                className={classes.saveButton}
                                size="large"
                                color="secondary"
                                disabled={swdc.buttonDisabled() || appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                                onClick={() => handleSave()}
                            >
                                {locale.t(locale.keys.subWorkspaceDetail.settingPanel.save)}
                            </Fab>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default withRouter(withStyles(styles)(Component));
