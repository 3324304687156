import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { Variants } from '@/common/components/messages/CommonMessage';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as schema from '@/bundles/schema/typescript/schema';
import * as model from '@/common/api/sub-workspace/detail/Edit';
import * as validator from '@/common/utils/validator';
import * as workspace from '@/common/api/workspace/workspace';
import * as modelOrganization from '@/common/models/organization';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';

const useSubWorkspaceDetailContainer = () => {
    const [values, setValues] = useState(model.New());
    const [organization, setOrganization] = useState(modelOrganization.New());
    const [logoDataUri, setLogoDataUri] = useState('');
    const ui = useUI();
    const appContainer = AdminAppContainer.useContainer();
    // ui
    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };
    const openMessage = (message: string, variant: Variants) => {
        const isOpen = true;
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen,
            message,
            variant,
        });
    };

    // ワークスペース情報初期化
    const loadWorkspace = async (displayId: string, auth: string) => {
        const result = await workspace.showWorkspace(displayId, auth);
        if (!result || !result.workspace) {
            throw new Error('Workspace not found');
        }
        updateWorkspace(result.workspace);

        const p = await workspace.findWorkspaceUser(values.workspace.id!, auth);
        const childWorkspaceMyself = p.users.find((u) => u.invitationEmail === appContainer.values.signinWorkspaceUserObject.invitationEmail);
        setValues({ ...values, childWorkspaceMyself });
    };

    const updateWorkspace = (w: schema.V1ObjectsWorkspace) => {
        values.logoUrl = w.logoUrl ? w.logoUrl : '';
        values.workspaceId = workspace.isInitialValue(w.displayId) ? '' : w.displayId;
        values.workspaceName = workspace.isInitialValue(w.displayName) ? '' : w.displayName;
        values.workspaceLang = w.language ? w.language : '';
        values.workspaceContactEmail = w.contactEmail ? w.contactEmail : '';
        values.enableSubWorkspace = w.enableSubWorkspace ? w.enableSubWorkspace : false;
        values.workspace = w;
        if (w.organizationDetail) {
            organization.name = w.organizationDetail.name ? w.organizationDetail.name : '';
            organization.zipCode = w.organizationDetail.zipCode ? w.organizationDetail.zipCode : '';
            organization.country = w.organizationDetail.country ? w.organizationDetail.country : '';
            organization.address1 = w.organizationDetail.address1 ? w.organizationDetail.address1 : '';
            organization.address2 = w.organizationDetail.address2 ? w.organizationDetail.address2 : '';
            organization.phoneNumber = w.organizationDetail.phoneNumber ? w.organizationDetail.phoneNumber : '';
            organization.customerId = w.organizationDetail.customerId ? w.organizationDetail.customerId : '';
        }
    };

    // ワークスペースロゴ
    const handleChangeLogoUrl = (v: string) => {
        setValues({ ...values, logoUrl: v });
    };

    const handleChangeLogoDataUri = (v: string) => {
        setLogoDataUri(v);
    };

    // ワークスペースID
    const handleChangeWorkspaceId = (v: string) => {
        setValues({ ...values, workspaceId: v, validateInit: { ...values.validateInit, workspaceId: true } });
    };
    const handleValidateWorkspaceId = () => {
        if (!values.validateInit.workspaceId) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.workspaceId === null ? '' : msg!.workspaceId.toString();
        return dispMessage;
    };

    // ワークスペース表示名
    const handleChangeWorkspaceName = (v: string) => {
        setValues({ ...values, workspaceName: v, validateInit: { ...values.validateInit, workspaceName: true } });
    };
    const handleValidateWorkspaceName = () => {
        if (!values.validateInit.workspaceName) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.workspaceName === null ? '' : msg!.workspaceName.toString();
        return dispMessage;
    };

    // ワークスペース言語
    const handleChangeWorkspaceLang = (v: string) => {
        setValues({ ...values, workspaceLang: v, validateInit: { ...values.validateInit, workspaceLang: true } });
    };
    const handleValidateWorkspaceLang = () => {
        if (!values.validateInit.workspaceLang) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.workspaceLang === null ? '' : msg!.workspaceLang.toString();
        return dispMessage;
    };

    // サブワークスペース作成可能
    const handleChangeEnableSubWorkspace = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, enableSubWorkspace: event.target.checked });
    };

    // 連絡先Eメールアドレス
    const handleChangeContactEmail = (v: string) => {
        setValues({ ...values, workspaceContactEmail: v, validateInit: { ...values.validateInit, workspaceContactEmail: true } });
    };
    const handleValidateContactEmail = () => {
        if (!values.validateInit.workspaceContactEmail) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.workspaceContactEmail === null ? '' : msg!.workspaceContactEmail.toString();
        return dispMessage;
    };

    // ワークスペース削除
    const handleChangeWorkspaceIdConfirm = (v: string) => {
        setValues({ ...values, workspaceIdConfirm: v });
    };

    const buttonDisabled = () => {
        return (
            validator.Validate<model.Props>(values, model.validations(), model.NewValidation) !== null ||
            validator.Validate<modelOrganization.OrganizationInfo>(organization, modelOrganization.validation(), modelOrganization.NewValidation) !== null
        );
    };

    const removeButtonDisabled = (): boolean => {
        return values.workspace.displayId !== values.workspaceIdConfirm;
    };

    return {
        values,
        organization,
        setOrganization,
        // load
        loadWorkspace,
        updateWorkspace,
        // ui
        ui,
        updateUIStatus,
        openMessage,
        // Config
        handleChangeLogoUrl,
        logoDataUri,
        handleChangeLogoDataUri,
        handleChangeWorkspaceId,
        handleValidateWorkspaceId,
        handleChangeWorkspaceName,
        handleValidateWorkspaceName,
        handleChangeWorkspaceLang,
        handleValidateWorkspaceLang,
        handleChangeContactEmail,
        handleValidateContactEmail,
        handleChangeEnableSubWorkspace,
        buttonDisabled,
        // Remove
        handleChangeWorkspaceIdConfirm,
        removeButtonDisabled,
    };
};
export const SubWorkspaceDetailContainer = createContainer(useSubWorkspaceDetailContainer);
