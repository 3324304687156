import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import Member from './Member';
import { MemberContainer } from './MemberContainer';
import locale from '@/common/utils/locale';

interface Props {}

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.memberManagement.index));

    return (
        <>
            <MemberContainer.Provider>
                <Member />
            </MemberContainer.Provider>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
