import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { EmailValidate } from '@/common/constants/email';
import * as schema from '@/bundles/schema/typescript/schema';

export interface Props {
    logoUrl: string;
    workspaceId: string;
    workspaceName: string;
    workspaceLang: string;
    workspaceContactEmail: string;
    enableSubWorkspace: boolean;

    validateInit: {
        workspaceId: boolean;
        workspaceName: boolean;
        workspaceLang: boolean;
        workspaceContactEmail: boolean;
    };
    workspaceIdConfirm: string;

    workspace: schema.V1ObjectsWorkspace;

    childWorkspaceMyself?: schema.V1ObjectsWorkspaceuserLarge;
    invitedUser?: schema.V1ObjectsWorkspaceuserLarge;
    errors: validator.ValidationOutput;
}

export const New = (): Props => ({
    logoUrl: '',
    workspaceId: '',
    workspaceName: '',
    workspaceLang: '',
    workspaceContactEmail: '',
    enableSubWorkspace: false,

    validateInit: {
        workspaceId: false,
        workspaceName: false,
        workspaceLang: false,
        workspaceContactEmail: false,
    },

    workspaceIdConfirm: '',

    workspace: {
        displayId: '',
        displayName: '',
    },

    // Initialize error holder.
    errors: NewValidation(),
});

export const validations = (): validator.Constraints => {
    return {
        workspaceId: {
            workspaceId: {
                message: locale.t(locale.keys.validation.workspaceIdFormat),
            },
        },
        workspaceName: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        workspaceLang: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        workspaceContactEmail: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            email: {
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        workspaceId: null,
        workspaceName: null,
        workspaceLang: null,
        workspaceContactEmail: null,
    };
};
