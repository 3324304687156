import { default as React, useEffect } from 'react';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import routes from '@/user/constants/routes';
import { useHistory } from 'react-router-dom';

interface Props {
    ui?: UI;
    skipEffect?: boolean;
}
export const Component: React.FC<Props> = (props) => {
    const ui = useUI(props.ui);
    const appContainer = AdminAppContainer.useContainer();
    const history = useHistory();
    useEffect(() => {
        if (props.skipEffect) {
            return;
        }

        if (ui.current !== UI.Loading) {
            return;
        }
        appContainer.onSignout(appContainer, appContainer.values);
        ui.update(UI.Loaded);
        history.push(routes.auth.login.index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.skipEffect, appContainer, ui]);

    return <></>;
};

export default Component;
