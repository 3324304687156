// @ts-nocheck
import React, { useEffect } from 'react';
import { Button, createStyles, Theme, WithStyles, withStyles, TextField, Grid } from '@material-ui/core';
import locale from '@/common/utils/locale';
import Cancel from '@/common/components/Button/Cancel';
import { getSplitNewLineString } from '@/common/utils/webappUtil';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as validator from '@/common/utils/validator';
import { remindWorkdpacesID } from '@/common/api/workspace/remind/me';
import { EmailValidate } from '@/common/constants/email';

const styles = (theme: Theme) =>
    createStyles({
        mb20: {
            marginBottom: 20,
        },
        button: {
            fontSize: '0.875rem',
            [theme.breakpoints.up('md')]: {
                width: 'auto',
            },
        },
        buttonCase: {
            textAlign: 'right',
        },
        textForm: {
            width: '100%',
            marginBlock: '0px',
        },
        tips: {
            paddingLeft: 20,
            fontSize: '0.775rem',
        },
    });

interface Props extends WithStyles<typeof styles> {
    onClose: () => void;
    value?: string;
}

const emailValidations = (): validator.Constraints => {
    return {
        email: {
            userEmail: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};
const NewEmailValidations = (): validator.ValidationOutput => {
    return {
        email: null,
    };
};

type Form = {
    email: string;
};

export const handleValidateEmail = (text: Form) => {
    const msg = validator.Validate<Form>(text, emailValidations(), NewEmailValidations);
    const dispMessage = msg === null ? '' : msg.email === null ? '' : msg!.email.toString();
    return dispMessage;
};

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI(UI.Loaded);
    const [email, setEmail] = React.useState('');
    // 初期表示時にはバリデーションエラーを表示しないためのステート
    const [isEdit, setIsEdit] = React.useState({ email: false });

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    const admitPush = async () => {
        ui.update(UI.Loading);
        try {
            await remindWorkdpacesID(email);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: `${locale.t(locale.keys.action.sent)}`,
                variant: Variants.success,
            });
        } catch (e) {
            // 送信したメールアドレスの登録有無にかかわらず成功するので、このメッセージが表示されるのはAPIに異常が発生した場合またはパラメータ不足のみ
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: `${locale.t(locale.keys.action.failedToSend)}`,
                variant: Variants.error,
            });
        } finally {
            ui.update(UI.Loaded);
        }
        props.onClose();
    };
    return (
        <>
            <TextField
                className={classes.textForm}
                id="email"
                label={locale.t(locale.keys.common.email)}
                margin="normal"
                variant="filled"
                value={email}
                inputProps={{ style: { height: '100%' } }}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
                    setIsEdit({ ...isEdit, email: true });
                    setEmail(event.target.value);
                }}
                error={isEdit.email && handleValidateEmail({ email }) !== ''}
                helperText={isEdit.email && handleValidateEmail({ email })}
            />
            <div className={classes.mb20}>
                <ul className={classes.tips}>
                    {getSplitNewLineString(locale.t(locale.keys.selectWorkspace.recovery.tips)).map((v, index) => {
                        return <li key={index}>{v}</li>;
                    })}
                </ul>
            </div>
            <div className={classes.buttonCase}>
                <Cancel cancelFunc={props.onClose} />
                <Button disabled={handleValidateEmail({ email }) !== ''} variant="contained" fullWidth onClick={admitPush} className={classes.button}>
                    {locale.t(locale.keys.action.send)}
                </Button>
            </div>
        </>
    );
};

export default withStyles(styles)(Component);
