import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { CollectiveInviteContainer } from './CollectiveInviteContainer';
import CollectiveInvite from './CollectiveInvite';
import { parse } from 'query-string';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.memberInvitation.collectiveInvite.index));
    const location = useLocation();
    const parsedQuery = parse(location.search);
    let backPath = undefined as string | undefined;
    if (parsedQuery.from != null && typeof parsedQuery.from === 'string') {
        backPath = decodeURIComponent(parsedQuery.from);
    }

    return (
        <>
            <SubHeader
                title={locale.t(locale.keys.memberInvitation.collectiveInvite.index)}
                pageDescription={locale.t(locale.keys.pageTitle.adminHeaderDescription.memberCollectiveInvite)}
                isBack={backPath}
            />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    <CollectiveInviteContainer.Provider>
                        <CollectiveInvite />
                    </CollectiveInviteContainer.Provider>
                </div>
            </div>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
