/**
 * Locale management module.
 *
 * How it works:
 * 1. First, call `locale.use(language)`
 * 2. Then, call `locale.t(key, {replacer})`
 */
import dotObject from 'dot-object';

import jaJson from '@/bundles/translation/json/webapp/ja.json';
import enJson from '@/bundles/translation/json/webapp/en.json';
import consts from '@/bundles/translation/typescript/webapp/consts';

export const keys = consts;

/**
 * Available languages.
 */
export enum Language {
    English = 'en',
    Japanese = 'ja',
}

const languages = [Language.English, Language.Japanese];

/**
 * localStorage keys.
 */
export enum Storage {
    Language = 'lang',
}

/**
 * Object representing current language.
 */
interface Current {
    language: Language;
}

/**
 * Set target language and type to use in context.
 */
export const use = (lang?: Language): Current => {
    if (!lang || !languages.includes(lang!)) {
        return {
            language: Language.English,
        };
    }

    // Parse language.
    window.localStorage.setItem(Storage.Language, lang);

    return {
        language: lang,
    };
};

/**
 *  Set target language
 * @param languages Language in priority order
 */
export const set = (...languages: string[]): void => {
    for (const lang of languages) {
        if (!lang) {
            continue;
        }
        if (lang && languages.includes(lang as Language)) {
            use(lang as Language);
            return;
        }
    }
    use(browserLanguage());
};

/**
 * Returns devise language or fallback to English.
 */
export const current = (): Language => {
    try {
        // localStorage is prioritized.
        const item = window.localStorage.getItem(Storage.Language);

        if (item && languages.includes(item as Language)) {
            return item as Language;
        }

        return browserLanguage();
    } catch (err) {
        return Language.English;
    }
};

/**
 * Return browser language
 */
const browserLanguage = (): Language => {
    // @ts-ignore
    const got = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || window.navigator.userLanguage;

    if (typeof got !== 'string') {
        return Language.English;
    }

    const matched = languages.filter((key) => got.toLowerCase().startsWith(key));

    if (!matched || !Array.isArray(matched) || !languages.includes(matched[0])) {
        return Language.English;
    }
    return matched[0];
};

/**
 * Given type and language, return imported JSON.
 */
const bundleOf = (lang: Language) => {
    switch (lang) {
        case Language.Japanese: {
            return jaJson;
        }

        // Fallback to English.
        default: {
            return enJson;
        }
    }
};

/**
 * Replacer is a set of key-value pairs.
 */
interface Replacer {
    [key: string]: string | number | undefined;
}

/**
 * t stands for translate, and returns string.
 * This method does not take care of plural strings.
 */
export const t = (key: string, replacer?: Replacer, language?: string): string => {
    let l: Language;
    if (language && languages.includes(language as Language)) {
        l = language as Language;
    } else {
        l = current();
    }
    const got = dotObject.pick(key, bundleOf(l));

    if (!got) {
        return key;
    }

    if (!replacer) {
        return got;
    }

    // Format when replacer exists.
    return got.replace(/\{(\w+)\}/g, (match: string, key: string) => replacer[key] || '');
};

export const isDynamic = (key: string): boolean => {
    const got = dotObject.pick(key, bundleOf(current()));
    if (!got) {
        return false;
    }
    const regexItem = new RegExp(/\{(\w+)\}/g);
    if (regexItem.test(got)) {
        return true;
    }
    return false;
};

/**
 * Pluralization handler.
 * This method does a greedy match against given replacer.
 * Use `t` method to reduce resource footprint.
 *
 * Translation must be following structure:
 * {
 *   key:
 *     one: Page
 *     two: Pages
 * }
 */
export const plural = (key: string, val: number, replacer?: Replacer): string => {
    const one = `${key}.one`;
    const two = `${key}.two`;

    if (val <= 1) {
        return t(one, replacer);
    }

    return t(two, replacer);
};
