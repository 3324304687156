import { default as step1 } from './step1.png';
import { default as step2 } from './step2.png';
import { default as step3 } from './step3.png';

interface Img {
    [key: string]: string;
}

const popupMfp: Img = {
    step1,
    step2,
    step3,
};
export default {
    popupMfp,
};
