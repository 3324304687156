import dotObject from 'dot-object';
import jaJson from '@/bundles/translation/json/error/ja.json';
import enJson from '@/bundles/translation/json/error/en.json';
import errorKey from '@/bundles/translation/typescript/error/error-key';
import errorConsts from '@/bundles/translation/typescript/error/consts';

export const keys = errorKey;
export type ErrorKey = keyof typeof errorKey;
/**
 * Available languages.
 */
export enum Language {
    English = 'en',
    Japanese = 'ja',
}
const languages = [Language.English, Language.Japanese];
/**
 * Replacer is a set of key-value pairs.
 */
interface Replacer {
    [key: string]: string | number | undefined;
}

/**
 * localStorage keys.
 */
export enum Storage {
    Language = 'lang',
}

/**
 * Given type and language, return imported JSON.
 */
const bundleOf = (lang: Language) => {
    switch (lang) {
        case Language.Japanese: {
            return jaJson;
        }

        // Fallback to English.
        default: {
            return enJson;
        }
    }
};

/**
 * Return browser language
 */
const browserLanguage = (): Language => {
    // @ts-ignore
    const got = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || window.navigator.userLanguage;

    if (typeof got !== 'string') {
        return Language.English;
    }

    const matched = languages.filter((key) => got.toLowerCase().startsWith(key));

    if (!matched || !Array.isArray(matched) || !languages.includes(matched[0])) {
        return Language.English;
    }
    return matched[0];
};

/**
 * Returns devise language or fallback to English.
 */
export const current = (): Language => {
    try {
        // localStorage is prioritized.
        const item = window.localStorage.getItem(Storage.Language);

        if (item && languages.includes(item as Language)) {
            return item as Language;
        }

        return browserLanguage();
    } catch (err) {
        return Language.English;
    }
};

/**
 * t stands for translate, and returns string.
 * This method does not take care of plural strings.
 */
export const translate = (
    errorKey: string,
    replacer?: Replacer,
    language?: string,
): {
    message: string;
    statusCode: number;
    code: string;
    errorId: string;
} => {
    // keyが存在しなければundefinedで500エラーを返す
    const key = errorConsts[errorKey as ErrorKey];
    let l: Language;
    if (language && languages.includes(language as Language)) {
        l = language as Language;
    } else {
        // デフォルトは日本語に設定
        l = current();
    }
    const generateError = {
        message: 'エラーの作成に失敗しました。',
        statusCode: 500,
        code: 'InternalError',
        errorId: 'E99999',
    };
    if (!key) {
        return generateError;
    }
    const got = {
        message: dotObject.pick(key.message, bundleOf(l)),
        statusCode: parseInt(dotObject.pick(key.statusCode, bundleOf(l)), 10),
        code: dotObject.pick(key.code, bundleOf(l)),
        errorId: dotObject.pick(key.errorId, bundleOf(l)),
    };

    if (!got) {
        return generateError;
    }

    if (!replacer) {
        return got;
    }

    // Format when replacer exists.
    return { ...got, message: got.message.replace(/\{(\w+)\}/g, (match: string, key: string) => replacer[key] || '') };
};
