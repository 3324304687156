import { default as React, useEffect } from 'react';
import { Theme, InputAdornment, IconButton, TextField, Typography, createStyles, withStyles, WithStyles, Grid, Button } from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import locale from '@/common/utils/locale';
import { WorkspaceSignupContainer } from './WorkspaceSignupContainer';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import * as model from '@/common/api/auth/workspace-signup/WorkspaceSignup';

const styles = (theme: Theme) =>
    createStyles({
        mt20: {
            marginTop: 20,
        },
        mb20: {
            marginBottom: 20,
        },
        formControl: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
                minWidth: 240,
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    createWorkspaceFunc: () => void;
}

export const Component: React.FC<Props> = (props) => {
    const lhc = LogoHeaderContainer.useContainer();
    const container = WorkspaceSignupContainer.useContainer();
    const { classes } = props;

    useEffect(() => {
        lhc.setBackFunc(() => {
            container.setScreenState(model.WorkspaceSignupState.AdminSetting);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Grid container spacing={32}>
                <Grid item xs={12} className={`${classes.mb20} ${classes.mt20}`}>
                    <Typography align="center" variant="h3" className={classes.mb20} style={{ wordWrap: 'break-word' }}>
                        {locale.t(locale.keys.newWorkspaceSetting.workspace, { workspaceName: container.values.workspaceName, workspaceId: container.values.workspaceId })}
                    </Typography>
                    <Typography align="center">{locale.t(locale.keys.newWorkspaceSetting.passwordSetting)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.mb20}
                        fullWidth
                        label={locale.t(locale.keys.common.email)}
                        margin="normal"
                        value={container.values.mail}
                        inputProps={{ readOnly: true, style: { height: '100%' } }}
                    />
                    <TextField
                        className={classes.mb20}
                        type={container.values.showPassword ? 'text' : 'password'}
                        fullWidth
                        variant="filled"
                        label={locale.t(locale.keys.common.password)}
                        value={container.values.password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangePassword(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={() => container.handleClickShowPassword()}>
                                        {container.values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            inputProps: { style: { height: '100%' } },
                        }}
                        error={container.handleValidatePassword() !== ''}
                        helperText={container.handleValidatePassword()}
                    />

                    <TextField
                        className={classes.mb20}
                        type={container.values.showPasswordConfirm ? 'text' : 'password'}
                        fullWidth
                        variant="filled"
                        label={locale.t(locale.keys.common.passwordConfirm)}
                        value={container.values.passwordConfirm}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => container.handleChangePasswordConfirm(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle verify visibility" onClick={() => container.handleClickShowPasswordConfirm()}>
                                        {container.values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            inputProps: { style: { height: '100%' } },
                        }}
                        error={container.handleValidatePasswordConfirm() !== ''}
                        helperText={container.handleValidatePasswordConfirm()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button className={classes.button} fullWidth variant="contained" disabled={container.passwordSettingNextButtonDisabled()} onClick={() => props.createWorkspaceFunc()}>
                        {locale.t(locale.keys.action.complete)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(Component);
