import { useState } from 'react';
import { createContainer } from 'unstated-next';
import * as validator from '@/common/utils/validator';
import * as model from '@/common/api/auth/workspace-signup/WorkspaceSignup';
import * as modelOrganization from '@/common/models/organization';
import useUI, { State as UI } from '@/common/components/hooks/useUI';

const useWorkspaceSignupContainer = () => {
    const ui = useUI();
    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };
    const [values, setValues] = useState(model.New());
    const [organization, setOrganization] = useState(modelOrganization.New());
    const [screenState, setScreenState] = useState(model.WorkspaceSignupState.ProfileSetting);
    const [avatarDataUri, setAvatarDataUri] = useState('');
    const [logoDataUri, setLogoDataUri] = useState('');

    // ユーザ アバター
    const handleChangeAvatarUrl = (v: string) => {
        setValues({ ...values, avatarUrl: v });
    };

    // ユーザ アバター画像
    const handleChangeAvatarDataUri = (v: string) => {
        setAvatarDataUri(v);
    };

    // ワークスペースロゴ
    const handleChangeLogoUrl = (v: string) => {
        setValues({ ...values, logoUrl: v });
    };

    // ワークスペースロゴ画像
    const handleChangeLogoDataUri = (v: string) => {
        setLogoDataUri(v);
    };

    // パスワード
    const handleChangePassword = (v: string) => {
        setValues({ ...values, password: v, passwordSettingValidateInit: { ...values.passwordSettingValidateInit, password: true } });
    };
    const handleValidatePassword = () => {
        if (!values.passwordSettingValidateInit.password) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.passwordSettingValidations(), model.NewPasswordSettingValidation);
        const dispMessage = msg === null ? '' : msg.password === null ? '' : msg!.password.toString();
        return dispMessage;
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values['showPassword'] });
    };

    // パスワード確認
    const handleChangePasswordConfirm = (v: string) => {
        setValues({ ...values, passwordConfirm: v, passwordSettingValidateInit: { ...values.passwordSettingValidateInit, passwordConfirm: true } });
    };
    const handleValidatePasswordConfirm = () => {
        if (!values.passwordSettingValidateInit.passwordConfirm) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.passwordSettingValidations(), model.NewPasswordSettingValidation);
        const dispMessage = msg === null ? '' : msg.passwordConfirm === null ? '' : msg!.passwordConfirm.toString();
        return dispMessage;
    };
    const handleClickShowPasswordConfirm = () => {
        setValues({ ...values, showPasswordConfirm: !values['showPasswordConfirm'] });
    };

    // 名前
    const handleChangeName = (v: string) => {
        setValues({ ...values, name: v, adminSettingValidateInit: { ...values.adminSettingValidateInit, name: true } });
    };
    const handleValidateName = () => {
        if (!values.adminSettingValidateInit.name) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.adminSettingValidations(), model.NewAdminSettingValidation);
        const dispMessage = msg === null ? '' : msg.name === null ? '' : msg!.name.toString();
        return dispMessage;
    };

    // ニックネーム
    const handleChangePhoneticName = (v: string) => {
        setValues({ ...values, phoneticName: v, adminSettingValidateInit: { ...values.adminSettingValidateInit, phoneticName: true } });
    };
    const handleValidatePhoneticName = () => {
        if (!values.adminSettingValidateInit.phoneticName) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.adminSettingValidations(), model.NewAdminSettingValidation);
        const dispMessage = msg === null ? '' : msg.phoneticName === null ? '' : msg!.phoneticName.toString();
        return dispMessage;
    };

    // 複合機のログインユーザー名
    const handleChangeDeviceLoginUser = (v: string) => {
        setValues({ ...values, deviceLoginUser: v, adminSettingValidateInit: { ...values.adminSettingValidateInit, deviceLoginUser: true } });
    };
    const handleValidateDeviceLoginUser = () => {
        if (!values.adminSettingValidateInit.deviceLoginUser) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.adminSettingValidations(), model.NewAdminSettingValidation);
        const dispMessage = msg === null ? '' : msg.deviceLoginUser === null ? '' : msg.deviceLoginUser.toString();
        return dispMessage;
    };

    // PIN
    const handleChangePin = (v: string) => {
        setValues({ ...values, pin: v, adminSettingValidateInit: { ...values.adminSettingValidateInit, pin: true } });
    };
    const handleValidatePin = () => {
        if (!values.adminSettingValidateInit.pin) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.adminSettingValidations(), model.NewAdminSettingValidation);
        const dispMessage = msg === null ? '' : msg.pin === null ? '' : msg!.pin.toString();
        return dispMessage;
    };

    // ユーザ言語
    const handleChangeUserLang = (v: string) => {
        setValues({ ...values, userLang: v, adminSettingValidateInit: { ...values.adminSettingValidateInit, userLang: true } });
    };
    const handleValidateUserLang = () => {
        if (!values.adminSettingValidateInit.userLang) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.adminSettingValidations(), model.NewAdminSettingValidation);
        const dispMessage = msg === null ? '' : msg.userLang === null ? '' : msg!.userLang.toString();
        return dispMessage;
    };

    // 連絡先Eメールアドレス
    const handleChangeContactEmail = (v: string) => {
        setValues({ ...values, contactEmail: v, adminSettingValidateInit: { ...values.adminSettingValidateInit, contactEmail: true } });
    };
    const handleValidateContactEmail = () => {
        const msg = validator.Validate<model.Form>(values, model.adminSettingValidations(), model.NewAdminSettingValidation);
        const dispMessage = msg === null ? '' : msg.contactEmail === null ? '' : msg!.contactEmail.toString();
        return dispMessage;
    };
    // ワークスペースID
    const handleChangeWorkspaceID = (v: string) => {
        setValues({ ...values, workspaceId: v, profileSettingValidateInit: { ...values.profileSettingValidateInit, workspaceId: true } });
    };
    const handleValidateWorkspaceID = () => {
        if (!values.profileSettingValidateInit.workspaceId) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.profileSettingValidations(), model.NewProfileSettingValidation);
        const dispMessage = msg === null ? '' : msg.workspaceId === null ? '' : msg!.workspaceId.toString();
        return dispMessage;
    };

    // ワークスペース表示名
    const handleChangeWorkspaceName = (v: string) => {
        setValues({ ...values, workspaceName: v, profileSettingValidateInit: { ...values.profileSettingValidateInit, workspaceName: true } });
    };
    const handleValidateWorkspaceName = () => {
        if (!values.profileSettingValidateInit.workspaceName) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.profileSettingValidations(), model.NewProfileSettingValidation);
        const dispMessage = msg === null ? '' : msg.workspaceName === null ? '' : msg!.workspaceName.toString();
        return dispMessage;
    };

    // ワークスペース言語
    const handleChangeWorkspaceLang = (v: string) => {
        setValues({ ...values, workspaceLang: v, profileSettingValidateInit: { ...values.profileSettingValidateInit, workspaceLang: true } });
    };
    const handleValidateWorkspaceLang = () => {
        if (!values.profileSettingValidateInit.workspaceLang) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.profileSettingValidations(), model.NewProfileSettingValidation);
        const dispMessage = msg === null ? '' : msg.workspaceLang === null ? '' : msg!.workspaceLang.toString();
        return dispMessage;
    };

    // 許諾
    const handleChangeTerms = (v: boolean) => {
        setValues({ ...values, terms: v, profileSettingValidateInit: { ...values.profileSettingValidateInit, terms: true } });
    };

    const handleValidateTerms = () => {
        if (!values.profileSettingValidateInit.terms) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.profileSettingValidations(), model.NewProfileSettingValidation);
        const dispMessage = msg === null ? '' : msg.terms === null ? '' : msg!.terms.toString();
        return dispMessage;
    };

    const profileSettingNextButtonDisabled = () => {
        return validator.Validate<model.Form>(values, model.profileSettingValidations(), model.NewProfileSettingValidation) !== null;
    };

    const organizationSettingNextButtonDisabled = () => {
        return validator.Validate<modelOrganization.OrganizationInfo>(organization, modelOrganization.validation(), modelOrganization.NewValidation) !== null;
    };

    const adminSettingNextButtonDisabled = () => {
        return validator.Validate<model.Form>(values, model.adminSettingValidations(), model.NewAdminSettingValidation) !== null;
    };

    const passwordSettingNextButtonDisabled = () => {
        return validator.Validate<model.Form>(values, model.passwordSettingValidations(), model.NewPasswordSettingValidation) !== null;
    };

    return {
        ui,
        updateUIStatus,
        values,
        setValues,
        organization,
        setOrganization,
        avatarDataUri,
        logoDataUri,
        handleChangePassword,
        handleValidatePassword,
        handleClickShowPassword,
        handleChangePasswordConfirm,
        handleValidatePasswordConfirm,
        handleClickShowPasswordConfirm,
        handleChangeName,
        handleValidateName,
        handleChangePhoneticName,
        handleValidatePhoneticName,
        handleChangeDeviceLoginUser,
        handleValidateDeviceLoginUser,
        handleChangePin,
        handleValidatePin,
        handleChangeUserLang,
        handleValidateUserLang,
        handleChangeWorkspaceID,
        handleValidateWorkspaceID,
        handleChangeWorkspaceName,
        handleValidateWorkspaceName,
        handleChangeWorkspaceLang,
        handleValidateWorkspaceLang,
        handleChangeTerms,
        handleValidateTerms,
        handleChangeAvatarUrl,
        handleChangeAvatarDataUri,
        handleChangeLogoUrl,
        handleChangeLogoDataUri,
        handleChangeContactEmail,
        handleValidateContactEmail,
        profileSettingNextButtonDisabled,
        organizationSettingNextButtonDisabled,
        adminSettingNextButtonDisabled,
        passwordSettingNextButtonDisabled,
        screenState,
        setScreenState,
    };
};
export const WorkspaceSignupContainer = createContainer(useWorkspaceSignupContainer);
