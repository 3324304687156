import { default as React } from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { SubWorkspaceDetailContainer } from './SubWorkspaceDetailContainer';
import Detail from './Detail';

const styles = (theme: Theme) => createStyles({});
interface Props extends RouteComponentProps<{ id: string }>, WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.subWorkspaceDetail.index));
    const { params } = props.match;

    return (
        <>
            <SubWorkspaceDetailContainer.Provider>
                <Detail displayId={params.id} />
            </SubWorkspaceDetailContainer.Provider>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
