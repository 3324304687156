import { default as React, useEffect, useState } from 'react';
import { Theme, createStyles, withStyles, WithStyles, Grid, Typography, Button, TextField } from '@material-ui/core';
import locale from '@/common/utils/locale';
import ImageFileSelector from '@/common/components/ImageFileSelector';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import ModalHeader from '@/common/components/headers/ModalHeader';
import { MemberContainer, diabledCreateButton } from '../MemberContainer';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: `0px ${theme.spacing.unit * 3}px`,
            height: '100%',
            gap: '12px',
            paddingTop: 24,
        },
        listRoot: {
            width: '100%',
            height: '40%',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        okBtn: {
            color: '#ff7300',
        },
    });
interface Props extends WithStyles<typeof styles> {}

const AddGroupMember: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const memberContainer = MemberContainer.useContainer();
    const [avatarUrl, setAvatarUrl] = useState<null | string>(null);

    // avatarUrlだけは、useEffectで更新する。
    // ImageFileSelectorのonLoadedで更新すると、引数を渡した時点でのステートに更新される問題があるため。
    useEffect(() => {
        if (avatarUrl !== null) {
            memberContainer.group.handleCreateGroupForm({ ...memberContainer.group.createGroupForm, imageDataUri: avatarUrl });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarUrl]);

    /**
     * @description モーダルを閉じる時の処理をまとめた関数
     */
    const closeModal = () => {
        memberContainer.closeModal();
        // 更新しようとしていた情報を保持するステートを初期化しないと、他画面にステートを引き継いで意図しない更新が発生する。
        memberContainer.group.resetCreateForm();
    };

    return (
        <>
            <ModalHeader
                title={locale.t(locale.keys.memberManagement.modal.addGroup.title)}
                onClose={() => {
                    closeModal();
                }}
                isLoading={appContainer.loadingState}
            />
            <Grid container direction="column" style={{ height: 'calc(100% - 48px)' }} className={classes.root}>
                <Grid item container direction="column" style={{ gap: '8px' }}>
                    <Typography>{locale.t(locale.keys.memberManagement.modal.addGroup.groupAvatar.title)}</Typography>
                    <ImageFileSelector
                        seed={appContainer.values.signinWorkspaceObject ? appContainer.values.signinWorkspaceObject.id : ''}
                        uploadWidth={512}
                        uploadHeight={512}
                        mobileWidth={160}
                        mobileHeight={160}
                        pcWidth={160}
                        pcHeight={160}
                        defaultUrl={''} // グループ作成時はなし
                        logoDataUri={memberContainer.group.createGroupForm.imageDataUri}
                        isAvatar={true}
                        onLoaded={(datauri) => {
                            setAvatarUrl(datauri);
                        }}
                        dependAppContainer={AdminAppContainer.useContainer}
                        editable={true}
                        isGroup={true}
                    />
                </Grid>

                <Grid item container direction="column" style={{ gap: '8px' }}>
                    <Typography>{locale.t(locale.keys.memberManagement.modal.addGroup.groupName.title)}</Typography>
                    <TextField
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            memberContainer.group.handleCreateGroupForm({ ...memberContainer.group.createGroupForm, groupName: e.target.value });
                        }}
                        value={memberContainer.group.createGroupForm.groupName}
                        placeholder={locale.t(locale.keys.memberManagement.modal.addGroup.groupName.title)}
                        variant="outlined"
                        margin={'dense'}
                        fullWidth
                        style={{ marginBlock: '4px' }}
                        error={memberContainer.group.validateResult ? (memberContainer.group.validateResult.groupNameHelperText !== '' ? true : false) : false}
                        helperText={memberContainer.group.validateResult ? memberContainer.group.validateResult.groupNameHelperText : ''}
                    />
                </Grid>

                <Grid container direction="row" justify="flex-end" id="emailmodal-footer" style={{ width: '100%' }}>
                    <Button onClick={() => closeModal()} size="small" color="primary">
                        {locale.t(locale.keys.action.cancel)}
                    </Button>
                    <Button
                        onClick={() => {
                            closeModal();
                            memberContainer.createGroup();
                        }}
                        variant="outlined"
                        className={classes.okBtn}
                        disabled={diabledCreateButton(memberContainer.group.validateResult)}
                        style={{ border: 'none' }}
                    >
                        {locale.t(locale.keys.action.ok)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(AddGroupMember);
