import { useState } from 'react';
import { createContainer } from 'unstated-next';
import * as model from '@/common/api/auth/signup/Signup';
import * as validator from '@/common/utils/validator';

const useSignupContainer = () => {
    const [values, setValues] = useState(model.New());
    const [screenState, setScreenState] = useState(model.MemberSignupState.MemberSignup);
    const [avatarDataUri, setAvatarDataUri] = useState('');

    // ユーザ アバター画像
    const handleChangeAvatarDataUri = (v: string) => {
        setAvatarDataUri(v);
    };

    // 名前
    const handleChangeName = (v: string) => {
        setValues({ ...values, name: v, validateInit: { ...values.validateInit, name: true } });
    };
    const handleValidateName = () => {
        if (!values.validateInit.name) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.name === null ? '' : msg!.name.toString();
        return dispMessage;
    };

    // ニックネーム
    const handleChangePhoneticName = (v: string) => {
        setValues({ ...values, phoneticName: v, validateInit: { ...values.validateInit, phoneticName: true } });
    };
    const handleValidatePhoneticName = () => {
        if (!values.validateInit.phoneticName) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.phoneticName === null ? '' : msg!.phoneticName.toString();
        return dispMessage;
    };

    // 複合機のログインユーザー名
    const handleChangeDeviceLoginUser = (v: string) => {
        setValues({ ...values, deviceLoginUser: v, validateInit: { ...values.validateInit, deviceLoginUser: true } });
    };
    const handleValidateDeviceLoginUser = () => {
        if (!values.validateInit.deviceLoginUser) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.deviceLoginUser === null ? '' : msg.deviceLoginUser.toString();
        return dispMessage;
    };

    // PIN
    const handleChangePin = (v: string) => {
        setValues({ ...values, pin: v, validateInit: { ...values.validateInit, pin: true } });
    };
    const handleValidatePin = () => {
        if (!values.validateInit.pin) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.pin === null ? '' : msg!.pin.toString();
        return dispMessage;
    };

    // ユーザ言語
    const handleChangeUserLang = (v: string) => {
        setValues({ ...values, userLang: v, validateInit: { ...values.validateInit, userLang: true } });
    };
    const handleValidateUserLang = () => {
        if (!values.validateInit.userLang) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.userLang === null ? '' : msg!.userLang.toString();
        return dispMessage;
    };
    // 連絡先Eメールアドレス
    const handleChangeContactEmail = (v: string) => {
        setValues({ ...values, contactEmail: v, validateInit: { ...values.validateInit, contactEmail: true } });
    };
    const handleValidateContactEmail = () => {
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.contactEmail === null ? '' : msg!.contactEmail.toString();
        return dispMessage;
    };

    // 許諾
    const handleChangeTerms = (v: boolean) => {
        setValues({ ...values, terms: v, validateInit: { ...values.validateInit, terms: true } });
    };

    const handleValidateTerms = () => {
        if (!values.validateInit.terms) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.terms === null ? '' : msg!.terms.toString();
        return dispMessage;
    };
    const buttonDisabled = () => {
        return validator.Validate<model.Form>(values, model.validations(), model.NewValidation) !== null;
    };

    // パスワード
    const handleChangePassword = (v: string) => {
        setValues({ ...values, password: v, passwordRecoveryValidateInit: { ...values.passwordRecoveryValidateInit, password: true } });
    };
    const handleValidatePassword = () => {
        if (!values.passwordRecoveryValidateInit.password) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.passwordRecoveryValidations(), model.NewPasswordRecoveryValidation);
        const dispMessage = msg === null ? '' : msg.password === null ? '' : msg!.password.toString();
        return dispMessage;
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values['showPassword'] });
    };

    // パスワード確認
    const handleChangePasswordConfirm = (v: string) => {
        setValues({ ...values, passwordConfirm: v, passwordRecoveryValidateInit: { ...values.passwordRecoveryValidateInit, passwordConfirm: true } });
    };
    const handleValidatePasswordConfirm = () => {
        if (!values.passwordRecoveryValidateInit.passwordConfirm) {
            return '';
        }
        const msg = validator.Validate<model.Form>(values, model.passwordRecoveryValidations(), model.NewPasswordRecoveryValidation);
        const dispMessage = msg === null ? '' : msg.passwordConfirm === null ? '' : msg!.passwordConfirm.toString();
        return dispMessage;
    };
    const handleClickShowPasswordConfirm = () => {
        setValues({ ...values, showPasswordConfirm: !values['showPasswordConfirm'] });
    };
    const savePasswordButtonDisabled = () => {
        return validator.Validate<model.Form>(values, model.passwordRecoveryValidations(), model.NewPasswordRecoveryValidation) !== null;
    };

    return {
        values,
        setValues,
        handleChangeName,
        handleValidateName,
        handleChangePhoneticName,
        handleValidatePhoneticName,
        handleChangeDeviceLoginUser,
        handleValidateDeviceLoginUser,
        handleChangePin,
        handleValidatePin,
        handleChangeTerms,
        handleValidateTerms,
        handleChangeUserLang,
        handleValidateUserLang,
        handleChangeContactEmail,
        handleValidateContactEmail,
        avatarDataUri,
        handleChangeAvatarDataUri,
        buttonDisabled,

        handleChangePassword,
        handleValidatePassword,
        handleClickShowPassword,
        handleChangePasswordConfirm,
        handleValidatePasswordConfirm,
        handleClickShowPasswordConfirm,
        savePasswordButtonDisabled,
        screenState,
        setScreenState,
    };
};
export const SignupContainer = createContainer(useSignupContainer);
