import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import logger from '@/common/utils/logger';
import * as management from '@/common/api/sub-workspace/management';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as schema from '@/bundles/schema/typescript/schema';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import locale from '@/common/utils/locale';
import * as plan from '@/admin/constants/plan';
import * as errorHandler from '@/common/utils/errorHandler';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';

export enum SelectedTab {
    tab1 = 'tab1',
    tab2 = 'tab2',
}
const convertPlanDisplayName = (planString: string, brand: string) => {
    switch (planString) {
        case plan.EPlan.FREE:
            return locale.t(locale.keys.common.plan.free) + brand;
        case plan.EPlan.INTERNAL:
            return locale.t(locale.keys.common.plan.internal) + brand;
        default:
            return locale.t(locale.keys.common.plan.free) + brand;
    }
};

const useContainer = () => {
    // Invitaiton.tsx ===========================================
    const appContainer = AdminAppContainer.useContainer();
    // Status.tsx ===========================================
    const [searchText, setSearchText] = useState('');
    const [pageTab1, setPageTab1] = useState(0);
    const [pageTab2, setPageTab2] = useState(0);
    const [rowsPerPageTab1, setRowsPerPageTab1] = useState(DEFAULT_ROWS_PER_PAGE);
    const [rowsPerPageTab2, setRowsPerPageTab2] = useState(DEFAULT_ROWS_PER_PAGE);
    const [defaultRows, setDefaultRows] = useState<management.Row[]>([]);
    const [values, setValues] = useState(management.New());
    const ui = useUI();

    async function createData(workspaceId: string, auth: string): Promise<management.Row[]> {
        try {
            const wsRes = await management.findSubWorkspace(workspaceId, auth);
            const verifiedWorkspaces = wsRes.workspaces.filter((ws) => {
                return ws.verified != null && ws.verified;
            });
            const rows: management.Row[] = [];
            for (const ws of verifiedWorkspaces) {
                const signinWorkspaceBrand = appContainer.values.signinWorkspaceObject.brand;
                let brand = '';
                if (signinWorkspaceBrand && ws.brand && signinWorkspaceBrand === plan.EBrand.SYSTEM) {
                    brand = `(${ws.brand!})`;
                }

                // 招待時メールアドレスがないときは（スキーマではnullable）、空のテキストをセルに表示する。
                const contactEmail = ws.contactEmail ? ws.contactEmail : '';

                rows.push({
                    id: rows.length + 1,
                    objId: ws.id!,
                    logoUrl: ws.logoUrl!,
                    displayId: ws.displayId,
                    displayName: ws.displayName,
                    active: ws ? ws.active! : false,
                    enableSubWorkspace: ws.enableSubWorkspace!,
                    language: ws ? ws.language! : schema.Language.En,
                    memberCount: ws.verifyUsercount != null ? ws.verifyUsercount! : 0,
                    organizationDetail: ws.organizationDetail != null ? ws.organizationDetail : null,
                    billingPlan: ws.billingPlan != null ? convertPlanDisplayName(ws.billingPlan, brand) : '',
                    contactEmail: contactEmail,
                    parentWorkspaceId: ws.parentWorkspace,
                });
            }
            return rows;
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            return [];
        }
    }
    useEffect(() => {
        (async () => {
            try {
                if (!values.init) {
                    const initData = await createData(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                    setValues({
                        ...values,
                        init: true,
                        rows: initData,
                    });
                    setDefaultRows(initData);
                    ui.update(UI.Loaded);
                }
            } catch (e) {
                ui.update(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [tab, setTab] = useState<SelectedTab>(SelectedTab.tab1);
    const tableFunctions = () => {
        const isTabString = (value: string): value is SelectedTab => {
            return value === 'tab1' || value === 'tab2' || value === 'tab3';
        };
        const handleTabChange = (e: React.ChangeEvent<{}>, value: string) => {
            if (isTabString(value)) {
                setTab(value);
            }
        };
        const handleChangeSearchText = () => (event: React.ChangeEvent<HTMLInputElement>) => {
            logger.debug('handleChangeSearchText');
            logger.debug(searchText);
            setSearchText(event.target.value);
        };

        function handleChangePageByTab(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number, tab: SelectedTab) {
            switch (tab) {
                case SelectedTab.tab1:
                    setPageTab1(newPage);
                    break;
                case SelectedTab.tab2:
                    setPageTab2(newPage);
                    break;
            }
        }
        const getPageByTab = (tab: SelectedTab) => {
            switch (tab) {
                case SelectedTab.tab1:
                    return pageTab1;
                case SelectedTab.tab2:
                    return pageTab2;
            }
            return pageTab1;
        };
        function handleChangeRowsPerPageByTab(tab: SelectedTab, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            switch (tab) {
                case SelectedTab.tab1:
                    setRowsPerPageTab1(parseInt(event.target.value, 10));
                    break;
                case SelectedTab.tab2:
                    setRowsPerPageTab2(parseInt(event.target.value, 10));
                    break;
            }
        }
        const getRows = () => {
            return defaultRows
                .filter((r) => {
                    return r.displayId.indexOf(searchText) !== -1 || r.displayName!.indexOf(searchText) !== -1 || r.contactEmail!.indexOf(searchText) !== -1;
                })
                .sort((a, b) => (a.id < b.id ? -1 : 1));
        };
        const getRowsPerPageByTab = (tab: SelectedTab) => {
            switch (tab) {
                case SelectedTab.tab1:
                    return rowsPerPageTab1;
                case SelectedTab.tab2:
                    return rowsPerPageTab2;
            }
        };
        async function handleChangeDisplayId(rowId: number, value: string) {
            try {
                const changedRows = values.rows;
                changedRows[rowId - 1].displayId = value;
                await management.updateWorkspaceDisplayId(appContainer.values.authorizationCode, changedRows[rowId - 1]);
                setValues({ ...values, rows: changedRows });
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        }
        async function handleChangeDisplayName(rowId: number, value: string) {
            try {
                const changedRows = values.rows;
                changedRows[rowId - 1].displayName = value;
                await management.updateWorkspaceDisplayId(appContainer.values.authorizationCode, changedRows[rowId - 1]);
                setValues({ ...values, rows: changedRows });
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        }
        async function handleChangeEnableSubWs(rowId: number, value: boolean) {
            try {
                const changedRows = values.rows;
                changedRows[rowId - 1].enableSubWorkspace = value;
                await management.updateEnableSubWorkspace(appContainer.values.authorizationCode, changedRows[rowId - 1]);
                setValues({ ...values, rows: changedRows });
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        }
        return {
            handleChangeRowsPerPageByTab,
            handleChangeSearchText,
            handleChangeDisplayName,
            handleChangeDisplayId,
            handleChangeEnableSubWs,
            handleChangePageByTab,
            handleTabChange,
            ui,
            getRows,
            getPageByTab,
            getRowsPerPageByTab,
            searchText,
            tab,
        };
    };

    return {
        ...tableFunctions(),
    };
};

export const SubWorkspacesContainer = createContainer(useContainer);
