import { default as React, useEffect, useRef } from 'react';
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Grid,
    Typography,
    Button,
    TextField,
    FormControl,
    Select,
    Input,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Chip,
    MenuItem,
    Popper,
    Grow,
    Paper,
    MenuList,
    FormHelperText,
} from '@material-ui/core';
import locale from '@/common/utils/locale';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as schema from '@/bundles/schema/typescript/schema';
import ModalHeader from '@/common/components/headers/ModalHeader';
import { MemberContainer, diabledAddMemberButton } from '../MemberContainer';
import CovasAvatar from '@/common/components/CovasAvatar';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: `0px ${theme.spacing.unit * 3}px`,
            height: '100%',
            gap: '12px',
            paddingTop: 24,
        },
        listRoot: {
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        okBtn: {
            color: '#ff7300',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
    });
interface Props extends WithStyles<typeof styles> {}

const AddGroupMember: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const memberContainer = MemberContainer.useContainer();
    const [groupSearchText, setGroupSearchText] = React.useState<string>('');
    const [selectedGroup, setSelectedGroup] = React.useState<string[]>([]);
    const [formHelperFlag, setFormHelperFlag] = React.useState({
        selectedGroup: false,
    });
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const addMemberList = memberContainer.member.form.selectedMemberIdList;
    // Everyoneの場合のみメンバーではなくユーザーと呼称する
    const isWorkspaceGroup = memberContainer.group.selectedGroup.groupName === schema.V1ObjectsDefaultGroup.Everyone;

    // グループリストにチェックを入れるたび、選択しているグループリストを更新する
    useEffect(() => {
        const updateData = selectedGroup.map((groupName) => {
            const group = memberContainer.group.getGroupRows(true).groups.find((group) => group.name === groupName);
            // ここでnullになることはないので、非nullアサーション演算子を付ける。
            // チェックボックス操作中にAPIでグループリストを更新したらnullになる可能性はあるが、現在は行わないので問題ない。
            return {
                id: group!.id,
                name: group!.name,
                avatarUrl: group!.avatarUrl,
                addMembers: addMemberList,
                removeMembers: [] as string[],
            };
        });
        memberContainer.group.handleUpdateGroupForm(updateData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroup]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const getGroupList = () => {
        const groupList = memberContainer.group.getGroupRows(true).groups;
        if (groupSearchText === '') return groupList;
        return groupList.filter((group) => group.name.toLowerCase().includes(groupSearchText.toLowerCase()));
    };

    const selectedMemberList = () => {
        const selectedGroup = memberContainer.member.memberListByGroup.find((groupList) => groupList.id === memberContainer.group.selectedGroup.groupId);
        if (selectedGroup === undefined || selectedGroup.members === undefined) return [] as schema.V1ObjectsWorkspaceuserMedium[];
        return selectedGroup.members.filter((member) => memberContainer.member.form.selectedMemberIdList.includes(member.id));
    };

    /**
     * @description モーダルを閉じる時の処理をまとめた関数
     */
    const closeModal = () => {
        memberContainer.closeModal();
        // 更新しようとしていた情報を保持するステートを初期化しないと、他画面にステートを引き継いで意図しない更新が発生する。
        memberContainer.group.handleUpdateGroupForm([]);
    };

    return (
        <>
            <ModalHeader
                title={isWorkspaceGroup ? locale.t(locale.keys.memberManagement.modal.addGroupMember.title) : locale.t(locale.keys.memberManagement.modal.addGroupMember.titleOpenbyGroup)}
                onClose={() => {
                    closeModal();
                }}
                isLoading={appContainer.loadingState}
            />
            <Grid container direction="column" style={{ height: 'calc(100% - 48px)', maxHeight: '80vh', justifyContent: 'space-between', flexWrap: 'nowrap' }} className={classes.root}>
                <Grid item container direction="column" style={{ gap: '8px', overflow: 'auto', flexWrap: 'nowrap', flexGrow: 1, height: 300 }}>
                    <Grid item container direction="column" style={{ gap: '8px' }}>
                        <Typography>{locale.t(locale.keys.memberManagement.modal.addGroupMember.selectGroup.title)}</Typography>
                        <Grid item style={{ width: '100%', position: 'relative' }}>
                            <FormControl fullWidth error={formHelperFlag.selectedGroup && diabledAddMemberButton(memberContainer.group.validateUpdateResult)}>
                                <div ref={anchorRef}>
                                    <Select
                                        displayEmpty
                                        open={false}
                                        onOpen={() => {}} // onOpenを定義しないとエラーが発生するので空関数を定義
                                        fullWidth
                                        value={selectedGroup}
                                        onClick={handleToggle}
                                        input={<Input id="select-multiple-chip" />}
                                        renderValue={(selected) => {
                                            if ((selected as string[]).length === 0) {
                                                // 選択しているグループが存在しない場合はプレースホルダーを表示する
                                                return (
                                                    <Typography
                                                        style={{
                                                            color:
                                                                memberContainer.group.validateUpdateResult &&
                                                                memberContainer.group.validateUpdateResult.selectedGroupHelperText !== '' &&
                                                                formHelperFlag.selectedGroup
                                                                    ? '#FF3D10' // エラー条件に当てはまる場合はエラーカラーを表示（useThemeフックが使用できるようになったとき、テーマカラーを参照するように修正したい）
                                                                    : '#767676', // それ以外はデフォルトカラーを表示（useThemeフックが使用できるようになったとき、テーマカラーを参照するように修正したい）
                                                        }}
                                                    >
                                                        {locale.t(locale.keys.memberManagement.modal.addGroupMember.selectGroup.placeholder)}
                                                    </Typography>
                                                );
                                            } else {
                                                return (
                                                    <div className={classes.chips}>
                                                        {(selected as string[]).map((value) => (
                                                            <Chip key={value} label={value} className={classes.chip} />
                                                        ))}
                                                    </div>
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                {// グループ未選択の場合のメッセージ
                                // エラー条件に当てはまるかつ、グループ選択フォームに選択したことがある場合のみ表示する
                                memberContainer.group.validateUpdateResult && memberContainer.group.validateUpdateResult.selectedGroupHelperText !== '' && formHelperFlag.selectedGroup && (
                                    <FormHelperText>{memberContainer.group.validateUpdateResult.selectedGroupHelperText}</FormHelperText>
                                )}
                            </FormControl>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                                style={{ width: '100%', zIndex: 100 }}
                                modifiers={{
                                    flip: {
                                        enabled: false,
                                    },
                                    preventOverflow: {
                                        enabled: false,
                                        boundariesElement: 'scrollParent',
                                    },
                                    hide: {
                                        enabled: false,
                                    },
                                }}
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                        <Paper style={{ maxHeight: 215, overflow: 'auto' }}>
                                            <Grid>
                                                <ListItem style={{ paddingBlock: '0px', marginInline: 'auto', paddingBlockStart: '8px' }}>
                                                    <TextField
                                                        placeholder={locale.t(locale.keys.memberManagement.modal.addGroupMember.selectGroup.searchGroup)}
                                                        variant="outlined"
                                                        margin={'dense'}
                                                        value={groupSearchText}
                                                        onChange={(e) => setGroupSearchText(e.target.value)}
                                                        fullWidth
                                                        style={{ marginBlock: '4px' }}
                                                        InputLabelProps={{ style: { fontSize: 12, transform: 'translate(14px, 10px) scale(1)' } }}
                                                        InputProps={{ style: { fontSize: 12, height: 30 } }}
                                                    />
                                                </ListItem>
                                                <MenuList>
                                                    {getGroupList().map((group) => (
                                                        <MenuItem
                                                            key={group.id}
                                                            value={group.name}
                                                            onClick={() => {
                                                                if (selectedGroup.indexOf(group.name) > -1) {
                                                                    setSelectedGroup(selectedGroup.filter((val) => val !== group.name));
                                                                } else {
                                                                    setSelectedGroup([...selectedGroup, group.name]);
                                                                }
                                                                setFormHelperFlag({ ...formHelperFlag, selectedGroup: true });
                                                            }}
                                                            selected={selectedGroup.indexOf(group.name) > -1}
                                                        >
                                                            <Checkbox checked={selectedGroup.indexOf(group.name) > -1} />
                                                            <ListItemText
                                                                primaryTypographyProps={{
                                                                    style: {
                                                                        whiteSpace: 'pre',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    },
                                                                }}
                                                                primary={group.name}
                                                            />
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Grid>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Grid>
                    </Grid>

                    <Grid item container direction="column" style={{ gap: '8px' }}>
                        <Typography style={{ width: '100%' }}>{locale.t(locale.keys.memberManagement.modal.addGroupMember.addMember.title)}</Typography>
                        <Grid item style={{ height: 'auto', maxHeight: 550, overflow: 'auto', width: '100%' }}>
                            <List className={classes.listRoot}>
                                {selectedMemberList()
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((val) => {
                                        return (
                                            <ListItem key={val.id}>
                                                <ListItemAvatar>
                                                    <CovasAvatar size={40} seed={val.invitationEmail} src={val.avatarUrl} onClick={() => {}} color="disabled" />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={val.name}
                                                    primaryTypographyProps={{
                                                        style: {
                                                            fontSize: 12,
                                                            fontWeight: 'bold',
                                                            color: '#333',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'pre' /**空白は詰めない */,
                                                        },
                                                    }}
                                                    secondary={val.invitationEmail}
                                                    secondaryTypographyProps={{ style: { fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }}
                                                />
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container direction="row" justify="flex-end" id="emailmodal-footer" style={{ width: '100%' }}>
                    <Button onClick={() => closeModal()} size="small" color="primary">
                        {locale.t(locale.keys.action.cancel)}
                    </Button>
                    <Button
                        onClick={() => {
                            closeModal();
                            memberContainer.updateGroup();
                        }}
                        variant="outlined"
                        className={classes.okBtn}
                        disabled={diabledAddMemberButton(memberContainer.group.validateUpdateResult)}
                        style={{ border: 'none' }}
                    >
                        {locale.t(locale.keys.action.ok)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(AddGroupMember);
