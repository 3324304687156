import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import * as management from '@/common/api/entry/management';
import { AdminAppContainer, EntrySelectedTab } from '@/admin/components/AdminAppContainer';
import * as schema from '@/bundles/schema/typescript/schema';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import moment from 'moment';
import { DateFormat } from '@/common/constants/dateFormat';
import { createCSV } from './exportCSV';
import { useGlobalEntrySearch } from '../../hooks/useGlobalEntrySearch';
import environment from '@/common/constants/environment';

const formatDate = (datetime: string): string => {
    return moment(new Date(datetime)).format(DateFormat.fullDateTimeWithDot);
};

const useContainer = () => {
    const appContainer = AdminAppContainer.useContainer();
    const globalEntrySearch = useGlobalEntrySearch().globalEntrySearch;
    // 検索のステート
    const [searchState, setSearchState] = useState<management.Search>({
        searchEntryNumber: globalEntrySearch.searchEntryNumber,
        searchStartDate: globalEntrySearch.searchStartDate,
        searchEndDate: globalEntrySearch.searchEndDate,
        searchCompany: globalEntrySearch.searchCompany,
        searchMail: globalEntrySearch.searchMail,
        searchName: globalEntrySearch.searchName,
    });
    // タブのステート
    const [tab, setTab] = useState<EntrySelectedTab>(globalEntrySearch.tab);
    // ソートのステート
    const [sortEntryNumber, setSortEntryNumber] = useState<schema.V1ObjectsSort>(globalEntrySearch.sortEntryNumber);
    const [sortDate, setSortDate] = useState<schema.V1ObjectsSort>(globalEntrySearch.sortDate);
    // ページのステート
    const [pageTab, setPageTab] = useState(globalEntrySearch.pageTab);
    const [rowsPerPageTab, setRowsPerPageTab] = useState(globalEntrySearch.rowsPerPageTab);
    const [pageCount, setPageCount] = useState(globalEntrySearch.pageCount);
    // 表示結果のステート
    const [rows, setRows] = useState<management.Row[]>([]);
    const ui = useUI();

    useEffect(() => {
        createDataList(
            tab,
            pageTab,
            rowsPerPageTab,
            {
                entryNumber: sortEntryNumber,
                entryDate: sortDate,
            },
            searchState,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 申込のTDとトークンを受け取って申込一覧の初期値を受け取る
    const createDataList = async (selectedTab: EntrySelectedTab, pageTab: number, rowsPerPageTab: number, sortType: management.SortType, search: management.Search): Promise<management.Row[]> => {
        try {
            // タブから申込状態へ変換
            let entryState: string;
            switch (selectedTab) {
                case EntrySelectedTab.tab1:
                    entryState = schema.V1ObjectsEntryState.Entry;
                    break;
                case EntrySelectedTab.tab2:
                    entryState = schema.V1ObjectsEntryState.Create;
                    break;
                case EntrySelectedTab.tab3:
                    entryState = schema.V1ObjectsEntryState.Complete;
                    break;
                case EntrySelectedTab.tab4:
                    entryState = schema.V1ObjectsEntryState.Cancel;
                    break;
                case EntrySelectedTab.tab5:
                    entryState = schema.V1ObjectsEntryState.Delete;
                    break;
                default:
                    entryState = schema.V1ObjectsEntryState.Entry;
            }

            // UIをローディング状態にしてリクエスト
            ui.update(UI.Loading);
            appContainer.updateLoadingState(UI.Loading);
            const entryRes = await management.findEntryList(
                pageTab * rowsPerPageTab,
                sortType,
                rowsPerPageTab,
                entryState as schema.V1ObjectsEntryState,
                {
                    ...search,
                },
                appContainer.values.authorizationCode,
            );

            setPageCount(entryRes.maxNumber);
            // レスポンスを表示する項目のステートに変換する
            const rows: management.Row[] = [];
            for (const entry of entryRes.entries) {
                let date = { createdAt: formatDate(entry.createdAt!), cancelDate: '', deleteDate: '' };
                if (entry.cancelDate) {
                    date.cancelDate = formatDate(entry.cancelDate);
                }
                if (entry.deleteDate) {
                    date.deleteDate = formatDate(entry.deleteDate);
                }

                rows.push({
                    id: rows.length + 1,
                    objId: '',
                    logoUrl: '', // 申込に紐づくワークスペースから値を保存する
                    entry: { ...entry, ...date },
                });
            }
            setRows(rows);
            ui.update(UI.Loaded);
            appContainer.updateLoadingState(UI.Loaded);
            return rows;
        } catch (e) {
            ui.update(UI.Error);
            errorHandler.handleApiError(appContainer, e);
            return [];
        }
    };

    const tableFunctions = () => {
        // タブチェック
        const isTabString = (value: string): value is EntrySelectedTab => {
            return value === 'tab1' || value === 'tab2' || value === 'tab3' || value === 'tab4' || value === 'tab5';
        };
        const handleTabChange = (e: React.ChangeEvent<{}>, value: string) => {
            if (isTabString(value)) {
                setTab(value);
            }
            createDataList(
                value as EntrySelectedTab,
                pageTab,
                rowsPerPageTab,
                {
                    entryNumber: sortEntryNumber,
                    entryDate: sortDate,
                },
                searchState,
            );
        };

        const handleChangeRowsPerPageByTab = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setRowsPerPageTab(parseInt(event.target.value, 10));
            createDataList(
                tab,
                pageTab,
                parseInt(event.target.value, 10),
                {
                    entryNumber: sortEntryNumber,
                    entryDate: sortDate,
                },
                searchState,
            );
        };
        const handleChangePageByTab = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
            setPageTab(newPage);
            createDataList(
                tab,
                newPage,
                rowsPerPageTab,
                {
                    entryNumber: sortEntryNumber,
                    entryDate: sortDate,
                },
                searchState,
            );
        };
        const sortByEntryNumber = () => {
            let sortType: schema.V1ObjectsSort = schema.V1ObjectsSort.None;
            switch (sortEntryNumber) {
                case schema.V1ObjectsSort.Asc:
                    sortType = schema.V1ObjectsSort.Desc;
                    break;
                case schema.V1ObjectsSort.Desc:
                    sortType = schema.V1ObjectsSort.Asc;
                    break;
                case schema.V1ObjectsSort.None:
                    sortType = schema.V1ObjectsSort.Asc;
                    break;
                default:
                    sortType = schema.V1ObjectsSort.None;
            }
            setSortEntryNumber(sortType);
            // 他のソート条件はリセット
            setSortDate(schema.V1ObjectsSort.None);
            createDataList(
                tab,
                pageTab,
                rowsPerPageTab,
                {
                    entryNumber: sortType,
                    entryDate: schema.V1ObjectsSort.None,
                },
                searchState,
            );
        };

        const sortByDate = () => {
            let sortType: schema.V1ObjectsSort = schema.V1ObjectsSort.None;
            switch (sortDate) {
                case schema.V1ObjectsSort.Asc:
                    sortType = schema.V1ObjectsSort.Desc;
                    break;
                case schema.V1ObjectsSort.Desc:
                    sortType = schema.V1ObjectsSort.Asc;
                    break;
                case schema.V1ObjectsSort.None:
                    sortType = schema.V1ObjectsSort.Asc;
                    break;
                default:
                    sortType = schema.V1ObjectsSort.None;
            }
            setSortDate(sortType);
            // 他のソート条件はリセット
            setSortEntryNumber(schema.V1ObjectsSort.None);
            createDataList(
                tab,
                pageTab,
                rowsPerPageTab,
                {
                    entryNumber: schema.V1ObjectsSort.None,
                    entryDate: sortType,
                },
                searchState,
            );
        };

        const onClickSearch = () => {
            createDataList(
                tab,
                pageTab,
                rowsPerPageTab,
                {
                    entryNumber: sortEntryNumber,
                    entryDate: sortDate,
                },
                searchState,
            );
        };

        const downloadCSV = async () => {
            // タブから申込状態へ変換
            let entryState: string;
            switch (tab) {
                case EntrySelectedTab.tab1:
                    entryState = schema.V1ObjectsEntryState.Entry;
                    break;
                case EntrySelectedTab.tab2:
                    entryState = schema.V1ObjectsEntryState.Create;
                    break;
                case EntrySelectedTab.tab3:
                    entryState = schema.V1ObjectsEntryState.Complete;
                    break;
                case EntrySelectedTab.tab4:
                    entryState = schema.V1ObjectsEntryState.Cancel;
                    break;
                case EntrySelectedTab.tab5:
                    entryState = schema.V1ObjectsEntryState.Delete;
                    break;
                default:
                    entryState = schema.V1ObjectsEntryState.Entry;
            }
            // CSV作成：申込日 昇順（古い順）固定、全件ダウンロード（絞り込み無）
            try {
                appContainer.updateLoadingState(UI.Loading);
                const res = await management.findCsvEntryList(
                    pageTab * rowsPerPageTab,
                    { entryNumber: schema.V1ObjectsSort.None, entryDate: schema.V1ObjectsSort.Asc },
                    rowsPerPageTab,
                    entryState as schema.V1ObjectsEntryState,
                    appContainer.values.authorizationCode,
                );
                createCSV(res, environment.tenant);
            } catch (e) {
                ui.update(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
            appContainer.updateLoadingState(UI.Loaded);
        };

        // 検索ボックスのステートを更新するハンドラー
        const handleChangeSearchEntryNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchState({
                ...searchState,
                searchEntryNumber: event.target.value,
            });
        };
        const handleChangeSearchStartDate = (date: Date | null) => {
            date && date.setHours(0, 0, 0, 0);
            setSearchState({
                ...searchState,
                searchStartDate: date,
            });
        };
        const handleChangeSearchEndDate = (date: Date | null) => {
            date && date.setHours(23, 59, 59, 999);
            setSearchState({
                ...searchState,
                searchEndDate: date,
            });
        };
        const handleChangeSearchCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchState({
                ...searchState,
                searchCompany: event.target.value,
            });
        };
        const handleChangeSearchName = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchState({
                ...searchState,
                searchName: event.target.value,
            });
        };
        const handleChangeSearchMail = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchState({
                ...searchState,
                searchMail: event.target.value,
            });
        };

        return {
            handleTabChange,
            handleChangeSearchEntryNumber,
            handleChangeSearchStartDate,
            handleChangeSearchEndDate,
            handleChangeSearchCompany,
            handleChangeSearchName,
            handleChangeSearchMail,
            handleChangeRowsPerPageByTab,
            handleChangePageByTab,
            onClickSearch,
            downloadCSV,
            ui,
            searchState,
            tab,
            pageTab,
            rowsPerPageTab,
            pageCount,
            sortByEntryNumber,
            sortEntryNumber,
            sortByDate,
            sortDate,
            rows,
        };
    };

    return {
        ...tableFunctions(),
    };
};

export const EntryContainer = createContainer(useContainer);
