import { default as React, useEffect, useState } from 'react';
import { withStyles, WithStyles, Theme, createStyles, Card, CardContent, Grid, Typography, Button, Fab, Avatar, Checkbox, FormControlLabel } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { CollectiveInviteContainer } from './CollectiveInviteContainer';
import * as schema from '@/bundles/schema/typescript/schema';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { showEllipsisString } from '@/common/utils/webappUtil';
import { csvFormat } from '@/common/constants/csvFormat';
import { default as Dropzone } from 'react-dropzone';
import { MultiLineText } from '@/common/components/messages/MultiLineText';
import DialogBase, { DialogBaseProps } from '@/common/components/Confirm/DialogBase';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
            textAlign: 'left',
            marginTop: '8px',
            marginBottom: '16px',
        },
        inviteButton: {
            color: 'white',
            minWidth: 240,
        },
        text: {
            marginTop: 50,
        },
        avatarIcon: {
            borderRadius: 0,
            width: 20,
            height: 20,
        },
        dropzone: {
            backgroundColor: '#eeeeee',
            border: '2px dashed #bdbdbd',
            borderRadius: 6,
            padding: 16,
            marginBlock: '16px',
            maxWidth: '100%',
            minWidth: '100%',
            cursor: 'pointer',
        },
        dropButton: {
            backgroundColor: 'white',
        },
        dropzoneInnerText: {
            paddingTop: '5px',
            textAlign: 'center',
            margin: '0 auto',
            fontSize: '15px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#666666',
        },
        dropzoneInnerDetailText: {
            paddingTop: '10px',
            textAlign: 'center',
            margin: '0 auto',
            fontSize: '10px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#666666',
        },
        linkStyleBtn: {
            fontFamily: ['Noto Sans JP', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'Meiryo', 'sans-serif'].join(','),
            fontSize: '15px',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            color: '#0000EE',
            padding: '0px',
            '&:active': {
                color: '#ff0000',
            },
        },
    });

const initialDialogObject: DialogBaseProps = {
    callBack: () => {},
    onClose: () => {},
    isOpen: false,
    title: '',
    renderChildren: () => <></>,
    type: 'alert',
};

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const { csvFileName, buttonDisable, ui, onSelectedCSV, onImportCsv, isSendEmailEnable, onChangeSendEmail, onClickExportUser } = CollectiveInviteContainer.useContainer();
    const [displayFileName, setDisplayFileName] = useState<string>(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));
    const [dialogObject, setDialogObject] = useState<DialogBaseProps>(initialDialogObject);

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);
    useEffect(() => {
        setDisplayFileName(showEllipsisString(csvFileName, csvFormat.CSVFILENAME_MAX));
    }, [csvFileName]);

    const onDrop = (accepts: File[]) => {
        // multiple={false}プロパティを指定しているので、必ず要素数は1になる。
        onSelectedCSV(accepts);
    };

    return (
        <div>
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    <Typography align="left" variant="h6" color="error">
                        {locale.t(locale.keys.memberInvitation.collectiveInvite.desctwo)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left" variant="h6">
                        {locale.t(locale.keys.memberInvitation.collectiveInvite.descthree)}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.text}>
                    <Typography align="left" variant="h5">
                        {locale.t(locale.keys.memberInvitation.collectiveInvite.csvupload)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Dropzone multiple={false} onDrop={(accepts) => onDrop(accepts)} accept={'.csv'}>
                        {({ getRootProps, getInputProps }) => (
                            <div className={classes.dropzone} {...getRootProps()}>
                                <Grid container spacing={8} direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerText}>{locale.t(locale.keys.memberInvitation.collectiveInvite.csvtitle)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerDetailText}>{locale.t(locale.keys.action.or)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <input {...getInputProps()} />
                                        <Button variant="outlined" type="button" component="label" className={classes.dropButton}>
                                            {locale.t(locale.keys.memberInvitation.collectiveInvite.uploadbutton)}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.dropzoneInnerText}>{displayFileName}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </Grid>

                <Grid item container xs={12} direction="column" justify={'flex-start'}>
                    <Grid item container direction="column" style={{ textAlign: 'left' }}>
                        <MultiLineText value={locale.t(locale.keys.memberInvitation.collectiveInvite.enableNotice.description)} />
                    </Grid>
                    <Grid item container style={{ marginBlockEnd: '16px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSendEmailEnable}
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            setDialogObject({
                                                callBack: () => {
                                                    setDialogObject(initialDialogObject);
                                                    onChangeSendEmail(false);
                                                },
                                                onClose: () => setDialogObject(initialDialogObject),
                                                isOpen: true,
                                                title: locale.t(locale.keys.memberInvitation.collectiveInvite.enableNotice.configmDialog.title),
                                                renderChildren: () => {
                                                    return (
                                                        <>
                                                            <MultiLineText value={locale.t(locale.keys.memberInvitation.collectiveInvite.enableNotice.configmDialog.message)} />
                                                        </>
                                                    );
                                                },
                                                type: 'confirm',
                                            });
                                        } else {
                                            onChangeSendEmail(true);
                                        }
                                    }}
                                    name="enable send email"
                                />
                            }
                            label={locale.t(locale.keys.memberInvitation.collectiveInvite.enableNotice.checkbox)}
                        />
                    </Grid>
                </Grid>

                <Grid item container xs={12} justify={'flex-start'}>
                    <Fab disabled={buttonDisable} variant="extended" onClick={() => onImportCsv()} className={classes.inviteButton} size="large" color="secondary">
                        {locale.t(locale.keys.memberInvitation.collectiveInvite.button)}
                    </Fab>
                </Grid>

                <Grid item xs={12} className={classes.text}>
                    <Typography align="left" variant="h6">
                        {locale.t(locale.keys.memberInvitation.collectiveInvite.process)}
                    </Typography>
                </Grid>
                <Grid container spacing={0} direction="row" alignItems="center" justify="flex-start">
                    <Avatar className={classes.avatarIcon} src="/assets/images/logo/csvfileicon.svg" />
                    {appContainer.values.signinWorkspaceUserObject.language === schema.Language.Ja ? (
                        // 日本語なら日本語のテンプレートのダウンロードリンク
                        <a href="/csv/template/ImportUsers_template_ja.csv" download>
                            {locale.t(locale.keys.memberInvitation.collectiveInvite.csvtemplate)}
                        </a>
                    ) : (
                        // 日本語以外なら英語テンプレートのダウンロードリンク
                        <a href="/csv/template/ImportUsers_template_en.csv" download>
                            {locale.t(locale.keys.memberInvitation.collectiveInvite.csvtemplate)}
                        </a>
                    )}
                </Grid>
                <Grid container spacing={0} direction="row" alignItems="center" justify="flex-start">
                    <Avatar className={classes.avatarIcon} src="/assets/images/logo/csvfileicon.svg" />
                    <button
                        className={classes.linkStyleBtn}
                        onClick={() => {
                            onClickExportUser();
                        }}
                    >
                        {locale.t(locale.keys.memberInvitation.collectiveInvite.exportedCsvtemplate)}
                    </button>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left">{locale.t(locale.keys.memberInvitation.collectiveInvite.download)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">{locale.t(locale.keys.memberInvitation.collectiveInvite.descriptionTitle)}</Typography>
                            <MultiLineText value={locale.t(locale.keys.memberInvitation.collectiveInvite.csvfileformat)} variant="body1" />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <DialogBase
                callBack={dialogObject.callBack}
                onClose={dialogObject.onClose}
                isOpen={dialogObject.isOpen}
                title={dialogObject.title}
                type={dialogObject.type}
                renderChildren={dialogObject.renderChildren}
            />
        </div>
    );
};

export default withStyles(styles)(Component);
