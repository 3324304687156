import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import locale from '@/common/utils/locale';
import { csvFormat4Endpoint } from '@/common/constants/csvFormat';
import * as encoding from 'encoding-japanese';
import logger from '@/common/utils/logger';
import { csvPaser, getSplitNewLineString } from '@/common/utils/webappUtil';
import * as workspace from '@/common/api/workspace/workspace';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import * as errLocale from '@/common/utils/locale/error-locale';
import environment from '@/common/constants/environment';
import { csvImportCheck } from '../../registration-endpoint/validation';
import * as schema from '@/bundles/schema/typescript/schema';
import * as endpointModel from '@/common/api/endpoints/collective/endpoint';
import { isNullOrEmptyArray } from '@/common/utils/array-helper/arrayHelper';
import { createOrder } from '@/common/api/x-storages/order/management';

const useGoogleMigration = () => {
    const droppedFile = useState<File | null>(null);
    const [csvFileName, setCsvFileName] = useState<string>(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));
    const ui = useUI();
    const [mode, setMode] = useState<schema.MigrateImportMode>(schema.MigrateImportMode.Mode1);
    const [strCode, setStrCode] = useState<string>('');
    const endpoints: schema.V1ObjectsEndpointsCollectiveSetting[] = [];
    const [fileInput, setFileInput] = useState<Blob | null>(null);
    const [authChecked, setAuthChecked] = useState<boolean>(false);
    let userLists: schema.V1ObjectsXStoragesOrderInfo[] = [];
    let shareLists: schema.V1ObjectsXStoragesOrderInfo[] = []; //共有クラウドストレージ
    let admin: schema.V1ObjectsWorkspaceuserLarge;
    let result: schema.V1WorkspaceuserIndexResponse = { count: 0, users: [] };

    const appContainer = AdminAppContainer.useContainer();

    const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === schema.MigrateImportMode.Mode1) {
            setMode(schema.MigrateImportMode.Mode1);
        } else {
            setMode(schema.MigrateImportMode.Mode2);
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuthChecked(event.target.checked);
    };

    useEffect(() => {
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * ファイル読み込み失敗時にファイルをクリアする
     */
    const clearSelectedFile = () => {
        setCsvFileName(locale.t(locale.keys.manageShareDevice.collective.noneFile));
        droppedFile[1](null);
        setFileInput(null);
    };

    //認可依頼作成
    const orderCreate = async (auth: string) => {
        try {
            if (mode === schema.MigrateImportMode.Mode1) {
                //実行ユーザーのクラウドストレージに登録
                const formValue: schema.V1XStoragesOrderCreateRequest = {
                    allowedDomain: '',
                    share: 0,
                    body: ``,
                    cc: [],
                    limit: 30,
                    service: schema.V1ObjectsServiceEnum.Googledrive,
                    title: '',
                    ordertype: schema.V1ObjectsRegisteredUserEnum.OneUser,
                    info: userLists,
                };
                ui.update(UI.Loading);
                await createOrder(formValue, auth);
            }
            if (mode === schema.MigrateImportMode.Mode2 && userLists.length > 0) {
                //それぞれのクラウドストレージに登録
                const formValue: schema.V1XStoragesOrderCreateRequest = {
                    allowedDomain: '',
                    share: 0,
                    body: '',
                    cc: [],
                    limit: 30,
                    service: schema.V1ObjectsServiceEnum.Googledrive,
                    title: '',
                    ordertype: schema.V1ObjectsRegisteredUserEnum.EachUser,
                    info: userLists,
                };
                ui.update(UI.Loading);
                await createOrder(formValue, auth);
            }
            if (mode === schema.MigrateImportMode.Mode2 && shareLists.length > 0) {
                //共有クラウドストレージに登録
                const formValue: schema.V1XStoragesOrderCreateRequest = {
                    allowedDomain: '',
                    share: 1,
                    body: ``,
                    cc: [],
                    limit: 30,
                    service: schema.V1ObjectsServiceEnum.Googledrive,
                    title: '',
                    info: shareLists,
                };
                ui.update(UI.Loading);
                await createOrder(formValue, auth);
            }

            ui.update(UI.Loaded);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.sent),
                variant: Variants.success,
            });
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * 通常業務csvファイルからGoogle通常業務を移行する。
     * @param auth 認証情報
     * @param file 通常業務csvファイル
     */
    const postUploadGoogleMigrationCsv = async (auth: string): Promise<void> => {
        try {
            ui.update(UI.Loading);
            if (!isNullOrEmptyArray(endpoints)) {
                const request: schema.V1EndpointsCollectiveMigrateCreateRequest = {
                    workspace: appContainer.values.signinWorkspaceObject.id!,
                    settings: endpoints,
                    filename: csvFileName,
                    migrateImportMode: mode,
                };
                await endpointModel.migrateCollectiveEndpoint(request, auth);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.request, { num: endpoints.length }),
                    variant: Variants.success,
                });
                ui.update(UI.Loaded);
                clearSelectedFile();
                setMode(schema.MigrateImportMode.Mode1);
            } else {
                // データ行なし
                clearSelectedFile();
                ui.update(UI.Loaded);
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvemptyerror),
                    variant: Variants.error,
                });
            }
        } catch (e) {
            // レスポンスエラーの時も選択ファイルクリア
            clearSelectedFile();
            ui.update(UI.Loaded);
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: 'リクエストに失敗しました。ステータスコード: ' + (e as any).response.status,
                variant: Variants.error,
            });
        }
    };

    /**
     * ドロップされたファイルを受け取る。
     * ファイルの分析を行う。必要なファイルがない場合はエラーを表示する。
     * @param accepts 受信したファイル
     * @returns
     */
    const onDrop = (accepts: File[]) => {
        if (accepts.length == 0 || accepts[0].size == 0) {
            clearSelectedFile();
            return;
        }

        // 文字コードを取得
        const reader = new FileReader();
        reader.onload = () => {
            const str = reader.result as string;
            let detectedStrCode = '';
            // BOM付きかチェック
            if (csvFormat4Endpoint.CSVFORMAT_BOM.test(str)) {
                // BOM付きの場合UTF8
                detectedStrCode = 'UTF8';
            } else {
                // 文字コード判定
                const detectResult = encoding.detect(str);
                if (!detectResult) {
                    // 判定不能の文字コードはUTF8にしておく
                    logger.error(`onSelectedCSV() unknown encoding. fileName:${csvFileName}`);
                    detectedStrCode = 'UTF8';
                } else {
                    const detectEncoding = detectResult as string;
                    detectedStrCode = detectEncoding === 'UTF8' ? 'UTF8' : 'SJIS';
                }
            }
            setStrCode(detectedStrCode);
        };
        reader.readAsArrayBuffer(accepts[0]);
        // ファイル名を取得
        setCsvFileName(accepts[0].name);
        droppedFile[1](accepts[0]);
        setFileInput(accepts[0]);
    };

    /**
     * ファイルからGoogle通常業務を取り出してCovas用通常業務/転送先にデータ変換する。
     * @returns
     */
    const onAnalyze = async () => {
        ui.update(UI.Loading);
        if (fileInput == null || fileInput.size == 0) {
            return;
        }
        const reader = new FileReader();
        reader.onload = async () => {
            if (fileInput && fileInput.size) {
                const text = reader.result as string;
                const lineInput = getSplitNewLineString(text);
                if (lineInput.length <= csvFormat4Endpoint.CSV_HEADER) {
                    // ヘッダー行のみのファイルはエラー
                    appContainer.updateMessage({
                        isOpen: true,
                        message: locale.t(locale.keys.validation.csvemptyerror),
                        variant: Variants.error,
                    });
                    ui.update(UI.Loaded);
                }
                let mailList: string[] = [];
                let deviceLoginUser: string[] = [];
                try {
                    result = await workspace.findWorkspaceUser(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                    const foundUser = result.users.find((user) => user.invitationEmail === appContainer.values.signinWorkspaceUserObject.invitationEmail);
                    if (foundUser) {
                        admin = foundUser;
                    } else {
                        throw new Error('Admin user not found');
                    }
                    for (let i = 0; i < result.users.length; i++) {
                        mailList.push(result.users[i].invitationEmail);
                        deviceLoginUser.push(result.users[i].deviceLoginUser);
                    }
                } catch (e) {
                    ui.update(UI.Loaded);
                    clearSelectedFile();
                    errorHandler.handleApiError(appContainer, e);
                    return;
                }
                let blankLine = false;

                // 1行ずつ処理
                for (let i = csvFormat4Endpoint.CSV_HEADER; i < lineInput.length; i += 1) {
                    // CSVパース
                    let spliteComma = [] as string[];
                    try {
                        spliteComma = csvPaser(lineInput[i], strCode);
                    } catch (e) {
                        appContainer.updateMessage({
                            isOpen: true,
                            message: locale.t(locale.keys.validation.csvemptyerror, { row: i }),
                            variant: Variants.error,
                        });
                        ui.update(UI.Loaded);
                        clearSelectedFile();
                        break;
                    }
                    // 入力形式チェック
                    const { success, endpoint, blank, error } = csvImportCheck(blankLine, spliteComma, i, environment.tenant, true);
                    if (!success && spliteComma[8] && spliteComma[8].toLocaleLowerCase().includes('googledrive')) {
                        appContainer.updateMessage({
                            ...error,
                        });
                        ui.update(UI.Loaded);
                        clearSelectedFile();
                        break;
                    }
                    blankLine = blank;
                    // google以外の通常業務はスキップ
                    if (spliteComma[8] && spliteComma[8].toLocaleLowerCase().includes('googledrive')) {
                        if (mode == schema.MigrateImportMode.Mode1) {
                            if (!blank) endpoints.push(endpoint);
                            userLists.push({
                                externalEmail: spliteComma[9],
                                registeredUser: admin,
                            });
                            // 重複するexternalEmailを除く
                            userLists = userLists.filter((user, index, self) => index === self.findIndex((u) => u.externalEmail === user.externalEmail));
                        }
                        if (mode == schema.MigrateImportMode.Mode2) {
                            if (!spliteComma[0] || spliteComma[0].trim() === '') {
                                if (!blank) endpoints.push(endpoint);
                                shareLists.push({
                                    externalEmail: spliteComma[9],
                                });
                                // 重複するexternalEmailを除く
                                shareLists = shareLists.filter((user, index, self) => index === self.findIndex((u) => u.externalEmail === user.externalEmail));
                                continue;
                            } else {
                                // Emailか複合機ログインユーザー名がワークスペースに存在していることを確認
                                if (!mailList.includes(spliteComma[csvFormat4Endpoint.COL_IDX_EMAIL]) && !deviceLoginUser.includes(spliteComma[csvFormat4Endpoint.COL_IDX_EMAIL])) {
                                    // 登録されていないアドレスはエラー
                                    appContainer.updateMessage({
                                        isOpen: true,
                                        message: errLocale.translate(errLocale.keys.E08234, { index: i + 1, row: csvFormat4Endpoint.COL_IDX_EMAIL + 1 }).message,
                                        variant: Variants.error,
                                    });
                                    ui.update(UI.Loaded);
                                    clearSelectedFile();
                                    break;
                                }
                            }
                            if (!blank) endpoints.push(endpoint);
                            userLists.push({
                                externalEmail: spliteComma[0] !== spliteComma[9] ? spliteComma[9] : '',
                                toUser: result.users.find((user) => user.invitationEmail === spliteComma[0] || user.deviceLoginUser === spliteComma[0]),
                            });
                            // 重複するexternalEmailとtoUserを除く
                            userLists = userLists.filter((user, index, self) => index === self.findIndex((u) => u.externalEmail === user.externalEmail && u.toUser === user.toUser));
                        }
                    }
                    //1000行読み込んだ場合
                    if (endpoints.length >= csvFormat4Endpoint.CSV_MAX_LINE) {
                        postUploadGoogleMigrationCsv(appContainer.values.authorizationCode);
                        //チェックありの時、ユーザー認可依頼メールを自動で送信
                        if (authChecked) orderCreate(appContainer.values.authorizationCode);
                        break;
                    }
                    // 最終行まで読み込んだ場合
                    if (i === lineInput.length - 1) {
                        if (blankLine) {
                            if (!isNullOrEmptyArray(endpoints)) {
                                // apiにリクエスト
                                postUploadGoogleMigrationCsv(appContainer.values.authorizationCode);
                                //チェックありの時、ユーザー認可依頼メールを自動で送信
                                if (authChecked) orderCreate(appContainer.values.authorizationCode);
                            } else {
                                // データ行なし
                                appContainer.updateMessage({
                                    isOpen: true,
                                    message: locale.t(locale.keys.validation.csvemptyerror),
                                    variant: Variants.error,
                                });
                                ui.update(UI.Loaded);
                            }
                        } else {
                            // qsのcsvインポート仕様に合わせる
                            // 最終行はEOFの前に改行がなければエラー
                            appContainer.updateMessage({
                                isOpen: true,
                                message: locale.t(locale.keys.validation.csvimporteoferror, { row: lineInput.length }),
                                variant: Variants.error,
                            });
                            ui.update(UI.Loaded);
                        }
                    }
                }
            } else {
                clearSelectedFile();
                ui.update(UI.Loaded);
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvemptyerror),
                    variant: Variants.error,
                });
            }
            clearSelectedFile();
        };
        reader.onerror = (e) => {
            clearSelectedFile();
            ui.update(UI.Loaded);
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.validation.csvemptyerror),
                variant: Variants.error,
            });
        };
        // 読み込み開始
        if (strCode === 'SJIS' || strCode === 'UTF8') {
            reader.readAsText(fileInput, strCode);
        } else {
            clearSelectedFile();
            ui.update(UI.Loaded);
            // それ以外対応していない文字コードとして扱う
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.validation.invalidCharacterCode),
                variant: Variants.error,
            });
        }
    };

    return {
        // states
        droppedFile,
        mode,
        csvFileName,
        authChecked,
        // functions
        onDrop,
        handleModeChange,
        handleCheckboxChange,
        onAnalyze,
    };
};

export const GoogleMigrationContainer = createContainer(useGoogleMigration);
