import { State as UIState } from '@/common/components/hooks/useUI';

export default {
    drawerWidth: 240,
    /**
     * When you make changes on base headerHeight, also checkout these stuffs to ensure the app looks consistent.
     *
     * In `/admin/common/headers/DashboardHeader.tsx` and `/user/common/headers/DashboardHeader.tsx`:
     * - Brand logo is not too big/small
     * - Shrink/expand browser width and header looks consistent on desktop/tablet/mobile media queries
     *
     * In `/common/headers/LogoHeader.tsx`:
     * - Manually check `styles.logo.marginTop` is configured to be vertically centered
     * - Shrink/expand browser width and header looks consistent on desktop/tablet/mobile media queries
     */
    headerHeight: 48,
    /**
     * When you make changes on base headerModalHeight, also checkout these stuffs to ensure the app looks consistent.
     *
     * In `src`:
     * - Search for modules that are using `headerModalHeight`, and make sure all dependencies does not looks weird.
     */
    headerModalHeight: 48,
    state: UIState,
    /**
     * The original logo aspect ratio is `20:3` with height of 21px.
     * However SVG viewbox contains extra padding thus the file ratio becomes `26:19`.
     * You'll need to manually tweak the size until it looks okay.
     */
    logoWidth: '210px',
    maxWidthScreen: '1920px',
    headerDescHeight: 72,
    breakPoints_1380: 1380,
    breakPoints_1600: 1600,
};
