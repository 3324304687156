import { useState } from 'react';
import { createContainer } from 'unstated-next';

interface State {
    back?: () => void;
}

const New = (): State => ({});

const useLogoHeaderContainer = () => {
    const [values, setValues] = useState<State>(New());

    const setBackFunc = (fn?: () => void) => {
        if (!fn) {
            setValues(New());
            return;
        }
        setValues({
            back: () => {
                fn();
                setValues(New());
            },
        });
    };

    return {
        setBackFunc,
        backFunc: values.back,
    };
};

export const LogoHeaderContainer = createContainer(useLogoHeaderContainer);
