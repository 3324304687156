import { Theme, createStyles } from '@material-ui/core';

export const tableStyles = (theme: Theme) =>
    createStyles({
        tab: {
            width: '78px',
            height: '19px',
            fontSize: '13px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: ' normal',
            color: '#666666',
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        titleLabel: {
            textAlign: 'left',
        },
        table: {
            minWidth: 300,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        pencil: {
            color: 'orange',
        },
        oddRow: {
            background: '#f2f4f5',
        },
        evenRow: {
            background: 'inherit',
        },
        enableSub: {
            width: '45px',
            height: '15px',
            fontSize: '15px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#43a047',
        },
        disableSub: {
            width: '60px',
            height: '15px',
            fontSize: '15px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#333333',
        },
        fab: {
            color: 'white',
            width: "160px",
            marginInline: "20px"
        },
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
        // Tableのセルのスタイルを個別に修正するためのプロパティ
        column1: {
            paddingTop: 0,
            paddingBottom: 0,
            width: "18%",
        },
        column2: {
            paddingTop: 0,
            paddingBottom: 0,
            width: "18%",
        },
        column3: {
            paddingTop: 0,
            paddingBottom: 0,
            width: "25%",
        },
        column4: {
            paddingTop: 0,
            paddingBottom: 0,
            width: "25%",
        },
        column5: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        headerCell1: {
            fontSize: '12px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#333333',
            width: "18%",
        },
        headerCell2: {
            fontSize: '12px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#333333',
            width: "18%",
        },
        headerCell3: {
            fontSize: '12px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#333333',
            width: "25%",
        },
        headerCell4: {
            fontSize: '12px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#333333',
            width: "25%",
        },
        headerCell5: {
            fontSize: '12px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#333333',
        },
        tableElementRoot: {
            width: "100%",
        },
        fabGridRoot: {
            flexGrow: 2
        }
    });
