import { default as React } from 'react';
import useTitle from '@/common/components/hooks/useTitle';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import SubWorkspaceMfps from './SubWorkspaceMfps';
import { SubWorkspaceMfpsContainer } from './SubWorkspaceMfpsContainer';

import locale from '@/common/utils/locale';

interface Props {}

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.subWorkspaceMfpManagement.index));

    return (
        <>
            <SubWorkspaceMfpsContainer.Provider>
                <SubWorkspaceMfps />
            </SubWorkspaceMfpsContainer.Provider>
        </>
    );
};

export default withStyles(styles)(Component);
