import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const indexXStorageStatus = async (auth: string, workspaceId: string, service: string, domain?: string) => {
    let optionalQuery = '';
    if (domain) optionalQuery += `&domain=${domain}`;

    const response = await axiosFactory.get<schema.V1WorkspaceuserXStorageStatusIndexResponse>(
        `https://${consts.environment.api.hostname}/v1/workspaces/${workspaceId}/x-storage-status?service=${service}${optionalQuery}`,
        {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        },
    );
    return response.data;
};
