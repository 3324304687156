import List from './List';
import New from './new/New';
import Edit from './detail/Edit';
import Register from './collective-register/Register';

export default {
    List,
    New,
    Edit,
    Register,
};
