import { default as React, useEffect } from 'react';
import { RouteComponentProps, withRouter, generatePath } from 'react-router';
import { default as UI } from '@/common/constants/ui';
import locale from '@/common/utils/locale';
import routes from '@/admin/constants/routes';
import { Theme, createStyles, WithStyles, withStyles, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, Grid, Paper, TablePagination, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { NewDeviceContainer } from './NewDeviceContainer';
import dummyNewDeviceList from './dummyNewDeviceList';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';

const styles = (theme: Theme) =>
    createStyles({
        heading: {
            height: 20,
            fontSize: 14,
            lineHeight: '1.43',
            color: '#333333',
            marginTop: 50,
            marginBottom: 20,
        },
        button: {
            minWidth: 96,
            maxWidth: 246,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        table: {
            minWidth: 500,
        },
        action: {
            textAlign: 'left',
            width: 50,
        },
        iconEdit: {
            color: '#ff7300',
        },
        mfpNumber: {
            textAlign: 'left',
            width: 230,
        },
        registeredPerson: {
            textAlign: 'left',
            width: 160,
        },
        registeredDatetime: {
            textAlign: 'left',
            width: 210,
        },
        deadline: {
            textAlign: 'left',
            width: 170,
        },
        registrationPin: {
            textAlign: 'left',
            width: 140,
        },
        noRecord: {
            textAlign: 'right',
            color: '#666666',
            height: 50,
        },
        gridTop: {
            height: 50,
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
        oddRow: {
            background: '#f2f4f5',
            minHeight: 60,
        },
        evenRow: {
            background: 'inherit',
            minHeight: 60,
        },
    });

type TParams = { id: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    skipEffect?: boolean;
}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const newDeviceContainer = NewDeviceContainer.useContainer();
    const appContainer = AdminAppContainer.useContainer();

    useEffect(() => {
        appContainer.updateLoadingState(newDeviceContainer.ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newDeviceContainer.ui]);

    useEffect(() => {
        if (props.skipEffect) {
            newDeviceContainer.loadDummyData(dummyNewDeviceList);
            newDeviceContainer.setUI(UI.state.Loaded);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (newDeviceContainer.goToDetailDevice) {
            props.history.push(generatePath(routes.device.edit, { id: newDeviceContainer.idSelected }));
            newDeviceContainer.setGoToDetailDevice(false);
            return;
        }
        void (async () => {
            try {
                if (!newDeviceContainer.isReloaded) {
                    newDeviceContainer.setUI(UI.state.Loading);
                    await newDeviceContainer.getRegisteringDeviceList();
                    newDeviceContainer.setUI(UI.state.Loaded);
                }
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newDeviceContainer.goToDetailDevice, newDeviceContainer.isReloaded]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.gridTop} container direction="row" justify="flex-start" alignItems="flex-start" />
                <Grid item xs={12}>
                    <Paper>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow className={classes.headerFont}>
                                        <TableCell className={classes.mfpNumber}>{locale.t(locale.keys.manageShareDevice.mfpNumber.index)}</TableCell>
                                        <TableCell className={classes.registeredPerson}>{locale.t(locale.keys.manageShareDevice.registeredPerson.index)}</TableCell>
                                        <TableCell className={classes.registeredDatetime}>{locale.t(locale.keys.manageShareDevice.registeredDatetime.index)}</TableCell>
                                        <TableCell className={classes.deadline}>{locale.t(locale.keys.manageShareDevice.deadline.index)}</TableCell>
                                        <TableCell className={classes.registrationPin}>{locale.t(locale.keys.manageShareDevice.registrationPin.index)}</TableCell>
                                        <TableCell className={classes.action}>{locale.t(locale.keys.manageShareDevice.action.index)}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {newDeviceContainer.showRows().map((row, index) => (
                                        <TableRow key={row.id} hover={true} className={index % 2 ? classes.evenRow : classes.oddRow}>
                                            <TableCell className={classes.mfpNumber}>{row.mfpNumber}</TableCell>
                                            <TableCell className={classes.registeredPerson}>{row.registeredPerson}</TableCell>
                                            <TableCell className={classes.registeredDatetime}>{row.registeredDate}</TableCell>
                                            <TableCell className={classes.deadline}>
                                                {row.deadline === Infinity
                                                    ? locale.t(locale.keys.manageShareDevice.deadlineValue.infinity)
                                                    : locale.t(locale.keys.manageShareDevice.remainDays.index, { day: row.deadline })}
                                            </TableCell>
                                            <TableCell className={classes.registrationPin}>{row.registrationPin}</TableCell>
                                            <TableCell className={classes.action}>
                                                <IconButton onClick={() => newDeviceContainer.handleClickGoToDetailDevice(row.id)}>
                                                    <Edit className={classes.iconEdit} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                {newDeviceContainer.totalCount > 0 ? (
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={6}
                                                count={newDeviceContainer.totalCount === undefined ? 0 : newDeviceContainer.totalCount}
                                                rowsPerPage={newDeviceContainer.rowsPerPage}
                                                page={newDeviceContainer.page}
                                                labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                                                labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                                                    <span>
                                                        {locale.t(locale.keys.table.displayedRowsArgs, {
                                                            from: paginationInfo.from,
                                                            to: paginationInfo.to,
                                                            count: paginationInfo.count,
                                                        })}
                                                    </span>
                                                )}
                                                backIconButtonProps={{ 'aria-label': locale.t(locale.keys.table.previousPage) }}
                                                nextIconButtonProps={{ 'aria-label': locale.t(locale.keys.table.nextPage) }}
                                                onChangePage={newDeviceContainer.handleChangePage}
                                                onChangeRowsPerPage={newDeviceContainer.handleChangeRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                ) : (
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={6} className={classes.noRecord}>
                                                <span>{locale.t(locale.keys.manageShareDevice.noRecord.index)}</span>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                )}
                            </Table>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(Component));
