import { default as React, useState } from 'react';
import { Theme, createStyles, WithStyles, withStyles, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, Grid, IconButton, TablePagination } from '@material-ui/core';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { Check, Block, Clear, UnfoldMore } from '@material-ui/icons';
import { CovasAvatar } from '@/common/components/CovasAvatar';
import Confirm from '@/common/components/Confirm/Confirm';
import locale from '@/common/utils/locale';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';
import { DetailDeviceContainer } from './DetailDeviceContainer';
import { getDeviceMember } from '../../../../common/models/device/useDevice';
import { styles as baseTableStyles } from './tableStyle';

const styles = (theme: Theme) =>
    createStyles({
        ...baseTableStyles(theme),
        avatar: {
            width: 90,
            maxWidth: 90,
            padding: 0,
            paddingLeft: 10,
        },
        avatarImg: {
            margin: `0 auto`,
        },
        phoneticName: {
            textAlign: 'left',
            width: 280,
            maxWidth: 280,
        },
        email: {
            textAlign: 'left',
            flexGrow: 1,
            paddingLeft: 10,
        },
        role: {
            textAlign: 'left',
            width: 130,
            paddingLeft: 10,
        },
        active: {
            textAlign: 'left',
            width: 190,
        },
        delete: {
            textAlign: 'center',
            width: 90,
        },
        iconClear: {
            color: '#ff7300',
        },
    });

interface Props extends WithStyles<typeof styles> {}

const MemberTable = (props: Props) => {
    const { classes } = props;
    const detailDeviceContainer = DetailDeviceContainer.useContainer();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const rows = () => {
        if (detailDeviceContainer.device.deviceMemberData === undefined) {
            return {
                totalCount: 0,
                rows: [],
            };
        }
        return {
            totalCount: detailDeviceContainer.device.deviceMemberData.users.length,
            rows: getDeviceMember(detailDeviceContainer.device.deviceMemberData.users, rowsPerPage, page * rowsPerPage, detailDeviceContainer.device.viewSettings.sortByName),
        };
    };

    return (
        <div className={classes.tableWrapper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.headerFont}>
                        <TableCell className={classes.avatar} />
                        <TableCell className={classes.email} align="left">
                            <div className={classes.gridInsideHeaderCell}>
                                <p className={classes.gridInsideHeaderCellText}>{`${locale.t(locale.keys.manageShareDevice.fullname.index)}/${locale.t(locale.keys.manageShareDevice.email.index)}`}</p>
                                <IconButton
                                    onClick={() => {
                                        detailDeviceContainer.device.changeSortByName();
                                    }}
                                >
                                    <UnfoldMore />
                                </IconButton>
                            </div>
                        </TableCell>
                        <TableCell className={classes.phoneticName}>{locale.t(locale.keys.manageShareDevice.phoneticName.index)}</TableCell>
                        <TableCell className={classes.role}>{locale.t(locale.keys.manageShareDevice.role.index)}</TableCell>
                        <TableCell className={classes.active}>{locale.t(locale.keys.manageShareDevice.accountStatus.index)}</TableCell>
                        <TableCell className={classes.delete}>{locale.t(locale.keys.action.delete)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows().rows.length === 0 ? (
                        <></>
                    ) : (
                        rows().rows.map((row, index) => (
                            <TableRow key={row.id} hover={true} className={index % 2 ? classes.evenRow : classes.oddRow}>
                                <TableCell className={classes.avatar}>
                                    <CovasAvatar alt="Workspace" size={40} seed={row.email} src={row.avatarUrl} className={props.classes.avatarImg} />
                                </TableCell>
                                <TableCell className={classes.email} align="left">
                                    <Grid container direction="column">
                                        <Grid item style={{ whiteSpace: 'pre-wrap' }}>
                                            {row.name}
                                        </Grid>
                                        <Grid item style={{ color: 'gray', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                            {row.email}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell className={classes.phoneticName}>{row.phonetic}</TableCell>
                                <TableCell className={classes.role}>
                                    <span>{row.role.roleName}</span>
                                </TableCell>
                                <TableCell className={classes.active}>
                                    {row.active && (
                                        <>
                                            <Check style={{ marginBottom: -5 }} />
                                            <span>{locale.t(locale.keys.manageShareDevice.status.active)}</span>
                                        </>
                                    )}
                                    {!row.active && (
                                        <>
                                            <Block style={{ marginBottom: -5 }} />
                                            <span>{locale.t(locale.keys.manageShareDevice.status.suspended)}</span>
                                        </>
                                    )}
                                </TableCell>
                                <TableCell className={classes.delete}>
                                    <Confirm value={row.id} name={row.email} callBack={detailDeviceContainer.confirmRemoveMember}>
                                        <IconButton aria-label="Delete">
                                            <Clear className={classes.iconClear} />
                                        </IconButton>
                                    </Confirm>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={6}
                            count={rows().totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={locale.t(locale.keys.manageShareDevice.table.rowsPerPage)}
                            labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                                <span>
                                    {locale.t(locale.keys.manageShareDevice.table.displayedRowsArgs, {
                                        from: paginationInfo.from,
                                        to: paginationInfo.to,
                                        count: paginationInfo.count,
                                    })}
                                </span>
                            )}
                            backIconButtonProps={{ 'aria-label': locale.t(locale.keys.table.previousPage) }}
                            nextIconButtonProps={{ 'aria-label': locale.t(locale.keys.table.nextPage) }}
                            onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    );
};

export default withStyles(styles)(MemberTable);
