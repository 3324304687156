import { default as React } from 'react';
import { Chip, Theme, createStyles, withStyles, WithStyles, Typography, FilledInput, MenuItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import { default as ChipInput } from 'material-ui-chip-input';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import locale from '@/common/utils/locale';
import blue from '@material-ui/core/colors/blue';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { InvitationMemberContainer } from '@/admin/components/member/invite/InvitationMemberContainer';
import routes from '@/admin/constants/routes';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
            textAlign: 'left',
        },
        inviteGrid: {
            textAlign: 'end',
        },
        inviteButton: {
            color: 'white',
            minWidth: 240,
        },
        collectiveRegistration: {
            textAlign: 'center',
        },
        root: {
            display: 'flex',
            border: '2px solid #bbbbbb',
            justifyContent: 'left',
            flexWrap: 'wrap',

            minHeight: 90,
            margin: 10,
            padding: 10,
        },
        chip: {
            textAlign: 'left',
            marginTop: '15px',
        },
        formControl: {
            maxWidth: '85%',
            minWidth: '85%',
            margin: '5px',
        },
        select: {
            maxWidth: '90%',
            minWidth: '90%',
        },
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
            marginTop: '-5px',
            width: 200,
        },
        selectInput: {
            padding: '28px 12px 9px 12px',
        },
    });
interface Props extends WithStyles<typeof styles> {
    onInvite: (emailStrings: string[] | null) => void;
}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = InvitationMemberContainer.useContainer();
    const location = useLocation();
    const currentPath = encodeURIComponent(location.pathname);

    const customChip = (args: any, key: number) => (
        <Chip
            key={key}
            className={args.className}
            style={{ pointerEvents: args.isDisabled ? 'none' : undefined, backgroundColor: args.isFocused ? blue[300] : container.errorChipColor(key) ? '#FF9999' : undefined }}
            onClick={args.handleClick}
            onDelete={args.handleDelete}
            label={args.text}
        />
    );

    return (
        <div>
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Typography align="left" variant="overline">
                                {locale.t(locale.keys.memberInvitation.memberEmailAddress.index)}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8}>
                            <Paper className={classes.root}>
                                <ChipInput
                                    chipRenderer={customChip}
                                    fullWidth={true}
                                    fullWidthInput={true}
                                    disableUnderline={true}
                                    error={container.errors.emails !== ''}
                                    value={container.form.emails}
                                    onAdd={(chip: string) => container.handleAdd(chip)}
                                    onDelete={(chip: string, index: number) => container.handleDelete(chip, index)}
                                    helperText={container.errors.emails}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="filled-period" variant="filled">
                                    {locale.t(locale.keys.memberInvitation.invitationLimit.index)}
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    id="period"
                                    value={container.form.limit}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => container.handleState(event.target.value)}
                                    input={<FilledInput name="period" id="filled-period" classes={{ input: classes.selectInput }} />}
                                    inputProps={{
                                        style: { height: '1.46em', lineHeight: 'normal' },
                                    }}
                                >
                                    <MenuItem value={7}>{locale.plural('memberInvitation.invitationUnit.day', 7, { n: 7 })}</MenuItem>
                                    <MenuItem value={30}>{locale.plural('memberInvitation.invitationUnit.day', 30, { n: 30 })}</MenuItem>
                                    <MenuItem value={0}>{locale.plural('memberInvitation.invitationUnit.infinity', 1, { n: 0 })}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* エラーがなくなるまで非活性状態 */}
                        <Grid item xs={12} sm={7} className={classes.inviteGrid}>
                            <Fab
                                variant="extended"
                                disabled={!container.form.validateInit.emails || container.errors.emails !== ''}
                                onClick={() => container.handleInvite(props.onInvite)}
                                className={classes.inviteButton}
                                size="large"
                                color="secondary"
                            >
                                {locale.t(locale.keys.memberInvitation.invitation.index)}
                            </Fab>
                        </Grid>
                        {/* 一括招待ボタン */}
                        <Grid item xs={12} sm={5} className={classes.collectiveRegistration}>
                            <RouterLink to={`${routes.user.collective.index}?from=${currentPath}`}>
                                <Fab variant="extended" className={classes.inviteButton} size="large" color="secondary">
                                    {locale.t(locale.keys.memberInvitation.collectiveInvite.index)}
                                </Fab>
                            </RouterLink>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default withStyles(styles)(Component);
