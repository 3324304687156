import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { EmailValidate } from '@/common/constants/email';
import * as schema from '@/bundles/schema/typescript/schema';

export interface Form {
    objId: string;
    displayId: string;
    displayName: string;
    logoUrl?: string;
    language?: schema.Language;
    enableSubWorkspace?: boolean;
    contactEmail?: string;
    validateInit: {
        displayId: boolean;
        displayName: boolean;
        language: boolean;
        contactEmail: boolean;
    };
    errors: validator.ValidationOutput;
}

export const New = (): Form => ({
    objId: '',
    displayId: '',
    displayName: '',
    logoUrl: '',
    contactEmail: '',
    validateInit: {
        displayId: false,
        displayName: false,
        language: false,
        contactEmail: false,
    },
    // Initialize error holder.
    errors: NewValidation(),
});

export const validations = (): validator.Constraints => {
    return {
        displayName: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        displayId: {
            workspaceId: {
                message: locale.t(locale.keys.validation.workspaceIdFormat),
            },
        },
        language: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        contactEmail: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            email: {
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        displayId: null,
        displayName: null,
        language: null,
        contactEmail: null,
    };
};
