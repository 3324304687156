import { useState } from 'react';
import * as H from 'history';
import { createContainer } from 'unstated-next';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { Variants } from '@/common/components/messages/CommonMessage';
import moment from 'moment';
import * as schema from '@/bundles/schema/typescript/schema';
import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import * as errorHandler from '@/common/utils/errorHandler';
import * as workspaceuser from '@/common/api/workspaceuser/workspaceuser';
import * as login from '@/common/api/auth/login/Login';
import userRole from '@/common/constants/userRole';
import routes from '@/admin/constants/routes';
import * as model from '@/common/api/workspaceuser/detail';
import * as validator from '@/common/utils/validator';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { MemberContainer } from '@/admin/components/member/MemberContainer';
import { forceSignout } from '@/common/utils/errorHandler';
import { agentSignIn } from '@/common/utils/authManagement/agentSignIn';

const useMemberDetailContainer = () => {
    const [values, setValues] = useState<model.Props>(model.NewProps());
    const appContainer = AdminAppContainer.useContainer();
    const container = MemberContainer.useContainer();
    const ui = useUI();
    const [form, setForm] = useState<model.Form>(model.NewForm());
    const [removeForm, setRemoveForm] = useState<model.RemoveForm>(model.NewRemoveForm());
    const [avatarDataUri, setAvatarDataUri] = useState('');
    const [editEmail, setEditEmail] = useState<boolean>(false);
    // 初期入力値
    const [initEmail, setInitEmail] = useState<string>('');

    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };

    const handleChange = (name: keyof model.Form) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const changed = { ...form, [name]: event.target.value };
        if (name === 'email') setEditEmail(true);
        setForm(changed);
        validate(changed);
    };
    const handleEmailConfirm = (name: keyof model.RemoveForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const changed = { ...form, [name]: event.target.value };
        setRemoveForm(changed);
    };

    const handleChangeAvatar = (v: string) => {
        setAvatarDataUri(v);
    };

    const handleChangeAdmin = (event: any, value: string) => {
        setForm({ ...form, admin: value });
    };

    const validate = (changed: model.Form) => {
        const valideteOutput = validator.Validate<model.Form>(changed, model.validations(), model.NewValidation);

        // VALIDATE.JSが不具合のためisEmptyが効かない
        // エラーがあった場合、空白の場合にはエラーメッセージを消す
        if (valideteOutput && valideteOutput.deviceLoginUser) {
            if (!changed.deviceLoginUser) {
                valideteOutput.deviceLoginUser = null;
            }
        }
        setValues({ ...values, errors: valideteOutput });
    };

    const handleChangeLanguage = (v: string) => {
        const changed = { ...form, language: v };
        setForm(changed);
        validate(changed);
    };

    const handleValidateLanguage = () => {
        const msg = validator.Validate<model.Form>(form, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.language === null ? '' : msg!.language.toString();
        return dispMessage;
    };

    const handleValidateEmail = () => {
        const msg = validator.Validate<model.Form>(form, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.email === null ? '' : msg!.email.toString();
        return dispMessage;
    };

    const handleValidateName = () => {
        const msg = validator.Validate<model.Form>(form, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.name === null ? '' : msg!.name.toString();
        return dispMessage;
    };

    const handleValidatePhonetic = () => {
        const msg = validator.Validate<model.Form>(form, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.phonetic === null ? '' : msg!.phonetic.toString();
        return dispMessage;
    };

    const handleValidateDeviceLoginUser = () => {
        const msg = validator.Validate<model.Form>(form, model.validations(), model.NewValidation);
        if (msg && msg.deviceLoginUser) {
            if (!form.deviceLoginUser) {
                msg.deviceLoginUser = null;
            }
        }
        const dispMessage = msg === null ? '' : msg.deviceLoginUser === null ? '' : msg!.deviceLoginUser.toString();
        return dispMessage;
    };

    const handleValidatePin = () => {
        const msg = validator.Validate<model.Form>(form, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.pin === null ? '' : msg!.pin.toString();
        return dispMessage;
    };

    const handleValidateContactEmail = () => {
        const msg = validator.Validate<model.Form>(form, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.contactEmail === null ? '' : msg!.contactEmail.toString();
        return dispMessage;
    };

    /**
     * 入力中のメールアドレスの重複チェック
     * @return 重複しているとき true それ以外 false
     */
    const duplicateCheck = () => {
        let msg = '';
        // メンバーのメールアドレスと比較
        if (container.member.allmemberWithNotVerified) {
            container.member.allmemberWithNotVerified.users.forEach((value) => {
                if (editEmail && form.email.toLowerCase() === value.invitationEmail.toLowerCase() && initEmail !== form.email) {
                    msg = locale.t(locale.keys.validation.alreadyUses, { email: form.email });
                }
            });
        }
        return msg;
    };

    const isError = (name: keyof model.Form) => {
        return !!(values.errors && name in values.errors && !!values.errors[name]);
    };

    const openMessage = (message: string, variant: Variants) => {
        const isOpen = true;
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen,
            message,
            variant,
        });
    };
    const getHelperText = (name: keyof model.Form) => {
        if (values.errors && name in values.errors && values.errors[name]) {
            return values.errors[name];
        }
        return '';
    };
    const buttonDisabled = () => {
        if (values.errors) {
            return !Object.entries(values.errors).every(([key, value]) => {
                return value === null;
            });
        }
        return false;
    };
    const removeButtonDisabled = () => {
        return form.email !== removeForm.email;
    };

    const onCloud = () => (event: React.MouseEvent<HTMLInputElement>) => {
        logger.debug('Cloud Setting');
        // TODO: cloud setting
    };
    const onEndpoint = () => (event: React.MouseEvent<HTMLInputElement>) => {
        logger.debug('EndPoint Setting');
        // TODO: endpoint setting
    };
    const onDevice = () => (event: React.MouseEvent<HTMLInputElement>) => {
        logger.debug('Shared Device Setting');
        // TODO: share device
    };

    const resetPasswordButtonDisabled = () => {
        // 一時停止中の場合、disabled
        return !values.user ? true : !values.user.active;
    };

    const onResetPassword = (signinWorkspaceId: string) => (event: React.MouseEvent<HTMLInputElement>) => {
        updateUIStatus(UI.Saving);
        (async () => {
            try {
                if (values && values.user) {
                    // Covas-277 roleによって管理者フラグを再精査する。
                    const isAdmin = values.user.role === userRole.admin || values.user.role === userRole.externalAdmin || values.user.role === userRole.systemAdmin;

                    const result = await login.myPasswordReset({ workspaceId: signinWorkspaceId, email: values.user.invitationEmail, admin: isAdmin });
                    if (result === null) {
                        logger.debug('onFailure', result);
                        openMessage(locale.t(locale.keys.action.failedToSend), Variants.error);
                        return;
                    }
                    openMessage(locale.t(locale.keys.action.sent), Variants.success);
                    updateUIStatus(UI.Loaded);
                } else {
                    throw new Error('user not found');
                }
            } catch (e) {
                updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };
    const onSuspendAccount = (authorizationCode: string) => (event: React.MouseEvent<HTMLInputElement>) => {
        updateUIStatus(UI.Saving);
        (async () => {
            try {
                if (values && values.user) {
                    const req: schema.V1WorkspaceuserUpdateRequest = {
                        active: false,
                        language: values.user.language,
                        name: values.user.name,
                        phoneticName: values.user.phoneticName,
                        role: values.user.role,
                        deviceLoginUser: values.user.deviceLoginUser,
                        pin: values.user.pin,
                        contactEmail: values.user.contactEmail,
                    };
                    const result = await workspaceuser.updateUser(values.user.id, authorizationCode, req);

                    openMessage(locale.t(locale.keys.action.saved), Variants.success);
                    values.user = result.user;

                    updateUIStatus(UI.Loaded);
                } else {
                    throw new Error('user not found');
                }
            } catch (e) {
                updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };
    const onResumeAccount = (authorizationCode: string) => (event: React.MouseEvent<HTMLInputElement>) => {
        updateUIStatus(UI.Saving);
        (async () => {
            try {
                if (values && values.user) {
                    const req: schema.V1WorkspaceuserUpdateRequest = {
                        active: true,
                        language: values.user.language,
                        name: values.user.name,
                        phoneticName: values.user.phoneticName,
                        role: values.user.role,
                        deviceLoginUser: values.user.deviceLoginUser,
                        pin: values.user.pin,
                        contactEmail: values.user.contactEmail,
                    };
                    const result = await workspaceuser.updateUser(values.user.id, authorizationCode, req);

                    openMessage(locale.t(locale.keys.action.saved), Variants.success);
                    values.user = result.user;
                    updateUIStatus(UI.Loaded);
                } else {
                    throw new Error('user not found');
                }
            } catch (e) {
                updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    const onRemove = (authorizationCode: string, history: H.History) => (event: React.MouseEvent<HTMLInputElement>) => {
        updateUIStatus(UI.Saving);
        (async () => {
            try {
                if (values && values.user) {
                    await workspaceuser.removeUser(values.user.id, authorizationCode);

                    if (values.user.role === userRole.externalAdmin) {
                        openMessage(locale.t(locale.keys.action.deleted), Variants.success);
                        // 強制的にログアウトしてワークスペース選択に飛ばす
                        forceSignout(appContainer);
                    } else {
                        // success
                        openMessage(locale.t(locale.keys.action.deleted), Variants.success);
                        history.push(routes.user.index);
                    }
                } else {
                    throw new Error('user not found');
                }
            } catch (e) {
                updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    const languageWording = (lang: schema.Language) => {
        switch (lang) {
            case schema.Language.Ja: {
                return locale.t(locale.keys.common.language.japanese);
            }
            default: {
                return locale.t(locale.keys.common.language.english);
            }
        }
    };
    const toLanguage = (lang: string): schema.Language => {
        try {
            return lang as schema.Language;
        } catch (e) {
            return schema.Language.En;
        }
    };

    const onGetInviteUrl = () => {
        (async () => {
            try {
                if (values && values.user) {
                    const rowLimit = moment('9999-12-31 23:59:59').diff(moment.now(), 'days');
                    const resendLimitDays: number = rowLimit > 7 ? rowLimit : 7;
                    const url = await workspaceuser.getInviteUrl(
                        { displayId: appContainer.values.signinWorkspaceObject.displayId, signupLimitDays: resendLimitDays, email: values.user.invitationEmail },
                        appContainer.values.authorizationCode,
                    );
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.action.invited),
                        variant: Variants.success,
                    });
                    window.open(url);
                } else {
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.action.failedToInvite),
                        variant: Variants.error,
                    });
                }
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    const onAgentSignIn = async () => {
        try {
            updateUIStatus(UI.Loading);
            await agentSignIn(appContainer.values.authorizationCode, appContainer.values.signinWorkspaceObject.displayId, values.user!.invitationEmail, values.user!.id, document.location.href);
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        } finally {
            updateUIStatus(UI.Loaded);
        }
    };

    return {
        values,
        form,
        ui,
        updateUIStatus,
        removeForm,
        handleChange,
        handleChangeLanguage,
        handleValidateLanguage,
        avatarDataUri,
        handleChangeAvatar,
        handleChangeAdmin,
        handleEmailConfirm,
        isError,
        getHelperText,
        openMessage,
        buttonDisabled,
        removeButtonDisabled,
        onCloud,
        onEndpoint,
        onDevice,
        onResetPassword,
        resetPasswordButtonDisabled,
        onSuspendAccount,
        onResumeAccount,
        onRemove,
        languageWording,
        toLanguage,
        validate,
        duplicateCheck,
        initEmail,
        setInitEmail,
        handleValidateEmail,
        handleValidateName,
        handleValidatePhonetic,
        handleValidateDeviceLoginUser,
        handleValidatePin,
        onGetInviteUrl,
        onAgentSignIn,
        handleValidateContactEmail,
    };
};
export const MemberDetailContainer = createContainer(useMemberDetailContainer);
