import { default as React } from 'react';

import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';

import { VerifyContainer } from './VerifyContainer';
import Verify from './Verify';

interface Props {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.memberSignup.index));
    return (
        <>
            <VerifyContainer.Provider>
                <Verify />
            </VerifyContainer.Provider>
        </>
    );
};

export default Component;
