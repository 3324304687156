import * as Cookies from 'js-cookie';
import logger from '@/common/utils/logger';
import * as crypto from 'crypto-js';
import environment from '@/common/constants/environment';
const secKey = 'covas-coookieKey';

const isLimitOfCookie = () => {
    return encodeURIComponent(document.cookie).length > 3500;
};
const deleteOldCookie = () => {
    Object.keys(Cookies.get()).forEach((cookieName) => {
        const neededAttributes = {
            // Here you pass the same attributes that were used when the cookie was created
            // and are required when removing the cookie
        };
        Cookies.remove(cookieName, neededAttributes);
    });
};

export const get = <T>(key: string) => {
    const value = Cookies.get(key);
    if (value) {
        try {
            const bytes = crypto.AES.decrypt(value, secKey);
            const originalText = bytes.toString(crypto.enc.Utf8);
            return JSON.parse(originalText) as T;
        } catch (e) {
            logger.error(e);
            return null;
        }
    }
    return null;
};

export const set = <T>(key: string, value: T, option?: Cookies.CookieAttributes) => {
    if (isLimitOfCookie()) {
        deleteOldCookie();
    }
    const encryptValue = crypto.AES.encrypt(JSON.stringify(value), secKey).toString();
    const parts = document.location.hostname.split('.');
    parts.shift();
    const upperleveldomain = parts.join('.');
    const secureOption = ['develop', 'test'].includes(environment.env!) ? false : true;
    if (option != null && option.secure != null) {
        Cookies.set(key, encryptValue, { ...option, domain: upperleveldomain });
    } else if (!option) {
        Cookies.set(key, encryptValue, { secure: secureOption, domain: upperleveldomain });
    } else {
        Cookies.set(key, encryptValue, { ...option, secure: secureOption, domain: upperleveldomain });
    }
};
export const remove = (key: string, option?: Cookies.CookieAttributes) => {
    Cookies.remove(key, option);
};
