import * as React from 'react';
import { Theme, createStyles, withStyles, WithStyles, SnackbarContent, IconButton, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { amber, green } from '@material-ui/core/colors';
import Close from '@material-ui/icons/Close';
import { Variants } from './CommonMessage';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import Info from '@material-ui/icons/Info';
import Warning from '@material-ui/icons/Warning';
import * as locale from '@/common/utils/locale/locale';

const styles = (theme: Theme) =>
    createStyles({
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: theme.palette.error.dark,
        },
        info: {
            backgroundColor: theme.palette.primary.main,
        },
        warning: {
            backgroundColor: amber[700],
        },
        icon: {
            fontSize: 20,
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing.unit,
        },
        message: {
            maxWidth: 460,
            display: 'flex',
            alignItems: 'center',
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
    message: string;
    resultCode?: string;
    onClose?: () => void;
    variant: Variants;
    className: string;
}

const MessageContent: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const { className, message, onClose } = props;
    const handleIcon = (variant: Variants) => {
        if (variant === Variants.success) {
            return <CheckCircle style={{ margin: 'auto' }} />;
        }
        if (variant === Variants.error) {
            return <Error style={{ margin: 'auto' }} />;
        }
        if (variant === Variants.warning) {
            return <Warning style={{ margin: 'auto' }} />;
        }
        if (variant === Variants.info) {
            return <Info style={{ margin: 'auto' }} />;
        }
    };

    /**
     * @param variant 結果
     * @param resultCode 結果に紐づくコード（問合せがあったときの解析時に使用する）
     * @returns
     */
    const resultCode = (variant: Variants, resultCode?: string) => {
        if (!resultCode) return <></>;

        if (variant === Variants.error) {
            return (
                <Typography style={{ color: 'inherit', font: 'inherit', fontSize: 12, wordWrap: 'break-word' }}>{locale.t(locale.keys.toasts.code.errorCode, { errorCode: resultCode })}</Typography>
            );
        }

        return <></>;
    };

    return (
        <SnackbarContent
            className={clsx(classes[props.variant], className)}
            aria-describedby="client-snackbar"
            style={{ flexWrap: 'nowrap' }}
            message={
                <Grid container direction="row" style={{ flexWrap: 'nowrap', gap: 12 }} id="client-snackbar" className={classes.message}>
                    {/* 24pxはアイコンの高さ。上下中央寄せするためにheightを設定する必要がある。 */}
                    <Grid item style={{ height: 24 }}>
                        {handleIcon(props.variant)}
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item style={{ width: '100%' }}>
                            <Typography style={{ color: 'inherit', font: 'inherit', wordWrap: 'break-word' }}>{message}</Typography>
                        </Grid>
                        <Grid item style={{ width: '100%' }}>
                            {resultCode(props.variant, props.resultCode)}
                        </Grid>
                    </Grid>
                </Grid>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <Close className={classes.icon} />
                </IconButton>,
            ]}
        />
    );
};
export default withStyles(styles, { withTheme: true })(MessageContent);
