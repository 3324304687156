import * as React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import ui from '@/common/constants/ui';

export const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#ffc342',
    },
    barColorPrimary: {
        backgroundColor: '#ff7300',
    },
})(LinearProgress);

const useStyles = (theme: Theme) =>
    createStyles({
        root: {
            // Progress Bar required to be rendered independently without getting
            // affected by pre/post DOM elements, therefore using absolute positioning.
            // This will avoid few pixel (height of Progress Bar) frictions on show/hide.
            left: 0,
            position: 'absolute',
            right: 0,
            top: ui.headerHeight,
            zIndex: 100,
        },
        modal: {
            // Progress Bar required to be rendered independently without getting
            // affected by pre/post DOM elements, therefore using absolute positioning.
            // This will avoid few pixel (height of Progress Bar) frictions on show/hide.
            left: 0,
            position: 'absolute',
            right: 0,
            top: ui.headerModalHeight,
            zIndex: 100,
        },
        description: {
            left: 0,
            position: 'absolute',
            right: 0,
            top: ui.headerDescHeight,
            zIndex: 100,
        },
    });

interface Props extends WithStyles<typeof useStyles> {
    isModal?: boolean;
    isDesc?: boolean;
}

const LoadingState: React.SFC<Props> = (props: Props) => {
    const { classes } = props;
    return (
        <div className={props.isModal ? classes.modal : props.isDesc ? classes.description : classes.root}>
            <ColorLinearProgress value={20} />
        </div>
    );
};

export default withStyles(useStyles, { withTheme: true })(LoadingState);
