import { useState } from 'react';
import { createContainer } from 'unstated-next';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import * as deviceModel from '@/common/api/workspace/mfp/mfp';
import { Variants } from '@/common/components/messages/CommonMessage';
import { AdminAppContainer } from '../AdminAppContainer';
import { default as UI } from '@/common/constants/ui';
import * as errorHandler from '@/common/utils/errorHandler';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';

interface Device {
    id: string;
    deviceName: string;
    mfpNumber: string;
    noMembers: number;
    mfpName: string;
    status?: string;
}

const useDeviceContainer = () => {
    const [ui, setUI] = useState(UI.state.Loading);
    const [idSelected, setIdSelected] = useState('');
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState<Device[]>([]);
    const [goToDetailDevice, setGoToDetailDevice] = useState(false);
    const [goToNewDevice, setGoToNewDevice] = useState(false);
    const [isReloaded, setIsReloaded] = useState(false);
    const appContainer = AdminAppContainer.useContainer();

    const handleClickGoToDetailDevice = (id: string) => {
        setIdSelected(id);
        setGoToDetailDevice(true);
    };

    const handleClickGoToNewDevice = () => {
        setGoToNewDevice(true);
    };

    const loadDummyData = (dummyDeviceList: Device[]) => {
        setRows(dummyDeviceList);
        setTotalCount(dummyDeviceList.length);
    };

    const getDeviceList = async () => {
        try {
            const dataResponse = await deviceModel.getWorkspaceMfp(
                appContainer.values.signinWorkspaceObject.id || '',
                appContainer.values.authorizationCode,
                (page * rowsPerPage).toString(),
                rowsPerPage.toString(),
                searchText,
            );
            if (!dataResponse) {
                throw new Error('force to fail');
            }
            const dataArr: schema.V1ObjectsWorkspacemfp[] = dataResponse.mfps;
            const deviceList = dataArr.map((device) => ({
                id: device.id,
                deviceName: device.deviceName,
                mfpNumber: device.mfpNumber,
                noMembers: device.memberCount !== undefined ? device.memberCount : 0,
                mfpName: device.mfpName,
                status: device.status,
            }));
            if (deviceList) {
                setTotalCount(dataResponse.totalCount);
                setRows(deviceList);
                setIsReloaded(true);
            }
        } catch (e) {
            setUI(UI.state.Error);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const showRows = () => {
        return rows.sort((a, b) => (a.id < b.id ? -1 : 1));
    };

    const handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handlePressEnterSearch = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        try {
            if (event.key === 'Enter') {
                await getDeviceList();
            }
        } catch (e) {
            setUI(UI.state.Error);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleClickIconSearch = async () => {
        try {
            await getDeviceList();
        } catch (e) {
            setUI(UI.state.Error);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
        setIsReloaded(false);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const maxPage = Math.floor(totalCount / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        if (page > maxPage) {
            setPage(maxPage);
        }
        setIsReloaded(false);
    };

    const [anchorEl, setAnchorEl] = useState<HTMLTableHeaderCellElement | null>();
    const [mfpNumberSelected, setMfpNumberSelected] = useState('');
    const openPopover = Boolean(anchorEl);

    const handleClickPopover = (mfpNumber: string, id: string) => (event: React.MouseEvent<HTMLTableHeaderCellElement>) => {
        setAnchorEl(event.currentTarget);
        setMfpNumberSelected(mfpNumber);
        setIdSelected(id);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleChangeMfpNumberSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMfpNumberSelected(event.target.value);
    };

    const handleClickSaveChangeMfpNumber = async () => {
        try {
            const dataResponse = await deviceModel.updateWorkspaceMfp(
                appContainer.values.signinWorkspaceObject.id || '',
                idSelected,
                { mfpNumber: mfpNumberSelected },
                appContainer.values.authorizationCode,
            );
            if (!dataResponse) {
                throw new Error('force to fail');
            } else {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.updated),
                    variant: Variants.success,
                });
                await getDeviceList();
                setAnchorEl(null);
                setIdSelected('');
            }
        } catch (e) {
            setUI(UI.state.Error);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    return {
        ui,
        setUI,
        isReloaded,
        goToDetailDevice,
        setGoToDetailDevice,
        handleClickGoToDetailDevice,
        goToNewDevice,
        setGoToNewDevice,
        handleClickGoToNewDevice,
        loadDummyData,
        getDeviceList,
        searchText,
        handleChangeSearchText,
        page,
        handleChangePage,
        rowsPerPage,
        handleChangeRowsPerPage,
        totalCount,
        showRows,
        handlePressEnterSearch,
        handleClickIconSearch,
        handleClickPopover,
        handleClosePopover,
        handleChangeMfpNumberSelected,
        openPopover,
        anchorEl,
        mfpNumberSelected,
        idSelected,
        handleClickSaveChangeMfpNumber,
    };
};
export const DeviceContainer = createContainer(useDeviceContainer);
