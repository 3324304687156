import { useState } from 'react';
import { createContainer } from 'unstated-next';
import * as schema from '@/bundles/schema/typescript/schema';

interface State {
    invitedUser?: string;
    user?: schema.V1ObjectsWorkspaceuserLarge;
    workspace?: schema.V1ObjectsWorkspace;
    token: string;
}

const New = (): State => ({
    token: '',
});

const useVerifyContainer = () => {
    const [values, setValues] = useState<State>(New());
    return {
        values,
        setValues,
    };
};
export const VerifyContainer = createContainer(useVerifyContainer);
