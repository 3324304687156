export interface NewDevice {
    id: string;
    hasDetail: boolean;
    mfpNumber: string;
    registeredPerson: string;
    registeredDate: string;
    deadline: number;
    registrationPin: string;
}

export const dummyNewDeviceList: NewDevice[] = [
    {
        id: '1',
        hasDetail: true,
        mfpNumber: '南麻布1F-MFP',
        registeredPerson: 'Person1',
        registeredDate: '2019.06.06 13:40',
        deadline: 7,
        registrationPin: '123456',
    },
    {
        id: '2',
        hasDetail: false,
        mfpNumber: '南麻布2F-MFP',
        registeredPerson: 'Person2',
        registeredDate: '2019.06.04 09:00',
        deadline: 5,
        registrationPin: '654321',
    },
];

export default dummyNewDeviceList;
