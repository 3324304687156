import { default as React } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import locale from '@/common/utils/locale';
import Grid from '@material-ui/core/Grid';
import { State as UI } from '@/common/components/hooks/useUI';
import Error from '@/common/components/state/Error';

import EntryTable from './EntryTable';
import { EntryContainer } from './EntryContainer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { EntrySearch } from '@/admin/components/entry/EntrySearch';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

export const Component: React.FC<Props> = (props) => {
    const container = EntryContainer.useContainer();

    return (
        <>
            <SubHeader title={locale.t(locale.keys.applicationList.index)} />
            <div className={props.classes.mainDiv}>
                {/* Loading, Saving中でも画面は出したままにしておく */}
                {container.ui.current !== UI.Error && (
                    <div>
                        <Grid container>
                            <Grid item xs={12}>
                                <EntrySearch />
                            </Grid>
                            <Grid item xs={12}>
                                <EntryTable
                                    classes={{
                                        root: '',
                                        table: '',
                                        tableWrapper: '',
                                        enableSub: '',
                                        disableSub: '',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {container.ui.current === UI.Error && (
                    <div data-testid={UI.Error}>
                        <Error />
                    </div>
                )}
            </div>
        </>
    );
};

export default withRouter(withStyles(styles)(Component));
