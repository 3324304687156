import { default as React } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withStyles, WithStyles, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, Paper, InputBase } from '@material-ui/core';
import { default as TablePagination, LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { Edit, Search } from '@material-ui/icons';
import * as Invitation from '@/common/api/sub-workspace/inivite/Invitation';
import locale from '@/common/utils/locale';
import { default as Mail } from '@/admin/components/sub-workspace/invite/mail/List';
import { default as MailButton } from '@/admin/components/sub-workspace/invite/MailButton';
import { InvitationContainer, SelectedTab } from '@/admin/components/sub-workspace/invite/InvitationContainer';
import Grid from '@material-ui/core/Grid';
import { tableStyles } from '@/admin/components/sub-workspace/css/table';
import SendStatus from '@/admin/components/common/SendStatus';
import { V1ObjectsMailResourceType } from '@/bundles/schema/typescript/schema';

interface Props extends RouteComponentProps, WithStyles<typeof tableStyles> {
    invitationRow: Invitation.Row[];
    mailSendBack: (limit: number, rowId: number) => void;
    mailSendErrorBack: (rowId: number) => void;
}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = InvitationContainer.useContainer();
    const onMailSend = (limit: number) => {
        props.mailSendBack(limit, container.modal.rowId);
    };
    const onMailSendError = () => {
        props.mailSendErrorBack(container.modal.rowId);
    };
    const pagination = (tab: SelectedTab) => (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={container.getInvitationRows(props.invitationRow).length}
            labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
            labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
            )}
            rowsPerPage={container.getRowsPerPageByTab(tab)}
            page={container.getPageByTab(tab)}
            backIconButtonProps={{
                'aria-label': locale.t(locale.keys.table.previousPage),
            }}
            nextIconButtonProps={{
                'aria-label': locale.t(locale.keys.table.nextPage),
            }}
            onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => container.handleChangePageByTab(event, p, tab)}
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                container.handleChangeRowsPerPageByTab(tab, event);
            }}
        />
    );
    const table = (headerRows: JSX.Element, bodyRows: JSX.Element, tab: SelectedTab, modal?: JSX.Element) => (
        <div className={classes.tableWrapper}>
            <Table className={classes.table}>
                <TableHead>{headerRows}</TableHead>
                <TableBody>{bodyRows}</TableBody>
            </Table>
            {pagination(tab)}
            {modal != null && modal}
        </div>
    );
    const modal = (
        <div>
            <Modal open={container.modal.modalOpen}>
                <div className={classes.modal}>
                    <Mail
                        onClose={container.onCloseSendMailModal}
                        onMailSend={onMailSend}
                        onMailSendError={onMailSendError}
                        mailTo={container.modal.email}
                        mailCC={container.modal.emailCC}
                        displayId={container.modal.displayId}
                        language={container.modal.language}
                    />
                </div>
            </Modal>
        </div>
    );

    // 招待Eメール ====================================
    const invitationHeader = (
        <>
            <TableRow>
                <TableCell className={classes.headerCell} align="left">
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.invitationNewWorkspace.tab3.contactEmailAddress.index)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell className={classes.headerCell} align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab3.invidationUser.index)}
                </TableCell>
                <TableCell className={classes.headerCell} align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab3.invidationDate.index)}
                </TableCell>
                <TableCell className={classes.headerCell} align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab3.period.index)}
                </TableCell>
                <TableCell className={classes.headerCell} align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab3.sendAction)}
                </TableCell>
                <TableCell className={classes.headerCell} align="left">
                    {locale.t(locale.keys.invitationNewWorkspace.tab3.mailStatus.index)}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                    {locale.t(locale.keys.invitationNewWorkspace.tab3.operation)}
                </TableCell>
            </TableRow>
        </>
    );
    const invitationBody = (
        <>
            {container
                .getInvitationRows(props.invitationRow)
                .slice(
                    container.getPageByTab(SelectedTab.tab3) * container.getRowsPerPageByTab(SelectedTab.tab3),
                    container.getPageByTab(SelectedTab.tab3) * container.getRowsPerPageByTab(SelectedTab.tab3) + container.getRowsPerPageByTab(SelectedTab.tab3),
                )
                .map((row, index) => (
                    <TableRow key={row.id} className={index % 2 ? classes.evenRow : classes.oddRow}>
                        <TableCell className={classes.tableCell} align="left">
                            {row.email}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left" component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                            {row.invitationDate}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                            {row.limit}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                            {row.lock ? (
                                <MailButton row={row} disabled={true} resendAction={() => {}} sendAction={() => {}} />
                            ) : (
                                <MailButton row={row} resendAction={container.handleSend} sendAction={container.handleSend} />
                            )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                            <SendStatus type={V1ObjectsMailResourceType.WorkspaceInvite} email={row.email} objectId={row.workspaceId!} />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {row.lock ? (
                                <IconButton aria-label="Edit">
                                    <Edit className={classes.pencil} style={{ color: 'gray' }} />
                                </IconButton>
                            ) : (
                                <Link to={`/sub-workspace/detail/${row.displayId}?invite=true`}>
                                    <IconButton aria-label="Edit">
                                        <Edit className={classes.pencil} />
                                    </IconButton>
                                </Link>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
        </>
    );

    return (
        <>
            <Paper className={classes.root}>
                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <Paper className={classes.searchPager}>
                            <IconButton className={props.classes.searchIconButton} aria-label="Search">
                                <Search />
                            </IconButton>
                            <InputBase className={props.classes.searchInput} value={container.searchText} inputProps={{ style: { height: '100%' } }} onChange={container.handleChangeSearchText()} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        {table(invitationHeader, invitationBody, SelectedTab.tab3, modal)}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
export default withRouter(withStyles(tableStyles)(Component));
