import React, { FC } from 'react';
import { TextField, WithStyles, withStyles, Grid, Button, createStyles, Typography } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';

const styles = () =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
            gap: '4px',
            flexDirection: 'column',
        },
        fieldRoot: {
            width: '100%',
        },
        footer: {
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'flex-start',
        },
    });

interface Props extends WithStyles<typeof styles> {
    mailTo?: string;
    mailCc?: string;
    title?: string;
    body?: string;
    expired?: number;
    onClose: () => void;
}

const SendAuthRequestMailPreview: FC<Props> = (props) => {
    return (
        <Grid id="preview-root" className={props.classes.root}>
            <Grid id="subhedder" className={props.classes.fieldRoot}>
                <Typography>{locale.t(locale.keys.memberAuthManagement.orderConfirmation.abstract)}</Typography>
            </Grid>
            <Grid id="preview-to" className={props.classes.fieldRoot}>
                <TextField
                    label={locale.t(locale.keys.memberAuthManagement.orderConfirmation.label.to)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    value={props.mailTo}
                    fullWidth
                    multiline
                />
            </Grid>
            <Grid id="preview-cc" className={props.classes.fieldRoot}>
                <TextField
                    label={locale.t(locale.keys.memberAuthManagement.orderConfirmation.label.cc)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    value={props.mailCc}
                    fullWidth
                />
            </Grid>
            <Grid id="preview-title" className={props.classes.fieldRoot}>
                <TextField
                    label={locale.t(locale.keys.memberAuthManagement.orderConfirmation.label.mailTitle)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    value={props.title}
                    fullWidth
                />
            </Grid>
            <Grid id="preview-body" className={props.classes.fieldRoot}>
                <TextField
                    label={locale.t(locale.keys.memberAuthManagement.orderConfirmation.label.mailBody)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    value={props.body}
                    fullWidth
                    multiline
                    rows={12}
                />
            </Grid>
            <Grid id="preview-footer" className={props.classes.footer}>
                <Button onClick={props.onClose}>{locale.t(locale.keys.action.close)}</Button>
            </Grid>
        </Grid>
    );
};

export const StyledSendAuthRequestMailPreview = withStyles(styles)(SendAuthRequestMailPreview);
