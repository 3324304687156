import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Theme, WithStyles, withStyles, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import CovasAvatar from '@/common/components/CovasAvatar';
import LoadingState from '@/common/components/ui-state/LoadingState';
import { State } from '@/common/components/AppContainerBase';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import dashboardHeader from '@/common/css/dashboardHeader';
import { showStringWithLimitByte } from '@/common/utils/webappUtil';
import * as locale from '@/common/utils/locale/locale';
import environment from '@/common/constants/environment';

const selectColor = (tenant: string) => {
    switch (tenant) {
        case "ricoh":
            return '#CC0033';
        default:
            return '#143b4e';
    }
}

const styles = (theme: Theme) =>
    dashboardHeader(theme, {
        headerBackgroundColor: selectColor(environment.tenant),
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{ code: string }> {
    onMenuOpen: () => void;
    appContainerValues?: State;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const avatarUrl = props.appContainerValues != null && props.appContainerValues.signinWorkspaceUserObject != null ? props.appContainerValues.signinWorkspaceUserObject.avatarUrl : '';
    const invitationEmail = props.appContainerValues != null && props.appContainerValues.signinWorkspaceUserObject != null ? props.appContainerValues.signinWorkspaceUserObject.invitationEmail : '';

    return (
        <>
            <AppBar position="static" className={classes.header}>
                <Toolbar variant="dense">
                    {/* SIOS Covasのロゴ */}
                    <Typography className={classes.serviceName} color="inherit">
                        <img className={classes.logo} alt="header-logo" width="100%" height="100%" src="/assets/images/logo/brand-white.svg" />
                    </Typography>

                    {props.appContainerValues && (
                        <>
                            {/* ワークスペースのロゴ画像 */}
                            <Typography className={classes.workspace} variant="h4" color="inherit">
                                <CovasAvatar
                                    alt="Workspace"
                                    size={36}
                                    seed={props.appContainerValues.signinWorkspaceObject.id}
                                    src={props.appContainerValues.signinWorkspaceObject.logoUrl}
                                    className={classes.avatar}
                                />
                                {locale.t(locale.keys.common.covasManagementSite)}:{/* ワークスペース表示名の表示文字数制限 */}
                                {showStringWithLimitByte(props.appContainerValues.signinWorkspaceObject.displayName, 80)}
                            </Typography>
                            <Typography className={classes.user} variant="h4" color="inherit" onClick={props.onMenuOpen}>
                                <CovasAvatar size={36} seed={invitationEmail} src={avatarUrl} className={classes.avatar} />
                                {/* ユーザー名の表示文字数制限 */}
                                <span className={classes.userName}>{showStringWithLimitByte(props.appContainerValues.signinWorkspaceUserObject.name, 10)}</span>
                            </Typography>
                            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={props.onMenuOpen}>
                                <MoreVert />
                            </IconButton>
                        </>
                    )}
                </Toolbar>

                {appContainer.loadingState && <LoadingState />}
            </AppBar>
        </>
    );
};

export default withStyles(styles)(withRouter(Component));
