import { default as React, Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Edit, UnfoldMore, Search, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { withStyles, WithStyles, Paper, Grid, Fab, Table, TableRow, TableCell, IconButton, TableBody, TableHead } from '@material-ui/core';
import { default as TablePagination, LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { tableStyles } from '@/admin/components/entry/css/table';
import { EntryContainer } from './EntryContainer';
import { EntrySelectedTab } from '@/admin/components/AdminAppContainer';
import locale from '@/common/utils/locale';
import { StyledTab, StyledTabs } from './Tab';
import { State as UI } from '@/common/components/hooks/useUI';
import { default as UIState } from '@/common/constants/ui';
import { useGlobalEntrySearch } from '../../hooks/useGlobalEntrySearch';
import * as schema from '@/bundles/schema/typescript/schema';

const Tabs = () => {
    const container = EntryContainer.useContainer();

    return container.ui.current === UI.Loading ? (
        <StyledTabs value={container.tab} onChange={(e: React.ChangeEvent<{}>, v: string) => container.handleTabChange(e, v)}>
            <StyledTab disabled={true} value={EntrySelectedTab.tab1} label={locale.t(locale.keys.applicationList.tab1.index)} />
            <StyledTab disabled={true} value={EntrySelectedTab.tab2} label={locale.t(locale.keys.applicationList.tab2.index)} />
            <StyledTab disabled={true} value={EntrySelectedTab.tab3} label={locale.t(locale.keys.applicationList.tab3.index)} />
            <StyledTab disabled={true} value={EntrySelectedTab.tab4} label={locale.t(locale.keys.applicationList.tab4.index)} />
            <StyledTab disabled={true} value={EntrySelectedTab.tab5} label={locale.t(locale.keys.applicationList.tab5.index)} />
        </StyledTabs>
    ) : (
        <StyledTabs value={container.tab} onChange={(e: React.ChangeEvent<{}>, v: string) => container.handleTabChange(e, v)}>
            <StyledTab disabled={false} value={EntrySelectedTab.tab1} label={locale.t(locale.keys.applicationList.tab1.index)} />
            <StyledTab disabled={false} value={EntrySelectedTab.tab2} label={locale.t(locale.keys.applicationList.tab2.index)} />
            <StyledTab disabled={false} value={EntrySelectedTab.tab3} label={locale.t(locale.keys.applicationList.tab3.index)} />
            <StyledTab disabled={false} value={EntrySelectedTab.tab4} label={locale.t(locale.keys.applicationList.tab4.index)} />
            <StyledTab disabled={false} value={EntrySelectedTab.tab5} label={locale.t(locale.keys.applicationList.tab5.index)} />
        </StyledTabs>
    );
};

interface EntryTableHeaderProps extends WithStyles<typeof tableStyles> {}
const EntryTableHeader = withStyles(tableStyles, { withTheme: true })((props: EntryTableHeaderProps) => {
    const { classes } = props;
    const container = EntryContainer.useContainer();
    let DateCell = <></>;
    switch (container.tab) {
        case 'tab4':
            DateCell = (
                <TableCell className={classes.headerCell2} variant="head" align="left" style={{ border: 'none' }}>
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.applicationList.tab4.date)}</p>
                        <Search style={{ marginBlock: 'auto' }} />
                        <IconButton onClick={container.sortByDate} disabled={container.ui.current === UI.Loading}>
                            {
                                container.sortDate === schema.V1ObjectsSort.Desc ? (
                                    <KeyboardArrowDown />
                                ): container.sortDate === schema.V1ObjectsSort.Asc ? (
                                    <KeyboardArrowUp />
                                ): (
                                    <UnfoldMore />
                                )
                            }
                        </IconButton>
                    </div>
                </TableCell>
            );
            break;
        case 'tab5':
            DateCell = (
                <TableCell className={classes.headerCell2} variant="head" align="left" style={{ border: 'none' }}>
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.applicationList.tab5.date)}</p>
                        <Search style={{ marginBlock: 'auto' }} />
                        <IconButton onClick={container.sortByDate} disabled={container.ui.current === UI.Loading}>
                            {
                                container.sortDate === schema.V1ObjectsSort.Desc ? (
                                    <KeyboardArrowDown />
                                ): container.sortDate === schema.V1ObjectsSort.Asc ? (
                                    <KeyboardArrowUp />
                                ): (
                                    <UnfoldMore />
                                )
                            }
                        </IconButton>
                    </div>
                </TableCell>
            );
            break;
        default:
            DateCell = (
                <TableCell className={classes.headerCell2} variant="head" align="left" style={{ border: 'none' }}>
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.applicationList.tab1.date)}</p>
                        <Search style={{ marginBlock: 'auto' }} />
                        <IconButton onClick={container.sortByDate} disabled={container.ui.current === UI.Loading}>
                            {
                                container.sortDate === schema.V1ObjectsSort.Desc ? (
                                    <KeyboardArrowDown />
                                ): container.sortDate === schema.V1ObjectsSort.Asc ? (
                                    <KeyboardArrowUp />
                                ): (
                                    <UnfoldMore />
                                )
                            }
                        </IconButton>
                    </div>
                </TableCell>
            );
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headerCell1} variant="head" align="left" style={{ border: 'none' }}>
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.applicationList.tab.entryNumber)}</p>
                        <Search style={{ marginBlock: 'auto' }} />
                        <IconButton onClick={container.sortByEntryNumber} disabled={container.ui.current === UI.Loading}>
                            {
                                container.sortEntryNumber === schema.V1ObjectsSort.Desc ? (
                                    <KeyboardArrowDown />
                                ): container.sortEntryNumber === schema.V1ObjectsSort.Asc ? (
                                    <KeyboardArrowUp />
                                ): (
                                    <UnfoldMore />
                                )
                            }
                        </IconButton>
                    </div>
                </TableCell>
                {DateCell}
                <TableCell className={classes.headerCell3} variant="head" align="left">
                    {locale.t(locale.keys.applicationList.tab.entryCategory)}
                </TableCell>
                <TableCell className={classes.headerCell4} variant="head" align="left">
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.applicationList.tab.manager)}</p>
                        <Search style={{ marginBlock: 'auto' }} />
                    </div>
                </TableCell>
                <TableCell className={classes.headerCell5} variant="head" align="left" style={{ border: 'none', textAlign: "center" }}>
                    {locale.t(locale.keys.invitationNewWorkspace.tab2.edit)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.headerCell1} variant="head" align="left" />
                <TableCell className={classes.headerCell2} variant="head" align="left" />
                <TableCell className={classes.headerCell3} variant="head" align="left">
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.applicationList.tab.company)}</p>
                        <Search style={{ marginBlock: 'auto' }} />
                    </div>
                </TableCell>
                <TableCell className={classes.headerCell4} variant="head" align="left">
                    <div className={classes.gridInsideHeaderCell}>
                        <p className={classes.gridInsideHeaderCellText}>{locale.t(locale.keys.applicationList.tab.mail)}</p>
                        <Search style={{ marginBlock: 'auto' }} />
                    </div>
                </TableCell>
                <TableCell className={classes.headerCell5} variant="head" align="left" />
            </TableRow>
        </TableHead>
    );
});
interface EntryTableBodyProps extends WithStyles<typeof tableStyles> {}
const EntryTableBody = withStyles(tableStyles, { withTheme: true })((props: EntryTableBodyProps) => {
    const { classes } = props;
    const container = EntryContainer.useContainer();
    const setGlobalEntrySearchState = useGlobalEntrySearch().actions.setGlobalEntrySearchState

    // グローバルステートをフォームへの入力ごとに保存することで、すべてのコンポーネントのレンダリングが実行され、パフォーマンスの低下につながる。
    // なので、画面遷移時にグローバルステートに検索結果を保存するようにする。
    const onClickLink = () => {
        setGlobalEntrySearchState({
            searchEntryNumber: container.searchState.searchEntryNumber,
            searchStartDate: container.searchState.searchStartDate,
            searchEndDate: container.searchState.searchEndDate,
            searchCompany: container.searchState.searchCompany,
            searchMail: container.searchState.searchMail,
            searchName: container.searchState.searchName,
            tab: container.tab,
            sortEntryNumber: container.sortEntryNumber,
            sortDate: container.sortDate,
            pageTab: container.pageTab,
            rowsPerPageTab: container.rowsPerPageTab,
            pageCount: container.pageCount
        });
    }
    return (
        <TableBody>
            {container.ui.current === UI.Loaded ? (
                container.rows.map((row, index) => {
                    return (
                        <Fragment key={row.id}>
                            <TableRow className={index % 2 ? classes.evenRow : classes.oddRow}>
                                <TableCell className={classes.column1} align="left" style={{ border: 'none' }}>
                                    {row.entry.entryNumber}
                                </TableCell>
                                <TableCell className={classes.column2} align="left" component="th" scope="row" style={{ border: 'none' }}>
                                    {
                                        container.tab === "tab4" ?
                                        row.entry.cancelDate:
                                        container.tab === "tab5" ?
                                        row.entry.deleteDate:
                                        row.entry.createdAt
                                    }
                                </TableCell>
                                <TableCell className={classes.column3} align="left">
                                    {row.entry.category}
                                </TableCell>
                                <TableCell className={classes.column4} align="left">
                                    {row.entry.clientLastName + row.entry.clientFirstName}
                                </TableCell>
                                <TableCell className={classes.column5} align="center" style={{ border: 'none' }}>
                                    <Link to={`/entry/detail/${row.entry.id}`} onClick={onClickLink}>
                                        <IconButton aria-label="Edit">
                                            <Edit className={classes.pencil} />
                                        </IconButton>
                                    </Link>
                                </TableCell>
                            </TableRow>
                            <TableRow className={index % 2 ? classes.evenRow : classes.oddRow}>
                                <TableCell className={classes.column1} align="left" />
                                <TableCell className={classes.column2} align="left" />
                                <TableCell className={classes.column3} align="left">
                                    {row.entry.clientCompany}
                                </TableCell>
                                <TableCell className={classes.column4} align="left">
                                    {row.entry.clientMail}
                                </TableCell>
                                <TableCell className={classes.column5} align="left" />
                            </TableRow>
                        </Fragment>
                    );
                })
            ) : (
                <TableRow />
            )}
        </TableBody>
    );
});

interface Props extends WithStyles<typeof tableStyles>, RouteComponentProps {}
const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const container = EntryContainer.useContainer();
    const [csvButtonDisabled, setCSVButtonDisabled] = useState(false)

    const onClickDownloadCSV = async() => {
        setCSVButtonDisabled(true)
        await container.downloadCSV()
        setCSVButtonDisabled(false)
    }

    let disabled = false;
    switch (container.ui.current) {
        case UI.Loading:
            disabled = true;
            break;
        default:
            disabled = false;
    }
    return (
        <Paper className={classes.root}>
            {container.ui.current === UI.Loading && <div data-testid={UIState.state.Loading} />}
            <Grid container>
                <Grid container item direction='row' justify={'flex-start'} className={classes.tableElementRoot}>
                    <Grid item style={{ textAlign: 'left' }}>
                        <Tabs />
                    </Grid>
                    <Grid item className={classes.fabGridRoot} style={{ textAlign: 'right', marginBlock: 'auto' }}>
                        <Fab disabled={disabled || csvButtonDisabled} variant="extended" onClick={() => onClickDownloadCSV()} className={classes.fab} size="large" color="secondary">
                            {locale.t(locale.keys.applicationList.csvButton)}
                        </Fab>
                    </Grid>
                </Grid>
                <Grid item className={classes.tableElementRoot}>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <EntryTableHeader />
                            <EntryTableBody />
                        </Table>
                        {disabled ? (
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={container.pageCount}
                                labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                                labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                                    <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                                )}
                                rowsPerPage={container.rowsPerPageTab}
                                page={container.pageTab}
                                backIconButtonProps={{
                                    'aria-label': locale.t(locale.keys.table.previousPage),
                                    disabled: true,
                                }}
                                nextIconButtonProps={{
                                    'aria-label': locale.t(locale.keys.table.nextPage),
                                    disabled: true,
                                }}
                                SelectProps={{ disabled: true }}
                                onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => container.handleChangePageByTab(event, p)}
                                onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => container.handleChangeRowsPerPageByTab(event)}
                            />
                        ) : (
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={container.pageCount}
                                labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                                labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                                    <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                                )}
                                rowsPerPage={container.rowsPerPageTab}
                                page={container.pageTab}
                                backIconButtonProps={{
                                    'aria-label': locale.t(locale.keys.table.previousPage),
                                }}
                                nextIconButtonProps={{
                                    'aria-label': locale.t(locale.keys.table.nextPage),
                                }}
                                onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => container.handleChangePageByTab(event, p)}
                                onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => container.handleChangeRowsPerPageByTab(event)}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default withRouter(withStyles(tableStyles)(Component));
