import { default as React, useState } from 'react';
import { Route, Switch } from 'react-router';
import '@/common/css/app.css';
import routes from '@/admin/constants/routes';
import PrivateRoute from '@/admin/components/PrivateRoute';
import Private from '@/admin/components/Private';
import Public from '@/admin/components/Public';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import CommonMessage, { Variants } from '@/common/components/messages/CommonMessage';
import Forbidden from '@/common/components/error/Forbidden';
import { agentCookieObserverAdmin } from '@/common/utils/authManagement/agentSignIn';

export const Component: React.FC = () => {
    const defaultMessData = {
        isOpen: false,
        message: '',
        variant: Variants.info,
        resultCode: undefined,
        autoHideDuration: undefined,
    };

    const [message, updateMessage] = useState(defaultMessData);
    const handleClose = () => {
        updateMessage(defaultMessData);
    };

    agentCookieObserverAdmin();

    return (
        <AdminAppContainer.Provider>
            <Switch>
                <Route path={routes.auth.index} component={Public} />
                <Route path={routes.forbidden.index}>
                    <Forbidden isAdminApp={true} />
                </Route>
                <PrivateRoute path={routes.dashboard.index} component={Private} />
            </Switch>
            <CommonMessage onClose={handleClose} isOpen={message.isOpen} variant={message.variant} message={message.message} dependAppContainer={AdminAppContainer.useContainer} />
        </AdminAppContainer.Provider>
    );
};

export default Component;
