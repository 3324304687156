import { default as full } from './full.png';
import { default as download } from './download.png';
import { default as upload } from './upload.png';
import { default as none } from './none.png';
import { default as noneEdit } from './noneedit.png';
import { default as hotprofile } from './hotprofile.png';
import { default as kintone } from './kintone.png';

interface Img {
    [key: string]: string;
}

const cloudImages: Img = {
    full,
    upload,
    download,
    none,
    hotprofile,
    kintone,
    noneEdit,
};
export default {
    cloudImages,
};
