import React, { FC, useEffect, useState } from 'react';
import { WithStyles, withStyles, Grid, Button, createStyles, FormControl, InputLabel, Select, MenuItem, ListItemText, FilledInput, TextField, Paper, Chip } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { StyledPresenter } from './Table';
import { GridSpacing } from '@material-ui/core/Grid';
import shareFormat from '@/common/constants/shareFormat';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { useGroup } from '@/common/models/groups/useGroup';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { indexXStorageStatus } from '@/common/api/workspace/x-storage-status';
import * as errorHandler from '@/common/utils/errorHandler';

const styles = () =>
    createStyles({
        root: {
            width: '100%',
            gap: '16px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    formValue: schema.V1XStoragesOrderCreateRequest;
    setFormValue: React.Dispatch<React.SetStateAction<schema.V1XStoragesOrderCreateRequest>>;
    selectedUsers: string[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    selectedGroups: string[];
    setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>;
    selectedOwnerUser: string;
    setSelectedOwnerUser: React.Dispatch<React.SetStateAction<string>>;
    externalMails: { error: string; input: string[] };
    setExternalMails: React.Dispatch<React.SetStateAction<{ error: string; input: string[] }>>;
}

/**
 * 登録先ワークスペースユーザーもしくはメールの送信先メールアドレスの選択
 * @param props
 * @returns
 */
const StepTwo: FC<Props> = (props) => {
    // リクエストで受け取ったメンバー一覧データを保存するためだけのステート
    const [reqData, setReqData] = useState<schema.V1ObjectsXStoragesStatus[]>([]);
    const [groupData, setGroupData] = useState<schema.V1WorkspaceGroupsIndexResponse>({
        count: 0,
        groups: [],
    });
    const appContainer = AdminAppContainer.useContainer();
    const group = useGroup();
    const ui = useUI(UI.Loaded);

    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };
    // 選択されているユーザの初期化
    // Step2の画面で毎回ユーザー、グループの一覧をfetchするので整合性を保つために初期化する
    const initialSelectedUsers = (selected: string[], reqData: schema.V1ObjectsXStoragesStatus[]) => {
        const initSelected: string[] = [];
        reqData.forEach((val) => {
            if (selected.indexOf(val.user.id) !== -1) {
                initSelected.push(val.user.id);
            }
        });
        // 選択されたユーザID
        return initSelected;
    };

    // 選択されているグループの初期化
    // Step2の画面で毎回ユーザー、グループの一覧をfetchするので整合性を保つために初期化する
    const initialSelectedGroups = (selected: string[], reqData: schema.V1WorkspaceGroupsShowResponse[]) => {
        const initSelected: string[] = [];
        reqData.forEach((val) => {
            if (selected.indexOf(val.id) !== -1) {
                initSelected.push(val.id);
            }
        });
        // 選択されたグループID
        return initSelected;
    };

    // 選択された登録先ユーザーの初期化
    // Step2の画面で毎回ユーザー一覧をfetchするので整合性を保つために初期化する
    const initialSelectedOwnerUser = (selected: string, reqData: schema.V1ObjectsXStoragesStatus[]) => {
        let initSelected = '';
        reqData.forEach((val) => {
            if (selected === val.user.id) {
                initSelected = val.user.id;
            }
        });
        // 選択されたユーザID
        return initSelected;
    };

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        (async () => {
            try {
                updateUIStatus(UI.Loading);
                const data = await indexXStorageStatus(appContainer.values.authorizationCode, appContainer.values.signinWorkspaceObject.id!, props.formValue.service, props.formValue.allowedDomain);

                // 選択済みユーザーをfetchされた内容に合わせて初期化
                const updatedSelectUsers = initialSelectedUsers(props.selectedUsers, data.users);
                props.setSelectedUsers([...updatedSelectUsers]);
                const updatedSelectOwnerUser = initialSelectedOwnerUser(props.selectedOwnerUser, data.users);
                props.setSelectedOwnerUser(updatedSelectOwnerUser);
                setReqData(data.users);
            } catch (e) {
                updateUIStatus(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }

            try {
                const val = await group.indexGroupData(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                setGroupData(val);
                // 選択済みグループをfetchされた内容に合わせて初期化
                const updatedSelecteGroups = initialSelectedGroups(props.selectedGroups, val.groups);
                props.setSelectedGroups([...updatedSelecteGroups]);
                updateUIStatus(UI.Loaded);
            } catch (e) {
                updateUIStatus(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.setFormValue]);
    /**
     * 次へボタンの有効化
     * @returns
     */
    const isDisableNext = () => {
        const { share, ordertype, info } = props.formValue;
        const hasError = props.externalMails.error !== '';
        const isInfoEmpty = !info || info.length === 0;

        if (share === shareFormat.personal) {
            if (ordertype === schema.V1ObjectsRegisteredUserEnum.OneUser) {
                if (hasError || isInfoEmpty || info.some((value) => !value.registeredUser)) {
                    return true;
                }
            } else {
                if (isInfoEmpty) {
                    return true;
                }
            }
        } else if (share === shareFormat.share) {
            if (hasError || isInfoEmpty) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    /**
     * 登録先ユーザータイプの選択
     * @param event
     */
    const handleRegisterdUsertype = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.setFormValue({
            ...props.formValue,
            ordertype: event.target.value as schema.V1ObjectsRegisteredUserEnum,
        });
    };

    return (
        <Grid container direction="column" id="steptwo-root" className={props.classes.root}>
            <FormControl variant="filled" style={{ margin: 0 }}>
                {!props.formValue.share && (
                    <>
                        <InputLabel htmlFor="steptwo-registeredUser" id="steptwo-registeredUser-label" shrink>
                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.register.index)}
                        </InputLabel>
                        <Select value={props.formValue.ordertype || schema.V1ObjectsRegisteredUserEnum.EachUser} onChange={handleRegisterdUsertype} input={<FilledInput id="steptwo-registeredUser" />}>
                            <MenuItem value={schema.V1ObjectsRegisteredUserEnum.EachUser}>
                                <ListItemText primary={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.ordettype.eachUser)} />
                            </MenuItem>
                            <MenuItem value={schema.V1ObjectsRegisteredUserEnum.OneUser}>
                                <ListItemText primary={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.ordettype.oneUser)} />
                            </MenuItem>
                        </Select>
                    </>
                )}
            </FormControl>

            {/* クラウドストレージ、1ユーザーのクラウドストレージに登録する */}
            {props.formValue.share === shareFormat.personal && props.formValue.ordertype === schema.V1ObjectsRegisteredUserEnum.OneUser ? (
                <Grid container direction="column" spacing={8 as GridSpacing}>
                    <Grid item>
                        <InputLabel htmlFor="steptwo-registeredUser" id="steptwo-registeredUser-label">
                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.tips.oneUser)}
                        </InputLabel>
                    </Grid>
                    <Grid item>
                        <StyledPresenter
                            formValue={props.formValue}
                            responseData={reqData}
                            setResponseData={setReqData}
                            setFormValue={props.setFormValue}
                            groupRows={groupData}
                            selectedUsers={props.selectedUsers}
                            setSelectedUsers={props.setSelectedUsers}
                            selectedGroups={props.selectedGroups}
                            setSelectedGroups={props.setSelectedGroups}
                            selectedOwnerUser={props.selectedOwnerUser}
                            setSelectedOwnerUser={props.setSelectedOwnerUser}
                            senderSelect={false}
                            externalMails={props.externalMails}
                            setExternalMails={props.setExternalMails}
                        />
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <InputLabel htmlFor="steptwo-registeredUser" id="steptwo-registeredUser-label">
                            {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.authDescription)}
                        </InputLabel>
                        <StyledPresenter
                            formValue={props.formValue}
                            responseData={reqData}
                            setResponseData={setReqData}
                            setFormValue={props.setFormValue}
                            groupRows={groupData}
                            selectedUsers={props.selectedUsers}
                            setSelectedUsers={props.setSelectedUsers}
                            selectedGroups={props.selectedGroups}
                            setSelectedGroups={props.setSelectedGroups}
                            selectedOwnerUser={props.selectedOwnerUser}
                            setSelectedOwnerUser={props.setSelectedOwnerUser}
                            senderSelect={true}
                            externalMails={props.externalMails}
                            setExternalMails={props.setExternalMails}
                        />
                    </div>
                </Grid>
            ) : (
                /* クラウドストレージ、各ユーザーのクラウドストレージに登録する */
                /* 共有クラウドストレージ */
                <Grid>
                    <InputLabel htmlFor="steptwo-registeredUser" id="steptwo-registeredUser-label">
                        {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.authDescription)}
                    </InputLabel>

                    <StyledPresenter
                        formValue={props.formValue}
                        responseData={reqData}
                        setResponseData={setReqData}
                        setFormValue={props.setFormValue}
                        groupRows={groupData}
                        selectedUsers={props.selectedUsers}
                        setSelectedUsers={props.setSelectedUsers}
                        selectedGroups={props.selectedGroups}
                        setSelectedGroups={props.setSelectedGroups}
                        selectedOwnerUser={props.selectedOwnerUser}
                        setSelectedOwnerUser={props.setSelectedOwnerUser}
                        senderSelect={true}
                        externalMails={props.externalMails}
                        setExternalMails={props.setExternalMails}
                    />
                </Grid>
            )}

            <Grid id="steptwo-footer">
                <Button onClick={() => props.setActiveStep(0)}>{locale.t(locale.keys.memberAuthManagement.orderCreate.button.back)}</Button>
                <Button variant="contained" color="secondary" style={{ color: '#fff' }} onClick={() => props.setActiveStep(2)} disabled={isDisableNext()}>
                    {locale.t(locale.keys.memberAuthManagement.orderCreate.button.next)}
                </Button>
            </Grid>
            <Grid />
        </Grid>
    );
};

export default withStyles(styles)(StepTwo);
