import { default as siosDashboard } from './siosDashboard.png';
import { default as siosDashboard2x } from './siosDashboard2x.png';
import { default as siosDashboard3x } from './siosDashboard3x.png';

interface Img {
    [key: string]: string;
}

const dashboardImg: Img = {
    siosDashboard,
    siosDashboard2x,
    siosDashboard3x,
};
export default {
    dashboardImg,
};
