import { default as React } from 'react';
import { WithStyles, Theme, createStyles, withStyles } from '@material-ui/core';
import CollectiveRegister from './CollectiveRegister';
import { RouteComponentProps } from 'react-router-dom';
import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { withRouter } from 'react-router';
import { CollectiveRegisterContainer } from './CollectiveRegisterContainer';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { getSplitNewLineString } from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{ id: string }> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.pageTitle.registrationEndpoint.index));
    const pageDescription = getSplitNewLineString(locale.t(locale.keys.pageTitle.adminHeaderDescription.registrationEndpoint)).join("\n")
    return (
        <>
            <SubHeader
                title={locale.t(locale.keys.registrationEndpoint.index)}
                pageDescription={pageDescription}
            />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    <CollectiveRegisterContainer.Provider>
                        <CollectiveRegister />
                    </CollectiveRegisterContainer.Provider>
                </div>
            </div>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
