import * as schema from '@/bundles/schema/typescript/schema';
import encoding from 'encoding-japanese';

const escapeDoubleQuote = (str: string) => {
    const escape = str.replaceAll(/"/g, '""');
    return `"${escape}"`;
};

export const convertCsv = (data: schema.V1ObjectsCollectiveInvite): string[] => {
    return [
        data.email,
        escapeDoubleQuote(data.name),
        escapeDoubleQuote(data.phoneticName),
        data.language,
        data.role,
        data.password,
        escapeDoubleQuote(data.deviceLoginUser),
        data.pin,
        data.mfpNumber.join('|'),
        data.contactEmail,
        data.group ? data.group.join('|') : '',
    ];
};

const jaHeader = ['Eメールアドレス', '名前', 'ヨミガナ', '言語', '権限', 'パスワード', '複合機のログインユーザー名', 'PIN', '機械番号', '連絡先Eメールアドレス', 'グループ'];
const jaSample = ['(記載例)user1@covas.com', 'username1', 'ユーザーイチ', 'ja', 'user', '#Ex0fPaSS', 'user1', '123456', '302A-610001|302A-610002', 'user2@covas.com', 'Group1|Group2'];
const enHeader = ['Email', 'Name', 'Phonetic', 'Language', 'Role', 'Password', 'Shared Device Login User', 'PIN', 'MFP No.', 'Contact email address', 'Group'];
const enSample = ['(Example)user1@covas.com', 'username1', 'nickname1', 'en', 'user', '#Ex0fPaSS', 'user1', '123456', '302A-610001|302A-610002', 'user2@covas.com', 'Group1|Group2'];

export const exportCsv = (data: schema.V1ObjectsCollectiveInvite[], language: schema.Language) => {
    const header = language === schema.Language.Ja ? jaHeader : enHeader;
    const sample = language === schema.Language.Ja ? jaSample : enSample;
    const csvData = [header, sample];
    data.forEach((d) => {
        csvData.push(convertCsv(d));
    });

    //作った二次元配列をCSV文字列に直す。
    let ctrlCode = '\n'; // 改行コードのデフォルトはLF
    if (window.navigator.userAgent.toLocaleLowerCase().indexOf('windows nt') !== -1) {
        ctrlCode = '\r\n'; // WindowsのときだけCR+LF
    }
    let csv_string = '';
    for (let d of csvData) {
        csv_string += d.join(',');
        csv_string += ctrlCode;
    }

    // 文字コード変換系
    const str = encoding.stringToCode(csv_string);
    const sjisString = encoding.convert(str, 'SJIS');
    const u8a = new Uint8Array(sjisString);

    //CSVのバイナリデータを作る
    let blob = new Blob([u8a], { type: 'text/csv' });

    let uri = URL.createObjectURL(blob);
    //リンクタグを作る
    let link = document.createElement('a');
    const lang = language === schema.Language.Ja ? 'ja' : 'en';
    const filename = `ImportUsers_template_${lang}.csv`;
    link.download = filename;
    link.href = uri;

    //作ったリンクタグをクリックさせる
    document.body.appendChild(link);
    link.click();

    //クリックしたら即リンクタグを消す
    document.body.removeChild(link);
};
